<div class="m-subheader">
  <div class="d-flex align-items-center">
    <div class="mr-auto">
      <h3 class="m-subheader__title">
        {{ 'hx.faq-list.title' | transloco }}
      </h3>
    </div>
    <div>
    </div>
  </div>
</div>

<div class="m-content">
  <div class="row">
    <div class="col-sm-12">
      <div class="m-portlet m-portlet--full-height">
        <div class="m-portlet__body">
          <div class="hx-filters m--margin-bottom-40">
            <div class="filter">
              <small>{{ 'hx.faq-list.search' | transloco }}</small>
              <form class="m-form" (ngSubmit)="applyFilters()">
                <div class="m-form__section m-form__section--first">
                  <div class="form-group m-form__group m--margin-top-0">
                    <div class="input-group">
                      <input type="text" required [(ngModel)]="params.query" name="query" class="form-control m-input" [placeholder]="'hx.faq-list.search' | transloco">
                      <span class="input-group-btn">
                        <button class="btn btn-outline-primary" type="submit">
                          {{ 'hx.faq-list.search' | transloco }}
                        </button>
                      </span>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>

          <div ngbAccordion>
            <div ngbAccordionItem *ngFor="let f of list">
              <h2 ngbAccordionHeader>
                <button ngbAccordionButton><div [innerHTML]="f.faq.title | uiLabel | safeHtml"></div></button>
              </h2>
              <div ngbAccordionCollapse>
                <div ngbAccordionBody>
                  <ng-template>
                    <span class="bold">{{ 'hx.faq-list.answer' | transloco }}: </span><div [innerHTML]="f.faq.answer | uiLabel | safeHtml"></div>
                    <hr class="solid m--margin-5">
                    <div class="m--margin-top-5" *ngIf="f.likePercentage > 0">
                      <span class="answer">{{ f.likePercentage }}% {{ 'hx.faq-list.answerHelpful' | transloco}}</span>
                    </div>
                    <div class="m--margin-bottom-10">
                      <span class="question">{{ 'hx.faq-list.isAnswerHelpfulForYou' | transloco}}?</span>
                      <span *ngFor="let op of f.faq.tags"><a class="tag" (click)="pressTag(op)">{{ op }}</a></span>
                    </div>
                    <div>
                      <button ngbAccordionToggle class="btn btn-sm btn-outline-primary ms-2 m--margin-right-10" (click)="vote(f.faq.id, like)">
                        <i class="la la-thumbs-up"></i>{{ 'hx.faq-list.yes' | transloco }}
                      </button>
                      <button type="button" class="btn btn-sm btn-outline-danger ms-2 m--margin-right-10" (click)="vote(f.faq.id, dislike)">
                        <i class="la la-thumbs-down"></i>{{ 'hx.faq-list.no' | transloco }}
                      </button>
                    </div>
                  </ng-template>
                </div>
              </div>
            </div>
          </div>

          <hx-loader [isLoading]="isLoading.list" [length]="list.length"></hx-loader>

          <div class="m--align-center" *ngIf="list.length && !isLoading.list && pagination.allItemCount > 0">
            <ngb-pagination [maxSize]="5" [rotate]="true" [collectionSize]="pagination.allItemCount"
                            [(page)]="params.page" [pageSize]="params.limit" (pageChange)="pageChanged($event)" class="d-flex justify-content-center mt-4">
            </ngb-pagination>
          </div>

        </div>
      </div>
    </div>
  </div>
</div>
