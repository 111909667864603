import { Component, Input } from '@angular/core';
import { Entity, OrderRowModel } from 'hx-services';

@Component({
  selector: 'hx-order-icon',
  templateUrl: './order-icon.component.html',
  styleUrls: ['./order-icon.component.css']
})
export class HxOrderIconComponent {
  @Input()
  set order(val: OrderRowModel) {
    this.model = val;
    this.isDelivery = !!val.deliveryRangeTime;
    this.isLegal =  val.clientType === Entity.LEGAL;
  }

  model!: OrderRowModel;
  isDelivery = false;
  isLegal = false;
}
