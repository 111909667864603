<hx-product-list *ngIf="!readonly"
                 [date]="date"
                 [store]="store"
                 [currency]="store.currency"
                 (productAdded)="onProductAdded($event)"></hx-product-list>

<div *ngIf="readonly" role="alert" class="l-content-width we-banner">
  <div class="we-banner__copy">{{ 'ord.order-new.text-1' | transloco }}</div>
</div>

<div class="m-widget1 m--padding-0">
  <div class="m_datatable m-datatable m-datatable--default m-datatable--loaded" style="overflow-x: auto;">
    <table class="m-datatable__table">
      <thead class="m-datatable__head">
      <tr class="m-datatable__row" style="left: 0;">
        <th class="m-datatable__cell">
          {{ 'ord.order-cart.table.head.name' | transloco }}
        </th>
        <th class="m-datatable__cell text-center">
          {{ 'ord.order-cart.table.head.weight' | transloco }}
        </th>
        <th class="m-datatable__cell text-center">
          {{ 'ord.order-cart.table.head.before' | transloco }}
        </th>
        <th class="m-datatable__cell text-center" style="width: 10rem;">
          {{ 'ord.order-cart.table.head.num' | transloco }}
        </th>
        <th class="m-datatable__cell text-center" style="width: 6rem;">
          {{ 'ord.order-cart.table.head.sum' | transloco }}
        </th>
        <th class="m-datatable__cell" style="width: 3rem;">
          #
        </th>
      </tr>
      </thead>
      <tbody class="m-datatable__body" style="">
      <ng-container *ngFor="let i = index; let cp of cart.products; trackBy: trackById">
        <tr *ngIf="(cp.cart && cp.cart.amount > 0) || cp.reserved" class="m-datatable__row">
          <td class="m-datatable__cell">
            {{ cp.productInfo.title | uiLabel }} <span *ngIf="cp.price.amount !== 1">{{ cp.price.amount }}</span>
          </td>
          <td class="m-datatable__cell text-center">
            <ng-container *ngIf="cp.productInfo.type === 'PRODUCT'">{{ cp.weight | weight}}</ng-container>
          </td>
          <td class="m-datatable__cell text-center">
            <ng-container *ngIf="cp.reservedAmount">{{cp.reservedAmount}}</ng-container>
          </td>
          <td class="m-datatable__cell text-center">
            <div class="input-group m-form__group">
              <button *ngIf="!readonly" (click)="subtractProductAmount(cp)"
                      [disabled]="cp.isLoading || !cp.isDownAmountVisible || cp.productInfo.type === delivery"
                      class="btn btn-primary btn-xs input-group-addon">
                <i class="la" [ngClass]="cp.isLoading ? 'la-spinner la-spin' : 'la-minus'"></i>
              </button>
              <label class="text-center min-width-45">{{cp.amountText}}</label>
              <button *ngIf="!readonly" (click)="addProductAmount(cp)"
                      [disabled]="cp.isLoading || cp.productInfo.type === delivery"
                      class="btn btn-primary btn-xs input-group-addon">
                <i class="la" [ngClass]="cp.isLoading ? 'la-spinner la-spin' : 'la-plus'"></i>
              </button>
            </div>
          </td>
          <td class="m-datatable__cell text-center">
            <span style="min-width: 10rem" class="m-widget1__number m--font-bold">
              {{ cp.priceValue | uiCurrency: store.currency}}
            </span>
          </td>
          <td class="m-datatable__cell">
            <button *ngIf="!readonly" (click)="removeProduct(cp)"
                    [disabled]="cp.isLoading || cp.productInfo.type === delivery" class="btn btn-outline-danger btn-xs"
                    [ngbTooltip]="'ord.order-cart.ng.deleteProduct' | transloco">
              <i class="la la-remove"></i>
            </button>
          </td>
        </tr>
      </ng-container>
      <tr class="m-datatable__row total-row">
        <td class="m-datatable__cell" colspan="3">
          {{ 'ord.order-cart.all' | transloco }}:
        </td>
        <td class="m-datatable__cell text-center">
          <strong>{{ cart.productCount }}</strong>
        </td>
        <td class="m-datatable__cell text-center" colspan="2" [ngClass]="{'m--font-accent': !discounts.length}">
          {{ cart.subTotalValue | uiCurrency: store.currency}}
        </td>
      </tr>
      <ng-container *ngIf="discounts.length && !isLoading.discounts">
        <tr class="m-datatable__row discount-row">
          <td class="m-datatable__cell" colspan="6">
            <h6>{{ 'ord.order-cart.table.body.discount' | transloco }}:</h6>
          </td>
        </tr>
        <ng-container *ngFor="let discount of discounts; let i = index">
          <tr *ngIf="!discount.removed" class="m-datatable__row" [ngClass]="{'is--disabled': !discount.enabled }">
            <td class="m-datatable__cell" colspan="4">
              {{ discount.promoTitle | uiLabel }}
              <span *ngIf="discount.priceId">- {{ discount.productTitle | uiLabel }}</span>
              ({{ discount.productAmount }})
            </td>
            <td class="m-datatable__cell text-center m--font-success">
              <!-- TODO @deedarb #ins other result types ? -->
              <span *ngIf="discount.resultType === 'PERCENT_DISCOUNT'">{{ discount.promoValue }} %</span>
              <span
                *ngIf="discount.resultType === 'MONEY_DISCOUNT'">{{ discount.value | uiCurrency: store.currency }}</span>
            </td>
            <td class="m-datatable__cell">
              <a (click)="toggleDiscount(discount)"
                 [ngClass]="{'m--disabled-discount': !discount.enabled, 'm--enabled-discount': discount.enabled}"
                 [ngbTooltip]="(discount.enabled ? 'ord.order-cart.ng.offDiscount' : 'ord.order-cart.ng.onDiscount') | transloco">
                <i class="la" [ngClass]="{'la-check-circle-o': discount.enabled, 'la-circle' : !discount.enabled}"></i>
              </a>
            </td>
          </tr>
        </ng-container>
      </ng-container>
      </tbody>
    </table>
  </div>

  <div class="row otp-confirmation">
    <div *ngIf="otp.isRequired" class="col-md-9">
      <button *ngIf="!otp.isCodeSent && !otp.isConfirmed" (click)="sendOtpCode()" type="button"
              class="btn btn-primary btn-sm">
        {{ 'ord.order-cart.btn.sendCode' | transloco }}
      </button>

      <div *ngIf="otp.isCodeSent && !otp.isConfirmed">
        <form (submit)="validateOtpCode()" class="d-inline-block">
          <input [(ngModel)]="otp.code" type="text" class="form-control d-inline-block" style="width: 90px;" name="code"
                 placeholder="XXXX" maxlength="4">

          <button type="submit" class="btn btn-sm">{{ 'ord.order-cart.btn.checkCode' | transloco }}</button>
        </form>

        <span class="m--margin-left-10" style="font-size: 14px;">
          <a (click)="sendOtpCode()">{{ 'ord.order-cart.sendAgain' | transloco }}&nbsp;</a>
          <span *ngIf="otp.resendTimeout && otp.resendTimeout > 0">
            {{ 'ord.order-cart.text-3' | transloco }} {{ otp.resendTimeout }} {{ 'ord.order-cart.text-4' | transloco }}
          </span>
        </span>
      </div>

      <span *ngIf="otp.isConfirmed" class="m--font-success">
        <i class="la la-check"></i> {{ 'ord.order-cart.confirmed' | transloco }}
      </span>
    </div>

    <div *ngIf="debtAvailable && otp.isConfirmed" class="col-md-3">
      <label for="debt" class="m--margin-right-10" style="font-weight: 400;vertical-align: middle;">
        {{ 'ord.order-cart.credit' | transloco }}:
      </label>

      <span class="m-switch m-switch--icon m-switch--outline m-switch--success ">
        <label>
          <input type="checkbox" checked="checked" id="debt" [(ngModel)]="isDebt" (ngModelChange)="onDebtChanged()">
          <span></span>
        </label>
      </span>
    </div>

    <div *ngIf="debtAvailable && !otp.isConfirmed" class="col-md-3">
      <div>
        <small>{{ 'ord.order-cart.text-1' | transloco }}</small>
      </div>
    </div>
  </div>

  <div *ngIf="discounts.length" class="m-widget1__item">
    <div class="row align-items-center m--padding-bottom-10">
      <div class="col">
        <h3 class="m-widget1__title">
          {{ 'ord.order-cart.total' | transloco }}:
        </h3>
      </div>

      <div class="col m--align-right" style="max-width: 150px;">
        <span style="width: 100px; display:inline-block;" class="text-center">
          {{ cart.productCount }}
        </span>
      </div>

      <div class="col m--align-right" style="max-width: 150px;">
        <div class="m-widget1__number m--font-accent">
          {{ cart.totalValue | uiCurrency: store.currency}}
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="isCalculateDiscountsFailed" class="m--margin-top-10">
    {{ 'ord.order-cart.text-2' | transloco }}
  </div>

  <div *ngIf="!readonly && cart.products.length > 0" class="m-widget1__item text-right">
    <button type="button" [disabled]="isLoading.discounts" class="btn btn-metal btn-sm"
            (click)="recalculateCart()">
      <ng-container *ngIf="isLoading.discounts">
        <i class="la la-spinner la-spin"></i>  {{ 'ord.order-cart.loading' | transloco }}
      </ng-container>
      <ng-container *ngIf="!isLoading.discounts">{{ 'ord.order-cart.fatal' | transloco }}</ng-container>
    </button>
  </div>
</div>

<!-- DELIVERY COMPONENT -->

<div class="form-group m-form__group is--highlight">
  <label class="m-option hc-radio">
    <span class="m-option__control">
      <span class="m-radio m-radio--brand m-radio--check-bold">
        <input [(ngModel)]="deliveryType" (ngModelChange)="onDeliveryTypeChanged()"
               type="radio" name="order_deliveryType" value="PICKUP">
        <span></span>
      </span>
    </span>
    <span class="m-option__label">
      <span class="m-option__head">
        <span class="m-option__title">
          {{ 'ord.order-delivery.pickup' | transloco }}
        </span>
      </span>
      <span class="m-option__body">
        {{ 'ord.order-delivery.clientOrder' | transloco }}
      </span>
    </span>
  </label>

  <label class="m-option hc-radio" [ngClass]="{'is--disabled': !store.hasDelivery}">
    <span class="m-option__control" *ngIf="store.hasDelivery">
      <span class="m-radio m-radio--brand m-radio--check-bold">
        <input [(ngModel)]="deliveryType" (ngModelChange)="onDeliveryTypeChanged()"
               type="radio" name="order_deliveryType" value="DELIVERY">
        <span></span>
      </span>
    </span>
    <span class="m-option__label">
      <span class="m-option__head">
        <span class="m-option__title">
          {{ 'ord.order-delivery.delivery' | transloco }}
        </span>
      </span>
      <span *ngIf="store.hasDelivery" class="m-option__body">
        {{ 'ord.order-delivery.orderCourier' | transloco }}
      </span>
      <span *ngIf="!store.hasDelivery" class="m-option__body">
        {{ 'ord.order-delivery.shopOff' | transloco }}
      </span>
    </span>
  </label>
</div>

<div *ngIf="deliveryType === 'PICKUP'" class="form-group m-form__group">
  <div class="row">
    <div class="col-md-5">
      <label class="m--font-bold m--margin-top-5">
        <span class="display-delivery">
          {{ 'ord.order-delivery.timePickup' | transloco }}:  {{pickupTime}}
        </span>
        <span *ngIf="!pickupTimes.length" class="display-delivery">
          <span ngbDropdown container="body">
            <i class="las la-ellipsis-v" ngbDropdownToggle></i>
            <span ngbDropdownMenu>
              <button ngbDropdownItem
                      (click)="reject($event, {delivery: true})">{{ 'ord.order-delivery.btn.refusal' | transloco }}</button>
            </span>
          </span>
        </span>
      </label>
      <div *ngIf="!pickupTimes.length">
        {{ 'hx.pickups.notfound' | transloco }}
      </div>
    </div>
  </div>
  <div class="row">
    <div class="display-delivery">
      <div class="col-md-12" *ngIf="pickupTimes.length">
        <span *ngFor="let timeConst of pickupTimes" [ngbTooltip]="timeConst.tooltip">
          <button class="time" [disabled]="timeConst.isDisabled"
                  [ngClass]="{'time--enabled': !timeConst.isDisabled, 'time--disabled': timeConst.isDisabled, 'time--selected': pickupTime?.startsWith(timeConst.time)}"
                  (click)="selectPickupTime(timeConst)">
            <span *ngIf="timeConst.isLoading" class="m-loader m-loader--primary m-loader--sm"
                  style="width: 30px;display: inline-block;"></span>

            <ng-container *ngIf="!timeConst.isLoading">
              <span class="time__title">
                {{ timeConst.time }}
              </span>

              <span class="time__footer">
                <span class="time__numbers">
                  <span class="time__numb" [ngClass]="{'time__numb--selected': pickupTime === timeConst.time}">
                    <span *ngIf="!timeConst.isLoading">{{ timeConst.orderCount }}</span>
                  </span>
                </span>
              </span>
            </ng-container>
          </button>
        </span>
        <span ngbDropdown container="body" style="vertical-align: middle">
            <i style="width: 1.5rem; font-size: 3rem; margin-top: 10px" class="las la-ellipsis-v" ngbDropdownToggle></i>
            <span ngbDropdownMenu>
              <span class="display-delivery" style="font-size: 12px;margin-left: 30px; margin-right: 5px">
                {{ 'ord.order-delivery.clientPickup' | transloco }}:
              </span>
              <span class="display-delivery" style="margin-right: -20px; width: 35%">
                <input type="text" [(ngModel)]="preferredPickupTime" [dropSpecialCharacters]="false"
                       class="form-control m-input" style="width: 60%" mask="Hh:m0" placeholder="09:00">
              </span>
              <span class="display-delivery" style="margin-left: -25px; width: 25%">
                <button ngbDropdownItem style="background: #2c60a9; color: white;"
                        (click)="reject($event, {pickupTime: preferredPickupTime})">{{ 'ord.btn.save' | transloco }}</button>
              </span>
            </span>
          </span>
      </div>
    </div>
  </div>
</div>

<div class="tab-pane is--highlight m--padding-top-0" [hidden]="deliveryType !== 'DELIVERY'">
  <ng-container *ngIf="cityDeliveryArea && deliveryPrices.length > 0 && stores.length > 0">
    <div class="row" *ngIf="['dgis', 'yandex', 'google'].includes(cityDeliveryArea.defaultMapType)">
      <div class="col-md-12">
        <hx-gis-address [store]="store" [stores]="stores" [cityDeliveryArea]="cityDeliveryArea"
                        [limitless]="componentName === 'manager'"
                        [(ngModel)]="deliveryAddress" (ngModelChange)="onDeliveryAddressChanged()"
                        [addresses]="addresses"></hx-gis-address>
      </div>
    </div>

    <div class="row" *ngIf="cityDeliveryArea.defaultMapType === 'none'">
      <div class="col-md-12">
        <hx-manual-address [store]="store"
                           [(ngModel)]="deliveryAddress" (ngModelChange)="onAddressInfoChanged()"
                           [addresses]="addresses"></hx-manual-address>
      </div>
    </div>
  </ng-container>

  <div class="row m--margin-top-15 manual-delivery-prices">
    <div class="col-md-12">
      <hx-loader [isLoading]="isLoading.deliveryPrices" [length]="deliveryPrices.length"></hx-loader>
      <button *ngFor="let dp of deliveryPrices"
              [disabled]="!isManualDeliverySelection"
              (click)="selectDeliveryPrice(dp)" class="btn btn-sm"
              [ngClass]="{'btn-outline-info': deliveryPriceId !== dp.id, 'btn-info': deliveryPriceId === dp.id}">
        {{ dp.value | uiCurrency: store?.currency }}
      </button>
    </div>
  </div>

  <div class="row address-info">
    <div class="col-md-2">
      <div class="form-group m-form__group">
        <label>{{ 'ord.order-delivery.entrance' | transloco }}</label>
        <input [(ngModel)]="entrance" (ngModelChange)="onAddressInfoChanged()" type="text"
               [ngbTooltip]="'ord.order-delivery.entrance' | transloco"
               class="form-control m-input">
      </div>
    </div>
    <div class="col-md-2">
      <div class="form-group m-form__group">
        <label>{{ 'ord.order-delivery.floor' | transloco }}</label>
        <input [(ngModel)]="floor" (ngModelChange)="onAddressInfoChanged()" type="text"
               [ngbTooltip]="'ord.order-delivery.floor' | transloco"
               class="form-control m-input">
      </div>
    </div>
    <div class="col-md-2">
      <div class="form-group m-form__group">
        <label>{{ 'ord.order-delivery.flat' | transloco }}</label>
        <input [(ngModel)]="flat" (ngModelChange)="onAddressInfoChanged()" type="text"
               [ngbTooltip]="'ord.order-delivery.flat' | transloco"
               class="form-control m-input">
      </div>
    </div>

    <div class="col-md-6">
      <div class="form-group m-form__group">
        <label>{{ 'ord.order-delivery.note' | transloco }}</label>
        <textarea [(ngModel)]="note" (ngModelChange)="onAddressInfoChanged()" class="form-control"
                  rows="2"></textarea>
      </div>
    </div>
  </div>

  <label class="m--font-bold m--margin-top-15">
      <span class="display-delivery">
        {{ 'courier.time.todelivery' | transloco }}:
      </span>
    <span *ngIf="!isLoading.deliveryRanges && !ranges.length" class="display-delivery">
        <span ngbDropdown container="body">
          <i class="las la-ellipsis-v" ngbDropdownToggle></i>
            <span ngbDropdownMenu>
              <button ngbDropdownItem
                      (click)="reject($event, {delivery: true})">{{ 'ord.order-delivery.refusal' | transloco }}</button>
            </span>
        </span>
      </span>
  </label>

  <div *ngIf="!isLoading.deliveryRanges && ranges.length">
    <div class="display-delivery">
      <div class="btn-group btn-toolbar m-btn-group m--margin-bottom-10" role="group">
        <div [ngbTooltip]="range.tooltip" *ngFor="let range of ranges; trackBy: trackByCell">
          <button (click)="toggleRange(range)" type="button"
                  [disabled]="range.isDisabled"
                  [ngClass]="{'btn-warning': range.id === cartRangeId, 'btn-success' : range.id === rangeId}"
                  class="btn btn-secondary btn-sm delivery-range-btn">
            <div class="display-delivery">
              <span>{{ range.fromTime | time }} - {{ range.toTime | time}}</span>
              <br>
              <span *ngIf="range.isLoading" class="m-loader m-loader--primary m-loader--sm"
                    style="width: 30px;display: inline-block;"></span>
              <span *ngIf="!range.isLoading">{{range.reserved}} / {{range.limit}}</span>
            </div>
            <div class="display-delivery" *ngIf="range.isDisabled">
              <div (click)="toggleDropdown($event)">
                <div ngbDropdown container="body">
                  <i style="width: 1rem; font-size: 2rem" class="las la-ellipsis-v" ngbDropdownToggle></i>
                  <div ngbDropdownMenu>
                    <button ngbDropdownItem
                            (click)="reject($event, {deliveryTime: range.fromTime})">{{ 'ord.order-delivery.refusal' | transloco }}</button>
                  </div>
                </div>
              </div>
            </div>
          </button>
        </div>
      </div>
    </div>
    <div class="display-delivery" style="vertical-align: middle">
      <div (click)="toggleDropdown($event)">
        <div ngbDropdown container="body">
          <i style="width: 1.5rem; font-size: 3rem" class="las la-ellipsis-v" ngbDropdownToggle></i>
          <div ngbDropdownMenu>
            <div class="display-delivery" style="font-size: 12px;margin-left: 30px; margin-right: 5px">
              {{ 'ord.order-delivery.text' | transloco }}:
            </div>
            <div class="display-delivery" style="margin-right: -20px; width: 35%">
              <input type="text" [(ngModel)]="preferredDeliveryTime" [dropSpecialCharacters]="false"
                     class="form-control m-input" style="width: 60%" mask="Hh:m0" placeholder="09:00">
            </div>
            <div class="display-delivery" style="margin-left: -25px; width: 25%">
              <button ngbDropdownItem style="background: #2c60a9; color: white;"
                      (click)="reject($event, {deliveryTime: preferredDeliveryTime})">{{ 'ord.btn.save' | transloco }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="!isLoading.deliveryRanges && !ranges.length">
    {{ 'courier.notfound' | transloco }}
  </div>

  <div *ngIf="isLoading.deliveryRanges && !ranges.length"
       class="m-page-loader m-page-loader--non-block m-page-loader--relative">
    <div class="m-loader m-loader--brand"></div>
  </div>

  <div class="hc-alert hc-alert--warning" *ngIf="showWarningAlert">
    {{ 'courier.list.warningtext' | transloco }}
  </div>
</div>
