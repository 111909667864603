import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { firstValueFrom } from 'rxjs';
import { toHttpParams } from '../utils/globals';
import { CityDeliveryMap, DeliveryZoneModel, PagedList } from '../interface';

@Injectable({
  providedIn: 'root'
})
export class HxDeliveryZoneService {
  constructor(
    private http: HttpClient,
  ) {
  }

  getStoresCoordinatesByCityId(cityId: number, brandId: number, orderDate?: string): Promise<CityDeliveryMap> {
    return firstValueFrom(this.http.get<CityDeliveryMap>(`/api/vanilla/delivery-zones/brands/${brandId}/cities/${cityId}`, {params: toHttpParams({fromDate: orderDate}, true)}));
  }

  getDeliveryZones(params: {
    page?: number;
    limit?: number;
    brandId?: number;
    cityId?: number;
    active?: boolean;
  }): Promise<PagedList<DeliveryZoneModel>> {
    return firstValueFrom(this.http.get<PagedList<DeliveryZoneModel>>(`/api/vanilla/delivery-zones`, {params: toHttpParams(params, true)}));
  }

  getZoneById(id: number): Promise<DeliveryZoneModel> {
    return firstValueFrom(this.http.get<DeliveryZoneModel>(`/api/vanilla/delivery-zones/${id}`));
  }

  removeZoneById(id: number): Promise<void> {
    return firstValueFrom(this.http.delete<void>(`/api/vanilla/delivery-zones/${id}`));
  }

  saveZone(zone: SaveZoneRequest): Promise<{ id: number }> {
    return firstValueFrom(this.http.post<{ id: number }>(`/api/vanilla/delivery-zones`, zone));
  }
}

export interface SaveZoneRequest {
  id?: number;
  brandId: number;
  cityId: number;
  zone: CityDeliveryMap;
  fromDate: string;
}
