<div class="hc-fileupload">
  <label class="custom-file">
    <input
      #files
      type="file"
      name="file"
      (change)="uploadFile($event)"
      class="custom-file-input"
      [accept]="acceptFiles"
      multiple>
    <span class="custom-file-control" data-button="Выбрать" data-select="Выберите файл ..."></span>
  </label>

  <div class="m-page-loader__wrapper" *ngIf="isLoading.upload" role="group">
    <div class="m-page-loader m-page-loader--non-block m-page-loader--relative">
      <div class="m-loader m-loader--brand"></div>
    </div>

    <button class="close" type="button" (click)="cancelPost()">
      <span>×</span>
    </button>
  </div>

  <div class="small m--margin-top-5" *ngIf="size">{{ 'hx.file-upload.size' | transloco }}: {{ size }} {{ 'hx.file-upload.mb' | transloco }}</div>
</div>
