<div *ngIf="showAddBtn" class="m-subheader">
  <div class="d-flex align-items-center">
    <div class="mr-auto">
      <h3 class="m-subheader__title">
        {{ 'hx.comment-list.comment' | transloco }}
      </h3>
    </div>

    <div>
      <a class="btn btn-success m-btn m-btn--custom m-btn--icon" (click)="showModal()">
        <span>
          <i class="la la-user-plus"></i>
          <span>
            {{ 'hx.comment-list.addComment' | transloco }}
          </span>
        </span>
      </a>
    </div>
  </div>
</div>

<div [ngClass]="{'m-content': showAddBtn}">
  <div class="row">
    <div class="col-sm-12">
      <div class="m-portlet m-portlet--full-height">
        <div class="m-portlet__body">
          <div class="table-responsive m-datatable--va-top m-datatable m-datatable--default m-datatable--loaded m--margin-top-20 m--margin-bottom-20">
            <table class="m-datatable__table" *ngIf="list.length && !isLoading.list">
              <thead class="m-datatable__head">
              <tr class="m-datatable__row m-datatable__row--head">
                <th class="m-datatable__cell">{{ 'hx.comment-list.date' | transloco }}</th>
                <th class="m-datatable__cell">{{ 'hx.comment-list.actor' | transloco }}</th>
                <th class="m-datatable__cell">{{ 'hx.comment-list.comment' | transloco }}</th>
              </tr>
              </thead>
              <tbody class="m-datatable__body">
                <tr *ngIf="isCompact" class="tr-height-40">
                  <td colspan="3" class="align-center"><a class="is--cursor-pointer" (click)="previous()">{{ 'hx.comment-list.previous' | transloco }}</a></td>
                </tr>
                <tr *ngFor="let i = index; let item of shownList; let $even=even"
                    class="m-datatable__row" [ngClass]="{ 'm-datatable__row--even': $even }">
                  <td class="m-datatable__cell">{{ item.date | date: 'dd.MM.yyyy HH:mm' }}</td>
                  <td class="m-datatable__cell">{{ item.actorFullName }}</td>
                  <td class="m-datatable__cell">{{ item.text }}</td>
                </tr>
              </tbody>
            </table>
          </div>

          <hx-loader [isLoading]="isLoading.list" [length]="list.length"></hx-loader>
        </div>
      </div>
    </div>
  </div>
</div>

