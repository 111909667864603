<div class="modal-header">
  <h3 class="modal-title" *ngIf="!id">{{ 'hx.client-edit-modal.profile' | transloco }}</h3>
  <h3 class="modal-title" *ngIf="id">{{ 'hx.client-edit-modal.changeProfile' | transloco }}</h3>

  <button class="close" type="button" (click)="modalInstance.dismiss()">
    <span>×</span>
  </button>
</div>

<div class="modal-body">
  <hx-client-edit [clientId]="id" (clientChanged)="onClientChanged($event)"></hx-client-edit>
</div>
