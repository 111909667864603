import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ErrorHandlerService } from '@callcenter-app/service/error-handler.service';
import { DateRange, FeedbackTypeRankingModel, isoDate, OperatorRankingModel, toRouterQueryParams } from 'hx-services';
import { Subscription } from 'rxjs';
import { ReportService } from '@callcenter-app/service/report.service';

@Component({
  selector: 'app-feedback-rating-report.m-grid__item.m-grid__item--fluid.m-wrapper',
  templateUrl: './feedback-rating-report.component.html',
  styleUrls: ['./feedback-rating-report.component.css']
})
export class FeedbackRatingReportComponent implements OnInit, OnDestroy {
  operatorRatingList: OperatorRankingModel[] = [];
  feedbackTypeRatingList: FeedbackTypeRankingModel[] = [];
  params: { date?: DateRange } = {};
  isLoading = {
    store: false,
    type: false
  };
  private sub!: Subscription;

  constructor(
    private reportService: ReportService,
    private aRoute: ActivatedRoute,
    private router: Router,
    private errorService: ErrorHandlerService,
  ) {
  }

  ngOnInit() {
    this.sub = this.aRoute.queryParams.subscribe(params => {
      this.params.date = {from: params['dateFrom'] ?? isoDate(new Date()), to: params['dateTo'] ?? isoDate(new Date())};
      this.getOperatorRating();
      this.getTypeRating();
    });
  }

  ngOnDestroy() {
    if (this.sub) {
      this.sub.unsubscribe();
    }
  }

  navigate() {
    this.router.navigate(['./'], {
      queryParams: toRouterQueryParams(this.params),
      relativeTo: this.aRoute
    });
  }

  private getOperatorRating() {
    this.isLoading.store = true;
    this.reportService.getOperatorRating(this.params).subscribe(response => {
      this.operatorRatingList = response;
      this.isLoading.store = false;
    }, error => {
      this.isLoading.store = false;
      this.errorService.handler(error);
    });
  }

  private getTypeRating() {
    this.isLoading.type = true;
    this.reportService.getFeedbackTypeRating(this.params).subscribe(response => {
      this.feedbackTypeRatingList = response;
      this.isLoading.type = false;
    }, error => {
      this.isLoading.type = false;
      this.errorService.handler(error);
    });
  }
}
