import { Component, HostListener, OnInit } from '@angular/core';
import { HxAuthService, OrderAction, PermissionKey } from 'hx-services';
import { Params } from '@angular/router';

interface MenuItem {
  title: string;
  isSub?: boolean;
  icon: string;
  link?: string;
  permissions?: PermissionKey[];
  queryParams?: Params;
  visible: boolean;
}

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.css']
})
export class SidebarComponent implements OnInit {
  profileUrl = `${location.protocol}//profile.${location.hostname.split('.').slice(1).join('.')}`;
  mainmenu: MenuItem[] = [
    {
      title: 'mainmenu.call-center',
      isSub: true,
      icon: 'ellipsis-h',
      visible: true
    },
    {
      title: 'mainmenu.call-center',
      link: 'call-center',
      icon: 'desktop',
      visible: true
    },
    {
      title: 'mainmenu.orders',
      link: 'orders/list',
      icon: 'list-ul',
      queryParams: {activeCart: false},
      visible: true
    },
    {
      title: 'mainmenu.control',
      isSub: true,
      icon: 'ellipsis-h',
      visible: true
    },
    {
      title: 'mainmenu.orders-archive',
      link: 'orders/archive',
      icon: 'archive',
      queryParams: {activeCart: false, actions: OrderAction.RECEIVED},
      visible: true
    },
    {
      title: 'mainmenu.calls',
      link: 'calls',
      icon: 'history',
      visible: true
    },
    {
      title: 'mainmenu.clients',
      link: 'clients/list/clients',
      icon: 'users',
      visible: true
    },
    {
      title: 'mainmenu.faq',
      link: 'faq/list',
      icon: 'bell',
      visible: true,
    },
    {
      title: 'mainmenu.users',
      link: 'users',
      permissions: [PermissionKey.callcenter_manager],
      icon: 'user-tie',
      visible: false,
    },
    {
      title: 'mainmenu.reports',
      isSub: true,
      permissions: [PermissionKey.callcenter_view_report],
      icon: 'ellipsis-h',
      visible: false
    },
    {
      title: 'mainmenu.report.calls-hours',
      link: 'report/calls-hours',
      permissions: [PermissionKey.callcenter_view_report],
      icon: 'hourglass-half',
      visible: false
    },
    {
      title: 'mainmenu.report.calls-status',
      link: 'report/calls-status',
      permissions: [PermissionKey.callcenter_view_report],
      icon: 'tty',
      visible: false
    },
    {
      title: 'mainmenu.report.calls',
      link: 'report/calls',
      permissions: [PermissionKey.callcenter_view_report],
      icon: 'phone-square',
      visible: false
    },
    {
      title: 'mainmenu.report.feedback',
      link: 'orders/feedbacks',
      permissions: [PermissionKey.callcenter_view_report],
      icon: 'comments-o',
      visible: false
    },
    {
      title: 'mainmenu.report.rejections',
      link: 'rejections',
      icon: 'comments-o',
      visible: false
    },
    {
      title: 'mainmenu.report.operator-order-stat',
      link: 'report/operator-order-stat',
      permissions: [PermissionKey.callcenter_view_report],
      icon: 'list-alt',
      visible: false
    },
    {
      title: 'mainmenu.report.feedback-rating',
      link: 'report/feedback-rating',
      permissions: [PermissionKey.callcenter_view_report],
      icon: 'line-chart',
      visible: false
    }
  ];

  constructor(
    private auth: HxAuthService,
  ) {
  }

  public menu = {
    settings: {
      click: true
    }
  };

  ngOnInit() {
    let permissions: PermissionKey[] = [];
    this.mainmenu.filter(r => r.permissions && r.permissions.length > 0).forEach(p => {
      p.permissions?.forEach(t => permissions.push(t));
    });
    this.auth.getPermissions({permissions: [...new Set(permissions)] }).subscribe(result => {
      if (result && result.length > 0) {
        this.mainmenu.filter(r => r.permissions && r.permissions.length > 0).forEach(m => {
          const filteredArray = m.permissions?.filter(v => result.map(r => r.key).includes(v)) ?? [];
          if (filteredArray.length > 0) {
            m.visible = true;
          }
        });
      }
    });
  }

  @HostListener('document:click', ['$event'])
  handleClickEvent(event: Event) {
    const body = document.getElementsByTagName('body')[0];
    const hasClass = body.className.indexOf('--on');

    if (hasClass !== -1 && (event.target as any).className.indexOf('open-sidebar') === -1) {
      this.toggleMobSidebar();
    }
  }

  toggleMobSidebar() {
    const body = document.getElementsByTagName('body')[0];
    const hasClass = body.className.indexOf('--on');

    if (hasClass !== -1) {
      body.classList.remove('m-aside-left--on');
    } else {
      body.classList.add('m-aside-left--on');
    }
  }

  routerLink(item: MenuItem): string {
    if (item.link) {
      return `/${item.link}`;
    }
    return `/${item.title}`;
  }
}
