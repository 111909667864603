import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ClientFullModel } from 'hx-services';

@Component({
  selector: 'hx-client-edit-modal.m-grid__item.m-grid__item--fluid',
  templateUrl: './client-edit.modal.html'
})
export class HxClientEditModal {

  @Input() id!: number;

  constructor(
    public modalInstance: NgbActiveModal,
  ) {
  }

  onClientChanged(res?: ClientFullModel) {
    if (res) {
      this.modalInstance.close(res);
    } else {
      this.modalInstance.dismiss();
    }
  }
}
