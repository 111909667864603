<div class="m-subheader">
  <div class="d-flex align-items-center">
    <div class="mr-auto">
      <h3 class="m-subheader__title" *ngIf="isArchive">
        {{ 'ord.order-list.archiveOrders' | transloco }}
      </h3>

      <h3 class="m-subheader__title" *ngIf="!isArchive && !isToday">
        {{ 'ord.order-list.allClose' | transloco }}
      </h3>

      <h3 class="m-subheader__title" *ngIf="!isArchive && isToday">
        {{ 'ord.order-list.today' | transloco }}
      </h3>
    </div>
    <div *ngIf="!isArchive && !isCashbox">
      <a class="btn btn-success m-btn m-btn--custom m-btn--icon" [state]="{backLink: location.path(true)}" [routerLink]="['/orders/edit']">
        <span>
          <i class="la la-cart-plus"></i>
          <span>
            {{ 'ord.order-list.newOrder' | transloco }}
          </span>
        </span>
      </a>
    </div>
  </div>
</div>

<div class="m-content">
  <div class="row">
    <div class="col-sm-12">
      <div class="m-portlet m-portlet--full-height">
        <div class="m-portlet__body">
          <div class="hx-filters">
            <div class="filter">
              <small>{{ 'ord.order-list.status' | transloco }}</small>
              <ng-select bindLabel="label" bindValue="id" [multiple]="true" [placeholder]="'ord.order-list.status' | transloco"
                         [(ngModel)]="actions" (change)="getTypeFilter()">
                <ng-option *ngFor="let op of typeOptions" [value]="op.id">{{op.label | transloco}}</ng-option>
              </ng-select>
            </div>

            <div class="filter">
              <small>{{ 'ord.order-list.search' | transloco }}</small>
              <form class="m-form" (ngSubmit)="getTypeFilter()">
                <div class="m-form__section m-form__section--first">
                  <div class="form-group m-form__group m--margin-top-0">
                    <div class="input-group">
                      <input type="text" required [(ngModel)]="query" name="query" autocomplete="off" class="form-control m-input" [placeholder]="'ord.order-list.placeholder.text-1' | transloco">

                      <span class="input-group-btn">
                        <button class="btn btn-outline" type="submit">
                          {{ 'ord.order-list.search' | transloco }}
                        </button>
                      </span>
                    </div>
                  </div>
                </div>
              </form>
            </div>

            <div class="filter">
              <small>{{ 'ord.order-list.client' | transloco }}</small>
              <hx-client-search [ngbTooltip]="'ord.order-list.client' | transloco" [clientId]="clientId" (clientChange)="onClientChanged($event)"></hx-client-search>
            </div>

            <div class="filter" *ngIf="!isToday">
              <small>{{ 'ord.order-list.data' | transloco }}</small>
              <hx-date-range-select [(ngModel)]="date" (selectChange)="getTypeFilter()"></hx-date-range-select>
            </div>

            <div class="filter">
              <small>{{ 'ord.order-list.registration' | transloco }}</small>
              <ng-select [(ngModel)]="decor" (change)="getTypeFilter()"
                         [items]="decorOptions" [placeholder]="'ord.order-list.registration' | transloco">
                <ng-template ng-label-tmp ng-option-tmp let-item="item">
                  <span>{{ ('decor.' + item) | transloco }}</span>
                </ng-template>
              </ng-select>
            </div>

            <div class="filter">
              <small>{{ 'ord.order-list.typeDelivery' | transloco }}</small>
              <ng-select [(ngModel)]="deliveryType" (change)="getTypeFilter()"
                         [items]="deliveryTypeOptions" [placeholder]="'ord.order-list.typeDelivery' | transloco">
                <ng-template ng-label-tmp ng-option-tmp let-item="item">
                  <span>{{ ('deliveryType.' + item) | transloco }}</span>
                </ng-template>
              </ng-select>
            </div>

            <div class="filter">
              <small>{{ 'ord.order-list.source' | transloco }}</small>
              <ng-select [(ngModel)]="sourceSystem" (change)="getTypeFilter()"
                         [items]="sourceSystemOptions" [placeholder]="'ord.order-list.source' | transloco">
                <ng-template ng-label-tmp ng-option-tmp let-item="item">
                  <span>{{ ('sourceSystem.' + item) | transloco }}</span>
                </ng-template>
              </ng-select>
            </div>

            <div class="filter">
              <small>{{ 'ord.order-list.fiscalSign' | transloco }}</small>
              <form class="m-form" (ngSubmit)="getTypeFilter()">
                <div class="m-form__section m-form__section--first">
                  <div class="form-group m-form__group m--margin-top-0">
                    <div class="input-group">
                      <input type="text" required [(ngModel)]="fiscalNumber" name="fiscalNumber" class="form-control m-input"
                        [placeholder]="'ord.order-list.fiscalSign' | transloco">
                      <span class="input-group-btn">
                        <button class="btn btn-outline" type="submit">
                          {{ 'ord.order-list.search' | transloco }}
                        </button>
                      </span>
                    </div>
                  </div>
                </div>
              </form>
            </div>

            <div class="filter">
              <small>{{ 'ord.order-list.brand' | transloco }}</small>
              <hx-brand-select [(ngModel)]="brandId"></hx-brand-select>
            </div>

            <div class="filter">
              <small>{{ 'ord.order-list.product' | transloco }}</small>
              <hx-product-info-select [(ngModel)]="productInfoIds" [types]="selectedPITypes" [multiple]="true" [brandId]="brandId" (selectChange)="getTypeFilter()" [disabled]="!brandId"></hx-product-info-select>
            </div>

            <ng-container *ngIf="isCallcenter">
              <div class="filter">
                <small>{{ 'ord.order-list.city' | transloco }}</small>
                <ng-select [items]="cityOptions" bindLabel="uiTitle" bindValue="id" [placeholder]="'ord.order-list.city' | transloco"
                           [(ngModel)]="cityId" (change)="onCityChanged()">
                  <ng-template ng-label-tmp let-item="item" let-clear="clear">
                    <span class="ng-value-label">{{item.title | uiLabel}}</span>
                    <span class="ng-value-icon right" (click)="clear(item)" aria-hidden="true">×</span>
                  </ng-template>
                  <ng-template ng-option-tmp let-item="item">
                    {{item.title | uiLabel}}
                  </ng-template>
                </ng-select>
              </div>
              <div class="filter">
                <small>{{ 'ord.order-list.shop' | transloco }}</small>
                <hx-store-select [disabled]="!cityId" [(ngModel)]="storeId" [cityId]="cityId" [default]="false" (selectChange)="onStoreChanged($event)"></hx-store-select>
              </div>

              <div class="filter">
                <small>{{ 'ord.order-list.ul' | transloco }}</small>
                <ng-select [(ngModel)]="clientType" (change)="getTypeFilter()"
                           [items]="entityTypeOptions" [placeholder]="'ord.order-list.placeholder.typeOrder' | transloco">
                  <ng-template ng-label-tmp ng-option-tmp let-item="item">
                    <span>{{ ('clientType.' + item) | transloco }}</span>
                  </ng-template>
                </ng-select>
              </div>

              <div class="filter">
                <small>{{ 'ord.order-list.operator' | transloco }}</small>
                <ng-select [ngbTooltip]="'ord.order-list.operator' | transloco" [(ngModel)]="operatorId" (change)="getTypeFilter()" [placeholder]="'ord.order-list.operator' | transloco" bindValue="id"
                           bindLabel="fullname">
                  <ng-option *ngFor="let op of operatorOptions" [value]="op.id">{{op.fullname}}</ng-option>
                </ng-select>
              </div>
            </ng-container>

            <ng-container *ngIf="isManager">
              <div class="filter">
                <small>{{ 'ord.order-list.shop' | transloco }}</small>
                <hx-store-select [(ngModel)]="storeId" (selectChange)="onStoreChanged($event)" [default]="false"></hx-store-select>
              </div>

              <div class="filter">
                <small>{{ 'ord.order-list.ul' | transloco }}</small>
                <ng-select [(ngModel)]="clientType" (change)="getTypeFilter()"
                           [items]="entityTypeOptions" [placeholder]="'ord.order-list.placeholder.typeClient' | transloco">
                  <ng-template ng-label-tmp ng-option-tmp let-item="item">
                    <span>{{ ('clientType.' + item) | transloco }}</span>
                  </ng-template>
                </ng-select>
              </div>
            </ng-container>

            <ng-container *ngIf="isCashbox">
              <div class="filter" *ngIf="!isToday">
                <small>{{ 'ord.order-list.courier' | transloco }}</small>
                <ng-select [ngbTooltip]="'ord.order-list.courier' | transloco" [(ngModel)]="courierId" (change)="getTypeFilter()" [placeholder]="'ord.order-list.courier' | transloco" bindValue="id"
                           bindLabel="fullname">
                  <ng-option *ngFor="let op of courierOptions" [value]="op.id">{{op.fullname}}</ng-option>
                </ng-select>
              </div>
            </ng-container>

            <div class="filter">
              <small>{{ 'ord.order-list.order' | transloco }}</small>
              <hx-checkbox [(ngModel)]="activeCart" label="filter.activeCart" (valueChange)="getTypeFilter()"></hx-checkbox>
            </div>
            <div class="filter" *ngIf="!isArchive && !isCashbox">
              <small>{{ 'ord.order-list.favorites' | transloco }}</small>
              <hx-checkbox [(ngModel)]="important" label="filter.important" [hideNo]="true" (valueChange)="getTypeFilter()"></hx-checkbox>
            </div>
          </div>

          <div class="m--margin-top-10">
            <a (click)="resetFilter()" class="btn btn-outline-primary m-btn m-btn--icon" [title]="'reset.list' | transloco">
              <span>{{ 'reset.list' | transloco }}</span>
            </a>
          </div>

          <div class="table-responsive m_datatable m-datatable--va-top m-datatable m-datatable--default m-datatable--loaded m--margin-top-20 m--margin-bottom-30">
            <table class="m-datatable__table" *ngIf="list.length && !isLoading.list">
              <thead class="m-datatable__head">
                <tr class="m-datatable__row m-datatable__row--head">
                  <th class="m-datatable__cell" *ngIf="!isArchive">
                    #
                  </th>
                  <th (click)="sortTable(column)" *ngFor="let column of columns" class="m-datatable__cell m-datatable__cell--sort text-nowrap">
                    <span>
                      {{ column.title | transloco }}
                      <i *ngIf="column.field" class="la" [ngClass]="{'la-sort-amount-desc': column.sort === 'desc', 'la-sort-amount-asc': column.sort === 'asc', 'la-sort': !column.sort}"></i>
                    </span>
                  </th>
                  <th class="m-datatable__cell">
                    &nbsp;
                  </th>
                  <th class="m-datatable__cell">
                    <span>{{ 'ord.order-list.table.head.status' | transloco }}</span>
                  </th>
                  <th class="m-datatable__cell">
                    <span>{{ 'ord.order-list.table.head.order' | transloco }}</span>
                  </th>
                  <th class="m-datatable__cell">
                    <span>{{ 'ord.order-list.table.head.sum' | transloco }}</span>
                  </th>
                  <th class="m-datatable__cell">
                    <span>{{ 'ord.order-list.table.head.client' | transloco }}</span>
                  </th>
                </tr>
              </thead>
              <tbody class="m-datatable__body">
                <tr *ngFor="let even = even; let i = index; let item of list" class="m-datatable__row"
                  [ngClass]="{ 'm-datatable__row--even': even }">
                  <td class="m-datatable__cell" *ngIf="!isArchive">
                    <span (click)="toggleToFavorite(item)" class="is--cursor-pointer" [ngStyle]="{'color': item.important ? 'orange' : ''}">
                      <i class="la" [ngClass]="{'la-star-o': !item.important, 'la-star': item.important}"></i>
                    </span>
                  </td>
                  <td class="m-datatable__cell">
                    <a *ngIf="item.routerLink" [routerLink]="item.routerLink.url" [queryParams]="item.routerLink.queryParams">
                      {{ item.id }}
                    </a>
                  </td>
                  <td class="m-datatable__cell text-nowrap">
                    <a *ngIf="item.routerLink" [routerLink]="item.routerLink.url" [queryParams]="item.routerLink.queryParams">
                      {{ item.uniqueNumber }}
                    </a>
                  </td>
                  <td *ngIf="!isCashbox" class="m-datatable__cell">{{ item.createDate | date:'dd.MM HH:mm' }}</td>
                  <td class="m-datatable__cell" *ngIf="!item.deliveryRangeTime">{{ item.date | date:'dd.MM HH:mm' }}</td>
                  <td class="m-datatable__cell" *ngIf="item.deliveryRangeTime">{{ item.date | date:'dd.MM' }} {{ item.deliveryRangeTime }}</td>
                  <td class="m-datatable__cell text-nowrap">
                    <hx-order-icon class="min-width-155" [order]="item"></hx-order-icon>
                  </td>
                  <td class="m-datatable__cell text-nowrap">
                    <span *ngIf="item.isCart" class="m-badge m-badge--warning m-badge--wide m-badge--rounded">
                      {{ 'status.CART' | transloco }}
                    </span>
                    <span *ngIf="!item.isCart" class="m-badge m-badge--{{ statusOptions.get(item.action)?.badge }} m-badge--wide m-badge--rounded">
                      {{ 'action.' + item.action | transloco }} <i *ngIf="item.action === 'CREATED' && item.lastModifyEventDate" [ngbTooltip]="'ord.order-list.last' | transloco: {date: item.lastModifyEventDate | date: 'dd.MM.yyyy HH:mm'}" class="la la-flag" style="font-size: 1rem;"></i>
                    </span>
                  </td>
                  <td class="m-datatable__cell">
                    <div *ngFor="let product of item.products">
                      <div *ngIf="item.action === 'CANCELLED' || product.status !== 'CANCELLED'" [ngClass]="{'is--red': product.status === 'CANCELLED'}">
                        {{ product.title | uiLabel }} <span *ngIf="product.status === 'CART'">({{ 'ord.order-list.basket' | transloco }}) </span> <span *ngIf="product.priceAmount !== 1">{{product.priceAmount}}</span>
                        —
                        <span>
                          <span *ngIf="product.type === 'PRODUCT'">{{ product.weight | weight }} ×</span> {{ product.amount }} {{ 'ord.order-list.num' | transloco }}
                        </span>
                      </div>
                    </div>
                  </td>
                  <td class="m-datatable__cell text-nowrap text-right" [ngClass]="{'is--red': item.isCancelled}">
                    <ng-container *ngIf="item.payment">
                      <span *ngFor="let pt of item.payment.payments">
                        <i style="vertical-align: middle;" [hxPaymentIcon]="pt.type" [ngbTooltip]="('paymentType.' + pt.type) | transloco"></i>
                      </span>
                    </ng-container>
                    {{ item.total | uiCurrency: item.currency }}
                  </td>
                  <td class="m-datatable__cell">
                    <ng-container *ngIf="item.client">
                      <a (click)="showClientModal(item.client)">{{ item.client.phone }}</a><br>
                      {{ item.client.fullname }}
                    </ng-container>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <hx-loader [isLoading]="isLoading.list" [length]="list.length"></hx-loader>

          <div class="m--align-center" *ngIf="!isLoading.list">
            <div class="btn-group btn-group-sm">
              <button (click)="seekPrev()" [disabled]="(!seek.hasNext && seek.dir === 'prev') || !seek.val" class="btn btn-sm btn-primary">
                <i class="las la-angle-double-left"></i>
              </button>
              <button (click)="seekNext()" [disabled]="!seek.hasNext && seek.dir === 'next'" class="btn btn-sm btn-primary">
                <i class="las la-angle-double-right"></i>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
