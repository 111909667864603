import { Component, NgZone, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { format } from 'date-fns';
import { BarData, DateRange, extractDateRange, isoDate, OperatorKpiModel, PieData, toRouterQueryParams } from 'hx-services';
import { Subscription } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { ReportService } from '@callcenter-app/service/report.service';

/**
 * Component for calls reposrt (graphic by operators - date range)
 *
 * @extends {BaseReportComponent}
 */
@Component({
  selector: 'app-calls-reports.m-grid__item.m-grid__item--fluid.m-wrapper',
  templateUrl: './calls-reports.component.html',
  styleUrls: ['./calls-reports.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class CallsReportsComponent implements OnInit, OnDestroy {
  data: OperatorKpiModel[] = [];
  isLoading = true;
  pieDatas: PieData[] = [];
  barDatas: BarData[] = [];
  total = 0;
  params: {
    date?: DateRange;
  } = {};
  private dates: string[] = [];
  private operatorIds: string[] = [];
  private operatorMap = new Map<string, string>();
  private sub!: Subscription;

  constructor(
    private aRoute: ActivatedRoute,
    private router: Router,
    private api: ReportService,
    private zone: NgZone,
  ) {
  }

  ngOnInit() {
    this.sub = this.aRoute.queryParamMap.subscribe(paramMap => {
      this.params.date = extractDateRange('date', paramMap) ?? {from: isoDate(Date.now()), to: isoDate(Date.now())};
      this.getList();
    });
  }

  ngOnDestroy() {
    if (this.sub) {
      this.sub.unsubscribe();
    }
  }

  browserOnly(fn: (cmp: any) => void) {
    const cmp = this;
    this.zone.runOutsideAngular(() => fn(cmp));
  }

  applyFilters() {
    this.navigate();
  }

  private navigate() {
    this.router.navigate(['./'], {
      queryParams: toRouterQueryParams(this.params),
      relativeTo: this.aRoute
    });
  }

  private getList() {
    this.isLoading = true;
    this.pieDatas = [];
    this.barDatas = [];
    this.api.getCallReport(this.params).subscribe(result => {
        this.total = 0;
        this.isLoading = false;
        if (result) {
          this.data = result;
          this.updateChart();
        }
      }, () => this.isLoading = false
    );
  }

  private updateChart(): void {
    if (this.data && this.data.length) {

      this.dates = this.data
        .filter(item => item.date)
        .map(item => item.date);

      this.dates = this.dates.filter((item, pos) => {
        return this.dates.indexOf(item) === pos;
      });

      this.dates.forEach(item => {
        this.barDatas.push({
          date: item
        });
      });

      this.operatorIds = [];
      this.operatorMap = new Map<string, string>();
      this.data.forEach((element, counter) => {
        // last object in list is common counter
        if (!element.date && counter === this.data.length - 1) {
          this.total = element.callCount;
        }
        // check is operator null exist
        const noOperator = !element.operator && counter !== this.data.length - 1;

        if (!element.date && (element.operator ||  noOperator)) {
          this.pieDatas.push({
            name: element.operator ? element.operator.fullname : 'Anonymous',
            value: element.callCount
          });
        }

        if (element.date && this.dates.length) {
          const index = this.dates.indexOf(element.date);
          if (index !== -1) {
            const operatorId = noOperator ? 'Anonymous' : element.operator.id + '';
            const operatorName = element.operator ? element.operator.fullname : 'Anonymous';
            this.barDatas[index][operatorId] = element.callCount + '';
            this.operatorMap.set(operatorId, operatorName);
            if (this.operatorIds.indexOf(operatorId) === -1) {
              this.operatorIds.push(operatorId);
            }
          }
        }
      });

      this.barDatas.sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime());
      this.barDatas.forEach(item => item.date = format(new Date(item.date), 'dd.MM'));

      if (this.pieDatas && this.pieDatas.length > 0) {
        this.createPie(this.pieDatas);
      }

      if (this.barDatas && this.barDatas.length > 0) {
        this.createBar(this.barDatas);
      }
    }
  }

  private createPie(pieDatas: PieData[]) {
    this.browserOnly(() => {
      Promise.all([
        import('@amcharts/amcharts4/core'),
        import('@amcharts/amcharts4/charts'),
        import('@amcharts/amcharts4/themes/animated').then(res => res.default)
      ]).then(([am4core, am4charts, am4themesAnimated]) => {
        am4core.useTheme(am4themesAnimated);
        am4core.options.autoDispose = true;

        const chartPie = am4core.create('chartPie', am4charts.PieChart);
        chartPie.data = pieDatas;
        chartPie.fontSize = 10;

        const pieSeries = chartPie.series.push(new am4charts.PieSeries());
        pieSeries.dataFields.value = 'value';
        pieSeries.dataFields.category = 'name';
        pieSeries.slices.template.stroke = am4core.color('#fff');
        pieSeries.slices.template.strokeWidth = 2;
        pieSeries.slices.template.strokeOpacity = 1;

        pieSeries.hiddenState.properties.opacity = 1;
        pieSeries.hiddenState.properties.endAngle = -90;
        pieSeries.hiddenState.properties.startAngle = -90;

        pieSeries.legendSettings.itemValueText = '{name} - {value}';

        chartPie.legend = new am4charts.Legend();
        chartPie.legend.position = 'right';
        chartPie.legend.fontSize = 10;
        chartPie.legend.scrollable = true;
        chartPie.legend.labels.template.text = '';

      }).catch((e) => {
        console.error('Error when creating chart', e);
      });
    });
  }

  private createBar(barDatas: BarData[]) {
    this.browserOnly(() => {
      Promise.all([
        import('@amcharts/amcharts4/core'),
        import('@amcharts/amcharts4/charts'),
        import('@amcharts/amcharts4/themes/animated').then(res => res.default)
      ]).then(([am4core, am4charts, am4themesAnimated]) => {
        am4core.useTheme(am4themesAnimated);
        am4core.options.autoDispose = true;

        const chartBar = am4core.create('barchart', am4charts.XYChart);
        chartBar.data = barDatas;
        chartBar.dateFormatter.inputDateFormat = 'dd.MM';
        chartBar.colors.step = 2;
        chartBar.fontSize = 10;

        chartBar.legend = new am4charts.Legend();
        chartBar.legend.position = 'bottom';
        chartBar.legend.paddingBottom = 20;
        chartBar.legend.labels.template.maxWidth = 95;

        const xAxis = chartBar.xAxes.push(new am4charts.CategoryAxis());
        xAxis.dataFields.category = 'date';
        xAxis.renderer.cellStartLocation = 0.1;
        xAxis.renderer.cellEndLocation = 0.9;
        xAxis.renderer.grid.template.location = 0;

        const yAxis = chartBar.yAxes.push(new am4charts.ValueAxis());
        yAxis.min = 0;

        const arrangeColumnsFn = () => {
          const series = chartBar.series.getIndex(0);

          const w = 1 - xAxis.renderer.cellStartLocation - (1 - xAxis.renderer.cellEndLocation);
          if (series && series.dataItems.length > 1) {
            const s0 = series.dataItems.getIndex(0);
            const s1 = series.dataItems.getIndex(1);
            if (!s0 || !s1) {
              return;
            }
            const x0 = xAxis.getX(s0, 'categoryX');
            const x1 = xAxis.getX(s1, 'categoryX');
            const delta = ((x1 - x0) / chartBar.series.length) * w;
            if (am4core.isNumber(delta)) {
              const middle = chartBar.series.length / 2;

              let newIndex = 0;
              chartBar.series.each(series => {
                if (!series.isHidden && !series.isHiding) {
                  series.dummyData = newIndex;
                  newIndex++;
                }
                else {
                  series.dummyData = chartBar.series.indexOf(series);
                }
              });
              const visibleCount = newIndex;
              const newMiddle = visibleCount / 2;
              chartBar.series.each(series => {
                const trueIndex = chartBar.series.indexOf(series);
                const newIndex = series.dummyData;

                const dx = (newIndex - trueIndex + middle - newMiddle) * delta;

                series.animate({ property: 'dx', to: dx }, series.interpolationDuration, series.interpolationEasing);
                series.bulletsContainer.animate({ property: 'dx', to: dx }, series.interpolationDuration, series.interpolationEasing);
              });
            }
          }
        };

        this.operatorIds.forEach(id => {
          const value = id;
          const fullname = this.operatorMap.get(value) ?? '-';
          const series = chartBar.series.push(new am4charts.ColumnSeries());
          series.dataFields.valueY = value;
          series.dataFields.categoryX = 'date';
          series.columns.template.tooltipHTML = '{valueY} - ' + fullname;
          series.name = fullname;

          series.events.on('hidden', arrangeColumnsFn);
          series.events.on('shown', arrangeColumnsFn);

          const bullet = series.bullets.push(new am4charts.LabelBullet());
          bullet.interactionsEnabled = false;
          bullet.dy = 30;
          bullet.label.text = '{valueY}';
          bullet.label.fill = am4core.color('#ffffff');
        });
      }).catch((e) => {
        console.error('Error when creating operator activity chart', e);
      });
    });
  }
}
