import { Component, EventEmitter, Inject, Input, OnChanges, OnDestroy, OnInit, Optional, Output, SimpleChanges, TrackByFunction } from '@angular/core';
import { firstValueFrom, Subject } from 'rxjs';
import {
  ComponentType,
  Currency,
  datelc,
  HX_COMPONENT_NAME,
  HxCategoryService,
  HxDatePropertyService,
  HxOrderService,
  HxProductInfoService,
  HxProductSplitDefinitionService,
  HxRejectionService,
  NotifierEventBusAddress,
  NotifierService,
  PriceShortModel,
  ProductAddedEvent,
  ProductCategoryModel,
  ProductCategoryType,
  ProductInfoType,
  ProductShortModel,
  ProductSplitDefinitionFullModel,
  RemainderProductModel, SalePriceModel,
  SaleProductModel,
  StoreBasicModel,
  toFixed
} from 'hx-services';
import { finalize, map, takeUntil, tap } from 'rxjs/operators';
import { HxToastrService } from '../../../services/toastr.service';
import { TranslocoService } from '@ngneat/transloco';

interface ProductBalanceInfo extends SaleProductModel {
  forSale?: boolean;
  free?: number;
  balance?: number;
  reservedDelivery?: number;
  reservedPickUp?: number;
  reserved?: string; // computed
  isProductDisabled?: boolean; // computed
  cart?: number;
  prices: ProductPrice[];
}

interface ProductPrice extends SalePriceModel {
  isProductDisabled?: boolean; // computed
  isCart?: boolean; // computed
  amount: number;
}

/**
 * Component of Categories with product list, click by product return {item: Object, price: Object}
 */
@Component({
  selector: 'hx-product-list',
  templateUrl: './product-list.component.html',
  styleUrls: ['./product-list.component.css']
})
export class HxProductListComponent implements OnInit, OnDestroy, OnChanges {
  @Input() currency?: Currency;
  @Input() cartProducts?: ProductShortModel[];
  @Input() date?: string;
  @Input() store!: StoreBasicModel;
  @Input() cityId?: number;
  @Output() productAdded = new EventEmitter<ProductAddedEvent>();
  @Output() splitSelected = new EventEmitter<ProductSplitDefinitionFullModel[]>();
  productInfos: ProductBalanceInfo[] = [];
  categories: ProductCategoryModel[] = [];

  isLoading = {
    category: true,
    remainder: false,
    main: true,
    decorEnabled: false,
    split: false,
  };
  isToday = false;
  selectedCategory?: ProductCategoryModel;
  isDecorEnabled = false;
  isShowDropdown = false;
  splitProductList: ProductSplitDefinitionFullModel[] = [];
  private cartMap = new Map<number, { cart: number, product: ProductShortModel }>();
  private $destroyed = new Subject<void>();

  constructor(
    private toastr: HxToastrService,
    private orderService: HxOrderService,
    private categoryService: HxCategoryService,
    private datePropertyService: HxDatePropertyService,
    private productInfoService: HxProductInfoService,
    private notifierService: NotifierService,
    private rejectionService: HxRejectionService,
    private productSplitDefinitionService: HxProductSplitDefinitionService,
    private tr: TranslocoService,
    @Optional() @Inject(HX_COMPONENT_NAME) public componentName: string
  ) {
  }

  ngOnChanges(changes: SimpleChanges): void {
    console.log('[product-list] ngOnChanges', changes);
    let init = false;
    if (changes['cartProducts']) {
      const cartProducts = changes['cartProducts'].currentValue as ProductShortModel[];
      if (cartProducts) {
        this.cartMap.clear();
        cartProducts.forEach(product => this.cartMap.set(product.productInfo.id, {cart: product.amount, product: product}));
        init = true;
      }
    }
    const handlerPairs: {storeId: number, date: string}[] = [];
    if (changes['date'] && changes['store']) {
      const date = changes['date'].currentValue as string;
      const prevDate: string | undefined = changes['date'].previousValue as string;
      const storeId = changes['store'].currentValue.id as number;
      const prevStoreId: number | undefined = changes['store'].previousValue?.id as number;
      if ((date !== prevDate || storeId !== prevStoreId) && prevDate && prevStoreId) {
        handlerPairs.push({storeId: prevStoreId, date: prevDate});
      }
    } else if (changes['date']) {
      const date = changes['date'].currentValue as string;
      const prevDate: string | undefined = changes['date'].previousValue as string;
      if (prevDate !== date && prevDate && this.store.id) {
        handlerPairs.push({storeId: this.store.id, date: prevDate});
      }
    } else if (changes['store']) {
      const storeId = changes['store'].currentValue.id as number;
      const prevStoreId: number | undefined = changes['store'].previousValue?.id as number;
      if (prevStoreId !== storeId && prevStoreId && this.date) {
        handlerPairs.push({storeId: prevStoreId, date: this.date});
      }
    }

    handlerPairs.forEach(pair => this.unregisterHandler(pair.storeId, pair.date));

    if ((changes['date'] || changes['store']) && this.store.id && this.date) {
      init = true;
      this.isLoading.decorEnabled = true;
      this.datePropertyService.getDecor({date: this.date, storeId: this.store.id}).then(result => {
        this.isDecorEnabled = result.decor.enabled;
        this.isLoading.decorEnabled = false;
      }, (err: any) => this.isLoading.decorEnabled = false);
    }
    if (init) {
      if (this.selectedCategory) {
        this.getProducts().then(() => this.loadRemainders());
      }
      this.registerHandler();
    }
  }

  ngOnInit() {
    this.getCategories().then(categories => {
      this.categories = categories.filter(cat => cat.components.includes(this.componentName as ComponentType));
      this.selectCategory(categories[0]);
    });
    this.notifierService.eventBusOpenObs.pipe(takeUntil(this.$destroyed)).subscribe(eb => {
      if (!eb) {
        return;
      }
      this.registerHandler();

      if (this.selectedCategory && this.store.id && this.date) {
        this.loadRemainders();
      }
    });

    const date = new Date(this.date ?? Date.now());
    const today = new Date();

    this.isToday = date.toDateString() === today.toDateString();

    // TODO @deedarb this.orderService.receiveAfterTimeChangeObs
    //   .pipe(
    //     takeUntil(this.$destroyed),
    //     filter(oe => oe.id === this.orderId),
    //     map(oe => oe.event),
    //     distinctUntilChanged((r1, r2) => r1.productTitle === r2.productTitle && r1.time === r2.time)
    //   );
  }

  ngOnDestroy() {
    this.unregisterHandler();
    this.$destroyed.next();
    this.$destroyed.complete();
  }

  trackById: TrackByFunction<any> = (index: number, obj: { id: number }) => obj.id;

  addToCart(event: Event, item: ProductBalanceInfo, price?: ProductPrice) {
    event.stopPropagation();
    if (item.type === ProductInfoType.DELIVERY) {
      return this.toastr.warning('hx.product-list.autoDeliveryInfo');
    }
    if (this.selectedCategory?.type === ProductCategoryType.DECORATION && !this.isDecorEnabled) {
      return this.toastr.warning('hx.product-list.decorationDisabled');
    }
    if (!price) {
      price = item.prices.find(p => p.amount === 1);
    }
    if (!item.forSale) {
      return this.toastr.warning('hx.product-list.productNotForSale');
    }

    if (this.isProductDisabled(item, price)) {
      return this.toastr.warning('hx.product-list.reserveNoFreeProduct');
    }
    if (!price) {
      throw new Error('[addToCart] price is undefined');
    }
    this.updateCart(item, price);
  }

  selectCategory(category: ProductCategoryModel) {
    if (category.id !== this.selectedCategory?.id) {
      this.selectedCategory = category;
      this.getProducts().then(() => this.loadRemainders());
    }
  }

  toggleDropdown(event: Event, productInfoId: number) {
    event.stopPropagation();
    this.isLoading.split = true;
    this.productSplitDefinitionService.getProductSplitDefinitions({
      limit: 100,
      inProductInfoId: productInfoId }
    ).subscribe({
      next: result => {
        this.isLoading.split = false;
        this.splitProductList = result.list;
      },
      error: () => this.isLoading.split = false
    });
  }

  reject(event: Event, id: number) {
    event.stopPropagation();
    this.rejectionService.prepareRejection({productInfoIds: [id]});
  }

  split(event: Event) {
    event.stopPropagation();
    this.splitSelected.emit(this.splitProductList);
  }

  private isInCart(productInfoId: number, priceAmount: number): boolean {
    const cartItem = this.cartMap.get(productInfoId);
    return this.componentName === ComponentType.cb && cartItem !== undefined && cartItem.cart >= priceAmount;
  }

  private streamHandler = (error: any, message: any) => {
    if (error) {
      console.error('error: ', error);
    } else {
      if (message.body) {
        const messageBody = message.body as { remainders: RemainderProductModel[] };
        const remainderMap: Map<number, RemainderProductModel> = new Map();
        messageBody.remainders.forEach(i => remainderMap.set(i.productInfo?.id, i));
        this.productInfos.filter(pbi => remainderMap.has(pbi.id)).forEach(pbi => {
          const productRemainder = remainderMap.get(pbi.id);
          pbi.balance = 0;
          if (productRemainder) {
            if (this.isToday) {
              if (productRemainder.balance !== undefined && productRemainder.productInfo.type !== ProductInfoType.SERVICE) {
                pbi.balance = productRemainder.balance;
              } else if (productRemainder.limit !== undefined && productRemainder.productInfo.type === ProductInfoType.SERVICE) {
                pbi.balance = productRemainder.limit;
              }
            } else {
              if (productRemainder.limit !== undefined) {
                pbi.balance = productRemainder.limit;
              }
            }
            pbi.free = toFixed(productRemainder.remainder);
            pbi.reservedDelivery = productRemainder.reservedDelivery || 0;
            pbi.reservedPickUp = productRemainder.reservedPickUp || 0;
            pbi.forSale = productRemainder.forSale;
            pbi.cart = productRemainder.cart || 0;
            this.computeProductBalanceInfo(pbi);
          }
        });
      }
    }
  };

  private computeProductBalanceInfo(pbi: ProductBalanceInfo): void {
    pbi.reserved = this.reserved(pbi);
    pbi.isProductDisabled = this.isProductDisabled(pbi);
    pbi.prices.sort((a, b) => (a.value - b.value)).forEach(price => {
      price.isProductDisabled = this.isProductDisabled(pbi, price);
      price.isCart = this.isInCart(pbi.id, price.amount);
    });
  }

  private updateCart(item: ProductBalanceInfo, price: ProductPrice) {
    const product: { id?: number, isCart: boolean} = {isCart: false};
    const cartItem = this.cartMap.get(item.id);
    if (cartItem) {
      if (price.amount === 1) {
        product.id = cartItem.product.id;
      }
      product.isCart = this.isInCart(item.id, price.amount);
      cartItem.cart -= price.amount;
    }
    item.isProductDisabled = this.isProductDisabled(item);
    item.prices.forEach(p => {
      p.isCart = this.isInCart(item.id, p.amount);
      p.isProductDisabled = this.isProductDisabled(item, p);
    });
    this.productAdded.emit({
      item: item,
      price: price,
      product: product
    });
  }

  private getCategories(): Promise<ProductCategoryModel[]> {
    this.isLoading.category = true;
    this.isLoading.main = true;

    return firstValueFrom(this.categoryService.getCategories({brandId: this.store.brandId, limit: 1000}).pipe(
      map(paged => paged.list),
      finalize(() => this.isLoading.main = false)
    ));
  }

  private getProducts(): Promise<SaleProductModel[]> {
    if (!this.selectedCategory || !this.store.id || !this.date) {
      console.error('[getProducts] at least one of params is undefined');
      return Promise.reject('params.undefined');
    }
    this.isLoading.category = true;
    this.productInfos = [];

    return firstValueFrom(this.productInfoService.getProductInfosForSale({categoryIds: [this.selectedCategory.id], storeId: this.store.id, fromDate: datelc(this.date)})
      .pipe(
        tap(productInfos => {
          if (productInfos.length) {
            this.productInfos = productInfos.map(saleProduct => {
              const pbi: ProductBalanceInfo = saleProduct;
              pbi.forSale = true;
              pbi.free = 0;
              pbi.balance = 0;
              pbi.reservedDelivery = 0;
              pbi.reservedPickUp = 0;
              pbi.cart = 0;
              this.computeProductBalanceInfo(pbi);
              return pbi;
            });
          } else {
            this.toastr.warning('hx.product-list.productListEmpty');
          }
        }),
        finalize(() => this.isLoading.category = false)
      ));
  }

  private unregisterHandler(storeId?: number, date?: string) {
    this.notifierService.unregisterHandler(this.balanceModifyEventName(storeId, date), this.streamHandler);
  }

  private balanceModifyEventName(storeId?: number, date?: string): string {
    const storeParam = storeId ?? this.store.id;
    const dateParam = date ?? this.date;
    if (storeParam && dateParam) {
      return NotifierEventBusAddress.remainder(storeParam, dateParam);
    }
    throw new Error('storeParam or dateParam is undefined');
  }

  private registerHandler() {
    this.notifierService.registerHandler(this.balanceModifyEventName(), this.streamHandler);
  }

  private reserved(item: ProductBalanceInfo): string {
    item.reservedDelivery = item.reservedDelivery || 0;
    item.reservedPickUp = item.reservedPickUp || 0;
    if (this.componentName === ComponentType.cb) {
      return `${item.reservedDelivery} + ${item.reservedPickUp}`;
    }
    return toFixed(item.reservedDelivery + item.reservedPickUp) + '';
  }

  private isProductDisabled(item: ProductBalanceInfo, price?: SalePriceModel): boolean {
    if ([ProductInfoType.PRODUCT, ProductInfoType.PROPERTY, ProductInfoType.SERVICE].includes(item.type)) {
      if (!price) {
        const sortedPrices = item.prices.sort((a, b) => a.amount - b.amount);
        price = sortedPrices.length > 0 ? sortedPrices[0] : undefined;
      }
      if (item.type === ProductInfoType.DELIVERY) {
        return true;
      }
      if (!price) {
        return false;
      }
      if (!item.forSale) {
        return true;
      }
      if (this.componentName !== ComponentType.cb && (item.free ?? 0) <= 0) {
        return true;
      }
      if (this.componentName === ComponentType.cb && this.cartMap.size > 0) {
        const cartItem = this.cartMap.get(item.id);
        if (cartItem && cartItem.cart >= price.amount) {
          return false;
        }
      }

      return (item.free ?? 0) < price.amount;
    } else {
      return true;
    }
  }

  private loadRemainders() {
    if (!this.date || !this.selectedCategory) {
      console.error('[loadRemainders] date and/or selectedCategoryId is undefined');
      this.toastr.error(this.tr.translate('hx.product-list.ts.noChoose'));
      return;
    }
    this.orderService.getRemainders({
      storeIds: [this.store.id],
      date: datelc(this.date),
      categoryIds: [this.selectedCategory.id]
    }).then(remainders => this.streamHandler(undefined, {body: {remainders: remainders}}));
  }
}
