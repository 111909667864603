import { Component, EventEmitter, forwardRef, Input, OnInit, Output } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { BankModel, HxBankService } from 'hx-services';

/**
 * Component search bank
 */
@Component({
  selector: 'hx-bank-select',
  templateUrl: './bank-select.component.html',
  styleUrls: ['./bank-select.component.css'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => HxBankSelectComponent),
    multi: true
  }]
})
export class HxBankSelectComponent implements OnInit, ControlValueAccessor {
  @Input() isDisabled = false;
  @Input() multiple = false;
  @Output() selectChange = new EventEmitter<BankModel | BankModel[] | undefined>();

  options: BankModel[] = [];
  selected: number | number[] | undefined;

  constructor(
    private bankService: HxBankService,
  ) {
  }

  ngOnInit(): void {
    this.bankService.getBankList({ limit: 1000 }).subscribe(list => {
      this.options = list.list;
    });
  }

  onModelChanged(val: any) {
    this.onChange(val);
    if (!this.multiple) {
      if (this.selected) {
        this.selectChange.emit(this.options.find(p => p.id === this.selected));
      } else {
        this.selectChange.emit();
      }
    } else {
      if (Array.isArray(this.selected)) {
        const ids: number[] = this.selected;
        this.selectChange.emit(this.options.filter(p => ids.includes(p.id)));
      } else {
        this.selectChange.emit([]);
      }
    }
  }

  onChange(val: any) {
    // console.log('onChange called', val);
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
  }

  setDisabledState(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }

  writeValue(obj?: number | number[]): void {
    this.selected = obj;
  }
}
