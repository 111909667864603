import { Routes } from '@angular/router';
import { HxClientListComponent } from './client-list/client-list.component';

export const ROUTES: Routes = [
  {
    path: 'list',
    children: [
      {
        path: 'clients',
        component: HxClientListComponent,
        pathMatch: 'full'
      }
    ]
  }
];
