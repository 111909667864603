<div class="m-subheader">
  <div class="d-flex align-items-center">
    <div class="mr-auto">
      <h3 class="m-subheader__title">
        {{ 'clientPage.title' | transloco }}
      </h3>
    </div>

    <!--
    <div *ngIf="isCallcenter">
      <a class="btn btn-success m-btn m-btn&#45;&#45;custom m-btn&#45;&#45;icon" (click)="addItem()">
        <span>
          <i class="la la-user-plus"></i>
          <span>
            Добавить клиента
          </span>
        </span>
      </a>
    </div>
    -->
  </div>
</div>

<div class="m-content">
  <!--Begin::Main Portlet-->
  <div class="row">
    <div class="col-sm-12">
      <div class="m-portlet m-portlet--full-height">
        <div class="m-portlet__body">
          <div class="m-form row">
            <div class="col-md-6">
              <form (ngSubmit)="onSubmitFilter()">
                <div class="m-form__section m-form__section--first">
                  <div class="form-group m-form__group m--margin-top-0">
                    <div class="input-group">
                      <input type="text" required [(ngModel)]="params.query" name="search" class="form-control m-input"
                             [placeholder]="'hx.client-list.placeholder.name' | transloco">

                      <span class="input-group-btn">
                      <button class="btn btn-outline" type="submit">
                        {{ 'hx.client-list.btn.search' | transloco }}
                      </button>
                    </span>
                    </div>
                  </div>
                </div>
              </form>
            </div>
            <!--<div class="col-md-1">
              <label class="m-checkbox m-checkbox&#45;&#45;text">
                <input type="checkbox" [(ngModel)]="params.vip" (ngModelChange)="applyFilters()">
                VIP
                <span></span>
              </label>
            </div>

            <div class="col-md-2">
              <label class="m-checkbox m-checkbox&#45;&#45;text">
                <input type="checkbox" [(ngModel)]="params.blacklisted" (ngModelChange)="applyFilters()">
                Черный список
                <span></span>
              </label>
            </div>-->

            <div class="col-md-3">
              <button type="button" (click)="resetFilter()" class="pull-right btn btn-outline-primary">
                {{ 'reset.list' | transloco }}
              </button>
            </div>
          </div>

          <div class="table-responsive m-datatable--va-top m-datatable m-datatable--default m-datatable--loaded m--margin-top-20 m--margin-bottom-20">
            <table class="m-datatable__table" *ngIf="list.length && !isLoading.list">
              <thead class="m-datatable__head">
              <tr class="m-datatable__row m-datatable__row--head">
                <th class="m-datatable__cell">#</th>
                <th class="m-datatable__cell">{{ 'hx.client-list.table.head.num' | transloco }}</th>
                <th class="m-datatable__cell">{{ 'hx.client-list.table.head.name' | transloco }}</th>
                <th class="m-datatable__cell">{{ 'hx.client-list.table.head.happyDay' | transloco }}</th>
                <th class="m-datatable__cell"></th>
              </tr>
              </thead>
              <tbody class="m-datatable__body">
              <tr *ngFor="let even = even; let i = index; let item of list"
                  class="m-datatable__row" [ngClass]="{ 'm-datatable__row--even': even }">
                <td class="m-datatable__cell">{{ (params.page - 1) * params.limit + (i + 1) }}</td>
                <td class="m-datatable__cell">{{ item.client.phone }}</td>
                <td class="m-datatable__cell">{{ item.client.fullname }}</td>
                <td class="m-datatable__cell">{{ item.client.birthDate | date: 'dd.MM.yyyy' }} <i *ngIf="item.birthDateUpdate" class="la la-info-circle" [ngbTooltip]="'hx.client-list.ng.time' | transloco: {date: item.birthDateUpdate | date: 'dd.MM.yyyy HH:mm:ss'}"></i></td>
                <td class="m-datatable__cell text-nowrap">
                  <button type="button" (click)="updateItem(item)"
                    class="btn btn-outline-primary btn-sm m-btn m-btn--icon m--margin-right-10" [ngbTooltip]="'hx.client-list.ng.change' | transloco">
                    <i class="la la-edit"></i>
                  </button>
                </td>
              </tr>
              </tbody>
            </table>
          </div>

          <hx-loader [isLoading]="isLoading.list" [length]="list.length"></hx-loader>

          <div class="m--align-center" *ngIf="list.length && !isLoading.list && pagination.allItemCount > 0">
            <ngb-pagination [maxSize]="5" [rotate]="true" [collectionSize]="pagination.allItemCount"
                            [(page)]="params.page" [pageSize]="params.limit"
                            (pageChange)="pageChanged($event)" class="d-flex justify-content-center mt-4">
            </ngb-pagination>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!--End::Main Portlet-->
</div>
