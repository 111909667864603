import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { HxOrderService } from 'hx-services';
import { ToastrService } from 'ngx-toastr';
import { TranslocoService } from '@ngneat/transloco';

@Component({
  selector: 'hx-comment-add',
  templateUrl: './comment-add.modal.html',
  styleUrls: ['./comment-add.modal.css']
})
export class HxModalCommentAddComponent {
  @Input() orderId!: number;
  comment?: string;
  isLoading = false;

  constructor(
    public modal: NgbActiveModal,
    private orderService: HxOrderService,
    private toastr: ToastrService,
    private tr: TranslocoService,
  ) { }

  deliverySelected() {
    this.comment = `${(this.comment ?? '')} @delivery`;
  }
  async save() {
    if (this.comment) {
      this.isLoading = true;
      try {
        await this.orderService.addComment(this.orderId, { comment: this.comment });
        this.toastr.success(this.tr.translate('hx.nt.saved'));
        this.modal.close();
      } finally {
        this.isLoading = false;
      }
    } else {
      this.toastr.error(this.tr.translate('hx.client-edit.ts.commentEmpty'));
    }
  }

  dismissModal() {
    this.modal.dismiss();
  }
}
