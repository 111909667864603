import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { ROUTES } from './hx-rejection-list.routing';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslocoModule } from '@ngneat/transloco';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HxComponentModule } from '../hx-component.module';
import { HxRejectionListComponent } from './rejection-list/rejection-list.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { HxPipeModule } from 'hx-services';

@NgModule({
  declarations: [
    HxRejectionListComponent,
  ],
  imports: [
    NgbModule,
    FormsModule,
    ReactiveFormsModule,
    CommonModule,
    TranslocoModule,
    NgSelectModule,
    HxPipeModule,
    HxComponentModule,
    RouterModule.forChild(ROUTES),
  ],
  exports: []
})
export class HxRejectionListModule {
}
