<div class="m-subheader">
  <div class="d-flex align-items-center">
    <div class="mr-auto">
      <h3 class="m-subheader__title m-subheader__title--separator">
        {{ 'ord.hx-feedback.title' | transloco }}
      </h3>
      <ul class="m-subheader__breadcrumbs m-nav m-nav--inline">
        <li class="m-nav__item m-nav__item--home">
          <a routerLink="/"  class="m-nav__link m-nav__link--icon">
            <i class="m-nav__link-icon la la-home"></i>
          </a>
        </li>
        <li class="m-nav__separator">
          -
        </li>
        <li class="m-nav__item">
          <a (click)="goBack()" class="m-nav__link">
            <span class="m-nav__link-text">
              {{ 'ord.hx-feedback.allReviews' | transloco }}
            </span>
          </a>
        </li>
        <li class="m-nav__separator">
          -
        </li>
        <li class="m-nav__item">
          <a routerLink="./" class="m-nav__link">
            <span class="m-nav__link-text">
              #{{feedback.orderNumber}}
            </span>
          </a>
        </li>
      </ul>
    </div>
  </div>
</div>

<div class="m-content">
  <div class="row">
    <div class="col-sm-12">
      <div class="m-portlet m-portlet--success m-portlet--head-solid-bg">
        <div class="row">
          <div class="col-lg-12">
            <div class="m-portlet__head">
              <div class="m-portlet__head-caption">
                <div class="m-portlet__head-title">
                  <h3 class="m-portlet__head-text">
                    {{ feedback.date | date: 'dd.MM.yyyy HH:mm' }}
                  </h3>
                </div>
              </div>

              <!--<div class="m-portlet__head-tools">
                <ul class="m-portlet__nav">
                  <li class="m-portlet__nav-item">
                    <span class="m-badge  m-badge&#45;&#45;success m-badge&#45;&#45;wide m-badge&#45;&#45;rounded">
                      {{ 'action.order.action' | transloco }}
                    </span>
                  </li>
                </ul>
              </div>-->
            </div>
          </div>
        </div>

        <div class="m-portlet__body">
          <div class="row" *ngFor="let fb of feedback.reviews">
            <div class="col-lg-12">
              <div>
                <b>{{'feedbackType.' + fb.type | transloco}} {{fullname(fb)}}</b>
                <span class="pull-right">{{fb.date | date:'dd.MM.yyyy HH:mm'}}</span>
              </div>
              <div *ngIf="fb.rating">
                <i class="la la-star" [ngClass]="{'is--good': fb.rating > 3, 'is--bad': fb.rating <= 3}" *ngFor="let ir of emptyArr(fb.rating)"></i>
              </div>
              <div *ngIf="!fb.rating">
                {{ 'ord.hx-feedback.noReviews' | transloco }}
              </div>
              <div>{{fb.text}}</div>
              <br/>
              <hr/>
            </div>
          </div>

          <div class="row m--margin-top-10 m--margin-bottom-15" *ngFor="let type of types">
            <div class="col-md-3">
              <label>{{'feedbackType.' + type | transloco}}</label>
            </div>
            <div class="col-md-9">
              <hx-rating [enabled]="true" [rating]="rating(type)" (rateChange)="onRateChanged($event, type)"></hx-rating>
            </div>
          </div>

          <div class="row">
            <div class="col-md-12">
              <h3>{{ 'ord.hx-feedback.commentClient' | transloco }}</h3>
              <pre>{{feedback.clientText}}</pre>
            </div>
          </div>

          <div class="row">
            <div class="col-md-12">
              <textarea [(ngModel)]="additionToClientText" rows="6" class="form-control"></textarea>
            </div>
          </div>

          <div class="row">
            <div class="col-lg-12">
              <label>{{ 'ord.hx-feedback.answerClient' | transloco }}</label>
              <textarea [(ngModel)]="feedback.supportText" rows="6" class="form-control"></textarea>
            </div>
          </div>
          <div class="row m--margin-top-5">
            <div class="col-lg-12">
              <label>{{ 'ord.hx-feedback.reviewNote' | transloco }}</label>
              <textarea [(ngModel)]="feedback.supportNote" rows="6" class="form-control"></textarea>
            </div>
          </div>
          <div class="row m--margin-top-10">
            <div class="col-lg-12">
              <button (click)="saveReply()" [disabled]="isSaving" class="btn btn-outline-primary"
                      [ngClass]="{'m-loader m-loader--light m-loader--primary m-loader--left': isSaving}">
                {{(isSaving ? 'ord.hx-feedback.btn.load' : 'ord.btn.save') | transloco}}
              </button>
              <span *ngIf="feedback.supportReplyDate" class="pull-right">
                {{ 'ord.hx-feedback.responseDate' | transloco }}: {{feedback.supportReplyDate | date: 'dd.MM.yyyy HH:mm'}} ({{feedback.supportFullname}})
              </span>
            </div>
          </div>
        </div>
      </div>
      <a (click)="goBack()">
        {{ 'ord.hx-feedback.back' | transloco }}
      </a>
    </div>
  </div>
</div>
