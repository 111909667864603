<form [formGroup]="companyForm" (ngSubmit)="saveCompany()" class="m--margin-left-20 m--margin-top-20">
  <div class="row m--margin-left-0">
    <p>{{'order.legalText' | transloco}}</p>
  </div>
  <div class="row">
    <div class="col-md-3">
      <label class="is--required">{{'company.title' | transloco}}</label>
      <input id="typeahead" type="text" class="form-control" formControlName="title" [ngbTypeahead]="search"
             [placeholder]="'company.title' | transloco"/>
    </div>
    <div class="col-md-3">
      <label class="is--required">{{'company.uin' | transloco}}</label>
      <input class="form-control m-input" formControlName="uin" type="text" [placeholder]="'company.uin' | transloco">
    </div>
    <div class="col-md-3">
      <label class="is--required">{{'company.address' | transloco}}</label>
      <input class="form-control m-input" formControlName="address" type="text"
             [placeholder]="'company.address' | transloco">
    </div>
    <div class="col-md-3">
      <label>{{'company.contract' | transloco}}</label>
      <input class="form-control m-input" formControlName="contract" type="text"
             [placeholder]="'company.contract' | transloco">
    </div>
  </div>
  <!--  <div class="row">-->
  <!--    -->
  <!--  </div>-->
  <div class="text-right m--margin-top-10">
    <button type="submit" [disabled]="isLoading.save || companyForm.pristine" class="btn btn-success">
      {{(companyForm.dirty ? 'button.save' : 'button.saved') | transloco}}
    </button>
  </div>
</form>
<hr>
<form [formGroup]="authorityForm" (ngSubmit)="saveAuthority()" class="m--margin-left-20 m--margin-top-20">
  <div class="row m--margin-left-0">
    <p>{{'company.letterOfAuthorityFileId' | transloco}}</p>
  </div>
  <ng-container *ngIf="infoExists; else saveCompanyMessage">
    <div class="row">
      <div class="col-md-3">
        <label class="is--required">{{'company.representativeFullname' | transloco}}</label>
        <input class="form-control m-input" formControlName="representativeFullname" type="text"
               [placeholder]="'company.representativeFullname' | transloco">
      </div>
      <div class="col-md-3">
        <label class="is--required">{{'company.letterOfAuthorityDate' | transloco}}</label>
        <hx-date-select formControlName="letterOfAuthorityDate"></hx-date-select>
      </div>
      <div class="col-md-3">
        <label class="is--required">{{'company.letterOfAuthorityNumber' | transloco}}</label>
        <input class="form-control m-input" formControlName="letterOfAuthorityNumber" type="text"
               [placeholder]="'company.letterOfAuthorityNumber' | transloco">
      </div>
    </div>
    <div class="row m--margin-top-10">
      <div class="col-md-4" *ngIf="!fileLetterList.length">
        <input hidden class="form-control m-input" formControlName="letterOfAuthorityFileId" type="text"
               [placeholder]="'company.letterOfAuthorityFileId' | transloco">
        <hx-file-upload (updateFiles)="onLetterFileUploaded($event)"
                        [size]="5"
                        [temporary]="true"
                        [containerTable]="hcOrdersRef"
                        [containerId]="orderId"
                        [fileName]="letterOfAuthority"
                        [folder]="folderLetterOfAuthority"></hx-file-upload>
      </div>
      <div class="col-md-3">
        <hx-file-list [fileList]="fileLetterList" (fileRemove)="onLetterFileRemoved($event)"></hx-file-list>
      </div>
    </div>
    <div class="text-right m--margin-top-10">
      <button type="submit" [disabled]="isLoading.save || authorityForm.pristine" class="btn btn-success">
        {{(companyForm.dirty ? 'button.save' : 'button.saved') | transloco}}
      </button>
    </div>
  </ng-container>
  <ng-template #saveCompanyMessage>
    <div class="attention">
      {{'company.saveForAuthorityForm' | transloco}}
    </div>
  </ng-template>
</form>
<hr>
