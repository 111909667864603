import { Component, OnInit } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { AsteriskCallEvent, AsteriskNotifyService } from '@callcenter-app/service/asterisk-notify.service';
import { PushNotificationsService } from '@callcenter-app/service/push-notification.service';
import { HxAuthService, NotifierService } from 'hx-services';

/**
 * Main page component
 */
@Component({
  selector: 'app-home.m-grid.m-grid--hor.m-grid--root.m-page',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {
  constructor(
    private callCenterService: AsteriskNotifyService,
    private pushNotification: PushNotificationsService,
    private tr: TranslocoService,
    private notifierService: NotifierService,
    private auth: HxAuthService,
  ) {
    tr.setDefaultLang('ru');
    tr.setActiveLang('ru');
  }

  ngOnInit() {
    this.pushNotification.requestPermission();
    const operatorId = this.auth.user.asteriskId;
    if (operatorId) {
      this.notifierService.eventBusOpenObs.subscribe(eb => {
        if (!eb) {
          return;
        }
        this.notifierService.registerHandler(`operator.${operatorId}`, (error, message) => {
          if (error) {
            console.error('error: ', error);
          } else if (message.body.clientPhone) {
            this.callCenterService.notify(message.body as AsteriskCallEvent);
          }
        });
      });
    }
  }
}
