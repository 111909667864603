<div class="row row m--padding-bottom-15">
  <div class="col-lg-3">
    <ul class="m-nav m-nav--category">
      <li class="m-nav__item" *ngFor="let category of categories; let i = index; trackBy:trackById" [ngClass]="{'m-nav__item--active': category.id === selectedCategory?.id}" >
        <a (click)="selectCategory(category)" class="m-nav__link">
          <span class="m-nav__link-title">
            <span class="m-nav__link-wrap">
              <span class="m-nav__link-text">
                {{ category.title | uiLabel }}
              </span>
            </span>
          </span>
        </a>
      </li>
    </ul>
  </div>

  <div class="col-lg-9" *ngIf="selectedCategory">
    <div class="m-widget1 m--padding-0">
      <div class="m-widget1__item">
        <div class="m--padding-bottom-10 hx-product-list-container">
          <div class="alert alert-warning" *ngIf="selectedCategory.type === 'DECORATION' && !isDecorEnabled && !isLoading.decorEnabled">
            {{ 'hx.product-list.alert' | transloco }}
          </div>
          <div class="hx-product-container" *ngFor="let pi of productInfos; trackBy:trackById">
            <div (click)="addToCart($event, pi)"
                 [ngClass]="{'is--disabled': pi.isProductDisabled }"
                 class="hx-product-container__element m-widget1__title m-widget1__title--list m--margin-bottom-10">
              <div class="m-widget1__line">
                <div>
                  {{ pi.title | uiLabel }}

                  <i class="la la-spinner la-spin" *ngIf="isLoading.remainder" style="font-size: 14px;"></i>
                </div>
                <span class="free-product">
                  {{ pi.free }}
                </span>
                <div (click)="toggleDropdown($event, pi.id)">
                  <div ngbDropdown container="body">
                    <i class="la la-ellipsis-v" ngbDropdownToggle></i>
                    <div ngbDropdownMenu>
                      <button ngbDropdownItem (click)="reject($event, pi.id)">{{ 'hx.product-list.btn.refusal' | transloco }}</button>
                      <div *ngIf="isLoading.split" class="m-loader m-loader--brand"></div>
                      <button ngbDropdownItem *ngIf="!isLoading.split && splitProductList.length > 0" (click)="split($event)">{{ 'hx.product-list.btn.divide' | transloco }}</button>
                    </div>
                  </div>
                </div>
              </div>

              <div class="m-widget1__numbers">
                <div [ngbTooltip]="(isToday ? 'Баланс' : 'Лимит') + ' | Доставка + Самовывоз | Корзина | Свободно'">
                  <span class="m--font-success">{{ pi.balance ? pi.balance : '∞' }} | </span>
                  <span class="m--font-brand">{{ pi.reserved }} | </span>
                  <span class="m--font-danger">{{ pi.cart }}</span>
                </div>
              </div>
              <div class="button-container">
                <button *ngFor="let price of pi.prices; trackBy:trackById" (click)="addToCart($event, pi, price)"
                        class="price-button" [ngClass]="{'is--disabled': price.isProductDisabled }">
                  <span><i *ngIf="price.isCart" class="m--font-warning la la-shopping-cart"></i> {{price.amount | priceLabel}} </span>
                  <br/>
                  <span class="price">{{ price.value | uiCurrency: currency }}</span>
                </button>
              </div>
            </div>
          </div>

          <div *ngIf="isLoading.category" style="margin-top: 10%;" class="m-page-loader m-page-loader--non-block m-page-loader--relative">
            <div class="m-loader m-loader--brand"></div>
          </div>
        </div>
      </div>

      <div *ngIf="!categories?.length && isLoading.main" style="margin-top: 20px;" class="m-page-loader m-page-loader--non-block m-page-loader--relative">
        <div class="m-loader m-loader--brand"></div>
      </div>
    </div>
  </div>
</div>
