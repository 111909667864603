import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ReportService } from '@callcenter-app/service/report.service';
import { DateRange, extractDateRange, isoDate, OperatorOrderStatisticModel, toRouterQueryParams } from 'hx-services';
import { Subscription } from 'rxjs';

interface OperatorOrderStatisticFullModel {
  list: OperatorOrderStatisticModel[];
  orderCreatedTotal: number;
  orderModifiedTotal: number;
  orderCancelledTotal: number;
  orderReceivedTotal: number;
}

/**
 * Отчет заказы по оператором
 */

@Component({
  selector: 'app-operator-order-stat.m-grid__item.m-grid__item--fluid.m-wrapper',
  templateUrl: './operator-order-stat.component.html',
  styleUrls: ['./operator-order-stat.component.css'],
})
export class OperatorOrderStatReportComponent implements OnInit, OnDestroy {
  reportName = 'operator-order-stat';
  isLoading = true;
  statistic?: OperatorOrderStatisticFullModel;
  params: {
    date?: DateRange;
  } = {};
  private sub!: Subscription;

  constructor(
    private aRoute: ActivatedRoute,
    private router: Router,
    private reportService: ReportService,
  ) {
  }

  ngOnInit() {
    this.sub = this.aRoute.queryParamMap.subscribe(paramMap => {
      this.params.date = extractDateRange('date', paramMap) ?? {from: isoDate(Date.now()), to: isoDate(Date.now())};
      this.loadList();
    });
  }

  ngOnDestroy() {
    if (this.sub) {
      this.sub.unsubscribe();
    }
  }

  applyFilters() {
    this.navigate();
  }

  private navigate() {
    this.router.navigate(['./'], {
      queryParams: toRouterQueryParams(this.params),
      relativeTo: this.aRoute
    });
  }

  private loadList() {
    this.statistic = {
      list: [],
      orderCancelledTotal: 0,
      orderCreatedTotal: 0,
      orderModifiedTotal: 0,
      orderReceivedTotal: 0
    };
    this.isLoading = true;
    this.reportService.getOperatorOrderStat(this.params).then(result => {
      this.isLoading = false;
      this.statistic = {
        list: result,
        orderCancelledTotal: result.filter(r => r.orderCancelledCount).reduce((a, obj) => a + obj.orderCancelledCount, 0),
        orderCreatedTotal: result.filter(r => r.orderCreatedCount).reduce((a, obj) => a + obj.orderCreatedCount, 0),
        orderModifiedTotal: result.filter(r => r.orderModifiedCount).reduce((a, obj) => a + obj.orderModifiedCount, 0),
        orderReceivedTotal: result.filter(r => r.orderReceivedCount).reduce((a, obj) => a + obj.orderReceivedCount, 0),
      };
    }, err => {
      console.error(err);
      this.isLoading = false;
    });
  }
}
