<div class="btn-group m-btn-group btn-toolbar" *ngIf="!isLoading.city && cities.length > 0" role="group">
  <button [disabled]="readonly" type="button" *ngFor="let city of cities"
    class="btn text-left"
    [ngClass]="{'btn-success': id === city.id, 'btn-default': id !== city.id}"
    (click)="onCitySelected(city)">
    <span class="m--margin-0">{{ city.title | uiLabel }}</span>
  </button>
</div>

<div class="btn-group m-btn-group btn-toolbar w-50" *ngIf="isLoading.city" role="group">
  <div class="m-page-loader m-page-loader--non-block m-page-loader--relative">
    <div class="m-loader m-loader--brand"></div>
  </div>
</div>
