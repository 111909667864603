/**
 * Vanilla API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type AppEventType = 'BALANCE_USED_ADDED' | 'BALANCE_USED_SUBTRACTED' | 'LIMIT_ADDED' | 'LIMIT_SUBTRACTED' | 'PRODUCT_REFUNDED' | 'BALANCE_TOTAL_ADDED' | 'BALANCE_TOTAL_SUBTRACTED' | 'SMS_NOTIFICATION_CREATED' | 'PUSH_NOTIFICATION_CREATED' | 'WHATSAPP_NOTIFICATION_CREATED' | 'FISCALIZATION_REFUND' | 'FISCALIZATION_SELL' | 'PAYMENT_STARTED' | 'ORDER_REFUNDED' | 'ORDER_RECEIVED' | 'ORDER_PAID' | 'ORDER_CANCELLED' | 'ORDER_INITED' | 'ORDER_CREATED' | 'ORDER_MODIFIED' | 'ORDER_PACKED' | 'ORDER_DECORED' | 'ORDER_TAKEN' | 'ORDER_SHIPPED' | 'ORDER_RETURNED' | 'ORDER_ARCHIVED' | 'ORDER_COMPLETED' | 'ORDER_MODIFYING' | 'ORDER_UNARCHIVED' | 'ORDER_CURBSIDE_PICKUP' | 'ORDER_CURBSIDE_PICKUP_SEEN' | 'PAY_INVOICE_SENT' | 'COIN_EARNED' | 'INVOICE_DISCARDED' | 'INVOICE_NEW' | 'INVOICE_REJECTED' | 'INVOICE_SENT' | 'INVOICE_CANCELLED' | 'INVOICE_ACCEPTED' | 'INVOICE_PAID' | 'INVOICE_PARTIAL_PAID' | 'INVOICE_INVENTORY' | 'PRODUCT_IN_PROGRESS' | 'PRODUCT_READY' | 'CASHBOX_SHIFT_CLOSED' | 'COMPANY_INFO_SAVED' | 'PAYMENT_INVOICE_GENERATED' | 'PRODUCT_INVOICE_GENERATED' | 'SERVICE_INVOICE_GENERATED' | 'PAYMENT_APPROVAL_CREATED' | 'PAYMENT_APPROVAL_MODIFIED' | 'PAYMENT_APPROVAL_CANCELLED' | 'PAYMENT_APPROVAL_SUCCEED' | 'ORDER_TRANSFERRED' | 'PAYMENT_APPROVAL_WAIT_PAYMENT';

export const AppEventType = {
    BALANCE_USED_ADDED: 'BALANCE_USED_ADDED' as AppEventType,
    BALANCE_USED_SUBTRACTED: 'BALANCE_USED_SUBTRACTED' as AppEventType,
    LIMIT_ADDED: 'LIMIT_ADDED' as AppEventType,
    LIMIT_SUBTRACTED: 'LIMIT_SUBTRACTED' as AppEventType,
    PRODUCT_REFUNDED: 'PRODUCT_REFUNDED' as AppEventType,
    BALANCE_TOTAL_ADDED: 'BALANCE_TOTAL_ADDED' as AppEventType,
    BALANCE_TOTAL_SUBTRACTED: 'BALANCE_TOTAL_SUBTRACTED' as AppEventType,
    SMS_NOTIFICATION_CREATED: 'SMS_NOTIFICATION_CREATED' as AppEventType,
    PUSH_NOTIFICATION_CREATED: 'PUSH_NOTIFICATION_CREATED' as AppEventType,
    WHATSAPP_NOTIFICATION_CREATED: 'WHATSAPP_NOTIFICATION_CREATED' as AppEventType,
    FISCALIZATION_REFUND: 'FISCALIZATION_REFUND' as AppEventType,
    FISCALIZATION_SELL: 'FISCALIZATION_SELL' as AppEventType,
    PAYMENT_STARTED: 'PAYMENT_STARTED' as AppEventType,
    ORDER_REFUNDED: 'ORDER_REFUNDED' as AppEventType,
    ORDER_RECEIVED: 'ORDER_RECEIVED' as AppEventType,
    ORDER_PAID: 'ORDER_PAID' as AppEventType,
    ORDER_CANCELLED: 'ORDER_CANCELLED' as AppEventType,
    ORDER_INITED: 'ORDER_INITED' as AppEventType,
    ORDER_CREATED: 'ORDER_CREATED' as AppEventType,
    ORDER_MODIFIED: 'ORDER_MODIFIED' as AppEventType,
    ORDER_PACKED: 'ORDER_PACKED' as AppEventType,
    ORDER_DECORED: 'ORDER_DECORED' as AppEventType,
    ORDER_TAKEN: 'ORDER_TAKEN' as AppEventType,
    ORDER_SHIPPED: 'ORDER_SHIPPED' as AppEventType,
    ORDER_RETURNED: 'ORDER_RETURNED' as AppEventType,
    ORDER_ARCHIVED: 'ORDER_ARCHIVED' as AppEventType,
    ORDER_COMPLETED: 'ORDER_COMPLETED' as AppEventType,
    ORDER_MODIFYING: 'ORDER_MODIFYING' as AppEventType,
    ORDER_UNARCHIVED: 'ORDER_UNARCHIVED' as AppEventType,
    ORDER_CURBSIDE_PICKUP: 'ORDER_CURBSIDE_PICKUP' as AppEventType,
    ORDER_CURBSIDE_PICKUP_SEEN: 'ORDER_CURBSIDE_PICKUP_SEEN' as AppEventType,
    PAY_INVOICE_SENT: 'PAY_INVOICE_SENT' as AppEventType,
    COIN_EARNED: 'COIN_EARNED' as AppEventType,
    INVOICE_DISCARDED: 'INVOICE_DISCARDED' as AppEventType,
    INVOICE_NEW: 'INVOICE_NEW' as AppEventType,
    INVOICE_REJECTED: 'INVOICE_REJECTED' as AppEventType,
    INVOICE_SENT: 'INVOICE_SENT' as AppEventType,
    INVOICE_CANCELLED: 'INVOICE_CANCELLED' as AppEventType,
    INVOICE_ACCEPTED: 'INVOICE_ACCEPTED' as AppEventType,
    INVOICE_PAID: 'INVOICE_PAID' as AppEventType,
    INVOICE_PARTIAL_PAID: 'INVOICE_PARTIAL_PAID' as AppEventType,
    INVOICE_INVENTORY: 'INVOICE_INVENTORY' as AppEventType,
    PRODUCT_IN_PROGRESS: 'PRODUCT_IN_PROGRESS' as AppEventType,
    PRODUCT_READY: 'PRODUCT_READY' as AppEventType,
    CASHBOX_SHIFT_CLOSED: 'CASHBOX_SHIFT_CLOSED' as AppEventType,
    COMPANY_INFO_SAVED: 'COMPANY_INFO_SAVED' as AppEventType,
    PAYMENT_INVOICE_GENERATED: 'PAYMENT_INVOICE_GENERATED' as AppEventType,
    PRODUCT_INVOICE_GENERATED: 'PRODUCT_INVOICE_GENERATED' as AppEventType,
    SERVICE_INVOICE_GENERATED: 'SERVICE_INVOICE_GENERATED' as AppEventType,
    PAYMENT_APPROVAL_CREATED: 'PAYMENT_APPROVAL_CREATED' as AppEventType,
    PAYMENT_APPROVAL_MODIFIED: 'PAYMENT_APPROVAL_MODIFIED' as AppEventType,
    PAYMENT_APPROVAL_CANCELLED: 'PAYMENT_APPROVAL_CANCELLED' as AppEventType,
    PAYMENT_APPROVAL_SUCCEED: 'PAYMENT_APPROVAL_SUCCEED' as AppEventType,
    ORDER_TRANSFERRED: 'ORDER_TRANSFERRED' as AppEventType,
    PAYMENT_APPROVAL_WAIT_PAYMENT: 'PAYMENT_APPROVAL_WAIT_PAYMENT' as AppEventType
};

