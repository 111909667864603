import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { NgSelectModule } from '@ng-select/ng-select';
import { TranslocoModule, TranslocoService } from '@ngneat/transloco';
import { HxComponentModule } from 'hx-component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { HxOrderConfiguratorComponent } from './order-configurator/order-configurator.component';
import { HxCitySelectorComponent } from './city-selector/city-selector.component';
import { HxOrderNewComponent } from './order-new/order-new.component';
import { HxOrderEditComponent } from './order-edit/order-edit.component';
import { RouterModule } from '@angular/router';
import { ROUTES } from './hx-order.routing';
import { HxAddCallModalComponent } from './order-new/modal/add-call.component';
import { HxOrderListComponent } from './order-list/order-list.component';
import { HxOrderIconComponent } from './order-icon/order-icon.component';
import { HxPipeModule } from 'hx-services';
import { HxOrderCartComponent } from './order-cart/order-cart.component';
import { HxPromoCodeComponent } from './promo-code/promo-code.component';
import { NgxMaskDirective, NgxMaskPipe, provideEnvironmentNgxMask } from 'ngx-mask';
import { HxOrderInvoiceDocComponent } from './order-invoice-doc/order-invoice-doc.component';
import { HxManagersInfoComponent } from './managers-info/managers-info.component';
import { HxOrderInfoComponent } from './order-info/order-info.component';
import { HxFeedbackListComponent } from './feedback-list/hx-feedback-list.component';
import { HxFeedbackEditComponent } from './feedback-edit/hx-feedback-edit.component';
import { HxFeedbackComponent } from './feedback/hx-feedback.component';
import { HxOrderEventListComponent } from './order-event-list/order-event-list.component';
import { HxEventDiffModalComponent } from './order-event-list/event-diff/event-diff.component';
import { HxOrderRefundedListComponent } from './order-refunded-list/order-refunded-list.component';
// @ts-ignore
import * as ru from './i18n/ru.json';
// @ts-ignore
import * as en from './i18n/en.json';

@NgModule({
  declarations: [
    HxOrderConfiguratorComponent,
    HxCitySelectorComponent,
    HxOrderNewComponent,
    HxOrderEditComponent,
    HxOrderInvoiceDocComponent,
    HxAddCallModalComponent,
    HxOrderListComponent,
    HxOrderIconComponent,
    HxOrderCartComponent,
    HxPromoCodeComponent,
    HxManagersInfoComponent,
    HxOrderInfoComponent,
    HxFeedbackListComponent,
    HxFeedbackEditComponent,
    HxFeedbackComponent,
    HxOrderEventListComponent,
    HxEventDiffModalComponent,
    HxOrderRefundedListComponent,
  ],
  imports: [
    NgbModule,
    FormsModule,
    ReactiveFormsModule,
    CommonModule,
    NgSelectModule,
    TranslocoModule,
    HxPipeModule,
    HxComponentModule,
    NgxMaskPipe,
    NgxMaskDirective,
    RouterModule.forChild(ROUTES),
    HxComponentModule,
  ],
  exports: [
    HxOrderEditComponent,
    HxOrderNewComponent,
    HxOrderIconComponent,
    HxOrderInvoiceDocComponent,
    HxPromoCodeComponent,
    HxManagersInfoComponent,
    HxOrderInfoComponent,
    HxFeedbackListComponent,
  ],
  providers: [
    provideEnvironmentNgxMask(),
  ]
})
export class HxOrderModule {
  constructor(private tr: TranslocoService) {
    this.tr.setTranslation(ru, 'ru', {merge: true});
    this.tr.setTranslation(en, 'en', {merge: true});
  }
}

export * from './order-edit/order-edit.component';
export * from './order-new/order-new.component';
export * from './order-icon/order-icon.component';
export * from './promo-code/promo-code.component';
export * from './order-invoice-doc/order-invoice-doc.component';
export * from './managers-info/managers-info.component';
export * from './order-info/order-info.component';
export * from './feedback-list/hx-feedback-list.component';
