import { Component, Inject, OnDestroy, OnInit, Optional } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import {
  ActorType,
  CityModel,
  ClientBasicModel,
  ClientShortModel,
  ComponentType,
  DeliveryType,
  DateRange,
  Entity,
  extractDateRange,
  HX_COMPONENT_NAME,
  HxAuthService,
  HxCityService,
  HxOrderService,
  HxStoreService,
  HxUserService,
  isoDate,
  OrderAction,
  OrderRowModel,
  OrderStatus,
  ProductInfoType,
  SeekDirection,
  SortType,
  SourceSystem,
  StoreBasicModel,
  toRouterQueryParams,
  uiLabel,
  UserBasicModel, extractDefaultStoreId
} from 'hx-services';
import { Subscription } from 'rxjs';
import { HxErrorHandlerService, HxModalActorComponent, HxToastrService } from 'hx-component';
import { Location } from '@angular/common';
import { TranslocoService } from '@ngneat/transloco';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

interface ListItem extends OrderRowModel {
  isCart: boolean;
  isCancelled: boolean;
  routerLink: { url: string, queryParams?: Params };
}

interface TableColumn {
  title: string;
  sort?: SortType;
  field?: 'id' | 'number' | 'date';
}

interface CityItem extends CityModel {
  uiTitle?: string;
}

/**
 * Component List of orders
 */
@Component({
  selector: 'hx-order-list',
  templateUrl: './order-list.component.html',
  styleUrls: ['./order-list.component.css']
})
export class HxOrderListComponent implements OnInit, OnDestroy {
  list: ListItem[] = [];
  statusOptions = new Map<OrderAction, { badge: string }>([
    [OrderAction.CREATED, {badge: 'info'}],
    [OrderAction.PACKED, {badge: 'warning'}],
    [OrderAction.DECORED, {badge: 'focus'}],
    [OrderAction.SHIPPED, {badge: 'warning'}],
    [OrderAction.RECEIVED, {badge: 'success'}],
    [OrderAction.CANCELLED, {badge: 'danger'}],
    [OrderAction.TAKEN, {badge: 'warning'}],
    [OrderAction.RETURNED, {badge: 'metal'}],
    [OrderAction.REFUNDED, {badge: 'danger'}],
    [OrderAction.WAIT_PAYMENT, {badge: 'focus'}]
  ]);
  typeOptions: { id: OrderAction, label: string }[] = [
    {id: OrderAction.CREATED, label: 'action.CREATED'},
    {id: OrderAction.PACKED, label: 'action.PACKED'},
    {id: OrderAction.DECORED, label: 'action.DECORED'},
    {id: OrderAction.WAIT_PAYMENT, label: 'action.WAIT_PAYMENT'},
    {id: OrderAction.SHIPPED, label: 'action.SHIPPED'},
    {id: OrderAction.RECEIVED, label: 'action.RECEIVED'},
    {id: OrderAction.CANCELLED, label: 'action.CANCELLED'},
    {id: OrderAction.REFUNDED, label: 'action.REFUNDED'},
  ];
  deliveryTypeOptions = ['PICKUP', 'DELIVERY'];
  decorOptions = ['INSCRIPTION', 'DECORED'];
  sourceSystemOptions = [
    'MANAGER',
    'CALLCENTER',
    'CASHBOX',
    'WHATSAPP',
    'INSTAGRAM',
    'ONLINE',
    'ONLINE_APP',
  ];
  entityTypeOptions = Object.keys(Entity);
  seek: {
    last?: string;
    first?: string;
    val?: string;
    hasNext?: boolean;
    dir?: SeekDirection;
  } = {};
  operatorOptions: UserBasicModel[] = [];
  courierOptions: UserBasicModel[] = [];
  cityOptions: CityItem[] = [];
  columns: TableColumn[] = [];
  isLoading = {
    list: true
  };
  isArchive = false;
  // query params
  decor?: 'INSCRIPTION' | 'DECORED';
  deliveryType?: DeliveryType;
  actions: OrderAction[] = [];
  query?: string;
  clientId?: number;
  cityId?: number;
  operatorId?: number;
  courierId?: number;
  productInfoIds: number[] = [];
  activeCart: boolean | undefined = false;
  date?: DateRange;
  orderBy?: 'id' | 'number' | 'date' = 'id';
  sort?: SortType = SortType.desc;
  storeId?: number;
  important?: boolean;
  fiscalNumber?: string;
  sourceSystem?: string;
  clientType?: Entity;
  // query param ends
  isCallcenter = false;
  isManager = false;
  isCashbox = false;
  isToday = false;
  brandId?: number;
  selectedPITypes: ProductInfoType[] = [ProductInfoType.PRODUCT, ProductInfoType.PROPERTY];
  private sub?: Subscription;
  private defaultActions: OrderAction[] = [];

  constructor(
    private orderService: HxOrderService,
    private storeService: HxStoreService,
    private cityService: HxCityService,
    private userService: HxUserService,
    private modal: NgbModal,
    private auth: HxAuthService,
    private aRoute: ActivatedRoute,
    private router: Router,
    public location: Location,
    private toaster: HxToastrService,
    private errorService: HxErrorHandlerService,
    private tr: TranslocoService,
    @Optional() @Inject(HX_COMPONENT_NAME) private componentName: string,
  ) {
  }

  ngOnInit() {
    this.isCallcenter = this.componentName === ComponentType.cc;
    this.isManager = this.componentName === ComponentType.manager;
    this.isCashbox = this.componentName === ComponentType.cb;
    if (this.componentName === ComponentType.cb) {
      this.defaultActions = [OrderAction.CREATED, OrderAction.DECORED, OrderAction.PACKED, OrderAction.WAIT_PAYMENT];
    }
    this.isArchive = this.aRoute.snapshot.data['archive'] === true;
    this.isToday = this.aRoute.snapshot.data['today'] === true;
    if (this.isCashbox) {
      this.columns = [
        {
          title: 'id',
          sort: SortType.desc,
          field: 'id',
        },
        {
          title: 'ord.order-list.ts.num',
          field: 'number',
        },
        {
          title: 'ord.order-list.ts.dataDelivery',
          field: 'date',
        }
      ];
    } else {
      this.columns = [
        {
          title: 'id',
          sort: SortType.desc,
          field: 'id',
        },
        {
          title: 'ord.order-list.ts.num',
          field: 'number',
        },
        {
          title: 'ord.order-list.ts.dataCreate',
        },
        {
          title: 'ord.order-list.ts.dataDelivery',
          field: 'date',
        }
      ];
    }

    this.sub = this.aRoute.queryParamMap.subscribe(paramMap => {
      const asNumberFn = (name: string) => paramMap.get(name) ? Number(paramMap.get(name)) : undefined;
      const asBooleanFn = (name: string) => paramMap.get(name) ? paramMap.get(name) === 'true' : undefined;
      const asArrFn = (name: string, mapFn: (key: string) => any) => paramMap.get(name)?.split(',').map(mapFn) ?? [];
      this.clientId = asNumberFn('clientId');
      this.storeId = asNumberFn('storeId') ?? extractDefaultStoreId();
      this.operatorId = asNumberFn('operatorId');
      this.courierId = asNumberFn('courierId');
      this.cityId = asNumberFn('cityId');
      this.activeCart = asBooleanFn('activeCart');
      this.important = asBooleanFn('important');
      this.actions = asArrFn('actions', str => str as OrderAction);
      this.productInfoIds = asArrFn('productInfoIds', str => Number(str));
      this.decor = paramMap.get('decor') as any;
      this.query = paramMap.get('query') ?? undefined;
      const orderByParam = paramMap.get('orderBy') ?? 'id';
      this.orderBy = orderByParam && ['id', 'number', 'date'].includes(orderByParam) ? orderByParam as any : undefined;
      this.fiscalNumber = paramMap.get('fiscalNumber') ?? undefined;
      this.sourceSystem = paramMap.get('sourceSystem') ?? undefined;
      this.sort = paramMap.get('sort') as SortType;
      this.deliveryType = paramMap.get('deliveryType') as DeliveryType;
      this.clientType = paramMap.get('clientType') as Entity;
      this.seek = {
        dir: paramMap.get('seekDir') as SeekDirection ?? SeekDirection.next,
        val: paramMap.get('seek') ?? undefined,
      };
      if (this.isToday) {
        this.date = {from: isoDate(new Date()), to: isoDate(new Date())};
      } else {
        this.date = extractDateRange('date', paramMap) ?? undefined;
      }

      if (this.isCallcenter) {
        if (this.cityOptions.length === 0) {
          this.loadCities();
        }
        if (this.operatorOptions.length === 0) {
          this.loadOperators();
        }
      }
      if (this.isCashbox) {
        if (!this.isToday) {
          /*if (!this.date) {
            this.date = Date.now();
          }*/
          if (this.courierOptions.length === 0) {
            this.loadCouriers();
          }
        }
        if (this.isToday && !this.deliveryType) {
          this.deliveryType = DeliveryType.PICKUP;
        }
      }
      if (this.actions.length === 0) {
        this.actions = [...this.defaultActions];
      }
      this.loadList();
    });
  }

  ngOnDestroy() {
    this.sub?.unsubscribe();
  }

  toggleToFavorite(item: OrderRowModel): void {
    this.orderService.setImportant(item.id, !item.important).subscribe(() => {
      const str = !item.important ? this.tr.translate('ord.order-info.ts.addFavorites') : this.tr.translate('ord.order-info.ts.deleteFavorites');
      this.toaster.success(this.tr.translate('ord.order-list.ts.dataCreate', {uniqueNumber: item.uniqueNumber, str: str}));
      item.important = !item.important;
    });
  }

  loadCities(): void {
    this.cityService.getMyCities().subscribe(cities => {
      this.cityOptions = cities;
      this.cityOptions.forEach(r => r.uiTitle = uiLabel(this.tr.getActiveLang(), r.title));
    }, err => this.errorService.check(err.error));
  }

  resetFilter() {
    this.orderBy = 'id';
    this.sort = SortType.desc;
    this.productInfoIds = [];
    this.actions = [...this.defaultActions];

    this.activeCart = false;
    this.clientId = undefined;
    this.query = undefined;
    this.cityId = undefined;
    this.storeId = undefined;
    this.deliveryType = undefined;
    this.clientType = undefined;
    this.date = undefined;
    this.decor = undefined;
    this.fiscalNumber = undefined;
    this.sourceSystem = undefined;
    this.important = undefined;
    this.operatorId = undefined;
    this.courierId = undefined;
    this.brandId = undefined;
    if (this.isCashbox && this.isToday) {
      this.deliveryType = DeliveryType.PICKUP;
    }
    this.getTypeFilter();
  }

  onClientChanged(client?: ClientBasicModel): void {
    this.clientId = client ? client.id : undefined;
    this.navigateUrl();
  }

  showClientModal(client: ClientShortModel) {
    const modalRef = this.modal.open(HxModalActorComponent, {size: 'lg'});
    modalRef.componentInstance.actorId = client.id;
    modalRef.componentInstance.actorType = ActorType.CLIENT;
  }

  onStoreChanged(store?: StoreBasicModel | StoreBasicModel[]) {
    if (Array.isArray(store)) {
      return;
    }
    if (this.brandId && store?.brandId !== this.brandId) {
      this.productInfoIds = [];
    }
    this.brandId = store?.brandId;
    this.getTypeFilter();
  }

  getTypeFilter(): void {
    this.navigateUrl();
  }

  sortTable(column: TableColumn): void {
    this.orderBy = column.field;
    if (column.sort) {
      this.sort = column.sort === SortType.desc ? SortType.asc : SortType.desc;
    } else {
      this.sort = SortType.desc;
      this.columns.forEach(el => {
        if (el.field !== column.field) {
          el.sort = undefined;
        }
      });
    }
    this.list = [];
    column.sort = this.sort;
    this.navigateUrl();
  }

  onCityChanged() {
    if (!this.cityId) {
      this.storeId = undefined;
      this.getTypeFilter();
      return;
    }
    this.storeId = undefined;
    this.storeService.getStoresByCity(this.cityId).then(() => {
      this.getTypeFilter();
    }, err => this.errorService.check(err.error));
  }

  seekPrev() {
    this.navigateUrl({seek: this.seek.first, seekDir: SeekDirection.prev});
  }

  seekNext() {
    this.navigateUrl({seek: this.seek.last, seekDir: SeekDirection.next});
  }

  private loadOperators() {
    this.userService.getUsers({components: [ComponentType.cc], limit: 1000}).subscribe(paged => {
      this.operatorOptions = paged.list;
    });
  }

  private loadCouriers() {
    this.auth.getStoreUsersByComponents({components: [ComponentType.delivery], limit: 1000})
      .subscribe(users => this.courierOptions = users,
        err => this.errorService.check(err.error));
  }

  private async loadList() {
    this.isLoading.list = true;
    this.list = [];
    try {
      const {list, first, last, hasNext} = await this.orderService.getOrderList({
        seek: this.seek.val,
        seekDir: this.seek.dir,
        archived: this.isArchive,
        activeCart: this.activeCart,
        clientId: this.clientId,
        limit: 20,
        sort: this.sort,
        orderBy: this.orderBy,
        query: this.query,
        cityId: this.cityId,
        storeIds: (this.storeId) ? Array.of(this.storeId) : [],
        actions: this.actions,
        deliveryType: this.deliveryType,
        clientType: this.clientType,
        fromDate: this.date?.from,
        toDate: this.date?.to,
        decor: this.decor,
        fiscalNumber: this.fiscalNumber,
        sourceSystem: this.sourceSystem,
        important: this.important,
        operatorId: this.operatorId,
        courierId: this.courierId,
        productInfoIds: this.productInfoIds,
      });
      this.seek.first = first;
      this.seek.last = last;
      this.seek.hasNext = hasNext;
      this.list = list.map(order => ({
        ...order, ...{
          isCancelled: order.action === OrderAction.CANCELLED,
          isCart: order.status === OrderStatus.CART,
          routerLink: this.getRouterLink(order),
        }
      }));
      this.isLoading.list = false;
    } catch (err: any) {
      this.errorService.check(err);
    } finally {
      this.isLoading.list = false;
    }
  }

  private getRouterLink(order: OrderRowModel) {
    if (this.isCashbox && order.status === OrderStatus.CART && order.sourceSystem === SourceSystem.CASHBOX) {
      return {url: `/sale`, queryParams: {id: order.id}};
    }
    return {url: `/orders/${order.id}`};
  }

  private navigateUrl(seek: {seek?: string, seekDir?: SeekDirection} = {}) {
    this.router.navigate(['./'], {
      queryParams: toRouterQueryParams({
        seek: seek.seek,
        seekDir: seek.seekDir,
        actions: this.actions,
        clientId: this.clientId,
        cityId: this.cityId,
        deliveryType: this.deliveryType,
        query: this.query,
        date: this.date,
        decor: this.decor,
        sort: this.sort,
        orderBy: this.orderBy,
        storeId: this.storeId,
        fiscalNumber: this.fiscalNumber,
        sourceSystem: this.sourceSystem,
        clientType: this.clientType,
        important: this.important,
        operatorId: this.operatorId,
        courierId: this.courierId,
        productInfoIds: this.productInfoIds,
        activeCart: this.activeCart,
      }),
      relativeTo: this.aRoute,
    });
  }
}
