import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { HxClientService } from 'hx-services';

@Component({
  selector: 'hx-client-indicator',
  templateUrl: './client-indicator.component.html',
  styleUrls: ['./client-indicator.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HxClientIndicatorComponent implements OnChanges {
  @Input() clientId?: number;

  clientNote?: string;
  vipInfo?: string;
  blackListInfo?: string;

  constructor(
    private clientService: HxClientService,
    private cdr: ChangeDetectorRef,
  ) {
  }

  ngOnChanges(changes: SimpleChanges): void {
    let clientIdChange = changes['clientId'];
    if (clientIdChange) {
      this.vipInfo = undefined;
      this.blackListInfo = undefined;
      this.clientNote = undefined;
      if (clientIdChange.currentValue !== undefined) {
        this.clientService.getClientBrandProperties(clientIdChange.currentValue).subscribe(props => {
          this.vipInfo = props.filter(p => p.vip).map(p => p.brandTitle).join('; ');
          this.blackListInfo = props.filter(p => p.blacklisted).map(p => p.brandTitle).join('; ');
          this.clientNote = props.filter(p => p.note !== undefined).map(p => `${p.note} [${p.brandTitle}]`).join('; ');
          this.cdr.markForCheck();
        });
      } else {
        this.cdr.markForCheck();
      }
    }
  }
}
