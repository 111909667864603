import { Component, NgZone, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { OperatorActivityDetailedReportModel, ReportService } from '@callcenter-app/service/report.service';
import { CallStatusInHourModel, isoDate, OperatorActivityModel, toRouterQueryParams } from 'hx-services';
import { format, isToday } from 'date-fns';
import { translate } from '@ngneat/transloco';
import { Subscription } from 'rxjs';

/**
 * Отчёт по статусам звонков в течение дня
 */
@Component({
  selector: 'app-calls-hours-report.m-grid__item.m-grid__item--fluid.m-wrapper',
  templateUrl: './calls-hours-report.component.html',
  styleUrls: ['./calls-hours-report.component.css'],
})
export class CallsHoursReportComponent implements OnInit, OnDestroy {
  multiOA: {count: number; time: string; timeWithSeconds: string; asteriskId: string; fullname: string}[] = [];
  date?: string;
  data: CallStatusInHourModel[] = [];
  isLoading = true;
  callStatus = {
    incoming: 'реклама',
    ringing: 'ожидание',
    waiting: 'в очереди',
    talking: 'разговор',
    finished: 'всего',
  };
  colorSchem = {
    incoming: '#67b7dc',
    ringing: '#cc4748',
    waiting: '#fdd400',
    talking: '#84b761',
    finished: '#cd82ad',
  };
  intervalList = [{
    id: 60, name: '1 час'
  }, {
    id: 30, name: '30 мин'
  }, {
    id: 15, name: '15 мин'
  }, {
    id: 5, name: '5 мин'
  }];
  operatorDetailedList: OperatorActivityDetailedReportModel[] = [];
  interval?: number;
  private sub!: Subscription;

  constructor(
    private aRoute: ActivatedRoute,
    private router: Router,
    private reportService: ReportService,
    private zone: NgZone,
  ) { }

  browserOnly(fn: (router: Router, date?: string) => void) {
    this.zone.runOutsideAngular(() => fn(this.router, this.date));
  }

  browserOA(fn: () => void) {
    this.zone.runOutsideAngular(() => fn());
  }

  ngOnInit() {
    this.sub = this.aRoute.queryParams.subscribe(params => {
      this.date = params['date'] ? params['date'] : isoDate(new Date());
      this.interval = params['interval'] ? Number(params['interval']) : 60;
      this.init();
    });
  }

  ngOnDestroy() {
    if (this.sub) {
      this.sub.unsubscribe();
    }
  }

  getTimeInMinute(timeInMilli: number): number {
    const seconds = timeInMilli / 1000;
    const minutes = seconds / 60;
    return Math.floor(minutes);
  }

  isToday(): boolean {
    return isToday((this.date) ? Number(this.date) : Date.now());
  }

  onIntervalChanged() {
    this.navigate();
  }

  private init() {
    this.isLoading = true;
    this.loadList();
  }

  private loadList() {
    if (this.date && this.interval) {
      this.reportService.getCallInHour({date: this.date, interval: this.interval}).subscribe(result => {
        this.isLoading = false;

        if (result) {
          this.data = result;
          this.updateChart();
        }
      }, () => this.isLoading = false);
    }


    const date = isoDate(this.date) ?? format(Date.now(), 'yyyy-MM-dd');
    this.reportService.getOperatorActivity(date).subscribe(result => {
      this.updateOperatorActivityChart(result);
    });
    this.reportService.getOperatorActivityDatailed(date).subscribe(result => {
      this.operatorDetailedList = result;
    });
  }

  navigate() {
    this.router.navigate(['./'], {
      queryParams: toRouterQueryParams({
        date: this.date,
        interval: this.interval
      }),
      relativeTo: this.aRoute
    });
  }

  private updateChart(): void {
    if (this.data && this.data.length) {
      this.browserOnly((router, date) => {
        Promise.all([
          import('@amcharts/amcharts4/core'),
          import('@amcharts/amcharts4/charts'),
          import('@amcharts/amcharts4/themes/animated').then(res => res.default)
        ]).then(([am4core, am4charts, am4themesAnimated]) => {
          am4core.useTheme(am4themesAnimated);

          const chart = am4core.create('barchart', am4charts.XYChart);

          chart.data = this.data;
          const dateAxis = chart.xAxes.push(new am4charts.DateAxis());
          dateAxis.renderer.grid.template.location = 0;
          dateAxis.renderer.minGridDistance = 30;

          const valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
          chart.dateFormatter.inputDateFormat = 'HH:mm';

          const totalIncomingCount = this.data.reduce((a, b) => a + b.incomingCount, 0);
          const totalWaitingCount = this.data.reduce((a, b) => a + b.waitingCount, 0);
          const totalRingingCount = this.data.reduce((a, b) => a + b.ringingCount, 0);
          const totalTalkingCount = this.data.reduce((a, b) => a + b.talkingCount, 0);
          const totalFinishedCount = this.data.reduce((a, b) => a + b.finishedCount, 0);

          const createSeriesFn = (field: string, status: string, name: string, color: string, total: number, description: string) => {
            const series = chart.series.push(new am4charts.LineSeries());
            series.dataFields.valueY = field;
            series.dataFields.dateX = 'time';
            series.dataFields.value = status;
            series.name = name + ' - ' + total;
            series.tooltipText = name + ': [b]{valueY}[/]';
            series.strokeWidth = 2;
            series.stroke = am4core.color(color);
            series.fill = am4core.color(color);
            series.dummyData = {
              description: description
            };
            const bullet = series.bullets.push(new am4charts.CircleBullet());
            bullet.circle.stroke = am4core.color('#fff');
            bullet.circle.strokeWidth = 2;
          };

          createSeriesFn('incomingCount', 'incoming', this.callStatus['incoming'], this.colorSchem['incoming'], totalIncomingCount, translate('incoming.description'));
          createSeriesFn('waitingCount', 'waiting', this.callStatus['waiting'], this.colorSchem['waiting'], totalWaitingCount,  translate('waiting.description'));
          createSeriesFn('ringingCount', 'ringing', this.callStatus['ringing'], this.colorSchem['ringing'], totalRingingCount, translate('ringing.description'));
          createSeriesFn('talkingCount', 'talking', this.callStatus['talking'], this.colorSchem['talking'], totalTalkingCount, translate('talking.description'));
          createSeriesFn('finishedCount', 'finished', this.callStatus['finished'], this.colorSchem['finished'], totalFinishedCount, translate('finished.description'));

          chart.legend = new am4charts.Legend();
          chart.legend.itemContainers.template.tooltipText = '{dataContext.dummyData.description}';
          chart.legend.itemContainers.template.togglable = false;
          chart.legend.itemContainers.template.clickable = true;
          chart.legend.itemContainers.template.events.on('hit', ev => {
            // @ts-ignore
            router.navigateByUrl('/calls?callStatuses=' + ev.target.dataItem.dataContext.dataFields.value + '&date=' + isoDate(date));
          });
          chart.cursor = new am4charts.XYCursor();

        }).catch((e) => {
          console.error('Error when creating chart', e);
        });
      });
    }
  }

  private updateOperatorActivityChart(result: OperatorActivityModel[]) {
    this.multiOA = result.map(item => ({
      count: item.onlineOperatorCount,
      time: format(new Date(item.date), 'HH:mm'),
      timeWithSeconds: format(new Date(item.date), 'HH:mm:ss'),
      asteriskId: item.asteriskId,
      fullname: (item.user) ? this.fio(item.user.fullname, item.type === 'ONLINE' ? '+' : '-') : 'Anonymous'
    }));

    this.browserOA(() => {
      Promise.all([
        import('@amcharts/amcharts4/core'),
        import('@amcharts/amcharts4/charts'),
        import('@amcharts/amcharts4/themes/animated').then(res => res.default)
      ]).then(([am4core, am4charts, am4themesAnimated]) => {
        am4core.useTheme(am4themesAnimated);

        const chartActivityOperator = am4core.create('barСhartOperatorActivity', am4charts.XYChart);
        chartActivityOperator.dateFormatter.inputDateFormat = 'HH:mm';
        chartActivityOperator.data = this.multiOA;

        const categoryAxis = chartActivityOperator.xAxes.push(new am4charts.CategoryAxis());
        categoryAxis.dataFields.category = 'time';
        categoryAxis.renderer.grid.template.location = 0;
        categoryAxis.renderer.minGridDistance = 30;
        categoryAxis.renderer.labels.template.horizontalCenter = 'right';
        categoryAxis.renderer.labels.template.verticalCenter = 'middle';
        categoryAxis.renderer.labels.template.rotation = 270;
        categoryAxis.renderer.labels.template.fontSize = 9;

        const valueAxis = chartActivityOperator.yAxes.push(new am4charts.ValueAxis());

        const series = chartActivityOperator.series.push(new am4charts.ColumnSeries());
        series.dataFields.valueY = 'count';
        series.dataFields.categoryX = 'time';
        series.columns.template.tooltipHTML = '{timeWithSeconds} <br>' + translate('onlineOperatorNumber')  + ' - {count} <br> {fullname}';

        const valueLabel = series.bullets.push(new am4charts.LabelBullet());
        valueLabel.label.text = '{count}';
        valueLabel.label.dy = 15;
        valueLabel.label.fontSize = 10;

      }).catch((e) => {
        console.error('Error when creating operator activity chart', e);
      });
    });
  }

  private fio(value: string, action: string): string {
    const fio = value
      .split(' ')
      .map((item, index) => item && item !== 'null' ? index === 0 ? item : `${item[0].toUpperCase()}.` : '')
      .join(' ');
    return action.concat(' ').concat(fio);
  }
}
