<ng-select [items]="items"
           bindValue="id"
           [placeholder]="'hx.client-search.placeholder.nameClient' | transloco"
           [typeToSearchText]="'hx.client-search.placeholder.startSearch' | transloco"
           [notFoundText]="'hx.client-search.placeholder.noFind' | transloco"
           [loadingText]="'hx.client-search.placeholder.loading' | transloco"
           [multiple]="false"
           [hideSelected]="true"
           [trackByFn]="trackByFn"
           [loading]="itemLoading"
           [typeahead]="itemInput$"
           (change)="onClientChanged($event)"
           [(ngModel)]="clientId">
  <ng-template ng-label-tmp let-item="item">
    {{item.phone}} - {{item.fullname}}
  </ng-template>
  <ng-template ng-option-tmp let-item="item">
    {{item.phone}} - {{item.fullname}}
  </ng-template>
</ng-select>
