import { Component, EventEmitter, forwardRef, Input, OnChanges, Output, SimpleChanges, ViewEncapsulation } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { HxCategoryService, ProductCategoryModel } from 'hx-services';

@Component({
  selector: 'hx-product-category-select',
  templateUrl: './product-category-select.component.html',
  styleUrls: ['./product-category-select.component.css'],
  encapsulation: ViewEncapsulation.None,
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => HxProductCategorySelectComponent),
    multi: true
  }]
})
export class HxProductCategorySelectComponent implements ControlValueAccessor, OnChanges {
  @Input() brandId!: number;
  @Input() multiple = false;
  @Input() default = false;
  @Output() selectChange = new EventEmitter<ProductCategoryModel | ProductCategoryModel[] | undefined>();

  options: ProductCategoryModel[] = [];
  disabled = false;
  selected?: number | number[];

  constructor(private categoryService: HxCategoryService) {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['brandId']) {
      this.loadCategories();
    }
  }

  onModelChanged(val: any) {
    this.onChange(val);
    this.selectChange.emit(this.getSelectedOptions());
  }

  onChange(val: any) {
    // console.log('onChange called', val);
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  writeValue(obj?: number | number[]): void {
    this.selected = obj;
  }

  private getSelectedOptions(): ProductCategoryModel | ProductCategoryModel[] | undefined {
    if (!this.multiple) {
      if (this.selected) {
        return this.options.find(p => p.id === this.selected);
      } else {
        return undefined;
      }
    } else {
      if (Array.isArray(this.selected)) {
        const ids: number[] = this.selected;
        return this.options.filter(p => ids.includes(p.id));
      } else {
        return [];
      }
    }
  }

  private loadCategories() {
    if (this.brandId) {
      this.categoryService.getCategories({brandId: this.brandId, limit: 1000})
        .subscribe(paged => {
          this.options = paged.list;
          if (this.default && paged.list.length > 0) {
            if (this.multiple) {
              this.selected = [paged.list[0].id];
            } else {
              this.selected = paged.list[0].id;
            }
            this.onModelChanged(this.selected);
          }
        });
    }
  }
}
