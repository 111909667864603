import { Component, Input } from '@angular/core';

@Component({
  selector: 'hx-loader',
  templateUrl: './hx-loader.component.html',
  styleUrls: ['./hx-loader.component.css']
})
export class HxLoaderComponent {
  @Input() isLoading = false;
  @Input() length = 0;

  constructor() { }

}
