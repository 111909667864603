import { Component, EventEmitter, forwardRef, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { BankAccountInfoModel, HxSupplierService } from 'hx-services';

@Component({
  selector: 'hx-bank-account-select',
  templateUrl: './bank-account-select.component.html',
  styleUrls: ['./bank-account-select.component.css'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => HxBankAccountSelectComponent),
    multi: true
  }]
})
export class HxBankAccountSelectComponent implements OnInit, OnChanges, ControlValueAccessor {
  @Input() supplierId!: number;
  @Input() isDisabled = false;
  @Input() multiple = false;
  @Output() selectChange = new EventEmitter<BankAccountInfoModel | BankAccountInfoModel[] | undefined>();

  options: BankAccountInfoModel[] = [];
  selected: number | number[] | undefined;

  constructor(
    private supplierService: HxSupplierService,
  ) {
  }

  ngOnInit(): void {
    this.loadBankAccounts();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['cityId']) {
      this.loadBankAccounts();
    }
  }

  onModelChanged(val: any) {
    this.onChange(val);
    if (!this.multiple) {
      if (this.selected) {
        this.selectChange.emit(this.options.find(p => p.id === this.selected));
      } else {
        this.selectChange.emit();
      }
    } else {
      if (Array.isArray(this.selected)) {
        const ids: number[] = this.selected;
        this.selectChange.emit(this.options.filter(p => ids.includes(p.id)));
      } else {
        this.selectChange.emit([]);
      }
    }
  }

  onChange(val: any) {
    // console.log('onChange called', val);
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
  }

  setDisabledState(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }

  writeValue(obj?: number | number[]): void {
    this.selected = obj;
  }

  private loadBankAccounts() {
    this.supplierService.getSupplierBankAccountList(this.supplierId).subscribe(list => {
      this.options = list;
    });
  }
}
