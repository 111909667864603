import { Injectable } from '@angular/core';
import { firstValueFrom } from 'rxjs';
import { CallResourceService, CallStatus, SeekDirection } from '../vn-api';

@Injectable({
  providedIn: 'root'
})
export class HxCallService {

  constructor(private callResource: CallResourceService) {
  }

  getCallList(params?: {
    cityIds?: Array<number>;
    countryIds?: Array<number>;
    date?: string;
    limit?: number;
    phone?: string;
    seek?: string;
    seekDir?: SeekDirection;
    statuses?: Array<CallStatus>;
  }) {
    return firstValueFrom(this.callResource.getCallList(
      params?.cityIds,
      params?.countryIds,
      params?.date,
      params?.limit,
      params?.phone,
      params?.seek,
      params?.seekDir,
      params?.statuses,
    ));
  }

  getCallDetailsByCallId(callId: string) {
    return firstValueFrom(this.callResource.getCallDetailsByCallId(callId));
  }

  saveCityIdForCall(callId: string, cityId: number) {
    return firstValueFrom(this.callResource.saveCityIdForCall(callId, cityId));
  }
}
