import { Component } from '@angular/core';
import { environment } from '@callcenter-env/environment';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
})
export class FooterComponent {
  appVersion = environment.version;
}
