<ul ngbNav [(activeId)]="activeNav" (activeIdChange)="onTabChanged()" #nav="ngbNav" class="nav-tabs" [destroyOnHide]="false">
  <li [ngbNavItem]="'view'">
    <a ngbNavLink>{{ 'ord.order-new.view' | transloco }}</a>
    <ng-template ngbNavContent>
      <div [attr.data-event]="isLoading.orderText | transloco" [ngClass]="{'is--block-ui': isLoading.order}">
        <hx-order-configurator [orderConfiguration]="orderConf"
                               (orderCreate)="onOrderCreated($event)"
                               (orderTransfer)="onOrderTransferred($event)">
        </hx-order-configurator>
      </div>
    </ng-template>
  </li>
  <li [ngbNavItem]="'cart'" [disabled]="isLoading.order">
    <a ngbNavLink>{{ 'ord.order-new.basket' | transloco }}</a>
    <ng-template ngbNavContent>
      <div *ngIf="order.id">
        <div class="row m--margin-bottom-10" style="align-items: center;">
          <div class="col-md-3">{{ 'ord.order-new.dataOrder' | transloco }}:</div>
          <div class="col-md-4">
            {{order.orderDate | date:'dd.MM.yyyy'}}
          </div>
        </div>

        <div class="row m--margin-bottom-15" style="align-items: center;">
          <label class="col-md-3 m--margin-bottom-0">
            {{ 'ord.order-new.city' | transloco }}:
          </label>

          <div class="col-md-9">
            {{ store?.city?.title | uiLabel }}
          </div>
        </div>

        <div class="row m--margin-bottom-15 m--margin-top-10" style="align-items: center;">
          <label class="col-md-3 m--margin-bottom-0">
            {{ 'ord.order-new.shop' | transloco }}:
          </label>

          <div class="col-md-9">
            {{ store?.title | uiLabel }}
          </div>
        </div>

        <hx-client-checker *ngIf="store"
                           [disabled]="order.paid === true"
                           [cityId]="store.city.id"
                           [(ngModel)]="clientPhone"
                           (clientChange)="onClientUpdated($event)">
        </hx-client-checker>

        <div class="row m--margin-bottom-15 m--margin-top-10" style="align-items: center;">
          <label class="col-md-3 m--margin-bottom-0">
            {{ 'ord.order-new.otherRecipient' | transloco }}:
          </label>

          <div class="col-md-9">
            <span class="m-switch m-switch--icon m-switch--outline m-switch--success">
              <label>
                <input type="checkbox" checked="checked" id="toggleChangeRecipient" (ngModelChange)="onRecipientChanged()" [(ngModel)]="changeRecipient">
                <span></span>
              </label>
            </span>
          </div>
        </div>

        <hx-client-checker *ngIf="order.cityId && changeRecipient" [cityId]="order.cityId" [label]="'recipient.name'"
                           [(ngModel)]="order.recipientClientPhone" (clientChange)="onRecipientClientUpdated($event)">
        </hx-client-checker>

        <div class="row m--margin-bottom-15 m--margin-top-10" style="align-items: center;">
          <label class="col-md-3 m--margin-bottom-0">
            {{ 'ord.order-new.attachCheck' | transloco }}:
          </label>

          <div class="col-md-9">
            <span class="m-switch m-switch--icon m-switch--outline m-switch--success">
              <label>
                <input type="checkbox" checked="checked" id="toggleWithoutReceipt" [(ngModel)]="order.withoutReceipt">
                <span></span>
              </label>
            </span>
          </div>
        </div>

        <div class="row" style="align-items: center;" *ngIf="!order.orderDate || !order.storeId">
          <label for="notes" class="col-md-3 m--margin-bottom-0"></label>

          <div class="col-md-9">
            <h5 class="m--margin-top-20">
              {{ 'ord.order-new.choose' | transloco }} <span *ngIf="!order.orderDate">{{ 'ord.order-new.data' | transloco }} </span> <span *ngIf="!order.orderDate && !order.storeId">{{ 'ord.order-new.end' | transloco }}</span> <span *ngIf="!order.storeId"> {{ 'ord.order-new.shopText' | transloco }}</span>
            </h5>
          </div>
        </div>

        <div class="m-form m--margin-top-15">
          <div class="m-form__section m-form__section--first">
            <hx-order-cart *ngIf="store && order.orderDate && order.products"
                           [store]="store"
                           [orderId]="order.id"
                           [date]="order.orderDate"
                           [time]="order.orderTime"
                           [products]="order.products"
                           [clientId]="client?.client?.id"
                           [readonly]="order.paid === true"
                           [clientType]="order.clientType"
                           [deliveryType]="order.deliveryType"
                           [timezone]="timezone"
                           [address]="orderAddress"
                           [addresses]="client?.additionalAddresses ?? []"
                           [pickupTime]="order.pickupTime"
                           [cartRangeId]="order.cartRangeId"
                           [rangeId]="order.deliveryRangeId"
                           (addressChange)="onAddressChanged($event)"
                           (pickupTimeSelected)="onPickupTimeSelected($event)"
                           (rangeSelect)="onRangeSelected($event)"
                           (cartChange)="onCartChanged($event)">
            </hx-order-cart>

            <div class="form-group m-form__group m--padding-top-0">
              <div class="row m--margin-bottom-20">
                <div class="col-md-12">
                  <div class="form-group m-form__group">
                    <label>{{ 'ord.order-cart.comment' | transloco }}</label>
                    <a class="mention" (click)="deliverySelected()">&#64;delivery</a>
                    <i class="las la-question-circle m--margin-left-10" [ngbTooltip]="'ord.order-cart.mention' | transloco"></i>
                    <textarea [(ngModel)]="comment" class="form-control" rows="2"></textarea>
                  </div>
                </div>
              </div>
            </div>

            <div class="form-group m-form__group m--padding-top-0" *ngIf="order.discounts">
              <div class="row">
                <div class="col-md-12">
                  <hx-promo-code [disabled]="order.paid ?? false" [orderId]="order.id" [discounts]="order.discounts"></hx-promo-code>
                </div>
              </div>
            </div>

            <div class="form-group m-form__group">
              <div class="display-decor">
                <label for="decorText">
                  {{ 'ord.order-new.registration' | transloco }}
                  <strong *ngIf="!inscription.enabled && !isLoading.decorEnabled">{{ 'ord.order-new.chooseData' | transloco }}</strong>
                </label>
              </div>
              <div class="display-decor">
                <div ngbDropdown container="body">
                  <i class="las la-ellipsis-v" ngbDropdownToggle></i>
                  <div ngbDropdownMenu>
                    <button ngbDropdownItem (click)="reject({decor: true})">{{ 'ord.order-new.refusal' | transloco }}</button>
                  </div>
                </div>
              </div>

              <textarea style="background-color: #fde7eb" [disabled]="!inscription.enabled" class="form-control m-input" id="decorText" [(ngModel)]="order.decorText" (focusout)="changeWhatsAppMsg()" rows="3"></textarea>
              <div *ngIf="inscription.from && inscription.to" class="decor-text-warning"><i class="las la-info-circle"></i>{{ textDecorWarning }}</div>
            </div>

            <div class="form-group m-form__group m--padding-top-0">
              <div class="row">
                <div class="col-md-3">
                  <label class="m--margin-right-20" style="vertical-align: middle;">
                    {{ 'ord.order-new.typeOrder' | transloco }}:
                  </label>

                  <ng-select [(ngModel)]="order.clientType" [items]="entityTypes" (click)="onClientTypeChanged()">
                    <ng-template ng-option-tmp ng-label-tmp let-item="item">
                      <span>{{ 'clientType.' + item | transloco }}</span>
                    </ng-template>
                  </ng-select>
                </div>
                <div class="col-md-6 align-self-end">
                  <a *ngIf="order.clientType === 'LEGAL'" (click)="toggleLegal()" class="btn btn-light m--margin-right-10">
                    <span>
                      <i class="m--margin-right-5 la"
                         [ngClass]="showLegal ? 'la-angle-up' : 'la-angle-down'"></i>
                      <span>{{ 'ord.order-new.text-2' | transloco }}</span>
                    </span>
                  </a>
                </div>
              </div>

              <div class="row" *ngIf="showLegal">
                <hx-company-info [orderId]="order.id" [clientId]="order.client"></hx-company-info>
              </div>
            </div>

            <div class="form-group m-form__group m--padding-top-0">
              <div class="row">
                <div class="col-md-6">
                  <label>{{ 'ord.order-new.file' | transloco }}:</label>

                  <hx-file-list [fileList]="fileList" (fileRemove)="onFileRemoved($event)"></hx-file-list>

                  <hx-file-upload (updateFiles)="onFileUploaded($event)"
                                  [size]="5"
                                  [temporary]="true"
                                  [containerTable]="hcOrdersRef"
                                  [containerId]="orderId"
                                  [folder]="folderDecor"></hx-file-upload>
                </div>
              </div>
            </div>

            <div class="form-group m-form__group">
              <label for="notes">{{ 'ord.order-new.note' | transloco }}:</label>
              <textarea class="form-control m-input" id="notes" [(ngModel)]="order.note" (focusout)="changeWhatsAppMsg()" rows="3"></textarea>
            </div>

            <div class="row form-group m-form__group">
              <div class="col-md-3">
                <label class="m--font-bold m--margin-top-5">{{ 'ord.order-new.orderSource' | transloco }}:</label>
              </div>

              <div class="col-md-4">
                <ng-select [(ngModel)]="order.from" [disabled]="isFromSite()" [items]="fromList">
                  <ng-template ng-label-tmp let-item="item">
                    <span>{{ 'from.' + item | transloco }}</span>
                  </ng-template>
                  <ng-template ng-option-tmp let-item="item">
                    <span>{{ 'from.' + item | transloco }}</span>
                  </ng-template>
                </ng-select>
              </div>

              <div class="col-md-5">
                <label for="toFavourite" class="m--margin-right-10" style="font-weight: 400;vertical-align: middle;">{{ 'ord.order-new.favorites' | transloco }}:</label>

                <span class="m-switch m-switch--icon m-switch--outline m-switch--success ">
                  <label>
                    <input type="checkbox" id="toFavourite" [(ngModel)]="order.important">
                    <span></span>
                  </label>
                </span>
              </div>
            </div>

            <div class="row form-group m-form__group">
              <div class="col-md-3">
                <label class="m--font-bold m--margin-top-5">{{ 'ord.order-new.client' | transloco }}:</label>
              </div>

              <div class="col-md-9">
                <div class="btn-group-sm referrers">
                  <button *ngFor="let item of referrers" (click)="setReferrer(item)"
                          class="btn m-btn--pill btn-sm" [ngClass]="{'btn-outline-info': item.code !== order.referrer, 'btn-info': item.code === order.referrer}">
                    {{ item.title | uiLabel }}
                  </button>
                  <button *ngIf="otherReferrers.length > 0" (click)="toggleMoreReferrers()" [ngbTooltip]="'ord.order-new.ng.moreOptions' | transloco" class="btn m-btn--pill btn-sm">
                    <i class="la" [ngClass]="{'la-arrow-up': otherReferrersVisible, 'la-arrow-down': !otherReferrersVisible}"></i>
                  </button>
                </div>

                <div class="m--margin-top-15" *ngIf="otherReferrersVisible">
                  <button *ngFor="let item of otherReferrers" (click)="setReferrer(item)"
                          class="btn m-btn--pill btn-sm" [ngClass]="{'btn-outline-info': item.code !== order.referrer, 'btn-info': item.code === order.referrer}">
                    {{ item.title | uiLabel }}
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div class="text-right m--margin-top-15">
            <button type="button" [disabled]="canCreateOrder()" (click)="submitOrder()" class="btn btn-success">
              {{ 'ord.btn.save' | transloco }}
            </button>
          </div>
        </div>
      </div>
    </ng-template>
  </li>
  <li [ngbNavItem]="'comment'">
    <a ngbNavLink>{{ 'ord.order-new.comment' | transloco }}</a>
    <ng-template ngbNavContent>
      <hx-comment-list *ngIf="order.id" [orderId]="order.id" [showAddBtn]="false"></hx-comment-list>
    </ng-template>
  </li>
</ul>

<div [ngbNavOutlet]="nav"></div>

<div id="cc-right-panel">
  <div class="row m--margin-top-10 align-items-end">
    <h6 style="padding: 0 1.25rem; width:100%; margin-left: 10px">
      {{ 'ord.order-new.timeShop' | transloco }}:
      <hx-managers-info [ngbTooltip]="'ord.order-new.ng.infoManager' | transloco" [groupId]="store?.id"></hx-managers-info>
    </h6>
    <h6 style="padding: 1.25rem; width:100%; margin-left: 10px">
      {{ 'ord.order-new.order' | transloco }}
      <span *ngIf="orderUniqueNumber">#{{ orderUniqueNumber}}</span>
      {{ 'ord.order-new.on' | transloco }} <span *ngIf="order?.orderDate">{{ order?.orderDate | date:'dd.MM.yyyy' }}</span>
      <span *ngIf="order.deliveryType === 'DELIVERY'"> {{ 'ord.order-new.deliveryShop' | transloco }} {{ store?.title | uiLabel }}</span>
    </h6>
    <textarea style="min-height: 140px; min-width: 200px; color: #000; background-color: #ffffff; margin-left: 15px; margin-right: 15px" class="form-control m-input" [(ngModel)]="whatsapp" rows="3" disabled></textarea>
    <span style="margin-left: 25px; font-weight: bold;" *ngIf="order?.total">
      {{ 'ord.order-new.pay' | transloco }}: {{order.total}} {{currency}}
      <a *ngIf="orderUniqueNumber" [ngbTooltip]="'ord.order-new.ng.copyText' | transloco"
         class="clickable" (click)="copyText();">
        <i class="la la-copy"></i>
      </a>
    </span>
    <div *ngIf="viewTotal && order.total && viewTotal !== order.total && activeNav === 'view'" class="m-alert m-alert--outline m-alert--outline-2x m-alert--square alert alert-info" role="alert">
      {{ 'ord.order-new.priceOrder' | transloco }} <strong>{{order.total}}</strong> {{ 'ord.order-new.on' | transloco }} <strong>{{viewTotal}}</strong>
    </div>
    <div *ngIf="errorMessage" class="m-alert m-alert--icon m-alert--air m-alert--square alert alert-danger" role="alert">
      <div class="m-alert__icon">
        <i class="la la-warning"></i>
      </div>
      <div class="m-alert__text">
        {{errorMessage | transloco}}
      </div>
    </div>

    <div *ngIf="status === 'ACTIVE'" style="width: 100%; margin-left: 15px;">
      <button (click)="prolongCartExpiration()" style="height: 100%; width:50%;" class="btn btn-warning btn-md">
        <i class="la la-shopping-cart"></i> {{ 'ord.order-new.btn.extend' | transloco }} {{ countDown | countDown }}
      </button>
      <button (click)="cancelCart()" style="height: 100%; width:50%;" class="btn btn-default btn-md">
        <i class="la la-close"></i> {{ 'ord.btn.cancel' | transloco }}
      </button>
    </div>
  </div>

  <div class="line"></div>

  <div class="row" *ngIf="rejectionInfo">
    <h6 style="padding: 1.25rem; width:100%; margin-left: 10px">
      {{ 'ord.order-new.reasons' | transloco }} <i class="la" [ngClass]="{'la-spinner la-spin' : isLoading.rejectSaving, 'la-check-circle': !isLoading.rejectSaving}" [ngbTooltip]="(!isLoading.rejectSaving ? 'ord.order-new.ng.successSave' : '') | transloco"></i>
    </h6>
    <div class="col-sm-12" *ngIf="rejectionInfo.productDefinitions.length">
      <div class="product-reject-list">
        <span class="reject-label">{{ 'ord.order-new.products' | transloco }}:</span>
        <span class="reject-text">{{ rejectionInfo.productDefinitions | joined:'title.' + lang }}</span>
      </div>
    </div>
    <div class="col-sm-12" *ngIf="rejectionInfo.decor">
      <div class="product-reject-list">
        <span class="reject-label">{{ 'ord.order-new.registration' | transloco }}:</span><span class="reject-text">{{ 'ord.order-new.absent' | transloco }}</span>
      </div>
    </div>
    <div class="col-sm-12" *ngIf="rejectionInfo.promos.length">
      <div class="product-reject-list">
        <span class="reject-label">{{ 'ord.order-new.promoCode' | transloco }}:</span><span class="reject-text">{{ rejectionInfo.promos }}</span>
      </div>
    </div>
    <div class="col-sm-12" *ngIf="rejectionInfo.delivery">
      <div class="product-reject-list">
        <span class="reject-label">{{ 'ord.order-new.timeDelivery' | transloco }}:</span><span class="reject-text">{{ 'ord.order-new.absent' | transloco }}</span>
      </div>
    </div>
    <div class="col-sm-12" *ngIf="rejectionInfo.pickup">
      <div class="product-reject-list">
        <span class="reject-label">{{ 'ord.order-new.timePickup' | transloco }}:</span><span class="reject-text">{{ 'ord.order-new.absent' | transloco }}</span>
      </div>
    </div>
    <div class="col-sm-12" *ngIf="rejectionInfo.deliveryTimes.length">
      <div class="product-reject-list">
        <span class="reject-label">{{ 'ord.order-new.timeDelivery' | transloco }}:</span><span class="reject-text">{{ rejectionInfo.deliveryTimes }}</span>
      </div>
    </div>
    <div class="col-sm-12" *ngIf="rejectionInfo.pickupTimes.length">
      <div class="product-reject-list">
        <span class="reject-label">{{ 'ord.order-new.timePickup' | transloco }}:</span><span class="reject-text">{{ rejectionInfo.pickupTimes }}</span>
      </div>
    </div>
    <div class="col-sm-12" *ngIf="rejectionInfo.addresses.length">
      <div class="product-reject-list">
        <span class="reject-label">{{ 'ord.order-new.address' | transloco }}:</span><span class="reject-text">{{ rejectionInfo.addresses }}</span>
      </div>
    </div>
    <div class="col-sm-12" style="margin-top: 10px;">
      <textarea style="min-height: 140px; min-width: 200px; color: #000; background-color: #ffffff; margin-left: 15px; margin-right: 15px"
                class="form-control m-input" [(ngModel)]="rejectionInfo.note" rows="3" [placeholder]="'ord.order-new.placeholder.rejectionReason' | transloco"></textarea>
    </div>
    <div class="col-sm-12" style="margin-top: 20px; margin-bottom: 10px">
      <div style="width: 100%; margin-left: 15px;">
        <button (click)="reject({note: rejectionInfo.note})" style="height: 100%; width:50%;" class="btn btn-success btn-md">
          {{ 'ord.order-new.btn.save' | transloco }}
        </button>
      </div>
    </div>
  </div>
</div>
