<div class="m-widget3">
  <div class="m-widget3__item" *ngFor="let event of events; trackBy: trackById">
    <div class="m-widget3__header">
      <div class="m-widget3__info">
        <span class="m-widget3__username">
          <strong>{{ event.date | date: 'dd.MM HH:mm:ss' : timezone }}</strong>
          <span style="margin-left: 1rem;" class="pointer" (click)="showActorModal(event)">{{ event.actorFullName | fio }}</span>
        </span>
      </div>
      <span class="m-widget3__status m--font-info">
        <button (click)="onEventClick(event)" [disabled]="event.isLoading" class="btn m-btn--pill btn-sm" [ngClass]="event.badge ? event.badge : ''">
          {{ 'hx.event.' + event.type | transloco }}
        </button>
      </span>
    </div>
    <div class="m-widget3__body" *ngIf="event.info">
      <p class="m-widget3__text">{{ event.info }}</p>
    </div>
  </div>
</div>
