import { Component, EventEmitter, forwardRef, Input, Output } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'hx-checkbox',
  templateUrl: './checkbox.component.html',
  styleUrls: ['./checkbox.component.css'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => HxCheckboxComponent),  // replace name as appropriate
      multi: true
    }
  ]
})
export class HxCheckboxComponent implements ControlValueAccessor {
  iconClass!: string;
  disabled = false;

  @Input() label: string = 'hx';
  @Input() hideNo = false;
  @Output() valueChange = new EventEmitter<boolean | undefined>();

  value: 'yes' | 'no' | 'all' = 'all';

  constructor() {
  }

  onChange(val: any) {

  }

  writeValue(obj: boolean | undefined): void {
    // console.log('[hx-checkbox] write value: ', obj);
    if (obj === null || obj === undefined) {
      this.value = 'all';
    } else if (obj) {
      this.value = 'yes';
    } else {
      this.value = 'no';
    }
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
  }

  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  setValue(val: 'yes' | 'no' | 'all') {
    this.value = val;
    this.onChange(this.toBool());
    this.valueChange.emit(this.toBool());
  }

  private toBool(): boolean | undefined {
    if (this.value === 'yes') {
      return true;
    } else if (this.value === 'no') {
      return false;
    }
    return undefined;
  }
}
