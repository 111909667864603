<div class="modal-header">
  <h3 class="modal-title">{{ 'hx.sms-info-modal.title' | transloco }}: {{event.data.phone}}</h3>
</div>

<div class="modal-body">
  <div class="row">
    <div class="col-3">
      <span [class]="'m-badge m-badge-- m-badge--wide m-badge--rounded'">
        {{ 'hx.sms-info-modal.send' | transloco }}
      </span>
    </div>
    <div class="col-3">
      <span>
        <strong>{{ event.createDate | date: 'yyyy-MM-dd HH:mm:ss' }}</strong>
      </span>
    </div>
  </div>

  <div class="row">
    <div class="col-3">
      <span [class]="'m-badge m-badge-- m-badge--wide m-badge--rounded'">
        {{ 'hx.sms-info-modal.sendClient' | transloco }}
      </span>
    </div>
    <div class="col-3">
      <span>
        <strong>{{ smsInfo.sendDate | date: 'yyyy-MM-dd HH:mm:ss' }}</strong>
      </span>
    </div>
  </div>

  <div class="row">
    <div class="col-3" *ngIf="smsInfo.status">
      <span [class]="'m-badge m-badge-- m-badge--wide m-badge--rounded'">
        {{ 'hx.sms.status.' + smsInfo.status | transloco}}
      </span>
    </div>
    <div class="col-3" *ngIf="smsInfo.lastStatusDate">
      <span class="m-widget3__username">
        <strong>{{ smsInfo.lastStatusDate | date: 'yyyy-MM-dd HH:mm:ss' }}</strong>
      </span>
    </div>
  </div>

  <div class="row">
    <div class="col-12">
      {{event.data.message}}
    </div>
  </div>

  <div class="modal-footer modal-footer--pad-right">
    <button type="button" (click)="close()" class="btn btn-secondary">
      {{ 'hx.btn.close' | transloco }}
    </button>
  </div>
</div>
