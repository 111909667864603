import { Component, forwardRef, Input, OnChanges, OnDestroy, SimpleChanges } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Subject } from 'rxjs';
import { AddressModel, StoreFullModel } from 'hx-services';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'hx-manual-address',
  templateUrl: './manual-address.component.html',
  styleUrls: ['./manual-address.component.css'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => HxManualAddressComponent),  // replace name as appropriate
      multi: true
    }
  ]
})
export class HxManualAddressComponent implements ControlValueAccessor, OnDestroy, OnChanges {
  @Input() addresses: AddressModel[] = [];
  @Input() store?: StoreFullModel;
  addressOptions: AddressModel[] = [];
  disabled = false;
  addressModel?: string;
  private selectedVal: AddressModel | undefined;
  set selected(val) {
    this.selectedVal = val;
    this.addressModel = val?.address;
    this.onChange(val);
  }

  get selected() {
    return this.selectedVal;
  }
  private $destroyed = new Subject<void>();

  ngOnChanges(changes: SimpleChanges): void {
    // console.log('[manual-address] ngOnChanges', changes);
    if (changes['addresses'] || changes['store']) {
      this.addressOptions = (this.addresses ?? []).filter(addr => addr.cityId === this.store?.city?.id)
        .sort((a, b) => {
          if (a.alias && b.alias) {
            return a.alias.localeCompare(b.alias);
          }
          if (a.alias) {
            return -1;
          }
          if (b.alias) {
            return 1;
          }
          if (a.lastUsageDate && b.lastUsageDate) {
            return new Date(b.lastUsageDate).getMilliseconds() - new Date(a.lastUsageDate).getMilliseconds();
          }
          return b.address.localeCompare(a.address);
        })
        .slice(0, 7);
    }
  }

  writeValue(val: AddressModel | undefined): void {
    if (val) {
      this.selectAddress(val);
    } else {
      this.selected = undefined;
    }
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {

  }

  onChange(val: any) {
  }

  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  ngOnDestroy(): void {
    this.$destroyed.next();
    this.$destroyed.complete();
  }

  selectAddress(addr: AddressModel): void {
    this.selected = addr;
  }

  onModelChanged() {
    this.selected = {
      address: this.addressModel
    } as AddressModel;
  }
}
