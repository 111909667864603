import { Component, OnDestroy, OnInit } from '@angular/core';

import {
  ClientFullModel,
  ClientRequest,
  CountryModel,
  HxAuthService,
  HxCallcenterService,
  HxClientService,
  HxOrderService,
  HxRejectionService,
  OrderRowModel,
  uiLabel,
  UpdateOrderResponse
} from 'hx-services';
import { PushNotificationsService } from '@callcenter-app/service/push-notification.service';
import { AsteriskNotifyService } from '@callcenter-app/service/asterisk-notify.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { environment } from '@callcenter-env/environment';
import { HxToastrService } from 'hx-component';
import { TranslocoService } from '@ngneat/transloco';
import Timeout = NodeJS.Timeout;

interface CityCountModel {
  name: string;
  date: string;
  count: number;
}

@Component({
  selector: 'app-call-center.m-grid__item.m-grid__item--fluid.m-wrapper',
  templateUrl: './call-center.component.html',
  styleUrls: ['./call-center.component.css']
})
export class CallCenterComponent implements OnInit, OnDestroy {
  private emptyUser: { callId?: string, phone?: string, event?: string, country?: CountryModel } = {};
  profile?: ClientFullModel;
  clientId = 0;
  showIncomingCall = false;
  incomingProfile?: ClientFullModel;
  isMobile = true;
  currentMobile = false;
  clientPhone?: string;
  currentPhone?: string;
  operatorId?: string;
  currentUser = {...this.emptyUser}; // текущий звонящий клиент
  newUser = {...this.emptyUser}; // новый звонящий клиент
  country?: CountryModel;
  orderList: OrderRowModel[] = [];
  statusMap = new Map<string, string>([
    ['CREATED', 'info'],
    ['PACKED', 'warning'],
    ['DECORED', 'focus'],
    ['SHIPPED', 'warning'],
    ['RECEIVED', 'success'],
    ['CANCELLED', 'danger'],
    ['NEW', 'info'],
    ['REJECTED', 'danger'],
    ['SHIPMENT', 'warning'],
    ['DELIVERED', 'focus'],
    ['PAID', 'success'],
    ['TAKEN', 'warning'],
    ['RETURNED', 'metal'],
    ['REFUNDED', 'danger'],
  ]);
  selectedOrderId?: number;
  lastSelectedCity?: string;
  cityCount: CityCountModel[] = [];

  isLoading = {
    profile: false,
    client: false
  };

  customPatterns = {
    'X': {
      pattern: new RegExp('/|0|4|5|6|7|/'),
    }, '9': {
      pattern: new RegExp('/|9|/'),
    }, 'N': {
      pattern: new RegExp('^(?!3)'),
    }, '0': {
      pattern: new RegExp('\\d'),
    }
  };
  isNotProd = !environment.production;
  uniqueNumber?: string;
  private $destroyed = new Subject<void>();
  private timeout?: Timeout;

  constructor(
    private toastr: HxToastrService,
    private pushNotification: PushNotificationsService,
    private callService: HxCallcenterService,
    private clientService: HxClientService,
    private asteriskNotifyService: AsteriskNotifyService,
    private orderService: HxOrderService,
    private rejectionService: HxRejectionService,
    private auth: HxAuthService,
    private tr: TranslocoService,
  ) {
  }

  ngOnInit() {
    this.operatorId = this.auth.user.asteriskId;
    if (this.operatorId) {
      this.loadLastCall();
    }
    this.startAsteriskHandler();
  }

  typedClient(event: string): void {
    let phone = event;
    if (phone && this.country) {
      const prefix = this.country.phonePrefix.replace(/\D+/g, '');
      phone = prefix + phone.replace(/\D+/g, '');
      if (phone.length === (prefix.length + this.country.phoneLength)) {
        this.isLoading.client = true;
        const initClientFn = (client: ClientFullModel) => {
          this.clientId = client.client.id;
          this.clientPhone = client.client.phone;
          this.loadClientOrders();
        };
        this.clientService.getClientByPhone(phone).then(async result => {
          if (!result) {
            const clientPhone = (prefix + phone).replace(/\D+/g, '');
            const obj = {
              phone: clientPhone,
              countryId: this.country?.id
            } as ClientRequest;
            try {
              await this.clientService.saveClient(obj);
              const clientFull = await this.clientService.getClientByPhone(phone);
              initClientFn(clientFull);
            } finally {
              this.isLoading.client = false;
            }
          }
        });
      }
    }
  }

  ngOnDestroy() {
    if (this.timeout) {
      clearTimeout(this.timeout);
    }
    this.$destroyed.next();
    this.$destroyed.complete();
  }

  closeAlertCall(): void {
    this.showIncomingCall = false;
    this.newUser = {...this.emptyUser};
  }

  acceptIncomingCall() {
    this.rejectionService.clearRejection();
    this.currentPhone = undefined;
    this.isMobile = this.currentMobile;
    this.loadData(this.incomingProfile);
    this.showIncomingCall = false;
    this.uniqueNumber = undefined;
    this.incomingProfile = undefined;
    this.currentUser = this.newUser;
    this.newUser = {...this.emptyUser};
    this.selectedOrderId = undefined;
  }

  editOrder(order: OrderRowModel) {
    this.uniqueNumber = order.uniqueNumber;
    this.orderService.startOrderModification(order.id).subscribe(() => this.selectedOrderId = order.id);
  }

  onOrderUpdated(obj: UpdateOrderResponse) {
    if (obj && obj.number) {
      this.toastr.success(this.tr.translate('call-center.ts.create-order', {uniqueNumber: obj.uniqueNumber}));
    }
    this.selectedOrderId = undefined;
    this.uniqueNumber = undefined;
    this.loadClientOrders();
    // this.profile = undefined;
    // this.currentUser = {...this.emptyUser};
  }

  onOrderCreated(orderId: number) {
    this.selectedOrderId = orderId;
  }

  private loadData(profile?: ClientFullModel): void {
    if (profile) {
      this.profile = profile;
      this.profile.client.phone = this.transformPhone(this.profile.client.phone, this.profile.country?.phoneLength);
      this.clientId = profile.client.id;
      this.country = profile.country;
      this.clientPhone = this.isMobile ? profile.client.phone : undefined;
      const currentPhone = this.isMobile ? profile.client.phone : this.currentPhone;
      if (currentPhone) {
        this.currentPhone = this.transformPhone(currentPhone, this.profile.country?.phoneLength);
      } else {
        this.currentPhone = undefined;
      }
      this.loadClientOrders();
    }
  }

  private startAsteriskHandler() {
    this.asteriskNotifyService.notifyObs.pipe(takeUntil(this.$destroyed)).subscribe(result => {
      if (this.currentUser.callId === result.callId) {
        this.currentUser.event = result.event;
      }

      if (this.newUser.callId === result.callId) {
        this.newUser.event = result.event;
        if (result.event === 'finished') {
          this.showIncomingCall = false;
          this.incomingProfile = undefined;
          this.newUser = {...this.emptyUser};
        }
      }

      if (result.event === 'ringing') {
        this.country = result.country;

        this.loadClientProfile(result.clientPhone);
        if (this.currentUser.callId) {
          this.newUser = {
            callId: result.callId,
            phone: result.clientPhone,
            event: result.event,
          };
          this.currentMobile = result.mobile;
        } else {
          this.currentUser = {
            callId: result.callId,
            phone: result.clientPhone,
            event: result.event,
          };
          this.isMobile = result.mobile;
        }
      }
    });
  }

  private async loadClientOrders() {
    this.cityCount = [];
    this.lastSelectedCity = undefined;
    const {list} = await this.orderService.getOrderList({
      limit: 5,
      clientId: this.clientId,
    });

    this.orderList = list.slice(0, 3);
    this.cityCount = Object.values(list.reduce((prev: {[key: string]: {name: string, date: string, count: number}}, r: OrderRowModel) => {
      const title = uiLabel(this.tr.getActiveLang(), r.cityTitle) ?? '-';
      if (prev[title]) {
        prev[title].count += 1;
      } else {
        prev[title] = {name: title, date: r.date, count: 1};
      }
      return prev;
    }, {}));
    this.cityCount.sort((a: CityCountModel, b: CityCountModel) => b.count - a.count || new Date(b.date).getDate() - new Date(a.date).getDate());
    if (this.cityCount[0]) {
      this.lastSelectedCity = this.cityCount[0].name;
    }
  }

  private transformPhone(phone: string, phoneLength?: number): string {
    if (!phoneLength) {
      phoneLength = 3;
    }
    return phone.substring(phone.length - phoneLength);
  }

  private loadClientProfile(phone: string) {
    this.isLoading.profile = true;
    this.clientService.getClientByPhone(phone).then(clientResult => {
      if (clientResult) {
        if (this.pushNotification.permission === 'granted') {
          const notificationName = clientResult.client.fullname || 'Входящий вызов';
          this.pushNotification.create(notificationName, {
            body: clientResult.client.phone,
            icon: '/assets/images/phone.png',
          }).subscribe(res => console.log(res), err => console.log(err));
        }

        if (this.profile && this.profile.client.phone) {
          this.showIncomingCall = true;
          this.country = this.profile.country;
          this.incomingProfile = clientResult;
        } else {
          this.loadData(clientResult);
        }
        this.isLoading.profile = false;
      } else {
        this.timeout = setTimeout(() => {
          this.loadClientProfile(phone);
        }, 1000);
      }
    });
  }

  private loadLastCall() {
    if (!this.operatorId) {
      console.error('operatorId is undefined');
      return;
    }
    this.callService.getLastCallByAsteriskId(this.operatorId).subscribe(lastCallInfo => {
      if (lastCallInfo.phone) {
        this.isMobile = lastCallInfo.mobile;
        this.loadClientProfile(lastCallInfo.phone);
        this.currentUser.callId = lastCallInfo.callId;
        this.currentUser.phone = lastCallInfo.phone;
        this.currentUser.event = lastCallInfo.event;
        this.currentUser.country = lastCallInfo.country;
        if (lastCallInfo.cartOrderIds && lastCallInfo.cartOrderIds.length > 0) {
          this.selectedOrderId = lastCallInfo.cartOrderIds[0];
        }
      }
    });
  }
}
