import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { HxOrderService, OrderResponse, PaymentType } from 'hx-services';
import { ToastrService } from 'ngx-toastr';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'app-pay-invoice-modal',
  templateUrl: './pay-invoice.modal.html',
  styleUrls: ['./pay-invoice.modal.css']
})
export class PayInvoiceModal implements OnInit {

  @Input() order!: OrderResponse;
  clientPhone?: string;
  isLoading = {
    sendPayInvoice: false,
    checkPaid: false,
  };

  constructor(
    private modalInstance: NgbActiveModal,
    private orderService: HxOrderService,
    private toastr: ToastrService,
  ) {
  }

  async ngOnInit() {
    this.clientPhone = this.order.phone?.substring(1);
  }

  dismissModal() {
    this.modalInstance.dismiss();
  }

  sendPayInvoice() {
    this.isLoading.sendPayInvoice = true;
    const phone = '7' + this.clientPhone;
    this.orderService.sendPayInvoice(this.order.id, {
      paymentType: PaymentType.KASPI,
      phone: phone,
    }).then(res => {
      this.toastr.success(`Счет на оплату отправлен клиенту на номер +${phone}, сумма ${res.total}`);
      this.isLoading.sendPayInvoice = false;
      if (!this.order.phone) {
        this.orderService.orderUpdated();
      }
    }, err => this.isLoading.sendPayInvoice = false);
  }

  checkPaid() {
    this.isLoading.checkPaid = true;
    this.orderService.checkPaymentStatus({orderId: this.order.id, type: PaymentType.KASPI})
      .pipe(finalize(() => this.isLoading.checkPaid = false))
      .subscribe(result => {
        if (result.paid) {
          this.toastr.success('Заказ оплачен!');
        } else {
          this.toastr.info('Заказ НЕ оплачен');
        }
      }, err => console.error(err));
  }

}
