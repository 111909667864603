import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from '@callcenter-app/app.module';
import { environment } from '@callcenter-env/environment';
import 'hammerjs';

if (environment.production) {
  enableProdMode();
}
if (environment.production) {
  console.log = () => {};
}
//
platformBrowserDynamic().bootstrapModule(AppModule);
