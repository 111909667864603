import { Component, EventEmitter, forwardRef, Input, OnInit, Output } from '@angular/core';
import { BrandModel, HxBrandService } from 'hx-services';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'hx-brand-select',
  templateUrl: './brand-select.component.html',
  styleUrls: ['./brand-select.component.css'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => HxBrandSelectComponent),
    multi: true
  }]
})
export class HxBrandSelectComponent implements OnInit, ControlValueAccessor {

  @Input() multiple = false;
  @Input() autoselect = true;
  @Output() selectChange = new EventEmitter<BrandModel | BrandModel[] | undefined>();

  disabled = false;
  options: BrandModel[] = [];
  selected: number | number[] | undefined;

  constructor(
    private brandService: HxBrandService,
  ) {
  }

  ngOnInit(): void {
    this.brandService.getMyBrands().subscribe(brands => {
      this.options = brands;
      if (this.autoselect && (this.options ?? []).length === 1) {
        this.selected = this.options[0].id;
        this.onModelChanged(this.selected);
      }
    });
  }

  onModelChanged(val: any) {
    this.onChange(val);
    if (!this.multiple) {
      if (this.selected) {
        this.selectChange.emit(this.options.find(p => p.id === this.selected));
      } else {
        this.selectChange.emit();
      }
    } else {
      if (Array.isArray(this.selected)) {
        const ids: number[] = this.selected;
        this.selectChange.emit(this.options.filter(p => ids.includes(p.id)));
      } else {
        this.selectChange.emit([]);
      }
    }
  }

  onChange(val: any) {
    // console.log('onChange called', val);
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  writeValue(obj?: number | number[]): void {
    this.selected = obj;
  }
}
