<div class="m-content">
  <div class="row" *ngIf="isNotProd">
    <div class="col-lg-9">
      <app-asterisk-demo [callId]="currentUser.callId"></app-asterisk-demo>
    </div>
  </div>
  <div *ngIf="!operatorId" class="alert alert-danger m-alert m-alert--icon m-alert--air m-alert--square m--margin-bottom-30" role="alert">
    <div class="m-alert__icon">
      <i class="la la-warning"></i>
    </div>
    <div class="m-alert__text">
      <b>Внимание проблемы в системе!</b> <br>
      <span *ngIf="!operatorId">Вы не выбрали Номер Оператора - выйдите из системы и войдите снова</span>
    </div>
  </div>

  <div class="row">
    <div class="col-lg-4">
      <div style="width: 100%;font-size: .9rem;" *ngIf="showIncomingCall" class="m-alert m-alert--icon m-alert--outline alert alert-success alert-dismissible fade show" role="alert">
        <div class="m-alert__text">
          {{ 'newcall.event.' + newUser.event | transloco }}
          {{ incomingProfile?.client?.phone }}
          <div *ngIf="incomingProfile?.client?.fullname">{{ incomingProfile?.client?.fullname }}</div>
        </div>

        <div class="m-alert__actions">
          <button type="button" (click)="acceptIncomingCall()" class="btn btn-brand btn-sm m-btn m-btn--wide">
            <i class="la la-phone m-animate-shake" style="vertical-align: middle; margin-right: 5px;" *ngIf="newUser.event === 'talking'"></i>
            Принять
          </button>
        </div>

        <button *ngIf="newUser.event === 'ringing'" type="button" (click)="closeAlertCall()" class="close alert__close-btn" aria-label="Close"></button>
      </div>
    </div>
  </div>

  <div class="row" *ngIf="country && profile">
    <div class="col-lg-9">
      <div style="width: 100%;font-size: .9rem;" class="m-alert m-alert--icon alert alert-dismissible fade show">
        <div class="m-alert__text">
          <div class="row">
            <div class="col-lg-3">
              Клиент:
              <input [patterns]="customPatterns" [showMaskTyped]="true" [prefix]="country.phonePrefix"
                     [mask]="country.phoneMask" type="text"
                     [(ngModel)]="currentPhone" (ngModelChange)="typedClient($event)" class="form-control min-width-155">
              <div>
                <small class="is--danger">Только моб. номера</small>
                <hx-client-indicator [clientId]="clientId"></hx-client-indicator>
              </div>
            </div>
            <div class="col-lg-9 align-self-center text-right">
              {{ currentUser.country?.title | uiLabel }} <span *ngIf="lastSelectedCity">, {{ lastSelectedCity }}</span>
            </div>
          </div>

          <table *ngIf="orderList?.length" class="table table-hover table-outline table-vcenter card-table">
            <thead>
            <tr>
              <th>
                #
              </th>
              <th>
                <span>дата</span>
              </th>
              <th>
                <span>статус</span>
              </th>
              <th>
                <span>заказ</span>
              </th>
              <th>
                <span>сумма</span>
              </th>
              <th>
                <a [routerLink]="['/orders/list']" [queryParams]="{clientId: clientId, activeCart: false}" target="_blank"> все заказы </a>
              </th>
            </tr>
            </thead>
            <tbody class="m-datatable__body">
            <tr *ngFor="let i = index; let item of orderList">
              <td>
                <a target="_blank" [routerLink]="'/orders/' + item.id">
                  {{ item.uniqueNumber }}
                </a>
                <span ngbTooltip="Заказ создан (по времени Астаны)">{{item.createDate | date: 'dd.MM.yyyy HH:mm'}}</span>
              </td>
              <td *ngIf="!item.deliveryRangeTime">{{ item.date | date:'dd.MM HH:mm' }}</td>
              <td *ngIf="item.deliveryRangeTime">{{ item.date | date:'dd.MM' }} {{ item.deliveryRangeTime }}</td>

              <td class="m-datatable__cell text-nowrap">
                <span [class]="'tag tag-' + statusMap.get(item.action)">
                  {{ 'action.' + item.action | transloco }}
                </span>
              </td>
              <td class="product-list">
                <div *ngFor="let cake of item.products">
                  <div *ngIf="cake.status !== 'CANCELLED' && item.action !== 'CANCELLED'">
                    {{ cake.title | uiLabel }} <span *ngIf="cake.priceAmount !== 1">{{cake.priceAmount}}</span>
                  </div>

                  <div *ngIf="item.action === 'CANCELLED' && cake.status === 'CANCELLED'" class="is--red">
                    {{ cake.title | uiLabel }} <span *ngIf="cake.priceAmount !== 1">{{cake.priceAmount}}</span> - {{ cake.amount }}
                  </div>
                </div>
              </td>
              <td>
                <span *ngIf="item.total">
                  {{ item.total | uiCurrency: item.currency }}
                </span>
              </td>
              <td>
                <a class="clickable" (click)="editOrder(item)" title="Изменить">изменить</a>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-lg-9">
      <div class="m-portlet m-portlet--head-solid-bg m-portlet--bordered"
        [ngClass]="{'m-portlet--danger': currentUser.event === 'finished', 'm-portlet--warning': currentUser.event === 'ringing', 'm-portlet--success': currentUser.event === 'talking'}">
        <div class="m-portlet__head">
          <div class="m-portlet__head-caption">
            <div class="m-portlet__head-title">
              <h3 class="m-portlet__head-text">
                <ng-container *ngIf="uniqueNumber">
                  Изменение заказа {{uniqueNumber}}
                </ng-container>
                <ng-container *ngIf="!uniqueNumber">
                  Новый заказ
                </ng-container>
                <small *ngIf="currentUser.event && profile && profile.client.phone">
                  {{ 'call.event.' + currentUser.event | transloco }} {{ profile.client.fullname }} {{ currentUser.phone }}
                </small>
              </h3>
            </div>
          </div>
          <div class="m-portlet__head-tools">
          </div>
        </div>

        <div class="m-portlet__body">
          <hx-order-new *ngIf="selectedOrderId || profile?.client?.phone || currentUser.callId"
                        [phone]="currentUser.phone"
                        [callId]="currentUser.callId"
                        [orderId]="selectedOrderId"
                        (orderCreate)="onOrderCreated($event)"
                        (orderUpdate)="onOrderUpdated($event)"></hx-order-new>
        </div>
      </div>
    </div>
  </div>
  <!--End::Main Portlet-->
</div>
