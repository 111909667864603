import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { HxUserService, UserComponentModel } from 'hx-services';

@Component({
  selector: 'hx-managers-info',
  templateUrl: './managers-info.component.html',
  styleUrls: ['./managers-info.component.css']
})
export class HxManagersInfoComponent implements OnChanges {
  @Input() groupId?: number;
  list: UserComponentModel[] = [];
  show = false;

  constructor(
    private userService: HxUserService,
  ) {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['groupId'] && changes['groupId'].currentValue) {
      this.load();
    }
  }

  open() {
    this.show = !this.show;
  }

  private load() {
    this.userService.getManagers({ groupId: this.groupId }).subscribe(result => {
      this.list = result;
    });
  }
}
