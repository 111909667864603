import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { CountryModel, Gender, getFormErrors, HxCountryService, HxUserService, MaritalStatus, SaveCallCenterUserRequest, UserProfileModel } from 'hx-services';
import { TranslocoService } from '@ngneat/transloco';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { HxToastrService } from 'hx-component';
import { isArray } from '@amcharts/amcharts4/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-user-edit',
  templateUrl: './user-edit.component.html',
  styleUrls: ['./user-edit.component.css']
})
export class UserEditComponent implements OnInit, OnDestroy {
  @Input() userProfile?: UserProfileModel;
  @Input() isEdit = false;
  @Input() countryId?: number;
  formUserInfo = new FormGroup({
    id: new FormControl<number | null>(null),
    phone: new FormControl<string | null>(null, [Validators.required]),
    firstname: new FormControl<string | null>(null, [Validators.required]),
    lastname: new FormControl<string | null>(null, [Validators.required]),
    patronymic: new FormControl<string | null>(null),
    email: new FormControl<string | null>(null),
    birthdate: new FormControl<string | null>(null, [Validators.required]),
    gender: new FormControl<string | null>(null, [Validators.required]),
    address: new FormControl<string | null>(null),
    maritalStatus: new FormControl<string | null>(null),
    iin: new FormControl<string | null>(null),
    position: new FormControl<string | null>(null),
    countryId: new FormControl<number | null>(null),
    countryIds: new FormControl<number[] | null>(null, [Validators.required]),
  });
  customPatterns = {
    'X': {
      pattern: new RegExp('/|0|4|5|6|7|/'),
    }, '9': {
      pattern: new RegExp('/|9|/'),
    }, 'N': {
      pattern: new RegExp('^(?!3)'),
    }, '0': {
      pattern: new RegExp('\\d'),
    }
  };
  isLoading = {
    user: false,
    save: false,
  };
  country?: CountryModel;
  countryIds: number[] = [];
  MaritalStatus = MaritalStatus;
  Gender = Gender;
  private $destroyed = new Subject<void>();

  constructor(
    private formBuilder: UntypedFormBuilder,
    private userService: HxUserService,
    private toastr: HxToastrService,
    private tr: TranslocoService,
    private countryService: HxCountryService,
    public modalInstance: NgbActiveModal,
  ) {
  }

  async ngOnInit() {
    if (this.userProfile) {
      const countries = await this.userService.getMembershipCountries(this.userProfile.id);
      this.countryIds = countries.map(r => r.id);
    }
    if (this.isEdit && this.countryId) {
      this.country = await this.countryService.getCountryById(this.countryId);
    }

    this.updateForm();

    this.formUserInfo.controls['phone'].valueChanges.pipe(takeUntil(this.$destroyed)).subscribe(phone => {
      if (this.country && phone) {
        const prefix = this.country.phonePrefix.replace(/\D+/g, '');
        const fullPhone = prefix + phone.replace(/\D+/g, '');
        if (fullPhone.length === (prefix.length + this.country.phoneLength)) {
          if (!this.isEdit) {
            this.isLoading.user = true;
            this.userService.getUserByPhone(fullPhone).subscribe(userProfile => {
              if (userProfile) {
                this.userProfile = userProfile;
                this.formUserInfo.patchValue({
                  id: userProfile.id,
                  firstname: userProfile.firstname,
                  lastname: userProfile.lastname,
                  patronymic: userProfile.patronymic,
                  email: userProfile.email,
                  birthdate: userProfile.birthdate,
                  gender: userProfile.gender,
                  address: userProfile.address,
                  maritalStatus: userProfile.maritalStatus,
                  iin: userProfile.iin,
                  position: userProfile.position
                });
              }
              this.isLoading.user = false;
            }, () => {
              this.isLoading.user = false;
              this.userProfile = undefined;
            });
          }
        } else {
          this.userProfile = undefined;
        }
      }
    });
  }

  ngOnDestroy() {
    this.$destroyed.next();
    this.$destroyed.complete();
  }

  resetUserPassword() {
    if (this.userProfile?.id) {
      this.userService.resetPassword(this.userProfile.id).then(
        () => this.toastr.success(this.tr.translate('user-edit.messageSuccess')),
        () => this.toastr.error(this.tr.translate('user-edit.messageError')));
    }
  }

  async onSubmitUserInfoForm() {
    if (!this.countryId) {
      this.toastr.error(this.tr.translate('user-edit.country.empty'));
      return;
    }
    Object.values(this.formUserInfo.controls).forEach(ctrl => {
      ctrl.markAsDirty();
      ctrl.updateValueAndValidity();
    });
    if (this.formUserInfo.valid) {
      this.formUserInfo.disable();
      const formModel = this.formUserInfo.value;
      if (!formModel.firstname || !formModel.lastname || !formModel.birthdate || !formModel.gender) {
        throw new Error();
      }
      const model: SaveCallCenterUserRequest = {
        id: formModel.id ?? undefined,
        countryId: this.countryId,
        phone: `${this.country?.phonePrefix}${formModel.phone}`.replace(/\D+/g, ''),
        email: formModel.email ?? undefined,
        firstname: formModel.firstname,
        lastname: formModel.lastname,
        patronymic: formModel.patronymic ?? undefined,
        birthdate: formModel.birthdate,
        iin: formModel.iin ?? undefined,
        gender: formModel.gender as Gender,
        address: formModel.address ?? undefined,
        maritalStatus: formModel.maritalStatus ?? undefined,
        position: formModel.position ?? undefined,
        countryIds: formModel.countryIds ?? []
      };
      this.isLoading.save = true;
      try {
        const result = await this.userService.saveCallCenterUser(model);
        this.formUserInfo.markAsPristine();
        this.toastr.success(this.tr.translate('user-edit.saveData'));
        this.modalInstance.close();
      } finally {
        this.formUserInfo.enable();
        this.isLoading.save = false;
      }
    } else {
      console.log('formErrors', getFormErrors(this.formUserInfo));
    }
  }

  countryChanged(country: CountryModel | CountryModel[] | undefined) {
    if (isArray(country)) {
      this.country = country[0];

    } else {
      this.country = country;
    }
  }

  private updateForm() {
    if (this.userProfile) {
      const formUserInfoModel: Partial<SaveCallCenterUserRequest> = {
        id: this.userProfile.id,
        phone: this.userProfile.phone?.substring(this.userProfile.phone?.length  - (this.country?.phoneLength ?? 0), (this.country?.phoneLength ?? 0) + this.userProfile.phone?.length),
        firstname: this.userProfile.firstname,
        lastname: this.userProfile.lastname,
        patronymic: this.userProfile.patronymic,
        email: this.userProfile.email,
        birthdate: this.userProfile.birthdate,
        gender: this.userProfile.gender,
        address: this.userProfile.address,
        maritalStatus: this.userProfile.maritalStatus,
        iin: this.userProfile.iin,
        position: this.userProfile.position,
        countryId: this.countryId,
        countryIds: this.countryIds,
      };
      this.formUserInfo.patchValue(formUserInfoModel);
    }
  }
}
