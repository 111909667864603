import { Component, EventEmitter, Inject, Input, OnInit, Optional, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { NgbDate, NgbDatepickerI18n, NgbInputDatepicker } from '@ng-bootstrap/ng-bootstrap';
import { HxDatepickerLocale } from '../common';
import { endOfISOWeek, endOfMonth, endOfQuarter, format, parse, startOfISOWeek, startOfMonth, startOfQuarter, subMonths, subQuarters, subWeeks } from 'date-fns';
import { DateNumberRange } from 'hx-services';
import { HX_DATE_FORMAT, HX_DEFAULT_DATE_FORMAT } from '../datepicker';

@Component({
  selector: 'hx-daterangepicker',
  templateUrl: './daterangepicker.component.html',
  styleUrls: ['./daterangepicker.component.css'],
  encapsulation: ViewEncapsulation.None,
  providers: [{provide: NgbDatepickerI18n, useClass: HxDatepickerLocale}]
})
export class HxDateRangePickerComponent implements OnInit {
  @Output() dateRangeChange = new EventEmitter<DateNumberRange>();

  private _range: DateNumberRange | undefined;
  @Input()
  set range(val) {
    this._range = val;
    this.rangeModel = this._range;
    this.viewModel = this.getViewModel();
    this.viewModelChanged({emit: false});
  }

  get range() {
    return this._range;
  }

  @ViewChild('dateRangePicker') dateRangePicker!: NgbInputDatepicker;

  rangeModel?: DateNumberRange;
  viewModel?: string;

  fromDate?: NgbDate;
  toDate?: NgbDate;
  hoveredDate?: NgbDate;
  placeholder?: string;
  private initialChange = true;

  constructor(@Optional() @Inject(HX_DATE_FORMAT) private dateFormat: string) {
    if (!this.dateFormat) {
      this.dateFormat = HX_DEFAULT_DATE_FORMAT;
    }
  }

  ngOnInit(): void {
    this.placeholder = this.dateFormat.toLowerCase() + '/' + this.dateFormat.toLowerCase();
  }

  viewModelChanged(opts: { emit: boolean } = {emit: true}) {
    if (this.initialChange) {
      this.initialChange = false;
      return;
    }
    if (!this.viewModel) {
      this.rangeModel = undefined;
      if (opts.emit) {
        this.dateRangeChange.emit(undefined);
      }
    } else if (this.viewModel.includes('/')) {
      const rangeArr = this.viewModel.split('/');
      const from = rangeArr[0];
      const to = rangeArr[1];
      if ((from.length + to.length) === 20) {
        const fromDate = parse(from, this.dateFormat, new Date());
        const toDate = parse(to, this.dateFormat, new Date());
        this.fromDate = new NgbDate(fromDate.getFullYear(), fromDate.getMonth() + 1, fromDate.getDate());
        this.toDate = new NgbDate(toDate.getFullYear(), toDate.getMonth() + 1, toDate.getDate());

        if (opts.emit) {
          this.rangeModel = {
            from: fromDate.getTime(),
            to: toDate.getTime()
          };
          this.dateRangeChange.emit(this.rangeModel);
        }
      }
    }
  }

  onDateSelected(date: NgbDate): void {
    if (!this.fromDate && !this.toDate) {
      this.fromDate = date;
    } else if (this.fromDate && !this.toDate && (date.after(this.fromDate) || date.equals(this.fromDate))) {
      this.toDate = date;
    } else {
      this.toDate = undefined;
      this.fromDate = date;
    }
    if (this.fromDate && this.toDate) {
      this.rangeModel = {
        from: new Date(this.fromDate.year, this.fromDate.month - 1, this.fromDate.day).getTime(),
        to: new Date(this.toDate.year, this.toDate.month - 1, this.toDate.day).getTime()
      };
      this.viewModel = this.getViewModel();
      this.viewModelChanged();
      this.dateRangePicker.close();
    }
  }

  isHovered(date: NgbDate): boolean {
    return this.fromDate !== undefined && this.toDate === undefined
      && this.hoveredDate !== undefined && date.after(this.fromDate) && date.before(this.hoveredDate);
  }

  isInside(date: NgbDate): boolean {
    return date.after(this.fromDate) && date.before(this.toDate);
  }

  isRange(date: NgbDate): boolean {
    return date.equals(this.fromDate) || date.equals(this.toDate) || this.isInside(date) || this.isHovered(date);
  }

  setLastWeek() {
    const fromDate = startOfISOWeek(subWeeks(new Date(), 1));
    const toDate = endOfISOWeek(subWeeks(new Date(), 1));
    this.setDates(fromDate, toDate);
  }

  setLastMonth() {
    const fromDate = startOfMonth(subMonths(new Date(), 1));
    const toDate = endOfMonth(subMonths(new Date(), 1));
    this.setDates(fromDate, toDate);
  }

  setLastQuarter() {
    const fromDate = startOfQuarter(subQuarters(new Date(), 1));
    const toDate = endOfQuarter(subQuarters(new Date(), 1));
    this.setDates(fromDate, toDate);
  }

  setThisWeek() {
    const fromDate = startOfISOWeek(new Date());
    const toDate = endOfISOWeek(new Date());
    this.setDates(fromDate, toDate);
  }

  setThisMonth() {
    const fromDate = startOfMonth(new Date());
    const toDate = endOfMonth(new Date());
    this.setDates(fromDate, toDate);
  }

  setThisQuarter() {
    const fromDate = startOfQuarter(new Date());
    const toDate = endOfQuarter(new Date());
    this.setDates(fromDate, toDate);
  }

  apply() {
    if (this.fromDate && this.toDate) {
      this.rangeModel = {
        from: new Date(this.fromDate.year, this.fromDate.month - 1, this.fromDate.day).getTime(),
        to: new Date(this.toDate.year, this.toDate.month - 1, this.toDate.day).getTime()
      };
      this.viewModel = this.getViewModel();
      this.viewModelChanged();
      this.dateRangePicker.close();
    }
  }

  private setDates(fromDate: Date, toDate: Date): void {
    this.fromDate = this.toNgbDate(fromDate);
    this.toDate = this.toNgbDate(toDate);
    this.dateRangePicker.navigateTo(this.toNgbDate(fromDate));
  }

  private toNgbDate(date: Date): NgbDate {
    return new NgbDate(date.getFullYear(), date.getMonth() + 1, date.getDate());
  }

  private getViewModel(): string {
    if (this.rangeModel && this.rangeModel.from && this.rangeModel.to) {
      return format(new Date(this.rangeModel.from), this.dateFormat) + '/' + format(new Date(this.rangeModel.to), this.dateFormat);
    }
    return '';
  }
}
