<ul ngbNav [(activeId)]="activeTab" (activeIdChange)="onTabChanged($event)" #nav="ngbNav" class="nav-tabs" [destroyOnHide]="false">
  <li [ngbNavItem]="'client'">
    <a ngbNavLink>{{ 'hx.client-edit.client' | transloco }}</a>
    <ng-template ngbNavContent>
      <div *ngIf="!isLoading.get && client" class="m-form m-form--state m-form--label-align-right">
        <div *ngIf="!cityId" class="btn-group" ngbDropdown role="group">
          <button [disabled]="client.id" class="btn btn-outline-success"
                  ngbDropdownToggle>{{(country?.title | uiLabel) || ('country.field.required' | transloco)}}</button>
          <div class="dropdown-menu" ngbDropdownMenu>
            <button ngbDropdownItem (click)="selectCountry(c)" *ngFor="let c of countries">{{c.title | uiLabel}}</button>
          </div>
        </div>

        <div *ngIf="country">
          <div class="row m--margin-bottom-10" style="align-items: center;">
            <div class="col-md-3">{{ 'hx.client-edit.name' | transloco }}</div>

            <div class="col-md-9">
              <input [(ngModel)]="client.fullname" type="text" class="form-control m-input" [placeholder]="'hx.client-edit.name' | transloco">
            </div>
          </div>

          <div class="row m--margin-bottom-10" style="align-items: center;">
            <label class="col-md-3 m--margin-bottom-0">
              {{ 'hx.client-edit.num' | transloco }}
            </label>

            <div class="col-md-9">
              <input type="text" [patterns]="customPatterns" [showMaskTyped]="true" [disabled]="client.id !== undefined"
                     [prefix]="country.phonePrefix" [mask]="country.phoneMask" [(ngModel)]="client.phone"
                     (ngModelChange)="checkPhoneExist()" class="form-control m-input">
              <small class="is--danger">{{ 'hx.client-edit.mobileNum' | transloco }}</small>
              <div *ngIf="errorText.phone" class="alert alert-danger">{{errorText.phone | transloco}}</div>
            </div>
          </div>

          <div class="row m--margin-bottom-10" style="align-items: center;">
            <div class="col-md-3">{{ 'hx.client-edit.happyDay' | transloco }}</div>
            <div class="col-md-9">
              <hx-date-select [(ngModel)]="client.birthDate" (ngModelChange)="onDateChanged()"></hx-date-select>
            </div>
          </div>

          <div *ngIf="clientBirthDate && isBirthDateUpdated" class="row m--margin-bottom-10" style="align-items: center;">
            <div class="col-md-3 is--required">{{ 'hx.client-edit.comment' | transloco }}</div>
            <div class="col-md-9">
              <textarea class="comment" [(ngModel)]="comment"></textarea>
            </div>
          </div>

          <hr>
          <ng-container *ngFor="let item of additionalContacts; let i = index; trackBy: trackById">
            <div *ngIf="transformPhone(item.value) !== client.phone" class="row m--margin-bottom-10">
              <label class="col-md-3 m--margin-bottom-0">
                <span *ngIf="item.type === 'PHONE'">{{ 'hx.client-edit.mobileNumber' | transloco }}</span>
                <span *ngIf="item.type === 'EMAIL'">email</span>
              </label>

              <div class="col-md-9">
                <div class="input-group">
                  <input *ngIf="item.type === 'PHONE'" type="text" [patterns]="customPatterns" [showMaskTyped]="true" [prefix]="country.phonePrefix"
                         [mask]="country.phoneMask" [(ngModel)]="item.value" class="form-control m-input">
                  <input *ngIf="item.type === 'EMAIL'" type="text" [(ngModel)]="item.value" class="form-control m-input">
                  <span class="input-group-btn">
                    <button class="btn btn-danger" (click)="removeContact(i)" [ngbTooltip]="'hx.client-edit.ng.deleteContact' | transloco" type="button">
                      <i class="la la-trash"></i>
                    </button>
                  </span>
                </div>
                <small *ngIf="item.type === 'PHONE'" class="is--danger">{{ 'hx.client-edit.mobileNum' | transloco }}</small>
              </div>
            </div>
          </ng-container>

          <div class="row add-button-container">
            <div class="col-md-12">
              <button class="btn btn-success pull-right" (click)="addAContact('PHONE')" type="button">
                <i class="la la-plus"></i> {{ 'hx.client-edit.btn.addPhone' | transloco }}
              </button>
              <button class="btn btn-success pull-right" (click)="addAContact('EMAIL')" type="button">
                <i class="la la-plus"></i> {{ 'hx.client-edit.btn.addMail' | transloco }}
              </button>
            </div>
          </div>

          <hr>

          <div class="row m--margin-bottom-10 client-address">
            <div class="col-md-12">
              <span *ngFor="let addr of additionalAddresses; let i = index; trackBy: trackById"
                    class="m-badge m-badge--metal m-badge--wide">
                {{cityMap.get(addr.cityId)?.title | uiLabel}}, {{addr.address}}
                <a (click)="removeAddress(addr)"><i class="la la-trash"></i></a>
              </span>
            </div>
          </div>
        </div>

        <div class="actions">
          <button type="button" (click)="submit()" [disabled]="isLoading.clientSubmit" class="btn btn-success">
            {{ 'hx.btn.save' | transloco }}
          </button>

          <button type="button" (click)="cancel()" class="btn btn-secondary">
            {{ 'hx.btn.cancel' | transloco }}
          </button>
        </div>
      </div>
    </ng-template>
  </li>
  <li [ngbNavItem]="'brand'">
    <a ngbNavLink>{{ 'hx.client-edit.info' | transloco }}</a>
    <ng-template ngbNavContent>
      <div class="table-responsive m-datatable--va-top m-datatable m-datatable--default m-datatable--loaded m--margin-top-20 m--margin-bottom-20">
        <table class="m-datatable__table" *ngIf="brandProperties.length && !isLoading.brandProperties">
          <thead class="m-datatable__head">
          <tr class="m-datatable__row m-datatable__row--head">
            <th class="m-datatable__cell">{{ 'hx.client-edit.table.head.brand' | transloco }}</th>
            <th class="m-datatable__cell">VIP</th>
            <th class="m-datatable__cell">{{ 'hx.client-edit.table.head.blacklist' | transloco }}</th>
            <th class="m-datatable__cell">{{ 'hx.client-edit.table.head.note' | transloco }}</th>
            <th class="m-datatable__cell"></th>
          </tr>
          </thead>
          <tbody class="m-datatable__body">
          <tr *ngFor="let even = even; let i = index; let item of brandProperties"
              class="m-datatable__row" [ngClass]="{ 'm-datatable__row--even': even }">
            <td class="m-datatable__cell">
              <span>{{ item.brandTitle }}</span>
            </td>
            <td class="m-datatable__cell"><strong *ngIf="item.vip">VIP</strong></td>
            <td class="m-datatable__cell"><strong *ngIf="item.blacklisted">{{ 'hx.client-edit.table.body.blacklist' | transloco }}</strong></td>
            <td class="m-datatable__cell">{{ item.note }}</td>
            <td class="m-datatable__cell text-nowrap">
              <button type="button" (click)="editBrandProperty(item)"
                      class="btn btn-outline-primary btn-sm m-btn m-btn--icon m--margin-right-10" [ngbTooltip]="'hx.client-edit.ng.change' | transloco">
                <i class="la la-edit"></i>
              </button>
              <button type="button" (click)="removeBrandProperty(item)"
                      class="btn btn-outline-danger btn-sm m-btn m-btn--icon m--margin-right-10" [ngbTooltip]="'hx.client-edit.ng.delete' | transloco">
                <i class="la la-trash"></i>
              </button>
            </td>
          </tr>
          </tbody>
        </table>
      </div>

      <button *ngIf="!brandProperties.length" class="btn btn-primary" (click)="addBrandProperty()">{{ 'hx.client-edit.btn.addNote' | transloco }}</button>
    </ng-template>
  </li>
  <li [ngbNavItem]="'coins'">
    <a ngbNavLink>{{ 'hx.client-edit.coins' | transloco }}</a>
    <ng-template ngbNavContent>
      <div class="m-form m-form--state m-form--label-align-right">
        <div class="row m--margin-bottom-10" style="align-items: center;">
          <div class="col-md-3">{{ 'hx.client-edit.activeCoins' | transloco }}</div>

          <div class="col-md-9">
            <input [(ngModel)]="activeCoinAmount" disabled type="text" class="form-control m-input">
          </div>
        </div>
        <div class="row m--margin-bottom-10" style="align-items: center;">
          <div class="col-md-12">
            <hx-brand-select [(ngModel)]="coinBrandId" (ngModelChange)="onCoinFilterChanged()"></hx-brand-select>
            <hx-country-select [(ngModel)]="coinCountryId" (ngModelChange)="onCoinFilterChanged()"></hx-country-select>
          </div>
        </div>
      </div>
      <div class="table-responsive m-datatable--va-top m-datatable m-datatable--default m-datatable--loaded m--margin-top-20 m--margin-bottom-20">
        <table class="m-datatable__table" *ngIf="!isLoading.coins">
          <thead class="m-datatable__head">
          <tr class="m-datatable__row m-datatable__row--head">
            <th class="m-datatable__cell">{{ 'hx.client-edit.coin.th.operation' | transloco }}</th>
            <th class="m-datatable__cell">{{ 'hx.client-edit.coin.th.amount' | transloco }}</th>
            <th class="m-datatable__cell">{{ 'hx.client-edit.coin.th.activationDate' | transloco }}</th>
            <th class="m-datatable__cell">{{ 'hx.client-edit.coin.th.expireDate' | transloco }}</th>
            <th class="m-datatable__cell"></th>
          </tr>
          </thead>
          <tbody class="m-datatable__body">
          <tr *ngFor="let even = even; let i = index; let t of coinTransactions" class="m-datatable__row" [ngClass]="{ 'm-datatable__row--even': even }">
            <td class="m-datatable__cell">
              <span class="alert" [ngClass]="{'alert-success': t.action === 'EARNED', 'alert-danger': t.action === 'CANCELLED', 'alert-info': t.action === 'SPENT'}">
                {{ ('hx.enum.CoinOperation.' + t.action) | transloco }}
              </span>
            </td>
            <td class="m-datatable__cell"><strong>{{t.amount}}</strong></td>
            <td class="m-datatable__cell"><strong>{{t.activationDate | date: 'dd.MM.yyyy HH:mm'}}</strong></td>
            <td class="m-datatable__cell"><strong>{{t.expireDate | date: 'dd.MM.yyyy HH:mm'}}</strong></td>
            <td class="m-datatable__cell text-nowrap">
              <a target="_blank" [routerLink]="'/orders/' + t.orderId">{{t.orderId}}</a>
            </td>
          </tr>
          </tbody>
        </table>
      </div>

      <hx-loader [isLoading]="isLoading.coins" [length]="coinTransactions.length"></hx-loader>

      <div class="m--align-center" *ngIf="coinTransactions.length && !isLoading.coins">
        <div class="btn-group btn-group-sm">
          <button (click)="seekPrev()" [disabled]="(!seek.hasNext && seek.dir === 'prev') || !seek.val" class="btn btn-sm btn-primary">
            <i class="las la-angle-double-left"></i>
          </button>
          <button (click)="seekNext()" [disabled]="!seek.hasNext && seek.dir === 'next'" class="btn btn-sm btn-primary">
            <i class="las la-angle-double-right"></i>
          </button>
        </div>
      </div>
    </ng-template>
  </li>
  <li [ngbNavItem]="'comments'">
    <a ngbNavLink>{{ 'hx.client-edit.comment' | transloco }}</a>
    <ng-template ngbNavContent>
      <div class="table-responsive m-datatable--va-top m-datatable m-datatable--default m-datatable--loaded m--margin-top-20 m--margin-bottom-20">
        <table class="m-datatable__table" *ngIf="!isLoading.comments">
          <thead class="m-datatable__head">
          <tr class="m-datatable__row m-datatable__row--head">
            <th class="m-datatable__cell">{{ 'hx.client-edit.comment.title' | transloco }}</th>
            <th class="m-datatable__cell">{{ 'hx.client-edit.comment.date' | transloco }}</th>
            <th class="m-datatable__cell">{{ 'hx.client-edit.comment.actor' | transloco }}</th>
            <th class="m-datatable__cell"></th>
          </tr>
          </thead>
          <tbody class="m-datatable__body">
          <tr *ngFor="let even = even; let i = index; let t of comments" class="m-datatable__row" [ngClass]="{ 'm-datatable__row--even': even }">
            <td class="m-datatable__cell">{{ t.text }}</td>
            <td class="m-datatable__cell">{{ t.date | date: 'dd.MM.yyyy HH:mm' }}</td>
            <td class="m-datatable__cell">{{ t.actorFullName }}</td>
          </tr>
          </tbody>
        </table>
      </div>

      <hx-loader [isLoading]="isLoading.comments" [length]="comments.length"></hx-loader>
    </ng-template>
  </li>
</ul>
<div [ngbNavOutlet]="nav"></div>
