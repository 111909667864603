import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { firstValueFrom } from 'rxjs';
import { toHttpParams } from '../utils/globals';
import { StoreWorkTimeModel } from '../vn-api';

interface StoreWorkTimeRequest {
  storeId: number;
  openTime: string;
  closeTime: string;
  date?: string;
  closed?: boolean;
}

@Injectable({
  providedIn: 'root'
})
export class HxStoreWorkTimeService {
  constructor(
    private http: HttpClient,
  ) {
  }

  getStoreWorkTimeById(id: number): Promise<StoreWorkTimeModel> {
    return firstValueFrom(this.http.get<StoreWorkTimeModel>(`/api/vanilla/store-work-times/${id}`));
  }

  getStoreWorkTimeByStoreAndDate(params: { storeId: number, date: string }): Promise<StoreWorkTimeModel> {
    return firstValueFrom(this.http.get<StoreWorkTimeModel>(`/api/vanilla/store-work-times`, {params: toHttpParams(params, true)}));
  }

  saveStoreWorkTime(body: StoreWorkTimeRequest): Promise<void> {
    return firstValueFrom(this.http.post<void>(`/api/vanilla/store-work-times`, body));
  }
}
