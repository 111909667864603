import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { HxOrderService, OrderResponse } from 'hx-services';

interface RefundedOrder extends OrderResponse {
  totalGram?: number;
  totalAmount?: number;
  isTotalShow?: boolean;
}

@Component({
  selector: 'hx-order-refunded-list',
  templateUrl: './order-refunded-list.component.html',
  styleUrls: ['./order-refunded-list.component.css']
})
export class HxOrderRefundedListComponent implements OnInit, OnDestroy {

  @Input({required: true}) orderId!: number;

  isLoading = {
    orders: true
  };

  orders: RefundedOrder[] = [];
  private sub!: Subscription;

  constructor(
    private orderService: HxOrderService,
  ) {
  }

  ngOnInit() {
    this.sub = this.orderService.updateOrderObs.subscribe(() => this.loadOrders());
    this.loadOrders();
  }

  ngOnDestroy() {
    if (this.sub) {
      this.sub.unsubscribe();
    }
  }

  private async loadOrders() {
    const orders: RefundedOrder[] = await this.orderService.getChildOrders(this.orderId);
    this.orders = orders
      .map(order => {
        order.isTotalShow = order.total < order.subTotal || !!order.discounts.length;
        let totalAmount = 0;
        let totalGram = 0;
        order.products.forEach(element => {
          totalAmount += element.amount;
          totalGram += element.weight * element.amount || 0;
        });
        order.totalAmount = totalAmount;
        order.totalGram = totalGram;
        return order;
      })
      .sort((a, b) => new Date(a.createDate).getDate() - new Date(b.createDate).getDate());
  }
}
