<div class="modal-header">
  <h3 class="modal-title">Информация по звонку</h3>

  <button class="close" type="button" (click)="modal.dismiss()">
    <span>×</span>
  </button>
</div>

<div class="modal-body">
  <div class="m-form m-form--state">
    <div class="m-portlet__body">
      <div *ngIf="callDetails && callDetails.length > 0" class="table-responsive m-datatable--va-top m-datatable m-datatable--default m-datatable--loaded m--margin-top-30 m--margin-bottom-30">
        <table class="m-datatable__table text-nowrap">
          <thead class="m-datatable__head">
          <tr class="m-datatable__row m-datatable__row--head">
            <th class="m-datatable__cell">
              <span>Номер</span>
            </th>
            <th class="m-datatable__cell">
              <span>Oператор</span>
            </th>
            <th class="m-datatable__cell">
              <span>Дата</span>
            </th>
            <th class="m-datatable__cell">
              <span>Время ожидания</span>
            </th>
          </tr>
          </thead>
          <tbody class="m-datatable__body">
          <tr *ngFor=" let even = even;let i = index; let callDetail of callDetails"
              class="m-datatable__row" [ngClass]="{ 'm-datatable__row--even': even }">
            <td class="m-datatable__cell">{{ callDetail.operatorId }}</td>
            <td class="m-datatable__cell"><span [ngbTooltip]="callDetail.operatorFullname">{{ callDetail.operatorFullname | fio }}</span></td>
            <td class="m-datatable__cell">{{ callDetail.date | date: 'dd.MM.yy HH:mm:ss' }}</td>
            <td class="m-datatable__cell">{{ callDetail.ringTime | date: 'HH:mm:ss':'UTC' }}</td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
    <hx-loader [isLoading]="isLoading.list" [length]="callDetails.length"></hx-loader>
    <br>
    <div class="text-right">
      <button type="button" (click)="modal.dismiss()" class="btn btn-secondary">
        Отмена
      </button>
    </div>
  </div>
</div>
