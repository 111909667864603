<ng-select [items]="items"
           bindValue="id"
           [placeholder]="'hx.user-search.placeholder.name' | transloco"
           [typeToSearchText]="'hx.user-search.placeholder.search' | transloco"
           [notFoundText]="'hx.user-search.placeholder.noFind' | transloco"
           [loadingText]="'hx.user-search.placeholder.loader' | transloco"
           [multiple]="false"
           [hideSelected]="true"
           [trackByFn]="trackByFn"
           [loading]="itemLoading"
           [typeahead]="itemInput$"
           (change)="onUserChanged($event)"
           [(ngModel)]="userId">
  <ng-template ng-label-tmp let-item="item">
    {{item.phone}} - {{item.lastname}} {{item.firstname}}
  </ng-template>
  <ng-template ng-option-tmp let-item="item">
    {{item.phone}} - {{item.lastname}} {{item.firstname}}
  </ng-template>
</ng-select>
