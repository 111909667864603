<div class="client-address" *ngIf="addressOptions && addressOptions.length > 0">
  <button *ngFor="let addr of addressOptions" (click)="selectAddress(addr)"
          type="button" class="btn m-btn--pill btn-outline-info btn-sm">
    <strong *ngIf="addr.alias">{{addr.alias}}:</strong> {{addr.address}}
    <small *ngIf="addr.entrance || addr.flat">
      (<ng-container *ngIf="addr.entrance">{{ 'hx.manual-address.p' | transloco }}.: {{addr.entrance}} </ng-container> <ng-container *ngIf="addr.flat">{{ 'hx.manual-address.flat' | transloco }}.: {{addr.flat}}</ng-container>)
    </small>
  </button>
</div>

<div>
  <input [(ngModel)]="addressModel" (ngModelChange)="onModelChanged()" autocomplete="false" class="form-control m-input" type="text" [placeholder]="'hx.manual-address.address' | transloco">
</div>
