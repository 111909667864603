import { Routes } from '@angular/router';
import { HxRejectionListComponent } from './rejection-list/rejection-list.component';

export const ROUTES: Routes = [
  {
    path: '',
    children: [
      {
        path: '',
        component: HxRejectionListComponent,
        pathMatch: 'full'
      }
    ]
  }
];
