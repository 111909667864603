/**
 * Vanilla API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type SourceSystem = 'CASHBOX' | 'CALLCENTER' | 'WHATSAPP' | 'INSTAGRAM' | 'KIOSK' | 'MANAGER' | 'ONLINE' | 'ONLINE_APP' | 'WEBKASSA' | 'QUEUE' | 'DELIVERY' | 'VANILLA' | 'SUPPLIER' | 'TELEGRAM' | 'CONFECTIONARY';

export const SourceSystem = {
    CASHBOX: 'CASHBOX' as SourceSystem,
    CALLCENTER: 'CALLCENTER' as SourceSystem,
    WHATSAPP: 'WHATSAPP' as SourceSystem,
    INSTAGRAM: 'INSTAGRAM' as SourceSystem,
    KIOSK: 'KIOSK' as SourceSystem,
    MANAGER: 'MANAGER' as SourceSystem,
    ONLINE: 'ONLINE' as SourceSystem,
    ONLINE_APP: 'ONLINE_APP' as SourceSystem,
    WEBKASSA: 'WEBKASSA' as SourceSystem,
    QUEUE: 'QUEUE' as SourceSystem,
    DELIVERY: 'DELIVERY' as SourceSystem,
    VANILLA: 'VANILLA' as SourceSystem,
    SUPPLIER: 'SUPPLIER' as SourceSystem,
    TELEGRAM: 'TELEGRAM' as SourceSystem,
    CONFECTIONARY: 'CONFECTIONARY' as SourceSystem
};

