<div class="cp__container m-widget4" *ngIf="fileList?.length">
  <div class="m-widget4__item" *ngFor="let file of fileList; let ix = index">
    <div class="m-widget4__img m-widget4__img--icon" (click)="showImage(file)" [ngbTooltip]="'hx.file-list.ng.success' | transloco: { file: file.fileName, date: (file.createDate | date: 'dd.MM.yyyy HH:mm') }">
      <img *ngIf="file.mimeType !== 'application/pdf'" [src]="baseLink + file.guid" alt="">
      <i *ngIf="file.mimeType === 'application/pdf'" class="la la-file-pdf-o la-2x"></i>
    </div>
    <div class="cp__text">
      <i *ngIf="file.temporary" [ngbTooltip]="'hx.file-list.ng.file' | transloco" class="la la-tachometer"></i> {{ file.fileName }}
    </div>
    <a (click)="deleteFile(file, ix)" [ngbTooltip]="'hx.file-list.ng.delete' | transloco" class="cp__remove">
      <i class="la la-remove remove"></i>
    </a>
  </div>
</div>
<ng-template #templateRef let-modal>
  <div class="modal-header">
    <h3 class="modal-title">{{ image.name }}</h3>
    <button class="close" type="button" (click)="closeModal(modal)"></button>
  </div>

  <div class="modal-body text-center">
    <iframe *ngIf="image.mimeType === 'application/pdf'" class="iframe" width="100%" height="100%" [src]="image.path"
            frameborder="0"></iframe>
    <img *ngIf="image.mimeType !== 'application/pdf'" [src]="image.path" [alt]="image.name"
         style="max-width: 100%;">
  </div>
</ng-template>
