import { Component, Inject, Input, OnDestroy, OnInit, Optional } from '@angular/core';
import {
  ClientBasicModel,
  ComponentType,
  extractDateRange, extractDefaultStoreId,
  extractNumber,
  FeedbackListParams,
  FeedbackModel,
  FeedbackType,
  HX_COMPONENT_NAME,
  HxAuthService,
  HxFeedbackService,
  HxStoreService,
  PermissionKey,
  Review,
  SourceSystem,
  StoreBasicModel,
  toRouterQueryParams,
  UiError
} from 'hx-services';
import { Subject } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslocoService } from '@ngneat/transloco';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { HxConfirmModalComponent, HxModalData } from 'hx-component';
import { takeUntil } from 'rxjs/operators';

/**
 * Component of feedback list added by Call-center or Kiosk
 */
@Component({
  selector: 'hx-feedback-list', // .m-grid__item.m-grid__item--fluid.m-wrapper //
  templateUrl: './hx-feedback-list.component.html',
  styleUrls: ['./hx-feedback-list.component.css']
})
export class HxFeedbackListComponent implements OnInit, OnDestroy {

  @Input() orderId?: number;

  list: FeedbackModel[] = [];
  sources: string[] = [];
  pagination = {allItemCount: 0};
  menu: { title: string, sort: string, field: string }[] = [];
  isLoading = {
    list: false
  };
  stores: StoreBasicModel[] = [];
  storeById = new Map<number, StoreBasicModel>();
  params: FeedbackListParams = {page: 1, limit: 20};
  hasPermission = false;
  feedbackTypes = Object.keys(FeedbackType);
  private $destroyed = new Subject<void>();
  private authUserId!: number;

  constructor(
    protected feedbackService: HxFeedbackService,
    protected aRoute: ActivatedRoute,
    protected router: Router,
    protected tr: TranslocoService,
    private modal: NgbModal,
    private storeService: HxStoreService,
    private auth: HxAuthService,
    // private modalService: HxModalService,
    @Optional() @Inject(HX_COMPONENT_NAME) public componentName: ComponentType,
  ) {
  }

  ngOnInit() {
    this.authUserId = this.auth.user.id;
    this.auth.hasPermission(PermissionKey.feedback_support).then(result => this.hasPermission = result.hasPermission);

    this.sources = Object.keys(SourceSystem)
      .filter(type => ![SourceSystem.CONFECTIONARY.trim(), SourceSystem.WEBKASSA.trim(), SourceSystem.KIOSK.trim()].includes(type));

    this.storeService.getStores().then(stores => {
      this.stores = stores;
      this.stores.forEach(store => this.storeById.set(store.id, store));
    });

    this.menu = [
      {
        title: this.tr.translate('ord.hx-feedback-list.ts.date'),
        sort: '',
        field: 'date'
      },
      {
        title: this.tr.translate('ord.hx-feedback-list.ts.rating'),
        sort: '',
        field: 'rating'
      }
    ];

    if (this.orderId) {
      this.params = {orderId: this.orderId} as FeedbackListParams;
      this.isLoading.list = true;
      this.loadList();
    } else {
      this.aRoute.queryParamMap.pipe(takeUntil(this.$destroyed)).subscribe(async params => {
        const asArrFn = (name: string, mapFn: (key: string) => any) => params.get(name)?.split(',').map(mapFn) ?? [];
        this.params.sources = asArrFn('sources', el => el as SourceSystem);
        this.params.types = asArrFn('types', el => el as FeedbackType);
        this.params.rating = extractNumber('rating', params);
        this.params.date = extractDateRange('date', params);
        this.params.page = extractNumber('page', params) ?? 1;
        this.params.limit = extractNumber('limit', params) ?? 20;
        this.params.clientId = extractNumber('clientId', params);
        this.params.sort = params.get('sort') ?? undefined;
        this.params.orderBy = params.get('orderBy') ?? undefined;
        this.params.targetUserId = extractNumber('targetUserId', params);
        this.params.storeId = extractNumber('storeId', params) ?? extractDefaultStoreId();
        this.params.hasOrder = params.get('hasOrder') ? params.get('hasOrder') === 'true' : undefined;
        this.params.orderId = extractNumber('orderId', params);
        if (this.componentName === 'cc' || this.params.storeId) {
          try {
            await this.loadList();
          } catch (err: any) {
            throw err;
          }
        }
      });
    }
  }

  ngOnDestroy() {
    this.$destroyed.next();
    this.$destroyed.complete();
  }

  ratingClass(rating: number) {
    if (rating === 5) {
      return 'rating-5';
    } else if (rating >= 4) {
      return 'rating-4';
    } else if (rating >= 3) {
      return 'rating-3';
    }
    return 'rating-2';
  }

  ratingIconClass(rr: Review) {
    if (rr.type === FeedbackType.SITE) {
      return `la la-television ${this.ratingClass(rr.rating)}`;
    } else if (rr.type === FeedbackType.DELIVERY) {
      return `la la-truck ${this.ratingClass(rr.rating)}`;
    } else if (rr.type === FeedbackType.CALLCENTER) {
      return `la la-headphones ${this.ratingClass(rr.rating)}`;
    } else if (rr.type === FeedbackType.PRODUCT) {
      return `la la-birthday-cake ${this.ratingClass(rr.rating)}`;
    } else if (rr.type === FeedbackType.SUPPORT) {
      return `la la-user ${this.ratingClass(rr.rating)}`;
    } else if (rr.type === FeedbackType.CASHBOX) {
      return `la la-calculator ${this.ratingClass(rr.rating)}`;
    } else if (rr.type === FeedbackType.DECOR) {
      return `la la-paint-brush ${this.ratingClass(rr.rating)}`;
    }
    return 'la';
  }

  pageChanged(event: number) {
    this.params.page = event;
    this.router.navigate(['./'], {
      queryParams: toRouterQueryParams(this.params),
      relativeTo: this.aRoute
    });
  }

  sortTable(item: { title: string, sort: string, field: string }): void {
    this.params.orderBy = item.field;

    if (item.sort) {
      this.params.sort = item.sort === 'desc' ? 'asc' : 'desc';
    } else {
      this.params.sort = 'desc';
      this.menu.forEach(el => {
        if (el.field !== item.field) {
          el.sort = '';
        }
      });
    }
    item.sort = this.params.sort;
    this.pageChanged(1);
  }

  onClientChanged(client?: ClientBasicModel): void {
    this.params.clientId = client ? client.id : undefined;
    this.applyFilters();
  }

  resetFilter(): void {
    this.params = {page: 1, limit: 20, sort: 'desc', orderBy: 'date'};
    this.pageChanged(1);
  }

  applyFilters(): void {
    if (!this.params.hasOrder) {
      this.params.hasOrder = undefined;
    }
    if (this.componentName === ComponentType.manager) {
      if (!this.params.storeId) {
        this.params.targetUserId = undefined;
      }
    }
    this.pageChanged(this.params.page);
  }

  filterRating(rating: number) {
    if (rating === this.params.rating) {
      this.params.rating = undefined;
    } else {
      this.params.rating = rating;
    }
    this.applyFilters();
  }

  private async loadList() {
    this.list = [];
    this.isLoading.list = true;
    try {
      const {count, list} = await this.feedbackService.getFeedbackList(this.params);
      this.list = list;
      this.pagination.allItemCount = count;
    } catch (err: any) {
      throw new UiError('unexpected', err);
    } finally {
      this.isLoading.list = false;
    }
  }

  removeFeedback(id: number) {
    const modalInstance = this.modal.open(HxConfirmModalComponent, {size: 'lg'});
    modalInstance.componentInstance.data = {message: 'hx.feedback.confirmRemove'} as HxModalData;
    modalInstance.result.then(() => {
      this.feedbackService.removeFeedback(id).then(() => {
        this.loadList();
      });
    }, (reason) => {
      console.log('Dissmissed reason : ', reason);
    });
  }

  get addButtonVisible(): boolean {
    if (this.orderId && this.list.length > 0) {
      return false;
    } else {
      return this.hasPermission;
    }
  }

  isCreator(feedback: FeedbackModel): boolean {
    return feedback.modifyUserId === this.authUserId;
  }
}
