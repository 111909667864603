<div class="m-subheader">
  <div class="d-flex align-items-center">
    <div class="mr-auto">
      <h3 class="m-subheader__title">
        {{ 'hx.rejection-list.failures' | transloco }}
      </h3>
    </div>
  </div>
</div>

<div class="m-content">
  <!--Begin::Main Portlet-->
  <div class="row">
    <div class="col-sm-12">
      <div class="m-portlet m-portlet--full-height">
        <div class="m-portlet__body">

          <div class="hx-filters">
            <ng-container *ngIf="isCallcenter">
              <div class="filter">
                <small>{{ 'hx.rejection-list.chooseCity' | transloco }}</small>
                <ng-select [(ngModel)]="params.cityId" [items]="cityList"
                           [placeholder]="'hx.rejection-list.chooseCity' | transloco"
                           bindValue="id" bindLabel="id" (ngModelChange)="loadStores()" name="city">
                  <ng-template ng-label-tmp let-item="item" let-clear="clear">
                    <span class="ng-value-label">{{item.title | uiLabel}}</span>
                    <span class="ng-value-icon right" (click)="clear(item)" aria-hidden="true">×</span>
                  </ng-template>
                  <ng-template ng-option-tmp let-item="item">
                    {{item.title | uiLabel}}
                  </ng-template>
                </ng-select>
              </div>

              <div class="filter">
                <small>{{ 'hx.rejection-list.shop' | transloco }}</small>
                <ng-select [(ngModel)]="params.storeId" [items]="stores" bindValue="id" bindLabel="id"
                           [placeholder]="'hx.rejection-list.chooseCity' | transloco"
                           (ngModelChange)="applyFilters()" name="storeId">
                  <ng-template ng-label-tmp let-item="item" let-clear="clear">
                    <span class="ng-value-label">{{item.title | uiLabel}}</span>
                    <span class="ng-value-icon right" (click)="clear(item)" aria-hidden="true">×</span>
                  </ng-template>
                  <ng-template ng-option-tmp let-item="item">
                    {{item.title | uiLabel}}
                  </ng-template>
                </ng-select>
              </div>
              <div class="filter">
                <small>{{ 'hx.rejection-list.address' | transloco }}</small>
                <input type="text" [(ngModel)]="params.query" name="query" (ngModelChange)="applyFilters(800)" autocomplete="off" class="form-control m-input" [placeholder]="'hx.rejection-list.searchAddress' | transloco">
              </div>
            </ng-container>

            <div *ngIf="isManager" class="filter">
              <small>{{ 'hx.rejection-list.shop' | transloco }}</small>
              <hx-store-select [(ngModel)]="params.storeId" (selectChange)="onStoreChanged($event)"></hx-store-select>
            </div>
            <div class="filter">
              <small>{{ 'hx.rejection-list.data' | transloco }}</small>
              <hx-date-select [(ngModel)]="params.date" (selectChange)="applyFilters()"></hx-date-select>
            </div>
            <div class="filter">
              <small>{{ 'hx.rejection-list.products' | transloco }}</small>
              <hx-product-info-select [(ngModel)]="params.productInfoIds" [types]="selectedPITypes" [multiple]="true" [brandId]="brandId" [disabled]="!brandId" (selectChange)="applyFilters()"></hx-product-info-select>
            </div>
          </div>

          <div class="row m--margin-top-10">
            <div class="col-md-12">
              <label class="m-checkbox m-checkbox--text" (change)="applyFilters()">
                <input type="checkbox" [(ngModel)]="params.deliveryExists">
                {{ 'hx.rejection-list.delivery' | transloco }}
                <span></span>
              </label>
              <label class="m-checkbox m-checkbox--text" (change)="applyFilters()">
                <input type="checkbox" [(ngModel)]="params.pickupExists">
                {{ 'hx.rejection-list.pickup' | transloco }}
                <span></span>
              </label>
              <label class="m-checkbox m-checkbox--text" (change)="applyFilters()">
                <input type="checkbox" [(ngModel)]="params.decorExists">
                {{ 'hx.rejection-list.registration' | transloco }}
                <span></span>
              </label>
              <label class="m-checkbox m-checkbox--text" (change)="applyFilters()">
                <input type="checkbox" [(ngModel)]="params.promoExists">
                {{ 'hx.rejection-list.promo' | transloco }}
                <span></span>
              </label>
            </div>
          </div>

          <div class="row m--margin-top-10">
            <div class="col-md-12">
              <button type="button" [disabled]="isLoading.list" (click)="applyFilters()" class="btn btn-outline-accent m-btn m-btn--icon">
                <i *ngIf="isLoading.list" class="la la-spinner la-sping"></i>
                {{ 'hx.rejection-list.btn.search' | transloco }}
              </button>
              <button type="button" (click)="resetFilter()" class="btn btn-outline-primary m-btn m-btn--icon">
                <span>{{ 'reset.list' | transloco }}</span>
              </button>
            </div>
          </div>

          <div class="table-responsive m_datatable m-datatable--va-top m-datatable m-datatable--default m-datatable--loaded m--margin-top-20 m--margin-bottom-30">
            <table class="m-datatable__table" *ngIf="list.length && !isLoading.list">
              <thead class="m-datatable__head">
              <tr class="m-datatable__row m-datatable__row--head">
                <th class="m-datatable__cell">#</th>
                <th class="m-datatable__cell">{{ 'hx.rejection-list.table.head.timeCreate' | transloco }}</th>
                <th class="m-datatable__cell">{{ 'hx.rejection-list.table.head.data' | transloco }}</th>
                <th class="m-datatable__cell">{{ 'hx.rejection-list.table.head.city' | transloco }}</th>
                <th class="m-datatable__cell">{{ 'hx.rejection-list.table.head.shop' | transloco }}</th>
                <th class="m-datatable__cell">{{ 'hx.rejection-list.table.head.client' | transloco }}</th>
                <th class="m-datatable__cell">{{ 'hx.rejection-list.table.head.order' | transloco }}</th>
                <th class="m-datatable__cell">{{ 'hx.rejection-list.table.head.products' | transloco }}</th>
                <th class="m-datatable__cell">{{ 'hx.rejection-list.table.head.registration' | transloco }}</th>
                <th class="m-datatable__cell">{{ 'hx.rejection-list.table.head.delivery' | transloco }}</th>
                <th class="m-datatable__cell">{{ 'hx.rejection-list.table.head.pickup' | transloco }}</th>
                <th class="m-datatable__cell">{{ 'hx.rejection-list.table.head.promo' | transloco }}</th>
                <th class="m-datatable__cell">{{ 'hx.rejection-list.table.head.address' | transloco }}</th>
                <th class="m-datatable__cell">{{ 'hx.rejection-list.table.head.note' | transloco }}</th>
              </tr>
              </thead>
              <tbody class="m-datatable__body">
              <tr *ngFor="let even = even; let i = index; let item of list"
                  class="m-datatable__row" [ngClass]="{ 'm-datatable__row--even': even }">
                <td class="m-datatable__cell">{{ (params.page - 1) * params.limit + (i + 1) }}</td>
                <td class="m-datatable__cell">
                  <span>{{ item.createDateTime | date: 'dd.MM.yyyy HH:mm:ss' }} {{ item.store?.timezone }}</span>
                </td>
                <td class="m-datatable__cell">
                  <span>{{ item.date }}</span>
                </td>
                <td class="m-datatable__cell">
                  <span>{{ item.city?.title | uiLabel }}</span>
                </td>
                <td class="m-datatable__cell">
                  {{ item.store?.title | uiLabel }}
                </td>
                <td class="m-datatable__cell">
                  {{item.client?.fullname | fio}}
                </td>
                <td class="m-datatable__cell">
                  <a *ngIf="item.orderId" [routerLink]="['/orders', item.orderId]">{{ item.orderId }}</a>
                </td>
                <td class="m-datatable__cell">
                  <div *ngFor="let product of item.productDefinitions">
                    {{ product.title | uiLabel }}
                  </div>
                </td>
                <td class="m-datatable__cell">
                  <span *ngIf="item.decor">{{ 'hx.rejection-list.table.head.absent' | transloco }}</span>
                </td>
                <td class="m-datatable__cell">
                  <div *ngFor="let deliveryTime of item.deliveryTimes">
                    {{ deliveryTime | time }}
                  </div>
                </td>
                <td class="m-datatable__cell">
                  <div *ngFor="let pickupTime of item.pickupTimes">
                    {{ pickupTime | time }}
                  </div>
                </td>
                <td class="m-datatable__cell">
                  <div *ngFor="let promo of item.promos">
                    {{ promo }}
                  </div>
                </td>
                <td class="m-datatable__cell">
                  <div *ngFor="let address of item.addresses">
                    {{ address }}
                  </div>
                </td>
                <td class="m-datatable__cell">{{item.note}}</td>
              </tr>
              </tbody>
            </table>
          </div>

          <hx-loader [isLoading]="isLoading.list" [length]="list.length"></hx-loader>

          <div class="m--align-center" *ngIf="list.length && !isLoading.list && pagination.allItemCount > 0">
            <ngb-pagination [maxSize]="5" [rotate]="true" [collectionSize]="pagination.allItemCount"
                            [(page)]="params.page" [pageSize]="params.limit"
                            (pageChange)="pageChanged($event)" class="d-flex justify-content-center mt-4">
            </ngb-pagination>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!--End::Main Portlet-->
</div>
