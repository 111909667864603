import { Component, Input } from '@angular/core';
// @ts-ignore
import { saveAs } from 'file-saver';
import { HxOrderService } from 'hx-services';
import { ToastrService } from 'ngx-toastr';
import { TranslocoService } from '@ngneat/transloco';
import { HttpResponse } from '@angular/common/http';

@Component({
  selector: 'hx-order-invoice-doc',
  templateUrl: './order-invoice-doc.component.html'
})
export class HxOrderInvoiceDocComponent  {
  @Input() orderId!: number;

  // @Output() output = new EventEmitter<>();

  constructor(
    private orderService: HxOrderService,
    private toastr: ToastrService,
    private tr: TranslocoService,
  ) {
  }

  printInvoice(type: 'product' | 'service' | 'payment') {
    this.orderService.generateInvoice(this.orderId, type).subscribe((response: HttpResponse<ArrayBuffer>) => {
      const contentDispositionHeader = response.headers.get('Content-Disposition');
      const responseBody = response.body;
      if (contentDispositionHeader && responseBody) {
        const result = contentDispositionHeader.split(';')[1].trim().split('=')[1];
        const blob = new Blob([responseBody], {type: 'application/octet-stream'});
        saveAs(blob, result);
      }
    }, (error: any) => {
      try {
        // @ts-ignore
        const err = JSON.parse(String.fromCharCode.apply(null, new Uint8Array(error.error)));
        this.toastr.error(this.tr.translate('order.print.' + err.message));
      } catch (e) {
        this.toastr.error(this.tr.translate('unexpectedError'));
      }
    });
  }
}
