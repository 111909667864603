import { Component, Inject, Input, OnDestroy, OnInit, Optional, TrackByFunction } from '@angular/core';
import { Subscription, zip } from 'rxjs';
import {
  AppEventModel,
  AppEventType,
  HX_COMPONENT_NAME,
  HxEventService,
  HxOrderService,
  OrderRefundedEvent,
  OrderTransferredEvent,
  ProductRefundedEvent
} from 'hx-services';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { HxEventDiffModalComponent } from './event-diff/event-diff.component';
import { Router } from '@angular/router';
import { TranslocoService } from '@ngneat/transloco';
import { HxErrorHandlerService, HxModalActorComponent, HxOrderReceiptComponent, HxSmsInfoModalComponent } from 'hx-component';
import { ToastrService } from 'ngx-toastr';


interface Event {
  [key: string]: Item;
}

interface Item {
  badge: string;
}

interface AppEventItem extends AppEventModel {
  badge?: string;
  isLoading?: boolean;
}

/**
 * Timeline of order events
 */
@Component({
  selector: 'hx-order-event-list',
  templateUrl: './order-event-list.component.html',
  styleUrls: ['./order-event-list.component.css']
})
export class HxOrderEventListComponent implements OnInit, OnDestroy {
  private _id: number | undefined;
  @Input()
  set id(val: number | undefined) {
    this._id = val;
    if (val) {
      this.loadEvents();
    }
  }

  get id(): number | undefined {
    return this._id;
  }

  @Input({required: true}) timezone!: string;
  @Input() eventClickFn?: (event: AppEventModel) => Promise<any>;

  events: AppEventItem[] = [];
  private sub!: Subscription;
  private eventList: Event = {
    'ORDER_CREATED': {
      badge: 'btn-outline-info'
    },
    'ORDER_MODIFIED': {
      badge: 'btn-brand'
    },
    'PAYMENT_STARTED': {
      'badge': 'btn-outline-info'
    },
    'ORDER_PACKED': {
      badge: 'btn-warning'
    },
    'ORDER_DECORED': {
      badge: 'btn-focus'
    },
    'ORDER_SHIPPED': {
      badge: 'btn-warning'
    },
    'ORDER_RECEIVED': {
      badge: 'btn-success'
    },
    'ORDER_CANCELLED': {
      badge: 'btn-danger'
    },
    'ORDER_NEW': {
      badge: 'btn-info'
    },
    'ORDER_REJECTED': {
      badge: 'btn-danger'
    },
    'ORDER_SHIPMENT': {
      badge: 'btn-warning'
    },
    'ORDER_DELIVERED': {
      badge: 'btn-focus'
    },
    'ORDER_PAID': {
      badge: 'btn-success'
    },
    'ORDER_TAKEN': {
      badge: 'btn-warning'
    },
    'ORDER_RETURNED': {
      badge: 'btn-metal'
    },
    'ORDER_REFUNDED': {
      badge: 'btn-danger'
    },
    'PRODUCT_REFUNDED': {
      badge: 'btn-metal'
    },
    'ORDER_UNARCHIVED': {
      badge: 'btn-light'
    },
    'FISCALIZATION_SELL': {
      badge: 'btn-success'
    },
    'FISCALIZATION_REFUND': {
      badge: 'btn-success'
    },
    'SMS_NOTIFICATION_SENT': {
      badge: 'btn-success'
    },
    'SMS_NOTIFICATION_CREATED': {
      badge: 'btn-success'
    },
  };

  constructor(
    private eventService: HxEventService,
    private orderService: HxOrderService,
    private errorService: HxErrorHandlerService,
    private modal: NgbModal,
    private router: Router,
    private toastr: ToastrService,
    private tr: TranslocoService,
    @Optional() @Inject(HX_COMPONENT_NAME) private componentName: string,
  ) {
  }

  ngOnInit() {
    this.sub = this.orderService.updateOrderObs.subscribe(() => this.loadEvents());
  }

  ngOnDestroy() {
    if (this.sub) {
      this.sub.unsubscribe();
    }
  }

  showActorModal(event: AppEventItem) {
    const modalRef = this.modal.open(HxModalActorComponent, {size: 'lg'});
    modalRef.componentInstance.actorId = event.actor;
    modalRef.componentInstance.actorType = event.actorType;
  }

  trackById: TrackByFunction<AppEventItem> = (index: number, obj: AppEventItem) => obj.id;

  onEventClick(event: AppEventItem) {
    // if (event.type === AppEventType.WEBKASSA_SELL || event.type === AppEventType.WEBKASSA_CANCELLED) {
    //   this.orderService.createOrderCheckModal(this.id);
    // }

    if (event.type === AppEventType.ORDER_PAID || event.type === AppEventType.ORDER_MODIFIED) {
      const modalInstance = this.modal.open(HxEventDiffModalComponent, {size: 'xl'});
      modalInstance.componentInstance.id = event.id;
      modalInstance.componentInstance.events = this.events;
    } else if (event.type === AppEventType.PRODUCT_REFUNDED) {
      console.log('[order-event-list] navigating to child order', event);
      this.orderService.getAppEventById(event.id).subscribe(appEvent => {
        const productRefundEvent = appEvent.data as ProductRefundedEvent;
        if (productRefundEvent.refundOrderId) {
          this.router.navigateByUrl(`/orders/${productRefundEvent.refundOrderId}`);
        } else {
          this.toastr.error(this.tr.translate('hx.order-event-list.ts.noFindList'));
        }
      });
    } else if (event.type === AppEventType.ORDER_REFUNDED) {
      console.log('[order-event-list] navigating to child order', event);
      this.orderService.getAppEventById(event.id).subscribe(appEvent => {
        const orderRefundedEvent = appEvent.data as OrderRefundedEvent;
        if (orderRefundedEvent.refundOrderId) {
          this.router.navigateByUrl(`/orders/${orderRefundedEvent.refundOrderId}`);
        } else {
          this.toastr.error(this.tr.translate('hx.order-event-list.ts.orderCancel'));
        }
      });
    } else if (event.type === AppEventType.ORDER_TRANSFERRED) {
      console.log('[order-event-list] navigating to transferred order', event);
      this.orderService.getAppEventById(event.id).subscribe(appEvent => {
        const orderTransferredEvent = appEvent.data as OrderTransferredEvent;
        this.router.navigateByUrl(`/orders/${orderTransferredEvent.transferOrderId}`);
      });
    } else if (event.type === AppEventType.SMS_NOTIFICATION_CREATED) {
      zip(this.orderService.getAppEventById(event.id), this.eventService.getSmsInfo(event.id))
        .subscribe(([eventFull, smsInfo]) => {
          const modalInstance = this.modal.open(HxSmsInfoModalComponent, {size: 'lg'});
          modalInstance.componentInstance.smsInfo = smsInfo;
          modalInstance.componentInstance.event = eventFull;
        });
    } else if (event.type === AppEventType.FISCALIZATION_SELL) {
      const modalInstance = this.modal.open(HxOrderReceiptComponent, {windowClass: 'modal--check'});
      modalInstance.componentInstance.checks = [{orderId: event.containerId}];
      modalInstance.componentInstance.isView = true;
    }
    if (this.eventClickFn) {
      event.isLoading = true;
      this.eventClickFn(event).then(() => event.isLoading = false, err => event.isLoading = false);
    }
  }

  private eventBadgeClass(event: AppEventItem): AppEventItem {
    if (this.eventList[event.type]) {
      event.badge = this.eventList[event.type].badge;
    }
    return event;
  }

  private loadEvents() {
    const orderId = this.id;
    if (!orderId) {
      return;
    }
    const fn = () => this.eventService.getOrderEventList(orderId).subscribe(events => {
      this.events = (events as AppEventItem[]).map(item => this.eventBadgeClass(item));
    }, err => {
      this.errorService.check(err.error);
    });

    fn();

    setTimeout(() => fn(), 1500);
  }
}
