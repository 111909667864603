import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Component, Input, OnInit } from '@angular/core';
import { AppEventModel, AppEventType, HxClientService, HxEventService, HxProductInfoService, HxStoreService, HxUserService, PaymentInfoModel } from 'hx-services';
import { TranslocoService } from '@ngneat/transloco';

interface ValueChange {
  changeType: string;
  globalId: { valueObject: string };
  left?: string | object;
  property: string;
  propertyChangeType: string;
  right?: string | object;
}

@Component({
  selector: 'hx-event-diff',
  templateUrl: './event-diff.component.html'
})
export class HxEventDiffModalComponent implements OnInit {

  @Input() id!: number;
  @Input() events!: AppEventModel[];

  currentEvent?: AppEventModel;
  isLoading = {
    diff: false
  };
  fileDownloadUrl = '/api/vanilla/files/download/';

  difference: any;
  changes: ValueChange[] = [];

  constructor(
    private modalInstance: NgbActiveModal,
    private productInfoService: HxProductInfoService,
    private userService: HxUserService,
    private storeService: HxStoreService,
    private clientService: HxClientService,
    private eventService: HxEventService,
    private tr: TranslocoService
  ) {
  }

  ngOnInit() {
    this.getJson(this.id);
  }

  dismissModal() {
    this.modalInstance.dismiss();
  }

  getJson(id: number) {
    let prevId: number;

    this.events.forEach((item, index) => {
      if (item.id === id && index !== 0) {
        const i = this.getPrevItem(index);
        this.currentEvent = item;
        prevId = this.events[i].id;
      }
    });
    // @ts-ignore
    this.eventService.getDiff({newEventId: id, oldEventId: prevId}).subscribe(result => {
      this.difference = result;
      const paymentInfoFn = (pi: PaymentInfoModel) => {
        let val = `${pi.value}`;
        if (pi.discount) {
          val += this.tr.translate('hx.event-diff.ts.discount', { pi: pi.discount });
        }
        if (pi.cashback) {
          val += ` cashback: ${pi.cashback}`;
        }
        return val;
      };
      this.changes = (result.orderDifference.changes as ValueChange[])
        .map(vc => {
          if (!vc.globalId.valueObject.includes('PaymentInfoDto')) {
            return vc;
          }
          if (vc.left && typeof vc.left === 'object') {
            vc.left = paymentInfoFn(vc.left as PaymentInfoModel);
          }
          if (vc.right && typeof vc.right === 'object') {
            vc.right = paymentInfoFn(vc.right as PaymentInfoModel);
          }
          return vc;
        });
      this.isLoading.diff = false;
    });
  }

  /**
   * Get only `created` or `modified` event with recursion
   *
   */
  private getPrevItem(index: number): number {
    const statusList = [AppEventType.ORDER_CREATED, AppEventType.ORDER_MODIFIED];
    const currentIndex = index - 1;

    if (statusList.indexOf(this.events[currentIndex].type) !== -1) {
      return currentIndex;
    } else {
      return this.getPrevItem(currentIndex);
    }
  }
}
