<div class="modal-body">
  <p>{{ 'ord.add-call.text-1' | transloco }} {{clientPhone}} {{ 'add-call.text-2' | transloco }} <span *ngIf="orderNumber">№{{ orderNumber }}</span>?</p>
</div>

<div class="modal-footer modal-footer--pad-right">
  <button type="button"
          [disabled]="isLoading" [ngClass]="{'m-loader m-loader--light m-loader--primary m-loader--left': isLoading}"
          (click)="add()" class="btn btn-success">
    {{ 'ord.add-call.btn.add' | transloco }}
  </button>

  <button type="button" (click)="close()" class="btn btn-secondary">
    {{ 'ord.add-call.btn.cancel' | transloco }}
  </button>
</div>
