/**
 * Vanilla API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type PermissionKey = 'call_record_download' | 'discard_product_input' | 'read_money_flow_report' | 'feedback_support' | 'price_upsert_permission' | 'callcenter_view_report' | 'cf_workflow_categories' | 'callcenter_manager' | 'auditor';

export const PermissionKey = {
    call_record_download: 'call_record_download' as PermissionKey,
    discard_product_input: 'discard_product_input' as PermissionKey,
    read_money_flow_report: 'read_money_flow_report' as PermissionKey,
    feedback_support: 'feedback_support' as PermissionKey,
    price_upsert_permission: 'price_upsert_permission' as PermissionKey,
    callcenter_view_report: 'callcenter_view_report' as PermissionKey,
    cf_workflow_categories: 'cf_workflow_categories' as PermissionKey,
    callcenter_manager: 'callcenter_manager' as PermissionKey,
    auditor: 'auditor' as PermissionKey
};

