import { Directive, ElementRef, Input } from '@angular/core';
import { PaymentType, PaymentType as VnPaymentType } from 'hx-services';

@Directive({
  selector: '[hxPaymentIcon]'
})
export class HxPaymentIconDirective {

  @Input('hxPaymentIcon')
  set paymentType(val: PaymentType | VnPaymentType) {
    if (!val) {
      return;
    }
    let cssClass = '';
    if (val === PaymentType.CASH) {
      cssClass = 'la la-money';
    } else if (val === PaymentType.POS || val === PaymentType.KASPI_POS) {
      cssClass = 'la la-credit-card';
    } else if (val === PaymentType.CHECKING_ACCOUNT) {
      cssClass = 'la la-file-text';
    } else if (val === PaymentType.RAHMET || val === PaymentType.SENIM) {
      cssClass = 'la la-qrcode';
    } else if (val === PaymentType.KASPI) {
      cssClass = 'la la-mobile';
    } else if (val === PaymentType.COIN) {
      cssClass = 'la la-coins';
    } else if (val !== PaymentType.DEBT) {
      cssClass = 'la la-internet-explorer';
    }
    (this.el.nativeElement as HTMLElement).className = cssClass;
  }

  constructor(private el: ElementRef) {
  }

}
