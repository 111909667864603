/**
 * Vanilla API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type MaritalStatus = 'MARRIED' | 'SINGLE' | 'DIVORCED' | 'WIDOWED';

export const MaritalStatus = {
    MARRIED: 'MARRIED' as MaritalStatus,
    SINGLE: 'SINGLE' as MaritalStatus,
    DIVORCED: 'DIVORCED' as MaritalStatus,
    WIDOWED: 'WIDOWED' as MaritalStatus
};

