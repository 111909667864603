<!-- BEGIN: Subheader -->
<div class="m-subheader">
  <div class="d-flex align-items-center">
    <div class="mr-auto">
      <h3 class="m-subheader__title">
        {{ 'user-list.title' | transloco }}
      </h3>
    </div>
    <div>
      <a (click)="updateItem()" class="btn btn-success m-btn--icon">
        <span>
          <i class="la la-user-plus"></i>
          {{ 'profile.action.create' | transloco }}
        </span>
      </a>
    </div>
  </div>
</div>
<!-- END: Subheader -->
<div class="m-content">
  <!--Begin::Main Portlet-->
  <div class="row">
    <div class="col-sm-12">
      <div class="m-portlet m-portlet--full-height">
        <div class="m-portlet__body">
          <div class="row">
            <div class="col-md-6">
              <small>{{ 'user-list.nameNum' | transloco }}</small>
              <form class="m-form" (ngSubmit)="getTypeFilter()">
                <div class="m-form__section m-form__section--first">
                  <div class="form-group m-form__group m--margin-top-0">
                    <div class="input-group">
                      <input type="text" required [(ngModel)]="query" name="query" class="form-control m-input" [placeholder]="'user-list.serachName' | transloco">
                      <span class="input-group-btn">
                        <button class="btn btn-outline-primary" type="submit">
                          {{ 'user-list.btn.search' | transloco }}
                        </button>
                      </span>
                    </div>
                  </div>
                </div>
              </form>
            </div>

            <div class="col-md-3">
              <small>{{ 'user-list.country' | transloco }}</small>
              <hx-country-select [(ngModel)]="countryId" [all]="false" (selectChange)="countryChanged($event)"></hx-country-select>
            </div>
          </div>

          <div class="m_datatable m-datatable--va-top m-datatable m-datatable--default m-datatable--loaded m--margin-top-30 m--margin-bottom-30">
            <table class="m-datatable__table" *ngIf="users.length && !isLoading.users">
              <thead class="m-datatable__head">
              <tr class="m-datatable__row m-datatable__row--head">
                <th class="m-datatable__cell">
                  <span>{{ 'user-list.name' | transloco }}</span>
                </th>

                <th class="m-datatable__cell">
                  <span>{{ 'user-list.position' | transloco }}</span>
                </th>

                <th class="m-datatable__cell">
                  <span>{{ 'user-list.phone' | transloco }}</span>
                </th>

                <th class="m-datatable__cell">
                  <span>{{ 'user-list.status' | transloco }}</span>
                </th>

                <th class="m-datatable__cell text-right">
                  <span>{{ 'user-list.events' | transloco }}</span>
                </th>
              </tr>
              </thead>
              <tbody class="m-datatable__body">
              <tr *ngFor="let even = even; let i = index; let item of users"
                  class="m-datatable__row" [ngClass]="{ 'm-datatable__row--even': even, 'm-datatable__row--disabled': !item.active }">

                <td class="m-datatable__cell">
                  {{ item.firstname }} {{ item.lastname }} {{ item.patronymic }}
                </td>

                <td class="m-datatable__cell">
                  {{ item.position }}
                </td>

                <td class="m-datatable__cell">
                  {{ item.phone }}
                </td>

                <td class="m-datatable__cell">
                    <span class="m-badge m-badge--wide m-badge--rounded" [ngClass]="{'m-badge--success': item.active, 'm-badge--danger': !item.active}">
                      {{ 'user.profile.' + item.active | transloco }}
                    </span>
                </td>

                <td class="m-datatable__cell text-nowrap text-right" style="min-width: 102px;">
                  <div>
                    <a (click)="updateItem(item)" class="btn btn-outline-primary btn-sm m-btn m-btn--icon" [ngbTooltip]="">
                      <i class="la la-edit"></i>
                    </a>

                    <a (click)="fireEmployee(item)" class="btn btn-outline-success m-btn btn-sm m-btn--icon" [ngbTooltip]="'user-list.employee' | transloco">
                      <i class="la la-walking"></i>
                    </a>
                  </div>
                </td>
              </tr>
              </tbody>
            </table>

            <hx-loader [length]="users.length" [isLoading]="isLoading.users"></hx-loader>
          </div>

        </div>
      </div>
    </div>
  </div>
  <!--End::Main Portlet-->
</div>

<ng-template #fireTemplateRef let-modal>
  <div class="modal-body">
    <p>{{ 'user.question.fire' | transloco }} {{ selectedUser?.shortname }}?</p>
  </div>

  <div class="modal-footer">
    <button type="button" class="btn btn-success m--margin-right-10" (click)="fire(modal)">{{ 'user-list.btn.yes' | transloco }}</button>

    <button type="button" class="btn btn-secondary" (click)="modal.dismiss()">{{ 'user-list.btn.no' | transloco }}</button>
  </div>
</ng-template>
