<div class="modal-header">
  <h3 class="modal-title">{{ 'hx.client-property-edit-modal.title' | transloco }}</h3>
  <button class="close" type="button" (click)="modalInstance.dismiss()">
    <span>×</span>
  </button>
</div>

<div class="modal-body">
  <form [formGroup]="form" (ngSubmit)="submitForm()" class="m-form m-form--state m-form--label-align-right">
    <div class="row m--margin-bottom-10" style="align-items: center;">
      <div class="col-md-3 is--required">{{ 'hx.client-property-edit-modal.brand' | transloco }}</div>

      <div class="col-md-9">
        <hx-brand-select formControlName="brandId"></hx-brand-select>
      </div>
    </div>
    <div class="row m--margin-bottom-10" style="align-items: center;">
      <div class="col-md-3 is--required">VIP</div>

      <div class="col-md-9">
        <div class="m-radio-inline">
          <label class="m-radio">
            <input formControlName="vip" type="radio" name="vip" [value]="true">
            {{ 'hx.client-property-edit-modal.yes' | transloco }}
            <span></span>
          </label>
          <label class="m-radio">
            <input formControlName="vip" type="radio" name="vip" [value]="false">
            {{ 'hx.client-property-edit-modal.no' | transloco }}
            <span></span>
          </label>
        </div>
      </div>
    </div>

    <div class="row m--margin-bottom-10" style="align-items: center;">
      <div class="col-md-3 is--required">{{ 'hx.client-property-edit-modal.blacklisted' | transloco }}</div>

      <div class="col-md-9">
        <div class="m-radio-inline">
          <label class="m-radio">
            <input formControlName="blacklisted" type="radio" name="blacklisted" [value]="true">
            {{ 'hx.client-property-edit-modal.yes' | transloco }}
            <span></span>
          </label>
          <label class="m-radio">
            <input formControlName="blacklisted" type="radio" name="blacklisted" [value]="false">
            {{ 'hx.client-property-edit-modal.no' | transloco }}
            <span></span>
          </label>
        </div>
      </div>
    </div>

    <div class="row m--margin-bottom-10" style="align-items: center;">
      <label class="col-md-3 m--margin-bottom-0">
        {{ 'hx.client-property-edit-modal.note' | transloco }}
      </label>

      <div class="col-md-9">
        <textarea formControlName="note" rows="5" class="form-control"></textarea>
      </div>
    </div>

    <div class="actions">
      <button type="submit" class="btn btn-success">
        {{ 'hx.btn.save' | transloco }}
      </button>

      <button (click)="modalInstance.dismiss()" class="btn btn-secondary">
        {{ 'hx.btn.cancel' | transloco }}
      </button>
    </div>
  </form>
</div>
