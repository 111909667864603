import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  forwardRef,
  Inject,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Optional,
  Output,
  SimpleChanges
} from '@angular/core';
import {
  ClientFullModel,
  ClientRequest,
  ComponentType,
  CountryModel,
  HX_COMPONENT_NAME,
  HxAuthService,
  HxClientService,
  HxCoinService,
  HxCountryService
} from 'hx-services';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { HxToastrService } from '../../../services/toastr.service';
import { BehaviorSubject } from 'rxjs';
import { switchMap } from 'rxjs/operators';

@Component({
  selector: 'hx-client-checker',
  templateUrl: './client-checker.component.html',
  styleUrls: ['./client-checker.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => HxClientCheckerComponent),  // replace name as appropriate
      multi: true
    }
  ]
})
export class HxClientCheckerComponent implements OnInit, ControlValueAccessor, OnChanges, OnDestroy {
  @Input() disabled = false;
  @Input() compact = false;
  @Input() cityId!: number;
  @Input() label = 'client.name';
  @Output() clientChange = new EventEmitter<ClientFullModel | undefined>();

  country?: CountryModel;
  client?: ClientFullModel;
  isLoading = {
    client: false,
    bonus: false,
  };
  clientPhone?: string;

  isCollapsed = true;

  customPatterns = {
    'X': {
      pattern: new RegExp('/|0|4|5|6|7|/'),
    }, '9': {
      pattern: new RegExp('/|9|/'),
    }, 'N': {
      pattern: new RegExp('^(?!3)'),
    }, '0': {
      pattern: new RegExp('\\d'),
    }
  };
  isCashbox = false;
  coinAmount?: number;
  private countrySubject = new BehaviorSubject<CountryModel | undefined>(undefined);
  private timeout?: NodeJS.Timeout;

  constructor(
    private toastr: HxToastrService,
    private clientService: HxClientService,
    private countryService: HxCountryService,
    private cdr: ChangeDetectorRef,
    private auth: HxAuthService,
    private coinService: HxCoinService,
    @Optional() @Inject(HX_COMPONENT_NAME) private componentName: string,
  ) {
  }

  ngOnDestroy(): void {
    this.countrySubject.complete();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['cityId'] && changes['cityId'].previousValue !== changes['cityId'].currentValue) {
      this.countryService.getCountryByCityId(this.cityId).subscribe(country => {
        this.country = country;
        this.countrySubject.next(country);
        this.cdr.markForCheck();
      });
    }
  }

  ngOnInit(): void {
    this.isCashbox = this.componentName === ComponentType.cb;
    this.countrySubject.subscribe(country => {
      if (country) {
        this.typedClient(this.clientPhone);
      }
    });
  }

  updateClient(): void {
    if (!this.client) {
      return;
    }
    if (this.timeout) {
      clearTimeout(this.timeout);
    }
    const cl = this.client.client;
    this.timeout = setTimeout(async () => {
      const result = await this.clientService.saveClient(this.clientService.buildRequest({ client: cl, additionalAddresses: this.client?.additionalAddresses ?? [], additionalContacts: this.client?.additionalContacts ?? [] }));
      const client = await this.clientService.getClient(result.id);
      this.initClient(client);
      this.toastr.success('client.update.success');
    }, 1500);

    this.loadCoin(this.client.client.id);
  }

  clear() {
    this.client = undefined;
    this.clientPhone = undefined;
    this.isLoading.client = false;
    this.clientChange.emit();
  }

  clientChanged(client: ClientFullModel) {
    if (client) {
      this.client = {...client};
      this.isCollapsed = true;
    } else {
      this.isCollapsed = true;
    }
  }

  typedClient(event?: string): void {
    let phone = event;
    if (phone && this.country) {
      const prefix = this.country.phonePrefix.replace(/\D+/g, '');
      if (phone.length === this.country.phoneLength) {
        phone = prefix + phone.replace(/\D+/g, '');
      }
      if (phone.length === (prefix.length + this.country.phoneLength)) {
        this.loadClientByPhone(phone);
      } else {
        this.client = undefined;
      }
    } else {
      this.client = undefined;
    }
  }

  onChange(val: any) {
    // console.log('onChange called', val);
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  writeValue(val: string | undefined): void {
    this.clientPhone = val;
    if (!val) {
      this.client = undefined;
    } else if (this.country) {
      this.typedClient(val);
    }
  }

  private transformPhone(phone: string): string {
    if (!this.country) {
      return phone;
    }
    return phone.substring(phone.length - this.country.phoneLength);
  }

  private async loadClientByPhone(phone?: string) {
    if (!phone) {
      return;
    }
    this.isLoading.client = true;
    const obj = {
      phone: phone,
      countryId: this.country?.id
    } as ClientRequest;
    try {
      const existingClient = await this.clientService.getClientByPhone(phone);
      if (existingClient) {
        this.initClient(existingClient);
        if (existingClient) {
          this.loadCoin(existingClient.client.id);
        }
      }
    } catch (err: any) {
      if (err.error?.message === 'client.notFound') {
        try {
          const result = await this.clientService.saveClient(obj);
          const client = await this.clientService.getClient(result.id);
          this.initClient(client);
          this.coinAmount = 0;
        } catch (err: any) {
          this.clear();
          this.cdr.markForCheck();
          throw err;
        }
      } else {
        throw err;
      }
    }
  }

  private initClient(client: ClientFullModel): void {
    if (!client.isMobile) {
      this.clientPhone = undefined;
      this.client = undefined;
      this.cdr.markForCheck();
      return;
    }
    this.client = client;
    this.country = this.client.country;
    const phone = `${this.client.client.phone}`;
    this.clientPhone = this.transformPhone(phone);
    this.onChange(this.clientPhone);
    this.clientChange.emit(this.client);
    this.isLoading.client = false;
    this.cdr.markForCheck();
  }

  private async loadCoin(clientId: number) {
    if (this.isCashbox) {
      const {brandId, countryId} = this.auth.user.store;
      this.isLoading.bonus = true;
      try {
        this.coinAmount = await this.coinService.getActiveCoins({clientId: clientId, countryId: countryId, brandId: brandId});
      } finally {
        this.isLoading.bonus = false;
        this.cdr.markForCheck();
      }
    }
  }
}
