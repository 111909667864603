<ng-select [items]="options"
           bindLabel="id"
           bindValue="id"
           [placeholder]="'hx.product-info-select.placeholder.name' | transloco"
           [typeToSearchText]="'hx.product-info-select.placeholder.search' | transloco"
           [notFoundText]="'hx.product-info-select.placeholder.noFind' | transloco"
           [loadingText]="'hx.product-info-select.placeholder.loading' | transloco"
           [(ngModel)]="selected"
           (ngModelChange)="onModelChanged($event)"
           [disabled]="disabled"
           [multiple]="multiple"
           [hideSelected]="true"
           [loading]="itemLoading"
           [typeahead]="itemInput$">
  <ng-template ng-label-tmp let-item="item" let-clear="clear">
    <span class="ng-value-label">{{item.title | uiLabel}}</span>
    <span class="ng-value-icon right" (click)="clear(item)" aria-hidden="true">×</span>
  </ng-template>
  <ng-template ng-option-tmp let-item="item">
    {{item.title | uiLabel}}
  </ng-template>
</ng-select>
