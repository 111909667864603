<div class="modal-header">
  <h3 class="modal-title">{{(data?.title || 'hx.confirm.title') | transloco}}</h3>

  <button class="close" type="button" (click)= "modalInstance.dismiss()">
    <span>×</span>
  </button>
</div>

<div class="modal-body">
  <p *ngIf="data?.message" [innerHtml]="(data?.message || '') | transloco: data?.params"></p>
</div>

<div class="modal-footer">
  <div class="text-right m--margin-top-15">
    <button type="button" (click)="modalInstance.close()" class="btn btn-success m--margin-right-10">
      {{(data?.confirmMessage || 'hx.confirm.ok') | transloco}}
    </button>

    <button *ngIf="!data?.hideCancel" type="button" (click)= "modalInstance.dismiss()" class="btn btn-secondary">
      {{(data?.cancelMessage || 'hx.confirm.cancel') | transloco}}
    </button>
  </div>
</div>
