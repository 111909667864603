import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

export interface HxModalData {
  title?: string;
  message: string;
  params?: any;
  cancelMessage?: string;
  confirmMessage?: string;
  hideCancel?: boolean;
}

@Component({
  selector: 'hx-confirm-modal',
  templateUrl: './confirm-modal.component.html'
})
export class HxConfirmModalComponent {

  @Input() data?: HxModalData;

  constructor(
    public modalInstance: NgbActiveModal,
  ) {
  }
}
