import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Action, ConfirmStatus, DiscountResultModel, HxOrderService, HxRejectionService, NotifierEventBusAddress, NotifierService, PromoCodeModel } from 'hx-services';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { TranslocoService } from '@ngneat/transloco';

@Component({
  selector: 'hx-promo-code',
  templateUrl: './promo-code.component.html',
  styleUrls: ['./promo-code.component.css']
})
export class HxPromoCodeComponent implements OnInit, OnDestroy {
  @Input() orderId!: number;
  @Input() disabled = false;
  @Input() discounts: DiscountResultModel[] = [];

  code?: string;
  confirmCode?: string;
  applied = false;
  confirmInputVisible = false;
  promoCode?: PromoCodeModel;
  rejected = false;
  isLoading = {
    promo: false,
    confirm: false,
  };
  errorMessage?: string;
  private $destroyed = new Subject<void>();

  constructor(private orderService: HxOrderService,
              private notifierService: NotifierService,
              private tr: TranslocoService,
              private rejectionService: HxRejectionService,
  ) {
  }

  private promoHandler: (err: any, message: { body: any, headers: any }) => void = (err, message) => {
    if (err) {
      console.error(err);
    } else {
      console.log('[promo-code] promoHandler message', message);
      const action: Action | undefined = message?.headers?.action;
      if (action === 'promoConfirmed') {
        this.loadPromoCode();
      } else if (action === 'promoRejected') {
        this.loadPromoCode();
      }
    }
  };

  ngOnInit(): void {
    this.notifierService.eventBusOpenObs.pipe(takeUntil(this.$destroyed)).subscribe(eb => {
      if (eb) {
        this.loadPromoCode();
        this.notifierService.registerHandler(NotifierEventBusAddress.orderEvent(this.orderId), this.promoHandler);
      }
    });
  }

  ngOnDestroy(): void {
    this.notifierService.unregisterHandler(NotifierEventBusAddress.orderEvent(this.orderId), this.promoHandler);
    this.$destroyed.next();
    this.$destroyed.complete();
  }

  applyPromoCode(): void {
    if (!this.disabled && this.code) {
      this.isLoading.promo = true;
      this.errorMessage = undefined;
      this.orderService.applyPromoCode(this.orderId, this.code).subscribe(response => {
        if (this.discounts.length > 0) {
          const maxPriorityDiscount = this.discounts.sort((a, b) => a.promoPriority - b.promoPriority)[0];
          console.log('maxPriorityDiscount', maxPriorityDiscount);
          if (response.priority < maxPriorityDiscount.promoPriority) {
            this.errorMessage = this.tr.translate('dsc.eventPromo.lowPriority', {promoTitle: maxPriorityDiscount.promoTitle.ru});
            this.isLoading.promo = false;
          } else {
            this.initPromoCode(response.promoCode);
          }
        } else {
          this.initPromoCode(response.promoCode);
        }
      }, err => {
        console.log('error', err);
        if (err.error?.message) {
          if (err.error?.message === 'dsc.system.notFound') {
            const systems = this.showToastrField(err.error.data.systems);
            this.errorMessage = this.tr.translate(err.error.message, { systems: systems });
          } else {
            this.errorMessage = this.tr.translate(err.error.message, err.error.data);
          }
        }
        this.loadingCompleted();
      });
    }
  }

  private showToastrField(msg: any[]): string {
    const errors: string[] = [];
    for (let index = 0; index < msg.length; index++) {
      const element = msg[index];
      errors.push(this.getTrans('sourceSystem.' + element));
    }
    if (errors.length > 0) {
      return errors.join(',');
    }
    return '';
  }


  private getTrans(code: string) {
    return this.tr.translate(code);
  }

  confirmPromoCode(): void {
    if (!this.disabled && this.promoCode && this.confirmCode) {
      this.isLoading.confirm = true;
      this.orderService.confirmPromoCode(this.promoCode.id, this.confirmCode).subscribe(promoCode => {
        this.isLoading.confirm = false;
        this.initPromoCode(promoCode);
      }, () => this.isLoading.confirm = false);
    }
  }

  reject() {
    if (this.code) {
      this.rejectionService.prepareRejection({promos: [this.code]});
    }
  }

  cancelPromoCode() {
    if (!this.disabled && this.applied && this.promoCode) {
      this.isLoading.promo = true;
      this.orderService.cancelPromoCode(this.orderId).subscribe(() => {
        this.initPromoCode();
      });
    }
  }

  private loadPromoCode() {
    this.isLoading.promo = true;
    this.orderService.getPromoCode(this.orderId).subscribe(promoCode => {
      this.initPromoCode(promoCode);
    }, () => this.loadingCompleted());
  }

  private initPromoCode(promoCode?: PromoCodeModel) {
    this.promoCode = promoCode;
    let confirmInputVisible = false;
    let rejected = false;
    let applied = false;

    if (promoCode) {
      this.code = promoCode.code;
      if (promoCode.confirmRequired) {
        if (promoCode.confirmStatus === ConfirmStatus.CONFIRMED) {
          applied = true;
        } else if (promoCode.confirmStatus === ConfirmStatus.REJECTED) {
          rejected = true;
        } else {
          confirmInputVisible = true;
        }
      } else {
        applied = true;
      }
    }
    if (applied !== this.applied) {
      this.orderService.recalcDiscounts();
    }
    this.applied = applied;
    this.rejected = rejected;
    this.confirmInputVisible = confirmInputVisible;
    this.loadingCompleted();
  }

  private loadingCompleted() {
    this.isLoading.promo = false;
  }
}
