import { Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';
import { FileModel, HxFileService } from 'hx-services';
import { HxToastrService } from '../../services/toastr.service';
import { HxErrorHandlerService } from '../../services/error-handler.service';

/**
 * Uploader files, return list of files's info (name, id)
 *
 * [size] - max size of files in MB
 */
@Component({
  selector: 'hx-file-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.css']
})
export class HxFileUploadComponent implements OnInit, OnDestroy {
  @Output() updateFiles = new EventEmitter<FileModel>();
  @ViewChild('files') files!: ElementRef;
  @Input() size?: number;
  @Input() isVanilla = false;
  @Input() folder?: string;
  @Input() containerTable?: string;
  @Input() containerId?: number;
  @Input() temporary?: boolean;
  @Input() fileName?: string;

  acceptFiles = 'image/*,application/pdf';
  isLoading = {
    upload: false
  };
  private postSub?: Subscription;

  constructor(
    private toastr: HxToastrService,
    private fileService: HxFileService,
    private errorHandlerService: HxErrorHandlerService,
  ) {}

  ngOnInit() {
    if (this.isVanilla) {
      this.acceptFiles = 'application/pdf, image/*';
    }
  }

  ngOnDestroy() {
    this.cancelPost();
  }

  uploadFile(event: any): void {
    if (event) {
      const files = event.target.files || event.srcElement.files || [];
      const formData = new FormData();
      if (files.length === 0) {
        return;
      }
      const file = files.item(0);
      if (this.fileName) {
        this.fileName = this.fileName + file.name.substring(file.name.lastIndexOf('.'));
      }
      for (const key in files) {
        if (files.hasOwnProperty(key)) {
          const element = files[key];
          if (this.size && element.size <= this.size * 1024 * 1024) {
            formData.append(element.name, element);
          } else {
            this.toastr.warning('files.size.istoobig');
            return;
          }
        }
      }

      this.postFiles(formData);
    }
  }

  cancelPost() {
    if (this.postSub) {
      this.isLoading.upload = false;
      this.postSub.unsubscribe();
    }
  }

  private postFiles(formData: FormData) {
    this.isLoading.upload = true;
    this.postSub = this.fileService.uploadFile(formData, {
      folder: this.folder,
      table: this.containerTable,
      ref: this.containerId,
      temporary: this.temporary,
      fileName: this.fileName,
    }).subscribe(result => {
      this.isLoading.upload = false;
      this.updateFiles.emit(result);
      this.reset();
    }, err => {
      this.isLoading.upload = false;
      this.errorHandlerService.check(err.error);
    });
  }

  private reset(): void {
    this.files.nativeElement.value = '';
  }
}
