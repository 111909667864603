import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { concat, of, Subject } from 'rxjs';
import { ComponentType, HxAuthService, HxUserService, UserBasicModel } from 'hx-services';
import { catchError, debounceTime, distinctUntilChanged, filter, map, switchMap, tap } from 'rxjs/operators';

/**
 * Component search user by phone or name
 * use any format of numbers or text
 */
@Component({
  selector: 'hx-user-search',
  templateUrl: './user-search.component.html',
  styleUrls: ['./user-search.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class HxUserSearchComponent implements OnInit {
  @Input() preload = false;
  @Input() components?: ComponentType[];
  @Input() userId?: number;

  @Output() userChange = new EventEmitter<UserBasicModel | undefined>();

  constructor(
    private userService: HxUserService,
    private auth: HxAuthService,
  ) {
  }

  items: UserBasicModel[] = [];
  itemLoading = false;
  itemInput$ = new Subject<string>();

  ngOnInit() {
    this.loadItems();
  }

  trackByFn(item: UserBasicModel) {
    return item.id;
  }

  private loadItems() {
    const initItems = (users: UserBasicModel[]) => {
      if (this.preload) {
        concat(of(users), this.itemInput$.pipe(
          debounceTime(100),
          distinctUntilChanged(),
          switchMap(term => {
            if (term && term.trim() !== '') {
              return of(users.filter(user => [user.lastname, user.firstname, user.patronymic, user.phone]
                .filter(el => !!el).join(' ').toLowerCase().indexOf(term.toLowerCase()) >= 0)
              ).pipe(catchError(() => of([])));
            }
            return of(users);
          })
        )).subscribe(items => this.items = items);
      } else {
        concat(
          of(users), // default items
          this.itemInput$.pipe(
            debounceTime(500),
            distinctUntilChanged(),
            filter(term => {
              const isOk: boolean = (term ?? '').trim() !== '';
              this.itemLoading = isOk;
              return isOk;
            }),
            switchMap(term => this.userService.getUsers({
              query: term,
              components: this.components,
            })),
            tap(() => this.itemLoading = false),
            map(items => items.list),
            catchError(() => of([] as UserBasicModel[])), // empty list on error
          )
        ).subscribe(items => this.items = items);
      }
    };
    if (this.preload) {
      this.auth.getStoreUsersByComponents({components: this.components}).subscribe(users => initItems(users));
    } else {
      if (this.userId) {
        this.userService.getUserById(this.userId).subscribe(user => initItems([user]));
      } else {
        initItems([]);
      }
    }
  }

  onUserChanged(user?: UserBasicModel) {
    if (user) {
      this.userChange.emit(user);
    } else {
      this.userChange.emit(undefined);
    }
  }
}
