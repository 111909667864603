import { Component, ElementRef, HostListener, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { HeaderService } from './header.service';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { KeycloakService } from 'keycloak-angular';
import { environment } from '@callcenter-env/environment';
import { HxAuthService, HxCallcenterService, HxUserService, UserBasicModel } from 'hx-services';
import { HxToastrService } from 'hx-component';

/**
 * Component of site header
 */
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
  @ViewChild('templateRef', {static: true}) templateRef!: TemplateRef<any>;
  public isOnline = window.navigator.onLine ?? true;
  operatorIdList: number[] = [];
  userList: UserBasicModel[] = [];
  fullname!: string;
  operator!: string;
  open = false;
  submenu = false;
  isProd: boolean = environment.production;
  isLoading = {
    operator: 'default',
    userList: false,
    id: true
  };
  position = '';
  showDropdown = false;

  @HostListener('document:click', ['$event']) handleKeyboardEvent(event: Event) {
    if (this.submenu) {
      if (!this._eref.nativeElement.contains(event.target)) {
        this.toggleSubmenu();
      }
    }
  }

  constructor(
    private _eref: ElementRef,
    private headerService: HeaderService,
    private toastr: HxToastrService,
    private keycloakService: KeycloakService,
    private userService: HxUserService,
    private router: Router,
    private callcenterService: HxCallcenterService,
    private auth: HxAuthService,
    public modal: NgbModal,
  ) {
    const ls = localStorage.getItem('sidebar');
    this.open = ls === 'minimize';
    if (this.open) {
      this.toggleSidebar();
    }
  }

  ngOnInit() {
    this.loadOperatorIds();
    this.checkNetwork();
    const user = this.auth.user;
    this.operator = user.asteriskId ?? '';

    if (!this.operator) {
      this.changeOperatorId();
    }

    this.fullname = user.shortname ?? '';
    this.position = user.position ?? 'Нет позиции';
  }

  isCurrentOperatorId(operatorId: number): boolean {
    return operatorId === +this.operator || this.isOperatorBuzy(operatorId);
  }

  showBusyOperator(operatorId: number): string {
    return this.userList.find(item => item.asteriskId === `${operatorId}`)?.fullname ?? '';
  }

  changeOperatorId() {
    this.submenu = false;
    this.isLoading.userList = true;

    this.userService.getOperatorIdList().subscribe(result => {
      this.userList = result;
      this.isLoading.userList = false;
    }, () => this.isLoading.userList = false);

    this.modal.open(this.templateRef, {size: 'sm'});
  }

  setOperatorId(operatorId: number) {
    if (!operatorId) {
      return;
    }

    this.isLoading.operator = 'loading';

    this.userService.setOperatorId(operatorId).subscribe(() => {
      window.location.reload();
      this.isLoading.operator = 'reload';
    }, err => console.error(err));
  }

  toggleSidebar(): void {
    const body = document.getElementsByTagName('body')[0];
    const hasClass = body.className.indexOf('--minimize');

    if (hasClass !== -1 && !this.open) {
      body.classList.remove('m-aside-left--minimize');
      body.classList.remove('m-brand--minimize');
      this.setLs('');
    } else {
      body.classList.add('m-aside-left--minimize');
      body.classList.add('m-brand--minimize');
      this.setLs('minimize');
    }

    this.open = !this.open;
  }

  toggleMobSidebar(): void {
    const body = document.getElementsByTagName('body')[0];
    const hasClass = body.className.indexOf('--on');

    if (hasClass !== -1 && this.open) {
      body.classList.remove('m-aside-left--on');
    } else {
      body.classList.add('m-aside-left--on');
    }

    this.open = !this.open;
  }

  toggleSubmenu(): void {
    this.submenu = !this.submenu;
  }

  logout() {
    this.keycloakService.logout();
  }

  navigateUrl(url: string) {
    this.toggleSubmenu();
    this.router.navigateByUrl(`/${url}`);
  }

  private checkNetwork() {
    this.headerService.network().subscribe(result => {
      this.isOnline = result === 'online';
      const msg = `network.${result}`;
      if (result === 'online') {
        this.toastr.success(msg);
      } else {
        this.toastr.warning(msg);
      }
    });
  }

  private setLs(str: string): void {
    localStorage.setItem('sidebar', str);
  }

  private isOperatorBuzy(operatorId: number): boolean {
    return this.userList.some(item => item.asteriskId === `${operatorId}`);
  }

  private loadOperatorIds() {
    this.isLoading.id = true;
    this.callcenterService.getOperatorIds().subscribe(result => {
      this.operatorIdList = result;
      this.isLoading.id = false;
    }, () => this.isLoading.id = false);
  }
}
