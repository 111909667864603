<ng-container *ngIf="!isLoading.get">
  <div class="m-subheader">
    <div class="d-flex align-items-center">
      <div class="mr-auto">
        <h3 class="m-subheader__title m-subheader__title--separator">
          {{ 'ord.hx-feedback-edit.title' | transloco }}
        </h3>
        <ul class="m-subheader__breadcrumbs m-nav m-nav--inline">
          <li class="m-nav__item m-nav__item--home">
            <a routerLink="/"  class="m-nav__link m-nav__link--icon">
              <i class="m-nav__link-icon la la-home"></i>
            </a>
          </li>
          <li class="m-nav__separator">
            -
          </li>
          <li class="m-nav__item">
            <a (click)="goBack()" class="m-nav__link">
            <span class="m-nav__link-text">
              {{ 'ord.hx-feedback-edit.allReviews' | transloco }}
            </span>
            </a>
          </li>
          <ng-container *ngIf="order">
            <li class="m-nav__separator">
              -
            </li>
            <li class="m-nav__item">
              <a [routerLink]="['/orders', order.id]" class="m-nav__link">
                <span class="m-nav__link-text">
                  #{{order.uniqueNumber}}
                </span>
              </a>
            </li>
          </ng-container>
        </ul>
      </div>
    </div>
  </div>

  <div class="m-content">
    <div class="row">
      <div class="col-sm-12">
        <div class="m-portlet m-portlet--success m-portlet--head-solid-bg">
          <div class="m-portlet__body">

            <ng-container *ngIf="!feedbackId && !orderId">
              <div class="row m--margin-top-10">
                <div class="col-md-3">
                  <label>{{ 'ord.hx-feedback-edit.chooseCity' | transloco }}</label>
                </div>
                <div class="col-md-4">
                  <hx-city-select [all]="false" [(ngModel)]="cityId"></hx-city-select>
                </div>
              </div>

              <div class="row m--margin-top-10" *ngIf="cityId">
                <div class="col-md-3">
                  <label>{{ 'ord.hx-feedback-edit.chooseShop' | transloco }}</label>
                </div>
                <div class="col-md-9">
                  <hx-store-select [default]="false" [cityId]="cityId"  [(ngModel)]="feedback.storeId"></hx-store-select>
                </div>
              </div>

              <div class="row m--margin-top-10" *ngIf="cityId">
                <div class="col-md-12">
                  <hx-client-checker [cityId]="cityId" (clientChange)="updateClient($event)"></hx-client-checker>
                </div>
              </div>
            </ng-container>

            <div class="row m--margin-top-10">
              <div class="col-md-3">
                <label>{{ 'ord.hx-feedback-edit.chooseSource' | transloco }}</label>
              </div>
              <div class="col-md-9">
                <div ngbDropdown class="d-inline-block">
                  <button class="btn btn-outline-primary" ngbDropdownToggle>{{sourceSystem?('sourceSystem.' + sourceSystem | transloco):'Не выбран'}}</button>
                  <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
                    <button (click)="selectSourceSystem(sourceSystem)" *ngFor="let sourceSystem of sourceSystems" ngbDropdownItem>{{'sourceSystem.'+sourceSystem | transloco}}</button>
                  </div>
                </div>
              </div>
            </div>

            <div class="row m--margin-top-10">
              <div class="col-md-3">
                <label>{{ 'ord.hx-feedback-edit.data' | transloco }}</label>
              </div>
              <div class="col-md-3">
                <input [(ngModel)]="dateTime" (ngModelChange)="onDateTimeChanged($event)" type="datetime-local" class="form-control m-input">
              </div>
            </div>

            <div class="row m--margin-top-10" *ngFor="let type of types">
              <div class="col-md-3">
                <label>{{'feedbackType.' + type | transloco}}</label>
              </div>
              <div class="col-md-9">
                <hx-rating [enabled]="true" [rating]="rating(type)" (rateChange)="onRateChanged($event, type)"></hx-rating>
              </div>
            </div>

            <div class="row m--margin-top-10">
              <div class="col-md-12">
                <label>{{ 'ord.hx-feedback-edit.message' | transloco }}</label>
                <textarea [(ngModel)]="feedback.clientText" rows="6" class="form-control"></textarea>
              </div>
            </div>

            <div class="row m--margin-top-10">
              <div class="col-lg-12">
                <label>{{ 'ord.hx-feedback-edit.answer' | transloco }}</label>
                <textarea [(ngModel)]="feedback.supportText" rows="6" class="form-control"></textarea>
              </div>
            </div>
            <div class="row m--margin-top-10">
              <div class="col-lg-12">
                <label>{{ 'ord.hx-feedback-edit.note' | transloco }}</label>
                <textarea [(ngModel)]="feedback.supportNote" rows="6" class="form-control"></textarea>
              </div>
            </div>
            <div class="row m--margin-top-10">
              <div class="col-lg-12">
                <button (click)="saveFeedback()" [disabled]="isLoading.save" class="btn btn-outline-primary"
                        [ngClass]="{'m-loader m-loader--light m-loader--primary m-loader--left': isLoading.save}">
                  {{(isLoading.save ? 'ord.hx-feedback-edit.btn.load' : 'ord.btn.save') | transloco}}
                </button>
              </div>
            </div>
          </div>
        </div>
        <a (click)="goBack()">
          {{ 'ord.hx-feedback-edit.back' | transloco }}
        </a>
      </div>
    </div>
  </div>
</ng-container>

<hx-loader [isLoading]="isLoading.get"></hx-loader>
