import { Component, ElementRef, EventEmitter, HostListener, Output, TemplateRef, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { MessageCount } from './message-count.model';
import { Notification } from 'hx-services';

@Component({
  selector: 'hc-notifier',
  templateUrl: './hc-notifier.component.html',
  styleUrls: ['./hc-notifier.component.css']
})
export class HcNotifierComponent {
  @ViewChild('templateRef') templateRef!: TemplateRef<any>;
  @Output() update = new EventEmitter<boolean>();

  events: Array<Notification> = [];
  isDropdown = false;
  messageCount: MessageCount = {all: 0, unread: 0, unseen: 0};
  profileUrl = `${location.protocol}//profile.${location.hostname.split('.').slice(1).join('.')}/notification`;
  selectNotification?: Notification;

  constructor(
    private elementRef: ElementRef,
    private modal: NgbModal,
  ) { }

  @HostListener('document:click', ['$event']) handleKeyboardEvent(event: Event) {
    if (this.isDropdown) {
      if (!this.elementRef.nativeElement.contains(event.target)) {
        this.showDropdown();
      }
    }
  }

  showDropdown() {
    this.isDropdown = !this.isDropdown;

    if (this.isDropdown && this.isMessagesUnseen()) {
      this.updateMessageCount();
    }

    this.update.emit(this.isDropdown);
  }

  private updateMessageCount() {
    const ids = this.events.map(el => el.id);
    this.markAsSeen(ids);
  }

  isMessagesUnseen(): boolean {
    return this.events.some(el => !el.seenDate);
  }

  markAsSeen(ids: Array<number>) {
    // TODO @deedarb
    // this.notificationService.markAsSeen(ids);
  }

  markAllAsRead() {
    const ids = this.events.map(el => el.id);
    this.markAsRead(ids);
  }

  markAsRead(ids: Array<number>) {
    // TODO @deedarb
    // this.notificationService.markAsRead(ids);
  }

  readNotification(item: Notification) {
    if (!item) {
      return;
    }

    this.showDropdown();
    this.markAsRead([item.id]);
    this.selectNotification = item;
    this.modal.open(this.templateRef, { size: 'sm' });
  }

}
