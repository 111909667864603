<div class="m-subheader">
  <div class="d-flex align-items-center">
    <div class="mr-auto">
      <h3 class="m-subheader__title">
        Отчёт по статусам звонков в течение дня
      </h3>
    </div>
    <div>
    </div>
  </div>
</div>

<div class="m-content">
  <div class="row">
    <div class="col-sm-12">
      <div class="m-portlet m-portlet--full-height">
        <div class="m-portlet__body">
          <div class="row">
            <div class="col-md-3">
              <hx-date-select [(ngModel)]="date" (selectChange)="navigate()"></hx-date-select>
            </div>

            <div class="col-md-2">
              <ng-select [(ngModel)]="interval" [items]="intervalList" placeholder="Выбрать интервал"
                         (ngModelChange)="onIntervalChanged()" bindLabel="name" bindValue="id"></ng-select>
            </div>
          </div>

          <h4 class="text-center mb-0" *ngIf="!data.length && !multiOA.length && !isLoading">
            Нет данных
          </h4>
          <div class="row" [hidden]="!data.length">
            <div class="col-lg-12">
              <div id="barchart" class="m--chart m--margin-top-30" [ngClass]="{'m--chart-done': data.length && !isLoading}"></div>
            </div>
          </div>
          <div class="row" [hidden]="!multiOA.length">
            <div class="col-lg-12">
              <div id="barСhartOperatorActivity" class="m--chart m--margin-top-30" [ngClass]="{'m--chart-done': multiOA.length && !isLoading}"></div>
            </div>
          </div>

          <div *ngIf="multiOA.length > 0 && operatorDetailedList.length > 0" class="m_datatable m-datatable--va-top m-datatable m-datatable--default m-datatable--loaded m--margin-top-30 m--margin-bottom-30">
            <table class="m-datatable__table">
              <thead class="m-datatable__head">
              <tr class="m-datatable__row m-datatable__row--head">
                <th class="m-datatable__cell">
                  <span>ФИО</span>
                </th>

                <th class="m-datatable__cell">
                  <span>онлайн</span>
                </th>

                <th class="m-datatable__cell">
                  <span>офлайн</span>
                </th>
              </tr>
              </thead>
              <tbody class="m-datatable__body">
              <tr *ngFor="let even = even; let i = index; let item of operatorDetailedList"
                  class="m-datatable__row" [ngClass]="{ 'm-datatable__row--even': even}">
                <td class="m-datatable__cell">
                  {{ item.lastname }} {{ item.firstname }}
                  <label *ngIf="isToday()" class="m--online"></label>
                </td>

                <td class="m-datatable__cell">
                  {{ getTimeInMinute(item.onlineTime) }} мин
                </td>

                <td class="m-datatable__cell">
                  {{ getTimeInMinute(item.offlineTime) }} мин
                </td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div *ngIf="!multiOA.length && isLoading" class="m-page-loader m-page-loader--non-block" style="margin-left: -80px; margin-top: -20px;">
  <div class="m-blockui">
    <span>
      Загрузка...
    </span>
    <span>
      <div class="m-loader m-loader--brand"></div>
    </span>
  </div>
</div>

<div class="hc-loader__bg" *ngIf="!multiOA.length && isLoading"></div>
