import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ClientModel, ClientPropertyModel, getFormErrors, HxClientService } from 'hx-services';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { TranslocoService } from '@ngneat/transloco';

interface FormModel {
  vip: boolean;
  blacklisted: boolean;
  note?: string;
  brandId: number;
}

@Component({
  selector: 'hx-client-property-edit-modal.m-grid__item.m-grid__item--fluid',
  templateUrl: './client-property-edit.modal.html',
  styleUrls: ['./client-property-edit.modal.css']
})
export class HxClientPropertyEditModal implements OnInit {
  @Input() property?: ClientPropertyModel;
  @Input() client!: ClientModel;
  form!: UntypedFormGroup;
  isLoading = {
    save: false,
  };

  constructor(
    public modalInstance: NgbActiveModal,
    private fb: UntypedFormBuilder,
    private toastr: ToastrService,
    private clientService: HxClientService,
    private tr: TranslocoService
  ) {
  }

  ngOnInit() {
    this.form = this.fb.group({
      brandId: [this.property?.brandId, [Validators.required]],
      vip: [this.property?.vip, [Validators.required]],
      blacklisted: [this.property?.blacklisted, [Validators.required]],
      note: [this.property?.note],
    });
  }

  submitForm() {
    Object.values(this.form.controls).forEach(ctrl => {
      ctrl.markAsDirty();
      ctrl.updateValueAndValidity();
    });
    if (this.form.valid) {
      const formModel = this.form.getRawValue() as FormModel;
      const model: Partial<ClientPropertyModel> = {
        vip: formModel.vip,
        blacklisted: formModel.blacklisted,
        note: formModel.note,
        brandId: formModel.brandId,
      };
      model.id = this.property?.id;
      this.isLoading.save = true;
      console.log('model=', model);
      this.clientService.saveClientBrandProperty(this.client.id, model as ClientPropertyModel).subscribe(() => {
        this.form.markAsPristine();
        this.toastr.success(this.tr.translate('hx.client-property-edit-modal.ts.save'));
        this.form.enable();
        this.isLoading.save = false;
        this.modalInstance.close();
      }, (err: any) => {
        this.form.enable();
        this.isLoading.save = false;
        this.toastr.error(err?.error?.message);
      });
    } else {
      console.log('formErrors', getFormErrors(this.form));
    }
  }
}
