import { Component, forwardRef, Input, OnChanges, OnDestroy, OnInit, SimpleChanges, TrackByFunction, ViewChild } from '@angular/core';
import { NgSelectComponent } from '@ng-select/ng-select';
import { concat, of, Subject } from 'rxjs';
import {
  AddressModel,
  CityDeliveryMap,
  createAddressOptions,
  GisAttributeInput,
  GisItem,
  GisService,
  GisStore,
  HxDgisInfoService,
  HxGoogleInfoService,
  HxRejectionService,
  HxYandexInfoService
} from 'hx-services';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { debounceTime, filter, switchMap, takeUntil, tap } from 'rxjs/operators';

@Component({
  selector: 'hx-gis-address',
  templateUrl: './gis-address.component.html',
  styleUrls: ['./gis-address.component.css'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => HxGisAddressComponent),  // replace name as appropriate
      multi: true
    }
  ]
})
export class HxGisAddressComponent implements OnInit, OnDestroy, OnChanges, ControlValueAccessor {

  @ViewChild('chosenstreet') ngSelect!: NgSelectComponent;
  @Input() addresses: AddressModel[] = [];
  @Input() store?: GisStore;
  @Input() stores: GisStore[] = [];
  @Input() cityDeliveryArea!: CityDeliveryMap;
  @Input() limitless = false;

  gisItem?: GisItem;
  streets: GisItem[] = [];
  streetIsLoading = false;
  streetsInput$ = new Subject<string>();
  showStoreDeliveryError = false;
  errorAddress?: string;
  disabled = false;
  isShowMap = false;
  addressOptions: AddressModel[] = [];
  isAdmDivVisible = false;
  isDgisActive = false;
  isYandexActive = false;
  isGoogleActive = false;
  isLoading = false;
  addressNotFound = false;
  searchTerm?: string;
  url!: string;

  private gisMap = new Map<string, GisService>();
  private gis!: GisService;
  private selectedVal: Partial<AddressModel> | undefined;

  set selected(val) {
    this.selectedVal = val;
    this.onChange(val);
  }

  get selected() {
    return this.selectedVal;
  }

  private storeDeliveryMap = new Map<number, any[]>();
  private $destroyed = new Subject<void>();

  constructor(
    private dgisInfoService: HxDgisInfoService,
    private yandexInfoService: HxYandexInfoService,
    private googleInfoService: HxGoogleInfoService,
    private rejectionService: HxRejectionService,
  ) {
    this.gisMap.set('dgis', dgisInfoService);
    this.gisMap.set('yandex', yandexInfoService);
    this.gisMap.set('google', googleInfoService);
  }

  ngOnDestroy(): void {
    this.gisMap.forEach((gis: GisService, key: string) => gis.destroyMap());
    this.$destroyed.next();
    this.$destroyed.complete();
  }

  ngOnInit(): void {
    if (this.cityDeliveryArea && this.cityDeliveryArea.defaultMapType && this.gisMap && !this.gis) {
      this.checkMapType(this.cityDeliveryArea.defaultMapType);
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['cityDeliveryArea'] && this.cityDeliveryArea) {
      this.streets = [];
      this.storeDeliveryMap.clear();
      this.gisMap.forEach((gis: GisService, key: string) => gis.destroyMap());
      this.checkMapType(this.cityDeliveryArea.defaultMapType);
      this.loadStreets(this.cityDeliveryArea.dgisRegionId, this.cityDeliveryArea.bbox, this.cityDeliveryArea.bounds);
      // normalize for rendering
      const polygons = this.cityDeliveryArea.deliveryMap.features.filter((feature: any) => feature.geometry.type === 'Polygon');
      polygons.forEach((feature: any) => {
        const storeId = feature.properties['store-id'];
        const features = this.storeDeliveryMap.get(storeId) ?? [];
        features.push(feature);
        this.storeDeliveryMap.set(storeId, features);
      });
      this.isAdmDivVisible = this.cityDeliveryArea.includeAdmDiv ?? false;
      if (this.isShowMap) {
        if (this.cityDeliveryArea.defaultMapType) {
          this.initMap(true);
        }
      }
      if (changes['store'] && changes['store'].currentValue?.id !== changes['store'].previousValue?.id) {
        setTimeout(() => {
          if (this.selected?.address) {
            this.selectAddress(this.selected);
          }
        });
      }
    }

    if (changes['addresses'] || changes['cityDeliveryArea']) {
      this.addressOptions = createAddressOptions(this.addresses, this.cityDeliveryArea, this.storeDeliveryMap, this.stores, this.store);
    }
  }

  writeValue(val: AddressModel | undefined): void {
    // console.log('[2gis] writeValue', val);
    if (val) {
      this.selectAddress(val);
    } else {
      this.selected = undefined;
    }
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {

  }

  onChange(val: any) {
  }

  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  selectAddress(addr: Partial<AddressModel>): void {
    this.selected = addr;
    if (addr.address) {
      this.gisItem = {
        addressName: addr.address,
        admDivName: addr.admDiv,
      };
      if (addr.latitude && addr.longitude) {
        this.gisItem.point = {
          lat: addr.latitude,
          lon: addr.longitude,
        };
      }
    }
  }

  streetValueChange(event: string) {
    this.streetsInput$.next(event);

    if (event === '') {
      this.gis.clearMapData();
    }
  }

  trackByFn(street: GisItem) {
    return street.addressName;
  }

  trackByAddr: TrackByFunction<AddressModel> = (index, obj) => obj.address;

  onStreetChanged(item?: GisItem) {
    if (item?.point && item?.addressName) {
      this.gis.updateMarker({latitude: item.point?.lat, longitude: item.point?.lon, addressName: item.addressName}, this.attr(this.gisItem));
    }

    console.log('[gis] onStreetChanged item', item);
    this.gisItem = item;

    if (item) {
      const addressOption = this.addressOptions.find(addr => {
        if (this.isAdmDivVisible) {
          return addr.address === item.addressName && addr.admDiv === item.admDivName;
        }
        return addr.address === item.addressName;
      });
      if (addressOption) {
        this.selected = structuredClone(addressOption);
      } else {
        this.selected = {
          ...(this.selected ?? {}), ...{
            latitude: item.point?.lat,
            longitude: item.point?.lon,
            address: item.addressName,
            admDiv: item.admDivName,
          }
        };
      }
    } else {
      this.selected = undefined;
    }
  }

  toggleMap() {
    if (!this.isShowMap) {
      this.isShowMap = true;
      this.gisMap.forEach((gis: GisService, key: string) => gis.destroyMap());
      this.initMap();
    } else {
      this.isShowMap = false;
    }
  }

  changeMap(mapName: string) {
    this.destroyMap(mapName);
    this.checkMapType(mapName);
    if (this.isShowMap) {
      this.initMap();
    }
  }

  setManualAddress() {
    if (this.searchTerm) {
      this.gisItem = {
        addressName: this.searchTerm,
      };
      this.selected = {address: this.searchTerm};
      this.ngSelect.close();
    }
  }

  reject() {
    if (this.searchTerm) {
      this.rejectionService.prepareRejection({addresses: [this.searchTerm]});
    }
  }

  private loadStreets(regionId?: number, bbox?: string, bounds?: string) {
    this.streetIsLoading = true;
    const emptyArr: GisItem[] = [];
    concat(of(emptyArr), this.streetsInput$.pipe(
      takeUntil(this.$destroyed),
      debounceTime(800),
      filter(term => (term ?? '').trim() !== ''),
      tap(() => this.streetIsLoading = true),
      switchMap(term => {
        this.addressNotFound = false;
        this.searchTerm = term;
        console.log('[gis] loadStreets', term);
        return this.gis.searchStreets(term, this.url, {regionId: regionId, bbox: bbox, bounds: bounds}, this.attr(this.gisItem));
      }), tap(() => {
        this.streetIsLoading = false;
        this.ngSelect.open();
      })
    )).subscribe({
      next: items => {
        if (items.filter(street => !street.disabled).length === 0) {
          this.addressNotFound = true;
        }
        if (this.limitless) {
          this.streets = items.map(item => {
            if (item.disabled) {
              item.hint = 'hx.cmp.gis-address.hasNoDelivery';
            }
            item.disabled = false;
            return item;
          });
        } else {
          this.streets = items;
        }
        this.streetIsLoading = false;
      },
      error: () => this.streetIsLoading = false
    });
  }

  private initMap(isCityChanged: boolean = false) {
    this.isLoading = true;
    this.gis.showMap(this.url, this.attr(isCityChanged ? undefined : this.gisItem), gisItem => {
      this.gisItem = gisItem;
      if (gisItem) {
        this.selected = {
          ...(this.selected ?? {}), ...{
            latitude: gisItem.point?.lat,
            longitude: gisItem.point?.lon,
            address: gisItem.addressName,
            admDiv: gisItem.admDivName,
          }
        };
      } else {
        this.selected = undefined;
      }
      this.isLoading = false;
    }).then(() => {
      this.isLoading = false;
    });
  }

  private checkMapType(mapName: string) {
    const gisValue = this.gisMap.get(mapName);
    if (gisValue) {
      this.gis = gisValue;
    }
    if (mapName === 'dgis') {
      this.isDgisActive = true;
      this.isYandexActive = false;
      this.isGoogleActive = false;
      this.url = '/api/vanilla/2gis';
    } else if (mapName === 'yandex') {
      this.isYandexActive = true;
      this.isDgisActive = false;
      this.isGoogleActive = false;
      this.url = '/api/vanilla/yandex';
    } else if (mapName === 'google') {
      this.isGoogleActive = true;
      this.isYandexActive = false;
      this.isDgisActive = false;
      this.url = '/api/vanilla/google';
    }
  }

  private destroyMap(mapName: string | undefined) {
    this.gisMap.forEach((gis: GisService, key: string) => {
      if (mapName && mapName !== key) {
        gis.destroyMap();
      }
    });
  }

  private attr(gisItem?: GisItem): GisAttributeInput {
    return {
      storeDeliveryMap: this.storeDeliveryMap,
      store: this.store,
      stores: this.stores,
      gisItem: gisItem,
      addressOptions: this.addressOptions,
      isAdmDivVisible: this.isAdmDivVisible,
      cityDeliveryArea: this.cityDeliveryArea,
    };
  }
}
