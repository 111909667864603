import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { firstValueFrom } from 'rxjs';
import { toHttpParams } from '../utils/globals';
import { DateRange, FeedbackModel, PagedList, Review } from '../interface';
import { SourceSystem } from '../vn-api';
import { FeedbackType } from '../enum';

@Injectable({
  providedIn: 'root'
})
export class HxFeedbackService {

  constructor(
    private http: HttpClient,
  ) { }

  getFeedbackList(params: FeedbackListParams): Promise<PagedList<FeedbackModel>> {
    return firstValueFrom(this.http.get<PagedList<FeedbackModel>>('/api/vanilla/feedbacks', { params: toHttpParams(params, true) }));
  }

  getFeedbackById(id: number): Promise<FeedbackModel> {
    return firstValueFrom(this.http.get<FeedbackModel>(`/api/vanilla/feedbacks/${id}`));
  }

  createFeedback(feedback: FeedbackModel): Promise<FeedbackModel> {
    return firstValueFrom(this.http.post<FeedbackModel>(`/api/vanilla/feedbacks`, feedback));
  }

  updateFeedback(id: number, feedback: FeedbackModel): Promise<FeedbackModel> {
    return firstValueFrom(this.http.put<FeedbackModel>(`/api/vanilla/feedbacks/${id}`, feedback));
  }

  removeFeedback(id: number): Promise<void> {
    return firstValueFrom(this.http.delete<void>(`/api/vanilla/feedbacks/${id}`));
  }

  saveReply(id: number, replyObj: { supportText?: string; supportNote?: string, reviews?: Review[], clientText?: string }) {
    return firstValueFrom(this.http.post(`/api/vanilla/feedbacks/${id}/support`, replyObj));
  }

  saveKioskFeedback(feedback: FeedbackModel): Promise<void> {
    return firstValueFrom(this.http.post<void>(`/api/vanilla/feedbacks/kiosk`, feedback));
  }
}

export interface FeedbackListParams {
  sources?: SourceSystem[];
  rating?: number;
  date?: DateRange;
  page: number;
  limit: number;
  clientId?: number;
  sort?: string;
  orderBy?: string;
  targetUserId?: number;
  types?: FeedbackType[];
  storeId?: number;
  hasOrder?: boolean;
  orderId?: number;
}
