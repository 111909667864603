<ng-select [items]="options"
           [(ngModel)]="selected"
           bindValue="id"
           bindLabel="groupTitle"
           (clear)="onCleared($event)"
           (ngModelChange)="onModelChanged($event)"
           groupBy="cityTitle"
           [disabled]="isDisabled"
           [multiple]="multiple"
           [placeholder]="'hx.store-select.placeholder.chooseShop' | transloco"
           [clearable]="true"
           [loading]="isLoading">
</ng-select>
