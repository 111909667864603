<div>
  <form (submit)="applyPromoCode()" class="d-inline-block">
    <div class="display-delivery">
      <input [(ngModel)]="code" type="text" class="form-control d-inline-block promo-code-input"
             name="code" autocomplete="off" [placeholder]="'ord.promo-code.placeholder.promo' | transloco" maxlength="30">

      <button *ngIf="!disabled" type="submit" class="btn btn-primary btn-sm m--margin-left-5" [disabled]="isLoading.promo">
        <i [hidden]="!isLoading.promo" class="la la-spinner la-spin"></i>
        {{ 'ord.promo-code.btn.ok' | transloco }}
      </button>
    </div>
    <div class="display-delivery" style="vertical-align: middle">
      <div ngbDropdown container="body">
        <i class="las la-ellipsis-v" style="font-size: 1.5rem;" ngbDropdownToggle></i>
        <div ngbDropdownMenu>
          <button ngbDropdownItem (click)="reject()">{{ 'ord.promo-code.btn.refusal' | transloco }}</button>
        </div>
      </div>
    </div>
    <ng-container *ngIf="applied">
      <span class="m--font-success m--margin-left-10">
        <i class="la la-check"></i> {{ 'ord.promo-code.applied' | transloco }}
      </span>
      <a *ngIf="!disabled" (click)="cancelPromoCode()" style="cursor: pointer">{{ 'ord.promo-code.cancel' | transloco }}</a>
    </ng-container>
    <span *ngIf="rejected" class="m--font-danger m--margin-left-10">
      <i class="la la-exclamation-triangle"></i> {{ 'ord.promo-code.rejected' | transloco }}
    </span>
    <span *ngIf="errorMessage" class="m--font-danger m--margin-left-10">
      <i class="la la-info-circle"></i> {{errorMessage}}
    </span>

  </form>

  <form *ngIf="!disabled && confirmInputVisible" (submit)="confirmPromoCode()" class="d-inline-block m--margin-left-10">
    <small>{{ 'ord.promo-code.awaiting' | transloco }} ({{promoCode?.confirmUser?.firstname}})</small>
    <input [(ngModel)]="confirmCode" type="text" class="form-control d-inline-block m--margin-left-5"
           style="width: 90px;" name="confirmCode"
           [placeholder]="'ord.promo-code.placeholder.text-1' | transloco" maxlength="4">
    <button type="submit" class="btn btn-outline-success btn-sm m--margin-left-5" [disabled]="isLoading.confirm">
      <i [hidden]="!isLoading.confirm" class="la la-spinner la-spin"></i>
      {{ 'ord.promo-code.btn.confirm' | transloco }}
    </button>
  </form>
</div>
