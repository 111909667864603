import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { ErrorHandlerService } from '@callcenter-app/service/error-handler.service';
import { CallRowModel, CallStatus, extractNumber, HxCallService, HxCityService, HxStoreService, SeekDirection, toRouterQueryParams } from 'hx-services';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CallDetailsComponent } from '@callcenter-app/pages/modal/call-details/call-details.component';

@Component({
  selector: 'app-calls-list.m-grid__item.m-grid__item--fluid.m-wrapper',
  templateUrl: './calls-list.component.html',
  styleUrls: ['./calls-list.component.css'],
})
export class CallsListComponent implements OnInit, OnDestroy {
  list: CallRowModel[] = [];
  eventStatus: { [key: string]: string } = {
    incoming: 'Идёт реклама...',
    waiting: 'В очереди...',
    ringing: 'Ожидает оператора...',
    talking: 'Разговор...',
  };
  counter = 1;
  isLoading = {
    list: true,
  };
  callStatuses: { id: CallStatus, label: string }[] = [
    {id: CallStatus.incoming, label: 'реклама'},
    {id: CallStatus.waiting, label: 'в очереди'},
    {id: CallStatus.ringing, label: 'ожидание'},
    {id: CallStatus.talking, label: 'разговор'},
    {id: CallStatus.finished, label: 'всего'},
  ];
  params: {
    limit: number,
    countryId?: number,
    statuses?: CallStatus[],
    date?: string,
    cityId?: number,
    phone?: string,
  } = {limit: 20};
  seek: {
    last?: string;
    first?: string;
    val?: string;
    hasNext?: boolean;
    dir?: SeekDirection;
  } = {};

  private sub!: Subscription;

  constructor(
    private aRoute: ActivatedRoute,
    private router: Router,
    private callService: HxCallService,
    private errorService: ErrorHandlerService,
    private storeService: HxStoreService,
    private cityService: HxCityService,
    private modal: NgbModal,
  ) {
  }

  ngOnInit() {
    this.sub = this.aRoute.queryParamMap.subscribe(paramMap => {
      this.params.date = paramMap.get('date') ?? undefined;
      this.params.phone = paramMap.get('phone') ?? undefined;
      this.params.countryId = extractNumber('countryId', paramMap);
      this.params.cityId = extractNumber('cityId', paramMap);
      const callStatuses = paramMap.get('statuses');
      this.params.statuses = callStatuses ? callStatuses.split(',').map(s => s as CallStatus) : [];
      this.params.limit = extractNumber('limit', paramMap) ?? 20;
      this.seek = {
        dir: paramMap.get('seekDir') as SeekDirection ?? SeekDirection.next,
        val: paramMap.get('seek') ?? undefined,
      };
      this.loadList();
    });
  }

  ngOnDestroy() {
    if (this.sub) {
      this.sub.unsubscribe();
    }
  }

  navigateUrl(seek: { seek?: string, seekDir?: SeekDirection } = {}) {
    this.router.navigate(['./'], {
      queryParams: {
        ...toRouterQueryParams(this.params), ...{
          seek: seek.seek,
          seekDir: seek.seekDir,
        }
      },
      relativeTo: this.aRoute
    });
  }

  resetFilter() {
    this.params = {limit: 20};
    this.navigateUrl();
  }

  openCallDetailsModal(callId: string) {
    const modalRef = this.modal.open(CallDetailsComponent, {size: 'lg'});
    modalRef.componentInstance.callId = callId;
    modalRef.result.then(() => {
    }, () => {
    });
  }

  applyFilters() {
    this.navigateUrl();
  }

  seekPrev() {
    this.navigateUrl({seek: this.seek.first, seekDir: SeekDirection.prev});
  }

  seekNext() {
    this.navigateUrl({seek: this.seek.last, seekDir: SeekDirection.next});
  }

  trimPhone() {
    if (this.params.phone) {
      this.params.phone = this.params.phone.split(' ').join('');
    }
  }

  private async loadList() {
    this.counter = 1;
    this.isLoading.list = true;
    try {
      const {list, first, last, hasNext} = await this.callService.getCallList({
        cityIds: this.params.cityId ? [this.params.cityId] : [],
        countryIds: this.params.countryId ? [this.params.countryId] : [],
        date: this.params.date,
        limit: this.params.limit,
        phone: this.params.phone,
        seek: this.seek.val,
        seekDir: this.seek.dir,
        statuses: this.params.statuses,
      });
      this.list = list;
      this.seek.first = first;
      this.seek.last = last;
      this.seek.hasNext = hasNext;
    } catch (err: any) {
      this.errorService.check(err.error);
    } finally {
      this.isLoading.list = false;
    }

  }
}
