import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ComponentType, extractNumber, FaqResponse, HxFaqService, HxReactionService, ReactionRequest, ReactionType, toRouterQueryParams } from 'hx-services';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'hx-faq-list.m-grid__item.m-grid__item--fluid.m-wrapper',
  templateUrl: './faq-list.component.html',
  styleUrls: ['./faq-list.component.css'],
  encapsulation: ViewEncapsulation.None,
})
export class HxFaqListComponent implements OnInit, OnDestroy {
  list: FaqResponse[] = [];
  pagination = {allItemCount: 0};
  params: {
    limit: number;
    page: number;
    query?: string;
    component?: ComponentType;
    brandId?: number
  } = {page: 1, limit: 20};
  isLoading = {
    list: false,
    save: false,
  };
  like = ReactionType.like;
  dislike = ReactionType.dislike;
  private $destroyed = new Subject<void>();

  constructor(
    private faqService: HxFaqService,
    private reactionService: HxReactionService,
    private aRoute: ActivatedRoute,
    private router: Router,
  ) {
  }

  ngOnInit(): void {
    this.aRoute.queryParamMap.pipe(takeUntil(this.$destroyed)).subscribe(paramMap => {
      this.params.page = extractNumber('page', paramMap) ?? 1;
      this.params.limit = extractNumber('limit', paramMap) ?? 20;
      this.params.query = paramMap.get('query') ?? '';
      this.params.brandId = extractNumber('brandId', paramMap) ?? (localStorage.getItem('defaultBrandId') == null) ? undefined : Number(localStorage.getItem('defaultBrandId'));
      this.load();
    });
  }

  ngOnDestroy() {
    this.$destroyed.next();
    this.$destroyed.complete();
  }

  applyFilters() {
    this.navigateUrl();
  }

  async vote(faqId: number, reaction: ReactionType) {
    const request: ReactionRequest = {containerId: faqId, containerClass: 'FAQ', reaction: reaction};
    this.isLoading.save = true;
    try {
      await this.reactionService.saveReaction(request);
      this.load();
    } finally {
      this.isLoading.save = false;
    }

  }

  pageChanged(page: number) {
    this.params.page = page;
    this.navigateUrl();
  }

  pressTag(tag: string) {
    this.params.query = tag;
    this.navigateUrl();
  }

  private async load() {
    this.list = [];
    this.isLoading.list = true;
    try {
      const result = await this.faqService.getFaqsWithReactions(this.params);
      this.list = result.list || [];
      this.pagination.allItemCount = result.count;
    } finally {
      this.isLoading.list = false;
    }
  }

  private navigateUrl() {
    this.router.navigate(['./'], {
      queryParams: toRouterQueryParams(this.params),
      relativeTo: this.aRoute
    });
  }
}
