import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { CallDetailModel, HxCallService } from 'hx-services';
import { ErrorHandlerService } from '@callcenter-app/service/error-handler.service';

@Component({
  selector: 'app-call-details',
  templateUrl: './call-details.component.html',
  styleUrls: ['./call-details.component.css']
})
export class CallDetailsComponent implements OnInit {

  @Input() callId!: string;
  callDetails: CallDetailModel[] = [];
  isLoading = {
    list: false,
  };

  constructor(
    public modal: NgbActiveModal,
    private callService: HxCallService,
    private errorHandlerService: ErrorHandlerService,
  ) {
  }

  async ngOnInit() {
    this.isLoading.list = true;
    try {
      this.callDetails = await this.callService.getCallDetailsByCallId(this.callId);
    } catch (err: any) {
      this.errorHandlerService.check(err);
    } finally {
      this.isLoading.list = false;
    }
  }
}
