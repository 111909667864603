import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { firstValueFrom } from 'rxjs';
import { toHttpParams } from '../utils/globals';
import { DeliveryRangeModel } from '../vn-api';

@Injectable({
  providedIn: 'root'
})
export class HxDeliveryService {
  constructor(
    private httpClient: HttpClient,
  ) {
  }

  getDeliveryRangeById(id: number): Promise<DeliveryRangeModel> {
    return firstValueFrom(this.httpClient.get<DeliveryRangeModel>(`/api/vanilla/delivery/ranges/${id}`));
  }

  releaseRange(rangeId: number, orderId: number): Promise<void> {
    return firstValueFrom(this.httpClient.delete<void>(`/api/vanilla/delivery/cart/${rangeId}`, {params: toHttpParams({orderId: orderId}, true)}));
  }

  reserveRange(courierId: number, orderId: number): Promise<void> {
    return firstValueFrom(this.httpClient.put<void>(`/api/vanilla/delivery/cart/${courierId}`, {}, {params: toHttpParams({orderId: orderId}, true)}));
  }

  getDeliveryRanges(params: { date: string, storeId: number }): Promise<DeliveryRangeModel[]> {
    return firstValueFrom(this.httpClient.get<DeliveryRangeModel[]>(`/api/vanilla/delivery/list`, {params: toHttpParams(params, true)}));
  }

  getNearestDeliveryRanges(params: {
    date: string;
    cityId: number;
    brandId: number;
    point: { latitude: number; longitude: number; };
    products: {
      productDefinitionId: number;
      amount: number;
      priceAmount: number;
    }[];
  }): Promise<DeliveryRangeModel[]> {
    return firstValueFrom(this.httpClient.post<DeliveryRangeModel[]>(`/api/vanilla/delivery/ranges/nearest`, params));
  }
}
