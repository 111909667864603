/**
 * Vanilla API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type ConditionType = 'EMPLOYEE' | 'CLIENT' | 'EVENT' | 'BIRTHDATE' | 'TOTAL' | 'FIRST_ORDER' | 'ACTIVATION_TIME' | 'POLYGON' | 'PRODUCT' | 'PRODUCT_COUNT' | 'SYSTEM' | 'DELIVERY_TYPE' | 'PRICE_AMOUNT' | 'ACCUMULATOR';

export const ConditionType = {
    EMPLOYEE: 'EMPLOYEE' as ConditionType,
    CLIENT: 'CLIENT' as ConditionType,
    EVENT: 'EVENT' as ConditionType,
    BIRTHDATE: 'BIRTHDATE' as ConditionType,
    TOTAL: 'TOTAL' as ConditionType,
    FIRST_ORDER: 'FIRST_ORDER' as ConditionType,
    ACTIVATION_TIME: 'ACTIVATION_TIME' as ConditionType,
    POLYGON: 'POLYGON' as ConditionType,
    PRODUCT: 'PRODUCT' as ConditionType,
    PRODUCT_COUNT: 'PRODUCT_COUNT' as ConditionType,
    SYSTEM: 'SYSTEM' as ConditionType,
    DELIVERY_TYPE: 'DELIVERY_TYPE' as ConditionType,
    PRICE_AMOUNT: 'PRICE_AMOUNT' as ConditionType,
    ACCUMULATOR: 'ACCUMULATOR' as ConditionType
};

