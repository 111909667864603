<div class="m-subheader">
  <div class="d-flex align-items-center">
    <div class="mr-auto">
      <h3 class="m-subheader__title">
        {{ 'calls-report.title' | transloco }}
      </h3>
    </div>
    <div>
    </div>
  </div>
</div>

<div class="m-content">
  <div class="row">
    <div class="col-sm-12">
      <div class="m-portlet m-portlet--full-height">
        <div class="m-portlet__body">
          <div class="row">
            <div class="col-lg-4">
              <hx-date-range-select [(ngModel)]="params.date" (selectChange)="applyFilters()"></hx-date-range-select>
            </div>
          </div>

          <h4 class="m--margin-top-20 text-center" *ngIf="!pieDatas.length && !isLoading">
            Нет данных
          </h4>

          <div class="row">
            <div class="col-lg-12">
              <h4 *ngIf="total" class="m--total">Всего: {{ total }}</h4>
              <br>
              <div *ngIf="total" id="chartPie" class="m--chart" [ngClass]="{'m--chart-done': pieDatas.length && !isLoading}"></div>
              <br>
              <br>
              <div>
                <div *ngIf="total" id="barchart" class="m--chart" [ngClass]="{'m--chart-done': barDatas.length && !isLoading}"></div>
              </div>
            </div>
          </div>

          <div *ngIf="!pieDatas.length && isLoading" class="m-page-loader m-page-loader--non-block">
            <div class="m-blockui">
              <span>
                Загрузка...
              </span>
              <div>
                <div class="m-loader m-loader--brand"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
