import { Component } from '@angular/core';
import { Notification } from 'hx-services';

@Component({
  selector: 'hc-alert',
  templateUrl: './hc-alert.component.html',
  styleUrls: ['./hc-alert.component.css']
})
export class HcAlertComponent {
  events: Array<Notification> = [];

  constructor() {
  }

  closeAlert(item: Notification, index: number) {
    this.events.splice(index, 1);
  }
}
