<div class="m-portlet m-portlet--full-height">
  <div class="m-portlet__body">
    <div class="row">
      <div class="col-lg-3">
        <input placeholder="Сотовый номер" type="text" [(ngModel)]="phone" class="form-control min-width-155">
      </div>

      <div class="col-lg-3">
        <input placeholder="asterisk id" type="text" [(ngModel)]="asteriskId" class="form-control">
      </div>

      <div class="col-md-6">
        <input placeholder="call id" type="text" [(ngModel)]="asteriskCallId" class="form-control">
      </div>

      <!--<div class="col-lg-2">
        <button type="button" (click)="resetFilter()" class="btn btn-outline-primary">{{ 'reset.list' | transloco }}</button>
      </div>-->
    </div>

    <div class="row m--margin-top-10">
      <div class="col-lg-12">
        <button (click)="generatePhone()" type="button" class="btn btn-outline-primary m-btn m-btn--outline-2x btn-sm">Random phone</button>
        <button (click)="incoming()" type="button" class="btn m--margin-left-10 btn-outline-primary m-btn m-btn--outline-2x btn-sm">incoming</button>
        <button (click)="ringing()" type="button" class="btn m--margin-left-10 btn-outline-success m-btn m-btn--outline-2x btn-sm">ringing</button>
        <button (click)="talking()" type="button" class="btn m--margin-left-10 btn-outline-info m-btn m-btn--outline-2x btn-sm">talking</button>
        <button (click)="finished()" type="button" class="btn m--margin-left-10 btn-outline-warning m-btn m-btn--outline-2x btn-sm">finished</button>
      </div>
    </div>
  </div>
</div>
