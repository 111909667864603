<div class="m-subheader">
  <div class="d-flex align-items-center">
    <div class="mr-auto">
      <h3 class="m-subheader__title">
        {{ 'reports.feedbackRating' | transloco }}
      </h3>
    </div>
    <div>
    </div>
  </div>
</div>

<div class="m-content">
  <div class="row">
    <div class="col-sm-12">
      <div class="m-portlet m-portlet--full-height">
        <div class="m-portlet__body">
          <div class="row">
            <div class="col-md-3">
              <hx-date-range-select [(ngModel)]="params.date" (ngModelChange)="navigate()"></hx-date-range-select>
            </div>
          </div>
          <div *ngIf="operatorRatingList.length && !isLoading.store">
            <br><br>
            <span class="font-weight-bold">{{ 'operatorRating' | transloco }}</span>
            <div class="m_datatable m-datatable--va-top m-datatable m-datatable--default m-datatable--loaded m--margin-top-30 m--margin-bottom-30">
              <table class="m-datatable__table">
                <thead class="m-datatable__head">
                <tr class="m-datatable__row m-datatable__row--head">
                  <th class="m-datatable__cell">Оператор</th>
                  <th class="m-datatable__cell text-right">Рейтинг</th>
                </tr>
                </thead>
                <tbody class="m-datatable__body">
                <ng-container *ngFor="let even = even;let i = index; let operatorRating of operatorRatingList">
                  <tr class="m-datatable__row" [ngClass]="{ 'm-datatable__row--even': even}">
                    <td class="m-datatable__cell">{{ operatorRating.operatorFio }}</td>
                    <td class="m-datatable__cell text-right">{{ operatorRating.rating }}</td>
                  </tr>
                </ng-container>
                </tbody>
              </table>
            </div>
            <br/>
            <br/>
          </div>

          <div *ngIf="feedbackTypeRatingList.length && !isLoading.type">
            <span class="font-weight-bold">{{ 'feedbackTypeRating' | transloco }}</span>
            <div  class="m_datatable m-datatable--va-top m-datatable m-datatable--default m-datatable--loaded m--margin-top-30 m--margin-bottom-30">
              <table class="m-datatable__table">
                <thead class="m-datatable__head">
                <tr class="m-datatable__row m-datatable__row--head">
                  <th class="m-datatable__cell">Тип</th>
                  <th class="m-datatable__cell text-right">Рейтинг</th>
                </tr>
                </thead>
                <tbody class="m-datatable__body">
                <ng-container *ngFor="let even = even;let i = index; let typeRating of feedbackTypeRatingList">
                  <tr class="m-datatable__row" [ngClass]="{ 'm-datatable__row--even': even}">
                    <td class="m-datatable__cell">{{ 'feedback.type.' + typeRating.type | transloco }}</td>
                    <td class="m-datatable__cell text-right">{{ typeRating.rating }}</td>
                  </tr>
                </ng-container>
                </tbody>
              </table>
            </div>
            <br/>
            <br/>
          </div>
          <hx-loader [length]="operatorRatingList.length || feedbackTypeRatingList.length" [isLoading]="isLoading.store || isLoading.type"></hx-loader>
        </div>
      </div>
    </div>
  </div>
</div>
