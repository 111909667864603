import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Data, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DomSanitizer } from '@angular/platform-browser';
import { Entity, HxOrderService, HxStoreService, OrderAction, OrderResponse, OrderStatus, PaymentType } from 'hx-services';
import { HxErrorHandlerService, HxToastrService } from 'hx-component';
import { Subject } from 'rxjs';
import { PayInvoiceModal } from '@callcenter-app/components/pay-invoice/pay-invoice.modal';
import { takeUntil } from 'rxjs/operators';

/**
 * Component view order info
 */
@Component({
  selector: 'app-order-view',
  templateUrl: './order-view.component.html',
  styleUrls: ['./order-view.component.css']
})
export class OrderViewComponent implements OnInit, OnDestroy {

  order!: OrderResponse;
  isArchive = false;

  isCart = false;
  canEdit = false;
  payInvoiceVisible = false;
  canCancelOrder = false;
  isLoading = {
    edit: false,
    kaspiPayInvoice: false,
  };
  private $destroyed = new Subject<void>();

  constructor(
    private modal: NgbModal,
    private aRoute: ActivatedRoute,
    private router: Router,
    private storeService: HxStoreService,
    private domSanitizer: DomSanitizer,
    private errorHandlerService: HxErrorHandlerService,
    private orderService: HxOrderService,
    private toastr: HxToastrService,
  ) {
  }

  ngOnInit() {
    const routeData: Data = this.aRoute.snapshot.data;
    this.order = routeData['order'];
    this.orderService.updateOrderObs.pipe(takeUntil(this.$destroyed)).subscribe(async () => {
      const orderResponse = await this.orderService.getFullOrderById(this.order.id);
      this.onOrderChanged(orderResponse);
    });

    this.aRoute.paramMap.pipe(takeUntil(this.$destroyed)).subscribe(async paramMap => {
      const id = Number(paramMap.get('id'));
      if (id !== this.order.id) {
        this.order = await this.orderService.getFullOrderById(id);
        this.onOrderChanged(this.order);
      }
    });
    this.onOrderChanged(this.order);
  }

  ngOnDestroy() {
    this.$destroyed.next();
    this.$destroyed.complete();
  }

  editOrder() {
    if (this.isLoading.edit) {
      return;
    }

    this.isLoading.edit = true;
    this.orderService.startOrderModification(this.order.id).subscribe(() => {
      this.isLoading.edit = false;
      this.router.navigate(['/orders/edit'], {
        state: {backLink: `/orders/${this.order.id}`},
        queryParams: {orderId: this.order.id}
      });
    }, err => {
      this.isLoading.edit = false;
      this.errorHandlerService.check(err.error);
    });
  }

  async showPayInvoiceModal() {
    const order = await this.orderService.getFullOrderById(this.order.id);
    const modalInstance = this.modal.open(PayInvoiceModal);
    modalInstance.componentInstance.order = order;
  }

  afterCancel() {
    this.toastr.success('order.canceled.success');
    this.loadOrder();
  }

  onOrderChanged(order: OrderResponse) {
    this.isCart = order.status === OrderStatus.CART;
    this.canEdit = this.getCanEdit(order);
    this.payInvoiceVisible = false;
    const payAvailable = !order.paymentDate
      && ![OrderAction.CANCELLED, OrderAction.RECEIVED, OrderAction.REFUNDED].includes(order.action)
      && order.status !== OrderStatus.COMPLETED;
    if (payAvailable && order.clientType !== Entity.LEGAL) {
      this.storeService.getActivePaymentTypes(order.storeId)
        .subscribe(paymentTypes => this.payInvoiceVisible = paymentTypes.includes(PaymentType.KASPI),
          err => console.error(err));
    }
    this.canCancelOrder = ![OrderAction.SHIPPED, OrderAction.CANCELLED, OrderAction.RECEIVED].includes(order.action) && !order.paid;
    this.isArchive = this.order.archived ?? false;
  }

  private async loadOrder() {
    this.order = await this.orderService.getFullOrderById(this.order.id);
    this.onOrderChanged(this.order);
    this.orderService.orderUpdated();
  }

  private getCanEdit(order: OrderResponse): boolean {
    const statusList = [OrderAction.CANCELLED, OrderAction.RECEIVED, OrderAction.SHIPPED, OrderAction.REFUNDED];
    return !statusList.includes(order.action) && order.status !== OrderStatus.COMPLETED;
  }
}
