<div class="m-subheader">
  <div class="d-flex align-items-center">
    <div class="mr-auto">
      <h3 class="m-subheader__title">
        {{ reportName + '.title' | transloco }}
      </h3>
    </div>
    <div>
    </div>
  </div>
</div>

<div class="m-content">
  <div class="row">
    <div class="col-sm-12">
      <div class="m-portlet m-portlet--full-height">
        <div class="m-portlet__body">
          <div class="row">
            <div class="col-lg-4">
              <hx-date-range-select [(ngModel)]="params.date" (selectChange)="applyFilters()"></hx-date-range-select>
            </div>
          </div>

          <h4 class="m--margin-top-20 text-center" *ngIf="statistic && !statistic.list.length && !isLoading">
            Нет данных
          </h4>

          <div *ngIf="statistic && statistic.list.length > 0"
               class="m_datatable m-datatable--va-top m-datatable m-datatable--default m-datatable--loaded m--margin-top-30 m--margin-bottom-30">
            <table class="m-datatable__table">
              <thead class="m-datatable__head">
              <tr class="m-datatable__row m-datatable__row--head">
                <th class="m-datatable__cell">
                  <span>Оператор</span>
                </th>

                <th class="m-datatable__cell">
                  <span>Создание</span>
                </th>

                <th class="m-datatable__cell">
                  <span>Изменение</span>
                </th>

                <th class="m-datatable__cell">
                  <span>Отмена</span>
                </th>

                <th class="m-datatable__cell">
                  <span>Доставленные</span>
                </th>
              </tr>
              </thead>
              <tbody class="m-datatable__body">
              <tr *ngFor="let even = even; let i = index; let item of statistic.list"
                  class="m-datatable__row" [ngClass]="{ 'm-datatable__row--even': even}">
                <td class="m-datatable__cell">
                  {{ item?.actorFullname | fio }}
                </td>

                <td class="m-datatable__cell">
                  {{ item?.orderCreatedCount }}
                </td>

                <td class="m-datatable__cell">
                  {{ item?.orderModifiedCount }}
                </td>

                <td class="m-datatable__cell">
                  {{ item?.orderCancelledCount }}
                </td>

                <td class="m-datatable__cell">
                  {{ item?.orderReceivedCount }}
                </td>
              </tr>
              </tbody>
              <tfoot class="m-datatable__foot">
              <tr class="m-datatable__row m-datatable__row--detail-expanded">
                <th class="m-datatable__cell">
                  <span>Итого</span>
                </th>

                <th class="m-datatable__cell">
                  <span>{{ statistic.orderCreatedTotal }}</span>
                </th>

                <th class="m-datatable__cell">
                  <span>{{ statistic.orderModifiedTotal }}</span>
                </th>

                <th class="m-datatable__cell">
                  <span>{{ statistic.orderCancelledTotal }}</span>
                </th>

                <th class="m-datatable__cell">
                  <span>{{ statistic.orderReceivedTotal }}</span>
                </th>
              </tr>
              </tfoot>
            </table>
          </div>

          <div *ngIf="statistic && !statistic.list.length && isLoading" class="m-page-loader m-page-loader--non-block"
               style="margin-left: -80px; margin-top: -20px;">
            <div class="m-blockui">
              <span>
                Загрузка...
              </span>
              <span>
                <div class="m-loader m-loader--brand"></div>
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
