<hx-order-info [order]="order" (orderChange)="onOrderChanged($event)">
  <hx-order-invoice-doc *ngIf="!isCart" [orderId]="order.id" class="m--margin-right-10"></hx-order-invoice-doc>

  <button *ngIf="canEdit" (click)="editOrder()" class="btn btn-outline-primary m-btn m-btn--icon m--margin-right-10"
          [disabled]="isLoading.edit"
          [ngClass]="{'m-loader m-loader--primary m-loader--left': isLoading.edit}">
    <span>
      <i class="la la-edit"></i>
      <span>Изменить</span>
    </span>
  </button>

  <button *ngIf="payInvoiceVisible"
          (click)="showPayInvoiceModal()" class="btn btn-outline-primary m-btn m-btn--icon m--margin-right-10"
          [disabled]="isLoading.kaspiPayInvoice"
          [ngClass]="{'m-loader m-loader--primary m-loader--left': isLoading.kaspiPayInvoice}">
    <span>kaspi.kz</span>
  </button>

  <app-cancel-order *ngIf="canCancelOrder"
                    [id]="order.id"
                    (update)="afterCancel()">
  </app-cancel-order>
</hx-order-info>
