<div class="btn-group btn-group-sm">
  <button type="button" class="btn" (click)="setValue('all')" [ngClass]="value === 'all' ? 'btn-metal': 'btn-light'">
    {{label + '.all' | transloco}}
  </button>
  <button type="button" class="btn" (click)="setValue('yes')" [ngClass]="value === 'yes' ? 'btn-metal': 'btn-light'">
    {{label + '.yes' | transloco}}
  </button>
  <button type="button" *ngIf="!hideNo" class="btn" (click)="setValue('no')" [ngClass]="value === 'no' ? 'btn-metal': 'btn-light'">
    {{label + '.no' | transloco}}
  </button>
</div>
