/**
 * Vanilla API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type CallStatus = 'calling' | 'incoming' | 'waiting' | 'ringing' | 'talking' | 'finished';

export const CallStatus = {
    calling: 'calling' as CallStatus,
    incoming: 'incoming' as CallStatus,
    waiting: 'waiting' as CallStatus,
    ringing: 'ringing' as CallStatus,
    talking: 'talking' as CallStatus,
    finished: 'finished' as CallStatus
};

