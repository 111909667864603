import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'countDown'
})
export class HxCountDownPipe implements PipeTransform {

  transform(value: any, args?: any): any {
    let timer = '00:00';

    if (value > 0) {
      const minute = Math.floor(value / 60);
      const sec = value % 60;
      const second = sec < 10 ? `0${sec}` : sec;

      timer = `${minute}:${second}`;
    }

    return timer;
  }

}
