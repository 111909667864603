<div class="modal-header">
  <h3 class="modal-title">{{ ('hx.modal-user.' + actorType.toLowerCase()) | transloco }}</h3>

  <button class="close" type="button" (click)="dismissModal()">
    <span>×</span>
  </button>
</div>

<div class="modal-body">
  <ng-container *ngIf="fullname">
    <h2 class="m--margin-bottom-15">{{ fullname }}</h2>
    <hr>
  </ng-container>
  <p class="modal-title m--margin-top-20 m--margin-bottom-0" *ngIf="birthDate">{{ 'hx.modal-user.birthDate' | transloco }}:</p>
  <p class="modal-text" *ngIf="birthDate">{{ birthDate }}</p>
  <p class="modal-title" *ngIf="phone">{{ 'hx.modal-user.mobile' | transloco }}: </p>
  <p class="m--margin-bottom-45 modal-text" *ngIf="phone">{{ phone }}</p>
  <a [href]="whatsappUrl">
    <i class="lab la-whatsapp soc-icon m--margin-right-10"></i>
  </a>
  <a [href]="tgUrl">
    <i class="lab la-telegram-plane soc-icon"></i>
  </a>
</div>

<div class="modal-footer">
  <div class="text-right m--margin-top-15">
    <button  class="btn btn-secondary" (click)="dismissModal()">
      {{ 'hx.btn.back' | transloco }}
    </button>
  </div>
</div>
