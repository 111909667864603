import { Component, EventEmitter, forwardRef, Input, OnInit, Output } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { DictionaryKey, DictionaryShortEntry, HxDictionaryService } from 'hx-services';

@Component({
  selector: 'hx-dictionary-select',
  templateUrl: './dictionary-select.component.html',
  styleUrls: ['./dictionary-select.component.css'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => HxDictionarySelectComponent),
    multi: true
  }]
})
export class HxDictionarySelectComponent implements OnInit, ControlValueAccessor {
  @Input({required: true}) dictionaryKey!: DictionaryKey;
  @Input() autoSelect = false;
  @Input() multiple = false;
  @Input() disabled = false;
  @Output() selectChange = new EventEmitter<DictionaryShortEntry | DictionaryShortEntry[] | undefined>();

  options: DictionaryShortEntry[] = [];
  selected: string | string[] | undefined;

  constructor(
    private dictionaryService: HxDictionaryService,
  ) {}

  async ngOnInit() {
    const {list, count} = await this.dictionaryService.getEntries({key: this.dictionaryKey, limit: 1000});
    this.options = list;
    if (this.autoSelect && (this.options ?? []).length === 1) {
      this.selected = this.options[0].code;
      this.onModelChanged(this.selected);
    }
  }

  onModelChanged(val: any) {
    this.onChange(val);
    if (!this.multiple) {
      if (this.selected) {
        this.selectChange.emit(this.options.find(p => p.code === this.selected));
      } else {
        this.selectChange.emit();
      }
    } else {
      if (Array.isArray(this.selected)) {
        const codes: string[] = this.selected;
        this.selectChange.emit(this.options.filter(p => codes.includes(p.code)));
      } else {
        this.selectChange.emit([]);
      }
    }
  }

  onChange(val: any) {
    // console.log('onChange called', val);
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  writeValue(obj?: string | string[]): void {
    this.selected = obj;
  }
}
