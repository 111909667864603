<div class="m-aside-menu m-aside-menu--skin-dark m-aside-menu--submenu-skin-dark">
  <ul class="m-menu__nav m-menu__nav--dropdown-submenu-arrow">
    <ng-container *ngFor="let item of mainmenu">
      <ng-container *ngIf="item.isSub && item.visible">
        <li class="m-menu__section" [title]="item.title | transloco">
          <h4 class="m-menu__section-text">
            {{ item.title | transloco }}
          </h4>
          <i class="m-menu__section-icon la la-ellipsis-h"></i>
        </li>
      </ng-container>

      <ng-container *ngIf="!item.isSub && item.visible">
        <li class="m-menu__item" routerLinkActive="m-menu__item--active" [routerLinkActiveOptions]="{ exact: true }">
          <a [routerLink]="routerLink(item)" [queryParams]="item.queryParams" class="m-menu__link">
            <i class="m-menu__link-icon la la-{{ item.icon }}" [title]="item.title | transloco"></i>

            <span class="m-menu__link-title">
              <span class="m-menu__link-wrap">
                <span class="m-menu__link-text">
                  {{ item.title | transloco }}
                </span>
              </span>
            </span>
          </a>
        </li>
      </ng-container>
    </ng-container>

    <li class="m-menu__section">
      <h4 class="m-menu__section-text">
        Пользователь
      </h4>
      <i class="m-menu__section-icon la la-ellipsis-h"></i>
    </li>

    <li class="m-menu__item">
      <a [href]="profileUrl" target="_blank" class="m-menu__link">
        <i class="m-menu__link-icon la la-male"></i>

        <span class="m-menu__link-title">
          <span class="m-menu__link-wrap">
            <span class="m-menu__link-text">
              Профиль
            </span>
          </span>
        </span>
      </a>
    </li>

    <li class="m-menu__item m--visible-tablet-and-mobile">
      <a routerLink="/logout" class="m-menu__link">
        <i class="m-menu__link-icon la la-sign-out"></i>

        <span class="m-menu__link-title">
          <span class="m-menu__link-wrap">
            <span class="m-menu__link-text">
              Выйти
            </span>
          </span>
        </span>
      </a>
    </li>
  </ul>
</div>
