import { Observable, throwError as observableThrowError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { KeycloakService } from 'keycloak-angular';
import { HxToastrService } from 'hx-component';
import { TranslocoService } from '@ngneat/transloco';

@Injectable()
export class CustomHttpInterceptor implements HttpInterceptor {

  constructor(
    private keycloakService: KeycloakService,
    private toastr: HxToastrService,
    private tr: TranslocoService,
  ) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const clonedReq = req.clone({ headers: req.headers.set('Accept-Language', this.tr.getActiveLang()) });
    return next.handle(clonedReq).pipe(catchError(error => {
      if (error instanceof HttpErrorResponse) {
        if (error.status === 401) {
          this.keycloakService.logout();
        }

        if (error.status === 502 || error.status === 504) {
          this.toastr.error(`Ошибка ${error.status} - Проблемы с сервером или интернетом (${req.url.split('/')[2]})`);
        }

        if (error.status === 500) {
          this.toastr.error(`Ошибка ${error.status} - ${error.statusText}`);
        }
      }

      return observableThrowError(error);
    }));
  }

}
