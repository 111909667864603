import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ActorType, HxClientService, HxUserService } from 'hx-services';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';

@Component({
  selector: 'hx-modal-actor',
  templateUrl: './modal-actor.component.html',
  styleUrls: ['./modal-actor.component.css']
})
export class HxModalActorComponent implements  OnInit {
  @Input() actorId!: number;
  @Input() actorType!: ActorType;

  birthDate?: string;
  fullname?: string;
  phone?: string;
  whatsappUrl?: SafeUrl;
  tgUrl?: SafeUrl;

  constructor(
    public modal: NgbActiveModal,
    private clientService: HxClientService,
    private userServise: HxUserService,
    private sanitizer: DomSanitizer,
  ) { }

  async ngOnInit() {
    if (this.actorType === ActorType.USER) {
      this.userServise.getUser(this.actorId)
        .subscribe(user => {
          this.birthDate = user.birthdate;
          this.fullname = user.fullname;
          this.phone = user.phone;
          if (this.phone) {
            this.whatsappUrl = this.sanitizer.bypassSecurityTrustUrl('https://wa.me/' + this.phone);
            this.tgUrl = this.sanitizer.bypassSecurityTrustUrl('tg://t.me/' + this.phone);
          }
        });
    }
    if (this.actorType === ActorType.CLIENT) {
      const clientData = await this.clientService.getClientById(this.actorId);
      this.birthDate = clientData.client.birthDate;
      this.fullname = clientData.client.fullname;
      this.phone = clientData.client.phone;
      if (this.phone) {
        this.whatsappUrl = this.sanitizer.bypassSecurityTrustUrl('https://wa.me/' + this.phone);
        this.tgUrl = this.sanitizer.bypassSecurityTrustUrl('tg://t.me/' + this.phone);
      }
    }
  }

  dismissModal() {
    this.modal.dismiss();
  }
}
