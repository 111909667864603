import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import {
  AtolFiscalResult,
  CASHBOX_SETTINGS_LOCALPRINTNOTE,
  getCurrencySymbol,
  HxAuthService,
  HxOrderService,
  HxWindowService,
  ReceiptModel,
  receiptTicketUrl,
  WebkassaFiscalResult,
  isoDate, UiLabel
} from 'hx-services';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { Subscription } from 'rxjs';
// @ts-ignore
import QRCode from 'qrcode';
import { formatISO } from 'date-fns';

interface OrderReceipt extends ReceiptModel {
  fiscalQR: SafeUrl;
  payQR: SafeUrl;
  webkassa?: WebkassaFiscalResult;
  atol?: AtolFiscalResult;
}

@Component({
  selector: 'hx-app-order-check',
  templateUrl: './order-receipt.component.html',
  styleUrls: ['./order-receipt.component.css']
})
export class HxOrderReceiptComponent implements OnInit, OnDestroy {
  @Input() checks: ReceiptModel[] = [];
  @Input() isView = false;
  @Input() printCount = 1;
  currencySymbol?: string;
  isLoading = {
    receipt: false
  };
  checkList: OrderReceipt[] = [];
  timesToPrintList: number[] = [0];
  printNote = false;
  printLocale?: keyof UiLabel;
  private uiQrPlainMap = new Map<number, string>();
  private printSub?: Subscription;
  private printListSub?: Subscription;

  constructor(
    private domSanitizer: DomSanitizer,
    private orderService: HxOrderService,
    private win: HxWindowService,
    private auth: HxAuthService,
  ) {
  }

  isRefund(check: ReceiptModel): boolean {
    return check && (check.total < 0 || check.isRefund);
  }

  isCashierVisible(check: ReceiptModel) {
    return check && check.cashier && check.cashier !== check.printman;
  }

  ngOnInit(): void {
    this.printNote = localStorage.getItem(CASHBOX_SETTINGS_LOCALPRINTNOTE) === 'true';
    this.timesToPrintList = Array.from(Array(this.printCount).keys());
    const onSuccessListFn = (checkResponses: OrderReceipt[]) => {
      this.isLoading.receipt = false;
      checkResponses.forEach(r => {
        r.printDate = formatISO(Date.now(), { representation: 'complete' });
        r.printman = this.auth.user.fullname ?? '';
        r.qr = this.uiQrPlainMap.get(r.orderId);
        if (r.qr) {
          r.payQR = this.domSanitizer.bypassSecurityTrustUrl(r.qr);
        }
        if (r.fiscalDetails) {
          if (r.fiscalDetails.type === 'atol') {
            r.atol = r.fiscalDetails as AtolFiscalResult;
          } else if (r.fiscalDetails.type === 'webkassa') {
            r.webkassa = r.fiscalDetails as WebkassaFiscalResult;
          }
        }
        this.printLocale = checkResponses[0].printLocale as any;
        QRCode.toDataURL(receiptTicketUrl(r)).then((result: string) => r.fiscalQR = this.domSanitizer.bypassSecurityTrustUrl(result));
      });
      this.checkList = checkResponses;
      if (checkResponses && checkResponses.length > 0) {
        this.currencySymbol = getCurrencySymbol(checkResponses[0].currency);
      }
    };

    const onErrorFn = () => this.isLoading.receipt = false;

    if (this.isView) {
      this.checks.forEach(r => {
        if (r.qr) {
          this.uiQrPlainMap.set(r.orderId, r.qr);
        }
      });
      const orderIds = this.checks.map(r => r.orderId);
      this.isLoading.receipt = true;
      this.orderService.getOrderReceipts({orderIds: orderIds}).subscribe(checkResponse => {
        onSuccessListFn(checkResponse as OrderReceipt[]);
      }, onErrorFn);
    } else {
      this.printListSub = this.orderService.printListCheckObs.subscribe(checks => {
        checks.forEach(r => {
          if (r.qr) {
            this.uiQrPlainMap.set(r.orderId, r.qr);
          }
        });
        const orderIds = checks.map(r => r.orderId);
        this.orderService.getOrderReceipts({orderIds: orderIds}).subscribe(checkResponse => {
          onSuccessListFn(checkResponse as OrderReceipt[]);

          const bodyEl = document.querySelector('body');
          if (bodyEl) {
            bodyEl.style.overflow = 'hidden';
            window.scrollTo(0,0);

            setTimeout(() => {
              this.win.nativeWindow.print();
              bodyEl.style.overflow = 'visible';
            }, 50);
          }
        });
      }, onErrorFn);
    }
  }

  ngOnDestroy(): void {
    if (this.printSub) {
      this.printSub.unsubscribe();
    }
    if (this.printListSub) {
      this.printListSub.unsubscribe();
    }
  }

  getVAT(total: number) {
    return Math.round(100 * total * 12 / 112) / 100;
  }

  clientInfo(check: ReceiptModel) {
    let info = '';
    if (check) {
      if (check.clientFullname) {
        info = check.clientFullname + ', ';
      }
      info = info + check.clientPhone;
    }
    return info;
  }

  recipientInfo(check: ReceiptModel) {
    let info = '';
    if (check) {
      if (check.recipientFullname) {
        info = check.recipientFullname + ', ';
      }
      info = info + check.recipientPhone;
    }
    return info;
  }

  printList() {
    this.orderService.printCheckList(this.checkList);
  }
}
