<div class="hc-alert__wrap">
  <ng-container *ngFor="let item of events; let i = index">
    <div class="hc-alert hc-alert--warning" role="alert" *ngIf="!item.readDate">
      <h4 class="hc-alert__heading">{{ item.topic }}</h4>

      <button (click)="closeAlert(item, i)" type="button" class="hc-alert__close" data-dismiss="alert" aria-label="Close">
        <span aria-hidden="true">&times;</span>
      </button>

      <p>{{ item.text }}</p>
    </div>
  </ng-container>
</div>
