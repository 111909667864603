<div class="m-subheader">
  <div class="d-flex align-items-center">
    <div class="mr-auto">
      <h3 class="m-subheader__title">
        История звонков
      </h3>
    </div>
    <div>
    </div>
  </div>
</div>

<div class="m-content">
<!--Begin::Main Portlet-->
<div class="row">
  <div class="col-sm-12">
	<div class="m-portlet m-portlet--full-height">
	  <div class="m-portlet__body">
      <div class="row">
        <div class="col-md-4">
          <form class="m-form" (ngSubmit)="applyFilters()">
            <div class="m-form__section m-form__section--first">
              <div class="form-group m-form__group m--margin-top-0">
                <div class="input-group">
                  <input type="text" required (keyup)="trimPhone()" [(ngModel)]="params.phone" name="phone" class="form-control m-input" placeholder="Поиск по телефону 7ХХХХХХХХХХ">
                  <span class="input-group-btn">
                    <button class="btn btn-outline-primary" type="submit">
                      поиск
                    </button>
                  </span>
                </div>
              </div>
            </div>
          </form>
        </div>

        <div class="col-md-2">
          <hx-date-select [(ngModel)]="params.date" (ngModelChange)="applyFilters()"></hx-date-select>
        </div>

        <div class="col-md-2">
          <ng-select [(ngModel)]="params.statuses" [items]="callStatuses" placeholder="Выбрать статус звонка" [multiple]="true"
                     (ngModelChange)="applyFilters()" bindLabel="label" bindValue="id"></ng-select>
        </div>

        <div class="col-md-2">
          <hx-country-select [(ngModel)]="params.countryId" [all]="false" (ngModelChange)="applyFilters()"></hx-country-select>
        </div>

        <div class="col-md-2">
          <hx-city-select [(ngModel)]="params.cityId" [countryId]="params.countryId" [all]="false" (ngModelChange)="applyFilters()"></hx-city-select>
        </div>
      </div>
      <br>
      <div class="row">
        <div class="col-md-12 text-right">
          <button type="button" (click)="resetFilter()" class="btn btn-outline-primary">{{ 'reset.list' | transloco }}</button>
        </div>
      </div>

      <div class="table-responsive m-datatable--va-top m-datatable m-datatable--default m-datatable--loaded m--margin-top-30 m--margin-bottom-30">
        <table class="m-datatable__table text-nowrap">
          <thead class="m-datatable__head">
            <tr class="m-datatable__row m-datatable__row--head">
              <th class="m-datatable__cell">
                <span>#</span>
              </th>
              <th class="m-datatable__cell">
                <span>номер</span>
              </th>
              <th class="m-datatable__cell">
                <span>оператор</span>
              </th>
              <th class="m-datatable__cell">
                <span>время звонка</span>
              </th>
              <th class="m-datatable__cell">
                <span>реклама</span>
              </th>
              <th class="m-datatable__cell">
                <span>в очереди</span>
              </th>
              <th class="m-datatable__cell">
                <span>ожидание</span>
              </th>
              <th class="m-datatable__cell">
                <span>разговор</span>
              </th>
              <th class="m-datatable__cell">
                <span>длительность</span>
              </th>
              <th class="m-datatable__cell" style="width: 250px;">
                <span>запись</span>
              </th>
            </tr>
          </thead>
          <tbody class="m-datatable__body">
            <tr *ngFor=" let even = even;let i = index; let item of list"
            class="m-datatable__row" [ngClass]="{ 'm-datatable__row--even': even }">
              <td class="m-datatable__cell">{{ counter + i }}</td>
              <td class="m-datatable__cell">
                <span *ngIf="item.type === 'IN'" class="la la-level-down m-badge m-badge--success" ngbTooltip="Входящий звонок"></span>
                <span *ngIf="item.type === 'OUT'" class="la la-arrow-up m-badge m-badge--info" ngbTooltip="Исходящий звонок"></span>
                <span *ngIf="!item.clientPhone">{{ item.phone }}</span>
                <span *ngIf="item.clientPhone">{{ item.clientPhone }}, {{ item.clientFullname }}</span>
              </td>
              <td class="m-datatable__cell">
                {{ item.operatorFullname | fio }}
                <span *ngIf="item.asteriskId">({{ item.asteriskId }})</span>
              </td>
              <td class="m-datatable__cell">{{ item.firstEventDate | date: 'dd.MM.yy HH:mm' }}</td>
              <td class="m-datatable__cell m-datatable__cell--center">
                {{ item.adTime | date: 'mm:ss'}}
              </td>
              <td class="m-datatable__cell m-datatable__cell--center">
                {{ item.waitTime | date: 'mm:ss' }}
              </td>
              <td class="m-datatable__cell m-datatable__cell--center">
                {{ item.ringTime | date: 'mm:ss' }}
              </td>
              <td class="m-datatable__cell m-datatable__cell--center">
                {{ item.talkTime | date: 'mm:ss' }}
              </td>
              <td class="m-datatable__cell m-datatable__cell--center">{{ item.totalTime | date: 'mm:ss' }}</td>
              <td class="m-datatable__cell" style="width: 250px;">
                <span *ngIf="item.event !== 'finished'" style="width: 150px;">
                  <span class="m-badge m-badge--wide"
                    [ngClass]="{'m-badge--danger': item.event === 'ringing', 'm-badge--info': item.event === 'incoming', 'm-badge--warning': item.event === 'waiting', 'm-badge--success': item.event === 'talking' }">
                    {{ eventStatus[item.event] }}
                  </span>
                </span>

                <span *ngIf="item.event === 'finished' && !item.talkTime">
                  <span class="m-badge m-badge--wide" [ngClass]="{
                  'm-badge--incoming': item.adTime > 0 && item.waitTime === 0 && item.ringTime === 0 && item.talkTime === 0,
                  'm-badge--waiting': item.adTime > 0 && item.waitTime > 0 && item.ringTime === 0 && item.talkTime === 0,
                  'm-badge--ringing': item.adTime > 0 && item.waitTime > 0 && item.ringTime > 0 && item.talkTime === 0 }">
                    <span *ngIf="item.ringTime > 0" (click)="openCallDetailsModal(item.callId)">Пропущенный</span>
                    <span *ngIf="item.ringTime === 0 && item.talkTime === 0">Пропущенный</span>
                  </span>
                </span>

                <audio preload="none" style="width: 250px;" *ngIf="item.event === 'finished' && item.talkTime" controls>
                  <source [src]="item.audioRecord">
                </audio>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <hx-loader [isLoading]="isLoading.list" [length]="list.length"></hx-loader>

      <div class="m--align-center" *ngIf="list.length">
        <div class="btn-group btn-group-sm">
          <button (click)="seekPrev()" [disabled]="(!seek.hasNext && seek.dir === 'prev') || !seek.val" class="btn btn-sm btn-primary">
            <i class="las la-angle-double-left"></i>
          </button>
          <button (click)="seekNext()" [disabled]="!seek.hasNext && seek.dir === 'next'" class="btn btn-sm btn-primary">
            <i class="las la-angle-double-right"></i>
          </button>
        </div>
      </div>
	  </div>
	</div>
  </div>
</div>
<!--End::Main Portlet-->
</div>
