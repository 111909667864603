import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { HxCallcenterService } from 'hx-services';

@Component({
  selector: 'hx-add-call-modal',
  templateUrl: './add-call.component.html',
  styleUrls: ['./add-call.component.css']
})
export class HxAddCallModalComponent {

  @Input() orderNumber?: string;
  @Input() orderId!: number;
  @Input() callId!: string;
  @Input() clientPhone!: string;
  isLoading = false;

  constructor(private modalRef: NgbActiveModal,
              private callcenterService: HxCallcenterService) {
  }

  add() {
    this.isLoading = true;
    this.callcenterService.addOrderToCall({orderId: this.orderId, callId: this.callId}).subscribe(() => {
      this.isLoading = false;
      this.modalRef.close();
    }, () => this.isLoading = false);
  }

  close() {
    this.modalRef.dismiss();
  }
}
