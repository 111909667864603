import { Component, EventEmitter, Input, Output, TemplateRef, ViewChild } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ErrorHandlerService } from '@callcenter-app/service/error-handler.service';
import { HxOrderService } from 'hx-services';
import { firstValueFrom } from 'rxjs';

/**
 * Component of cancel order Button with modal and reason textarea
 *
 * @export
 * @class CancelOrderComponent
 */
@Component({
  selector: 'app-cancel-order',
  templateUrl: './cancel-order.component.html',
  styleUrls: ['./cancel-order.component.css']
})
export class CancelOrderComponent {
  @ViewChild('cancelOrder') cancelOrder!: TemplateRef<any>;
  @Input() id!: number;
  @Output() update = new EventEmitter<void>();

  cancelReason?: string;

  constructor(
    public modal: NgbModal,
    private orderService: HxOrderService,
    private errorHandlerService: ErrorHandlerService,
  ) { }

  showModal(): void {
    this.cancelReason = '';
    this.modal.open(this.cancelOrder, {});
  }

  submitCancel(dialogRef: NgbModalRef): void {
    const obj = {
      reason: this.cancelReason
    };

    firstValueFrom(this.orderService.cancelOrder(this.id, obj)).then(() => {
      this.update.emit();
      dialogRef.dismiss();
    }, err => this.errorHandlerService.check(err.error));
  }
}
