import { ResolveFn, Routes } from '@angular/router';
import { HxOrderEditComponent } from './order-edit/order-edit.component';
import { HxOrderListComponent } from './order-list/order-list.component';
import { FeedbackModel, HxFeedbackService } from 'hx-services';
import { inject } from '@angular/core';
import { HxFeedbackComponent } from './feedback/hx-feedback.component';
import { HxFeedbackEditComponent } from './feedback-edit/hx-feedback-edit.component';
import { HxFeedbackListComponent } from './feedback-list/hx-feedback-list.component';

const feedbackResolveFn: ResolveFn<FeedbackModel> = route => inject(HxFeedbackService).getFeedbackById(Number(route.paramMap.get('id')));

export const ROUTES: Routes = [
  {
    path: 'edit',
    component: HxOrderEditComponent,
    pathMatch: 'full'
  },
  {
    path: 'list',
    component: HxOrderListComponent,
    pathMatch: 'full'
  },
  {
    path: 'archive',
    component: HxOrderListComponent,
    pathMatch: 'full',
    data: {
      archive: true
    }
  },
  {
    path: 'today',
    component: HxOrderListComponent,
    pathMatch: 'full',
    data: {
      today: true
    }
  },
  {
    path: 'feedbacks',
    children: [
      {
        path: '',
        component: HxFeedbackListComponent,
        pathMatch: 'full'
      },
      {
        path: 'edit',
        component: HxFeedbackEditComponent,
        pathMatch: 'full'
      },
      {
        path: ':id',
        component: HxFeedbackComponent,
        pathMatch: 'full',
        resolve: {
          feedback: feedbackResolveFn
        }
      }
    ]
  }
];

