import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { CountryModel } from 'hx-services';

@Injectable({
  providedIn: 'root'
})
export class AsteriskNotifyService {
  private notifySubject = new Subject<AsteriskCallEvent>();
  public readonly notifyObs = this.notifySubject.asObservable();

  notify(event: AsteriskCallEvent) {
    this.notifySubject.next(event);
  }
}

export interface AsteriskCallEvent {
  callId: string;
  clientPhone: string;
  date: number;
  event: 'calling' | 'incoming' | 'waiting' | 'ringing' | 'talking' | 'finished';
  operatorId: string;
  audioRecord: string;
  mobile: boolean;
  country: CountryModel;
}
