<div class="modal-header">
  <h3 class="modal-title">
    Заказ № <strong>{{ order.uniqueNumber }}</strong>
  </h3>
  <button class="close" type="button" (click)="dismissModal()">
    <span>×</span>
  </button>
</div>

<div class="modal-body">
  <p>
    Для отправки счета на оплату введите номер телефона клиента
  </p>

  <div class="pay-invoice">
    <div>
      <input [(ngModel)]="clientPhone" [mask]="'(000) 000-00-00'" [prefix]="'+7 '" class="form-control" type="tel"/>

      <button class="btn btn-outline-primary" [disabled]="isLoading.sendPayInvoice" (click)="sendPayInvoice()">
        <i *ngIf="isLoading.sendPayInvoice" class="la la-spinner la-spin"></i>
        Отправить
      </button>
    </div>
  </div>
  <br/>
  <p>
    Если клиенту не пришло уведомление о счете на оплату: <br/>
    Для оплаты клиенту необходимо в приложении kaspi.kz в разделе "Платежи"
    найти Happy Cake и указать номер заказа <strong>{{order.uniqueNumber}}</strong>
  </p>
</div>

<div class="modal-footer">
  <button type="button" [disabled]="isLoading.checkPaid" class="btn btn-success" (click)="checkPaid()">
    <i *ngIf="isLoading.checkPaid" class="la la-spinner la-spin"></i>
    Проверить статус оплаты
  </button>
</div>
