import { Component, EventEmitter, Inject, Input, OnChanges, Optional, Output, SimpleChanges } from '@angular/core';
import { CityModel, HX_COMPONENT_NAME, HxAuthService, HxCityService } from 'hx-services';

/**
 * Component choose city
 */
@Component({
  selector: 'hx-city-selector',
  templateUrl: './city-selector.component.html',
  styleUrls: ['./city-selector.component.css']
})
export class HxCitySelectorComponent implements OnChanges {
  @Output() cityChange = new EventEmitter<CityModel | undefined>();

  @Input() id?: number;
  @Input() readonly = false;
  @Input() brandId?: number;

  isLoading = {
    city: false,
  };
  cities: CityModel[] = [];

  constructor(
    private cityService: HxCityService,
    private auth: HxAuthService,
    @Optional() @Inject(HX_COMPONENT_NAME) private componentName: string,
  ) {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['brandId']) {
      this.cities = [];
      if (this.brandId) {
        this.isLoading.city = true;
        this.auth.getCitiesByBrandId(this.brandId).then(cities => {
          this.cities = cities;
          if (cities.length === 1) {
            this.onCitySelected(cities[0]);
          }
          this.isLoading.city = false;
        }, () => this.isLoading.city = false);
      }
    }
  }

  onCitySelected(item?: CityModel) {
    if (this.id === item?.id && this.cities.length > 1) {
      this.id = undefined;
      this.cityChange.emit(undefined);
    } else {
      this.cityChange.emit(item);
    }
  }
}
