import { Component, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CountryModel, extractNumber, HxUserService, UserProfileModel } from 'hx-services';
import { Subscription } from 'rxjs';
import { isArray } from '@amcharts/amcharts4/core';
import { HxToastrService } from 'hx-component';
import { UserEditComponent } from '@callcenter-app/pages/user/user-edit/user-edit.component';

@Component({
  selector: 'app-user-list',
  templateUrl: './user-list.component.html',
  styleUrls: ['./user-list.component.css']
})
export class UserListComponent implements OnInit, OnDestroy {
  @ViewChild('fireTemplateRef') public fireTemplateRef!: TemplateRef<any>;
  users: UserProfileModel[] = [];
  query?: string;
  isLoading = {
    users: false,
  };
  selectedUser?: UserProfileModel;
  countryId?: number;
  country?: CountryModel;
  private sub!: Subscription;

  constructor(
    private modal: NgbModal,
    private userService: HxUserService,
    private toastr: HxToastrService,
    private router: Router,
    private aRoute: ActivatedRoute,
  ) { }

  ngOnInit() {
    this.sub = this.aRoute.queryParamMap.subscribe(paramMap => {
      this.query = paramMap.get('query') ?? '';
      this.countryId = extractNumber('countryId', paramMap);
      this.loadList();
    });
  }

  ngOnDestroy() {
    if (this.sub) {
      this.sub.unsubscribe();
    }
  }

  countryChanged(country: CountryModel | CountryModel[] | undefined) {
    if (isArray(country)) {
      this.country = country[0];
    } else {
      this.country = country;
    }
    this.pageChanged(1);
  }

  fireEmployee(item: UserProfileModel) {
    this.selectedUser = {...item};
    this.modal.open(this.fireTemplateRef, { size: 'sm', centered: true });
  }

  updateItem(user?: UserProfileModel) {
    const modalInstance = this.modal.open(UserEditComponent, {size: 'lg'});
    modalInstance.componentInstance.userProfile = user;
    modalInstance.componentInstance.countryId = this.countryId;
    if (user) {
      modalInstance.componentInstance.isEdit = true;
    } else {
      modalInstance.componentInstance.isEdit = false;
    }
    modalInstance.result.then(() => {
      this.loadList();
      modalInstance.close();
    }, (reason) => {
      console.log('Dismissed reason : ', reason);
      modalInstance.close();
    });
  }

  async fire(modal: any) {
    if (!this.selectedUser || !this.countryId) {
      return;
    }

    try {
      await this.userService.fireCallCenterUser(this.selectedUser.id, this.countryId);
      this.toastr.success('user.profile.fire.success');
      this.loadList();
    } finally {
      modal.dismiss();
    }
  }

  getTypeFilter() {
    this.pageChanged(1);
  }

  pageChanged(page: number) {
    this.router.navigate(['./'], {
      queryParams: {
        page: page,
        query: this.query,
        countryId: this.countryId,
      },
      relativeTo: this.aRoute
    });
  }

  private async loadList() {
    if (this.countryId) {
      this.users = [];
      this.isLoading.users = true;
      try {
        this.users = await this.userService.getCallCenterUsers(this.countryId, this.query);
      } finally {
        this.isLoading.users = false;
      }

    }
  }
}
