<div class="row m--margin-bottom-10" style="align-items: center;" *ngIf="country">
  <div *ngIf="!compact" class="col-md-3">{{ label | transloco }}:</div>

<!--  <div *ngIf="!client" class="col-md-4">-->
<!--    <small *ngIf="compact">{{ label | transloco }}:</small>-->
<!--    <div class="m-loader&#45;&#45;primary m-loader&#45;&#45;right" [ngClass]="{'m-loader': isLoading.client}">-->
<!--      <input [patterns]="customPatterns" [showMaskTyped]="true" [prefix]="country.phonePrefix" [mask]="country.phoneMask" [disabled]="disabled" type="text" [(ngModel)]="clientPhone" class="form-control min-width-155"-->
<!--             (ngModelChange)="typedClient($event)">-->
<!--      <small class="is&#45;&#45;danger">{{ 'hx.client-checker.mobileNum' | transloco }}</small>-->
<!--    </div>-->
<!--  </div>-->

  <div [ngClass]="compact ? 'col-md-12' : 'col-md-9'">
    <small *ngIf="compact">{{ label | transloco }}:</small>
    <div class="input-group">
      <input [patterns]="customPatterns" [showMaskTyped]="true" [prefix]="country.phonePrefix" [mask]="country.phoneMask" [disabled]="disabled" type="text" [(ngModel)]="clientPhone"
               (ngModelChange)="typedClient($event)" class="form-control min-width-155 max-width-155">

      <input  *ngIf="client" type="text" [disabled]="disabled" [(ngModel)]="client.client.fullname" class="form-control" [placeholder]="'<Новый' + (label | transloco) + '>'" (ngModelChange)="updateClient()">

      <span *ngIf="client" class="input-group-btn btn-layer">
        <button *ngIf="!isCashbox" class="btn btn-outline-info" (click)="isCollapsed = !isCollapsed" type="button">
          <i class="la la-edit"></i>
          <i class="la" [ngClass]="isCollapsed ? 'la-angle-down' : 'la-angle-up'"></i>
        </button>
        <button *ngIf="!disabled" class="btn btn-outline-danger" (click)="clear()" [ngbTooltip]="'hx.client-checker.ng.clear'" type="button">
          <i class="la la-remove"></i>
        </button>
      </span>
    </div>
    <div>
      <small class="is--danger">{{ 'hx.client-checker.mobileNum' | transloco }}</small>
      <span *ngIf="isLoading.bonus && client" class="la la-spinner has--spin"></span>
      <small *ngIf="!isLoading.bonus && isCashbox && client" class="m--margin-5 is--success">{{ 'hx.client-checker.bonus' | transloco }}: {{ coinAmount }}</small>
      <hx-client-indicator *ngIf="client" [clientId]="client.client.id"></hx-client-indicator>
    </div>
  </div>
</div>
<ng-container *ngIf="!isCashbox && client">
  <div [ngbCollapse]="isCollapsed">
    <div class="card">
      <div class="card-body">
        <hx-client-edit [clientId]="client.client.id" [clientName]="client.client.fullname" [cityId]="cityId" (clientChanged)="clientChanged($event)"></hx-client-edit>
      </div>
    </div>
  </div>
</ng-container>
