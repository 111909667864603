/**
 * Vanilla API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type CallType = 'IN' | 'OUT' | 'CALLBACK';

export const CallType = {
    IN: 'IN' as CallType,
    OUT: 'OUT' as CallType,
    CALLBACK: 'CALLBACK' as CallType
};

