import { NgModule } from '@angular/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { TranslocoModule } from '@ngneat/transloco';
import { HxComponentModule } from '../hx-component.module';
import { RouterModule, Routes } from '@angular/router';
import { HxFaqListComponent } from './faq-list/faq-list.component';
import { HxPipeModule } from 'hx-services';

const ROUTES: Routes = [
  {
    path: '',
    children: [
      {
        path: 'list',
        component: HxFaqListComponent,
        pathMatch: 'full'
      }
    ]
  }
];

@NgModule({
  declarations: [
    HxFaqListComponent,
  ],
  imports: [
    NgbModule,
    FormsModule,
    ReactiveFormsModule,
    CommonModule,
    TranslocoModule,
    HxComponentModule,
    RouterModule.forChild(ROUTES),
    HxPipeModule
  ],
  exports: []
})
export class HxFaqListModule {
}
