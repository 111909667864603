<ng-container *ngFor="let order of orders">
  <table class="table table-danger m--margin-bottom-0">
    <thead>
    <tr>
      <th>
        <span class="m-badge  m-badge--danger m-badge--wide m-badge--rounded m--margin-right-10">
          {{ 'product.status.REFUNDED' | transloco }}
        </span>
        #{{order.uniqueNumber}} {{ 'hx.order-refunded-list.from' | transloco }} {{ order.createDate | date: 'dd.MM.yyyy HH:mm' }}
      </th>

      <th class="text-right no-margin-label">
        {{order.refundUserFullname | fio}}
      </th>
    </tr>
    </thead>
  </table>

  <div class="is--responsive-table">
    <table class="table">
      <thead>
      <tr>
        <th>{{ 'hx.order-refunded-list.table.head.name' | transloco }}</th>
        <th class="text-right">{{ 'hx.order-refunded-list.table.head.price' | transloco }}</th>
        <th class="text-right">{{ 'hx.order-refunded-list.table.head.weight' | transloco }}</th>
        <th class="text-right">{{ 'hx.order-refunded-list.table.head.num' | transloco }}</th>
        <th class="text-right">{{ 'hx.order-refunded-list.table.head.sum' | transloco }}</th>
      </tr>
      </thead>
      <tbody>
      <ng-container *ngFor="let product of order.products" >
        <ng-container>
          <tr>
            <td>
              <span *ngIf="product.status === 'REFUNDED' || product.status === 'PAID_CANCELLED'">{{ product.productInfo?.title | uiLabel }}</span>
              <span *ngIf="product.price?.amount !== 1"> {{ product.price?.amount }}</span>
            </td>
            <td class="text-right text-nowrap">
              {{ product.price?.value | uiCurrency:order.currency }}
            </td>
            <td class="text-right text-nowrap">
              <span *ngIf="product.weight">
                {{ product.weight | weight }}
              </span>
            </td>
            <td class="text-right">
              {{ product.amount }}
            </td>
            <td class="text-right text-nowrap">
              <!--              <i class="la la-certificate" title="со скидкой" *ngIf="isDiscounted(product.id)"></i>-->
              {{ product.value | uiCurrency:order.currency }}
            </td>
          </tr>
        </ng-container>
      </ng-container>
      <tr>
        <td>
          <span [ngClass]="{'m--font-bolder m--regular-font-size-lg2': order.total === order.subTotal}">{{ 'hx.order-refunded-list.table.body.total' | transloco }}</span>
        </td>
        <td class="text-right"></td>
        <td class="text-right text-nowrap">
          {{ order.totalGram | weight }}
        </td>
        <td class="text-right">
          {{ order.totalAmount }}
        </td>
        <td class="text-right text-nowrap">
          <h5 class="m--font-danger" [ngClass]="{'m--font-normal': order.discounts?.length}">
            {{ order.subTotal | uiCurrency:order.currency }}
          </h5>
        </td>
      </tr>

      <tr *ngIf="order.discounts.length">
        <td colspan="5">{{ 'hx.order-refunded-list.table.body.discount' | transloco }}:</td>
      </tr>

      <tr [ngClass]="{'is--disabled': !discount.enabled}" *ngFor="let discount of order.discounts">
        <td class="m--padding-left-20">
          {{ discount.promoTitle | uiLabel }}
        </td>
        <td class="text-right">
        </td>
        <td class="text-right">
        </td>

        <td class="text-right">
          {{ discount.productAmount }}
        </td>

        <td class="text-right">
          <h5 class="m--font-info m--font-normal">
            <!-- TODO @deedarb #ins other result types ? -->
            <span *ngIf="discount.resultType === 'PERCENT_DISCOUNT'">{{ discount.promoValue }} %</span>
            <span *ngIf="discount.resultType === 'MONEY_DISCOUNT'">{{ discount.value | uiCurrency: order.currency }}</span>
          </h5>
        </td>
      </tr>

      <tr *ngIf="order.isTotalShow">
        <td>
          <h5>{{ 'hx.order-refunded-list.table.body.allTotal' | transloco }}</h5>
        </td>
        <td class="text-right">
        </td>
        <td class="text-right text-nowrap">
          {{ order.totalGram | weight }}
        </td>
        <td class="text-right">
          {{ order.totalAmount }}
        </td>
        <td class="text-right text-nowrap">
          <h5 class="m--font-success">
            {{ order.total | uiCurrency:order.currency }}
          </h5>
        </td>
      </tr>
      </tbody>
    </table>
  </div>
</ng-container>
