<div class="m-subheader">
  <div class="d-flex align-items-center">
    <div class="mr-auto">
      <h3 class="m-subheader__title">
        {{ 'ord.hx-feedback-list.title' | transloco }}
      </h3>
    </div>

    <div *ngIf="addButtonVisible">
      <a class="btn btn-success m-btn m-btn--custom m-btn--icon" [routerLink]="['/orders/feedbacks/edit']" [queryParams]="{orderId: orderId}">
        <span>
          <i class="la la-user-plus"></i>
          <span>
            {{ 'ord.hx-feedback-list.addReview' | transloco }}
          </span>
        </span>
      </a>
    </div>
  </div>
</div>

<div class="m-content">
  <div class="row">
    <div class="col-sm-12">
      <div class="m-portlet m-portlet--full-height">
        <div class="m-portlet__body">
          <ng-container *ngIf="!orderId">
            <div class="hx-filters">
              <div class="filter">
                <small>{{ 'ord.hx-feedback-list.shop' | transloco }}</small>
                <hx-store-select [(ngModel)]="params.storeId" (selectChange)="applyFilters()"></hx-store-select>
              </div>
<!--              <div *ngIf="componentName === 'cc'" class="filter">-->
<!--                <small>Магазин</small>-->
<!--                <ng-select [items]="stores" bindLabel="id" bindValue="id" [multiple]="false"-->
<!--                           placeholder="Выберите магазин"-->
<!--                           [(ngModel)]="params.storeId" (change)="applyFilters()">-->
<!--                  <ng-template ng-label-tmp ng-option-tmp let-item="item">-->
<!--                    {{item.title | uiLabel}}-->
<!--                  </ng-template>-->
<!--                </ng-select>-->
<!--              </div>-->
              <div class="filter">
                <small>{{ 'ord.hx-feedback-edit.type' | transloco }}</small>
                <ng-select bindValue="value" bindLabel="key"
                           [multiple]="true" [placeholder]="'ord.hx-feedback-list.placeholder.chooseType' | transloco"
                           [(ngModel)]="params.types" (change)="applyFilters()">
                  <ng-container *ngIf="componentName === 'cc'">
                    <ng-option *ngFor="let type of feedbackTypes" [value]="type">{{'feedbackType.' + type | transloco}}</ng-option>
                  </ng-container>
                  <ng-container *ngIf="componentName === 'manager'">
                    <ng-option *ngFor="let type of ['DELIVERY','CASHBOX','PRODUCT']" [value]="type">{{'feedbackType.' + type | transloco}}</ng-option>
                  </ng-container>
                </ng-select>
              </div>
              <div class="filter">
                <small>{{ 'ord.hx-feedback-list.data' | transloco }}</small>
                <hx-date-range-select [(ngModel)]="params.date" (selectChange)="applyFilters()"></hx-date-range-select>
              </div>
              <div class="filter">
                <small>{{ 'ord.hx-feedback-list.rating' | transloco }}</small>
                <div style="margin: 0.7rem 0;">
                  <i class="la clickable"
                     [ngClass]="{'la-star': (params.rating ?? 0) >= ir, 'la-star-o': (params.rating ?? 0) < ir || !params.rating}"
                     (click)="filterRating(ir)" *ngFor="let ir of [1,2,3,4,5]"></i>
                </div>
              </div>
              <div class="filter filter__has-order">
                <label class="m-checkbox m-checkbox--state-primary">
                  <input type="checkbox" [(ngModel)]="params.hasOrder" (change)="applyFilters()">
                  {{ 'ord.hx-feedback-list.order' | transloco }}
                  <span></span>
                </label>
              </div>
              <div class="filter">
                <small>{{ 'ord.hx-feedback-list.order' | transloco }}</small>
                <hx-client-search [clientId]="params.clientId" (clientChange)="onClientChanged($event)"></hx-client-search>
              </div>
              <div class="filter" *ngIf="componentName === 'manager'">
                <small>{{ 'ord.hx-feedback-list.employee' | transloco }} <ng-container *ngIf="!params.storeId">({{ 'ord.hx-feedback-edit.chooseShop' | transloco }})</ng-container></small>
                <hx-user-select [(ngModel)]="params.targetUserId" [preload]="true" [disabled]="!params.storeId"
                                [storeId]="params.storeId" (selectChange)="applyFilters()"></hx-user-select>
              </div>
              <div class="filter" *ngIf="componentName === 'cc'">
                <small>{{ 'ord.hx-feedback-list.employee' | transloco }}</small>
                <hx-user-select [(ngModel)]="params.targetUserId" [storeId]="params.storeId" (selectChange)="applyFilters()"></hx-user-select>
              </div>
              <div class="filter">
                <small>{{ 'ord.hx-feedback-list.source' | transloco }}</small>
                <ng-select *ngIf="sources.length > 0"
                           [multiple]="true"
                           [placeholder]="'ord.hx-feedback-list.placeholder.chooseType' | transloco"
                           [(ngModel)]="params.sources" (change)="applyFilters()">
                  <ng-option *ngFor="let s of sources" [value]="s">{{'sourceSystem.' + s | transloco}}</ng-option>
                </ng-select>
              </div>
            </div>

            <button class="btn btn-outline-primary m--margin-top-10" (click)="resetFilter()">{{ 'ord.hx-feedback-list.reset' | transloco }}</button>
          </ng-container>

          <div class="table-responsive m-datatable--va-top m-datatable m-datatable--default m-datatable--loaded m--margin-top-20 m--margin-bottom-20">
            <table class="m-datatable__table" *ngIf="list.length && !isLoading.list">
              <thead class="m-datatable__head">
              <tr class="m-datatable__row m-datatable__row--head">
                <th class="m-datatable__cell" (click)="sortTable(menuItem)" *ngFor="let menuItem of menu">
                  <span>
                    {{ menuItem.title }}
                    <ng-container>
                      <i class="la"
                         [ngClass]="{'la-sort-amount-desc': menuItem.sort === 'desc', 'la-sort-amount-asc': menuItem.sort === 'asc', 'd-none': menuItem.sort === ''}"></i>
                      <i class="la la-sort" *ngIf="menuItem.sort === ''"></i>
                    </ng-container>
                  </span>
                </th>
                <th class="m-datatable__cell">{{ 'ord.hx-feedback-list.ratings' | transloco }}</th>
                <th class="m-datatable__cell">{{ 'ord.hx-feedback-list.comment' | transloco }}</th>
                <th class="m-datatable__cell">{{ 'ord.hx-feedback-list.ord' | transloco }}</th>
                <th class="m-datatable__cell">{{ 'ord.hx-feedback-list.client' | transloco }}</th>
                <th class="m-datatable__cell">{{ 'ord.hx-feedback-list.manager' | transloco }}</th>
                <th class="m-datatable__cell">{{ 'ord.hx-feedback-list.shop' | transloco }}</th>
                <th class="m-datatable__cell">#</th>
              </tr>
              </thead>
              <tbody class="m-datatable__body">
              <tr *ngFor="let i = index; let item of list; let $even=even"
                  class="m-datatable__row" [ngClass]="{ 'm-datatable__row--even': $even }">
                <td class="m-datatable__cell">{{ item.date | date: 'dd.MM.yyyy HH:mm' }}</td>
                <td class="m-datatable__cell text-nowrap">
                  <i class="la la-star" [ngClass]="ratingClass(item.avgRating)"></i> {{item.avgRating}}
                </td>
                <td class="m-datatable__cell">
                  <i *ngFor="let rr of item.reviews" [ngbTooltip]="'feedbackType.' + rr.type | transloco" [ngClass]="ratingIconClass(rr)"></i>
                </td>

                <td class="m-datatable__cell">
                  <hx-call-audio *ngIf="item.callId" [callId]="item.callId"></hx-call-audio> {{item.clientText}}
                </td>

                <td class="m-datatable__cell">
                  <a *ngIf="item.orderId" [routerLink]="['/orders', item.orderId]">{{ item.orderId }} - {{ item.orderNumber }}</a>
                </td>

                <td class="m-datatable__cell">{{ item.clientPhone }}
                  <br>{{ item.clientFullname }}
                </td>

                <td class="m-datatable__cell">
                  {{ item.supportFullname }}
                </td>

                <td class="m-datatable__cell">
                  {{ storeById.get(item.storeId)?.title | uiLabel }}
                </td>
                <td class="m-datatable__cell text-nowrap">
                  <a class="la la-arrow-circle-o-right clickable" [ngbTooltip]="'ord.hx-feedback-list.ng.view' | transloco" [routerLink]="['/orders/feedbacks', item.id]"></a>
                  <a *ngIf="hasPermission && isCreator(item)" [ngbTooltip]="'ord.hx-feedback-list.ng.edit' | transloco" class="la la-edit clickable is--edit" [routerLink]="['/orders/feedbacks/edit']" [queryParams]="{feedbackId: item.id}"></a>
                  <a *ngIf="hasPermission && isCreator(item)" [ngbTooltip]="'ord.hx-feedback-list.ng.delete' | transloco" class="la la-times-circle-o clickable is--remove" (click)="removeFeedback(item.id)"></a>
                </td>
              </tr>
              </tbody>
            </table>
          </div>

          <hx-loader [isLoading]="isLoading.list" [length]="list.length"></hx-loader>

          <div class="m--align-center" *ngIf="!orderId && list.length && !isLoading.list && pagination.allItemCount > 0">
            <ngb-pagination [maxSize]="5" [rotate]="true" [collectionSize]="pagination.allItemCount"
                            [(page)]="params.page" [pageSize]="params.limit"
                            (pageChange)="pageChanged($event)" class="d-flex justify-content-center mt-4">
            </ngb-pagination>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
