import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { ROUTES } from './hx-client-list.routing';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { HxClientListComponent } from './client-list/client-list.component';
import { TranslocoModule } from '@ngneat/transloco';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HxComponentModule } from '../hx-component.module';

@NgModule({
    declarations: [
        HxClientListComponent,
    ],
    imports: [
        NgbModule,
        FormsModule,
        ReactiveFormsModule,
        CommonModule,
        TranslocoModule,
        HxComponentModule,
        RouterModule.forChild(ROUTES)
    ],
    exports: []
})
export class HxClientListModule {
}
