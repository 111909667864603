import { Component, Input, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import { environment } from '@callcenter-env/environment';
import { CallType } from 'hx-services';

@Component({
  selector: 'app-asterisk-demo',
  templateUrl: './asterisk-demo.component.html',
  styleUrls: ['./asterisk-demo.component.css']
})
export class AsteriskDemoComponent implements OnInit {

  private _callId?: string;
  @Input()
  set callId(val) {
    this._callId = val;
    if (val) {
      this.asteriskCallId = val;
    }
  }

  get callId() {
    return this._callId;
  }

  phone = '77008889991';
  asteriskId = '204';
  asteriskCallId?: string;

  constructor(private httpClient: HttpClient, private toastr: ToastrService) {
  }

  ngOnInit(): void {
    this.generatePhone();
  }

  finished() {
    this.execute('finished');
    this.toastr.info(`callId изменен!`);
  }

  talking() {
    this.execute('talking');
  }

  ringing() {
    this.execute('ringing');
  }

  incoming() {
    this.asteriskCallId = this.phone + '.' + Math.random();
    this.execute('incoming');
  }

  private execute(eventName: string) {
    if (environment.production) {
      return;
    }
    this.httpClient.post('/api/asterisk/call-event', {
      audioRecord: 'http://172.27.124.216:8033/monitor/2017/10/03/q-6001-87474771993-20171003-121711-1507011414.as8f7d7df8.wav',
      callId: `${this.asteriskCallId}`,
      clientPhone: this.phone,
      date: Date.now(),
      type: CallType.IN,
      event: eventName,
      operatorId: this.asteriskId
    }).subscribe(() => {
      this.toastr.info(`${eventName} отправлен!`);
    });
  }

  generatePhone() {
    this.phone = '7700' + (Math.random() + '').substring(2, 9);
  }
}
