import { Component, EventEmitter, forwardRef, Inject, Input, OnChanges, OnInit, Optional, Output, SimpleChanges } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { HX_COMPONENT_NAME, HxAuthService, HxStoreService, StoreBasicModel, uiLabel } from 'hx-services';
import { TranslocoService } from '@ngneat/transloco';
import { firstValueFrom, Subject } from 'rxjs';

interface StoreFullModel extends StoreBasicModel {
  cityTitle?: string
  groupTitle?: string
}

@Component({
  selector: 'hx-store-select',
  templateUrl: './store-select.component.html',
  styleUrls: ['./store-select.component.css'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => HxStoreSelectComponent),
    multi: true
  }]
})
export class HxStoreSelectComponent implements OnInit, OnChanges, ControlValueAccessor {
  @Input() cityId?: number;
  @Input() multiple = false;
  @Input() default = true;
  @Input() excludeClosed = false;
  @Input() filterFn?: (storeList: StoreBasicModel[]) => StoreBasicModel[];
  @Output() selectChange = new EventEmitter<StoreBasicModel | StoreBasicModel[] | undefined>();

  options: StoreFullModel[] = [];
  isDisabled = false;
  isLoading = false;
  private $ready = new Subject<void>();
  private $selected?: number | number[];

  get selected(): number | number[] | undefined {
    return this.$selected;
  }

  set selected(val: number | number[] | undefined) {
    this.$selected = val;
    if (val && !Array.isArray(val)) {
      localStorage.setItem('defaultStoreId', String(val));
      const brandId = this.options.find(r => r.id === val)?.brandId;
      if (brandId) {
        localStorage.setItem('defaultBrandId', String(brandId));
      }
    }
    if (Array.isArray(val) && val.length) {
      localStorage.setItem('multipleDefaultStoreIds', JSON.stringify(val));
    }
    this.onChange(val);
  }

  constructor(
    private tr: TranslocoService,
    private storeService: HxStoreService,
    @Optional() @Inject(HX_COMPONENT_NAME) public componentName: string
  ) {
  }

  async ngOnInit() {
    await firstValueFrom(this.tr.selectTranslateObject('hx.closed'));
    await this.loadOptions();
    this.$ready.next();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['cityId']) {
      this.loadOptions();
    }
  }

  onModelChanged(val: any) {
    this.selectChange.emit(this.getSelectedOptions());
  }

  onChange(val: any) {
    // console.log('onChange called', val);
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
  }

  writeValue(obj: any): void {
    firstValueFrom(this.$ready).then(() => {
      this.selected = obj;
    });
  }

  setDisabledState(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }

  onCleared($event: any) {
    localStorage.removeItem('defaultStoreId');
  }

  private getSelectedOptions(): StoreBasicModel | StoreBasicModel[] | undefined {
    if (!this.multiple) {
      if (this.selected) {
        return this.options.find(p => p.id === this.selected);
      } else {
        return undefined;
      }
    } else {
      if (Array.isArray(this.selected)) {
        const ids: number[] = this.selected;
        return this.options.filter(p => ids.includes(p.id));
      } else {
        return [];
      }
    }
  }

  private async loadOptions() {
    this.isLoading = true;
    try {
      const stores = await this.storeService.getFullStores({ page: 1, limit: 1000, cityId: this.cityId });
      this.options = stores.list
        .map(store => {
          const group = store as StoreFullModel;
          group.cityTitle = uiLabel(this.tr.getActiveLang(), store.city?.title);
          if (!group.endWorkDate || new Date(group.endWorkDate) > new Date()) {
            group.groupTitle = uiLabel(this.tr.getActiveLang(), group.title);
          } else {
            group.groupTitle = uiLabel(this.tr.getActiveLang(), group.title) + ' (' + this.tr.translate('hx.closed') + ')';
          }
          return group;
        });
      this.options = this.options.sort((a, b) => (a.cityId < b.cityId) ? -1 : 1);

      if (this.excludeClosed) {
        this.options = this.options.filter(group => group.active);
      }

      if (this.filterFn) {
        this.options = this.filterFn(this.options);
      }
    } finally {
      this.isLoading = false;
    }
  }
}
