import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { APP_INITIALIZER, ErrorHandler, Injectable, Injector, NgModule } from '@angular/core';
import { HTTP_INTERCEPTORS, HttpClientModule, HttpErrorResponse } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastrModule, ToastrService } from 'ngx-toastr';
import { NgSelectModule } from '@ng-select/ng-select';
import { AppComponent } from '@callcenter-app/app.component';
import { environment } from '@callcenter-env/environment';
import { KeycloakAngularModule, KeycloakService } from 'keycloak-angular';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { CallCenterComponent } from '@callcenter-app/pages/call-center/call-center.component';
import { HeaderComponent } from '@callcenter-app/components/header/header.component';

import { FooterComponent } from '@callcenter-app/components/footer/footer.component';
import { SidebarComponent } from '@callcenter-app/components/sidebar/sidebar.component';
import { HomeComponent } from '@callcenter-app/pages/home/home.component';
import { CallsHoursReportComponent } from '@callcenter-app/pages/report/calls-hours-report/calls-hours-report.component';
import { CallsReportsComponent } from '@callcenter-app/pages/report/calls-reports/calls-reports.component';
import { CallsStatusReportComponent } from '@callcenter-app/pages/report/calls-status-report/calls-status-report.component';
import { CancelOrderComponent } from '@callcenter-app/components/cancel-order/cancel-order.component';
import { CallsListComponent } from '@callcenter-app/pages/calls-list/calls-list.component';
import { OrderViewComponent } from '@callcenter-app/pages/order/order-view/order-view.component';
import { CustomHttpInterceptor } from '@callcenter-app/service/http.interceptor';
import { HcNotifierComponent } from '@callcenter-app/components/hc-notifier/hc-notifier.component';
import { HcAlertComponent } from '@callcenter-app/components/hc-alert/hc-alert.component';
import { AppRoutingModule } from '@callcenter-app/app-routing.module';
import * as Sentry from '@sentry/browser';
import {
  ApiModule,
  ComponentType,
  Configuration,
  flattenObject,
  HX_APP_VERSION,
  HX_COMPONENT_NAME,
  HX_ENV_MODE,
  HX_GOOGLE_MAPS_API_KEY,
  HX_KEYCLOAK,
  HxAuthService,
  HxError,
  HxPipeModule,
  initKeycloak,
  TranslocoRootModule,
  UiError
} from 'hx-services';
import { DatePipe } from '@angular/common';
import { NgxMaskDirective, NgxMaskPipe, provideEnvironmentNgxMask } from 'ngx-mask';
import { OperatorOrderStatReportComponent } from '@callcenter-app/pages/report/operator-order-stat/operator-order-stat.component';
import { HxOrderModule } from 'hx-order';
import { AsteriskDemoComponent } from '@callcenter-app/components/asterisk-demo/asterisk-demo.component';
import { FeedbackRatingReportComponent } from '@callcenter-app/pages/report/feedback-rating/feedback-rating-report.component';
import { HxComponentModule } from 'hx-component';
import { CallDetailsComponent } from '@callcenter-app/pages/modal/call-details/call-details.component';
import { PayInvoiceModal } from '@callcenter-app/components/pay-invoice/pay-invoice.modal';
import { APP_VERSION } from '@callcenter-env/version';
import { TranslocoService } from '@ngneat/transloco';
import { UserListComponent } from '@callcenter-app/pages/user/user-list/user-list.component';
import { UserEditComponent } from '@callcenter-app/pages/user/user-edit/user-edit.component';

if (environment.sentryUrl) {
  Sentry.init({
    release: environment.version,
    environment: environment.production ? 'production' : 'staging',
    ignoreErrors: ['Unauthorized', 'productBalance.zero'],
    dsn: environment.sentryUrl,
    beforeSend: (event, hint) => {
      console.log('[sentry] before send', event, hint);
      if (hint && hint.originalException && hint.originalException instanceof HttpErrorResponse) {
        if (hint.originalException.status === 401) {
          console.log('[sentry] skipped error logging', event);
          return null;
        }
      }
      return event;
    },
    integrations: [
      new Sentry.Integrations.TryCatch({
        XMLHttpRequest: false,
      }),
    ],
  });
}

@Injectable()
export class CustomErrorHandler implements ErrorHandler {
  constructor(private injector: Injector) {
  }

  handleError(error: any) {
    if (error.promise && error.rejection) {
      console.error('[got-rejection] %O', error);
      error = error.rejection;
    }
    const toastr = this.injector.get(ToastrService);
    const tr = this.injector.get(TranslocoService);
    if (error instanceof HttpErrorResponse) {
      const ex: HxError = error.error?.message ? error.error as HxError : {
        code: 577,
        id: '-',
        message: error.message,
        data: error.error ?? {},
      };
      toastr.error(ex.description ?? tr.translate(ex.message, flattenObject(ex.data)), tr.translate('error') + ' ' + ex.id);
    }
    if (error instanceof UiError) {
      toastr.error(tr.translate(error.message, error.data ?? {}));
    } else {
      console.error('[error-handler] %O', error);
    }
    Sentry.captureException(error.originalError || error);
  }
}

function getKeycloakUrl() {
  if (environment.production) {
    return location.origin.replace(location.origin.split('//')[1].split('.')[0], 'auth') + '/auth';
  }
  return environment.keycloakUrl;
}

@NgModule({
  declarations: [
    AppComponent,
    CallCenterComponent,
    HeaderComponent,
    FooterComponent,
    SidebarComponent,
    HomeComponent,
    OrderViewComponent,
    CallsListComponent,
    CancelOrderComponent,
    CallsHoursReportComponent,
    CallsReportsComponent,
    CallsStatusReportComponent,
    HcNotifierComponent,
    HcAlertComponent,
    OperatorOrderStatReportComponent,
    AsteriskDemoComponent,
    FeedbackRatingReportComponent,
    CallDetailsComponent,
    PayInvoiceModal,
    UserListComponent,
    UserEditComponent,
  ],
  imports: [
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot(),
    HxPipeModule,
    HxComponentModule,
    HxOrderModule,
    TranslocoRootModule,
    NgSelectModule,
    KeycloakAngularModule,
    NgbModule,
    NgxMaskPipe,
    NgxMaskDirective,
    ApiModule.forRoot(() => new Configuration({basePath: ''})),
  ],
  providers: [
    DatePipe,
    provideEnvironmentNgxMask(),
    {
      provide: HTTP_INTERCEPTORS,
      useClass: CustomHttpInterceptor,
      multi: true,
    },
    {
      provide: ErrorHandler,
      useClass: CustomErrorHandler,
    },
    {
      provide: HX_COMPONENT_NAME,
      useValue: ComponentType.cc
    },
    {
      provide: HX_APP_VERSION,
      useValue: APP_VERSION
    },
    {
      provide: HX_KEYCLOAK,
      useValue: {
        url: getKeycloakUrl(),
        clientId: environment.keycloakClientId,
        realm: environment.keycloakRealm
      }
    },
    {
      provide: HX_GOOGLE_MAPS_API_KEY,
      useValue: environment.googleMapsApiKey
    },
    {
      provide: APP_INITIALIZER,
      useFactory: initKeycloak,
      multi: true,
      deps: [KeycloakService, HxAuthService, HX_KEYCLOAK, TranslocoService],
    },
    { provide: HX_ENV_MODE, useValue: environment.production },
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
