import { inject, NgModule } from '@angular/core';
import { ResolveFn, RouterModule, Routes } from '@angular/router';

import { HomeComponent } from './pages/home/home.component';
import { CallCenterComponent } from './pages/call-center/call-center.component';
import { OrderViewComponent } from './pages/order/order-view/order-view.component';
import { CallsListComponent } from './pages/calls-list/calls-list.component';
import { CallsReportsComponent } from './pages/report/calls-reports/calls-reports.component';
import { CallsStatusReportComponent } from './pages/report/calls-status-report/calls-status-report.component';
import { CallsHoursReportComponent } from './pages/report/calls-hours-report/calls-hours-report.component';
import { OperatorOrderStatReportComponent } from '@callcenter-app/pages/report/operator-order-stat/operator-order-stat.component';
import { HxOrderModule } from 'hx-order';
import { FeedbackRatingReportComponent } from '@callcenter-app/pages/report/feedback-rating/feedback-rating-report.component';
import { HxOrderService, OrderResponse } from 'hx-services';
import { UserListComponent } from '@callcenter-app/pages/user/user-list/user-list.component';
import { UserEditComponent } from '@callcenter-app/pages/user/user-edit/user-edit.component';

const orderResolveFn: ResolveFn<OrderResponse> = route => inject(HxOrderService)
    .getFullOrderById(Number(route.paramMap.get('id')));

export const ROUTES: Routes = [
  {
    path: '',
    component: HomeComponent,
    children: [
      {
        path: '',
        pathMatch: 'full',
        redirectTo: 'call-center'
      },
      {
        path: 'call-center',
        component: CallCenterComponent,
      },
      {
        path: 'clients',
        loadChildren: () => import('hx-component').then(m => m.HxClientListModule)
      },
      {
        path: 'orders',
        loadChildren: () => import('hx-order').then(m => m.HxOrderModule)
      },
      {
        path: 'orders/:id',
        component: OrderViewComponent,
        pathMatch: 'full',
        resolve: {
          order: orderResolveFn,
        }
      },
      {
        path: 'calls',
        component: CallsListComponent,
      },
      {
        path: 'rejections',
        loadChildren: () => import('hx-component').then(m => m.HxRejectionListModule)
      },
      {
        path: 'users',
        children: [
          {
            path: '',
            component: UserListComponent,
          },
          {
            path: 'new',
            component: UserEditComponent,
          },
          {
            path: ':id/edit',
            component: UserEditComponent,
          },
        ]
      },
      {
        path: 'report',
        children: [
          {
            path: 'calls',
            component: CallsReportsComponent,
          },
          {
            path: 'calls-status',
            component: CallsStatusReportComponent,
          },
          {
            path: 'calls-hours',
            component: CallsHoursReportComponent
          },
          {
            path: 'operator-order-stat',
            component: OperatorOrderStatReportComponent
          },
          {
            path: 'feedback-rating',
            component: FeedbackRatingReportComponent
          },
        ]
      },
      {
        path: 'faq',
        loadChildren: () => import('hx-component').then(m => m.HxFaqListModule)
      },
    ]
  },
  {
    path: '**',
    redirectTo: 'call-center'
  }
];

@NgModule({
  imports: [
    HxOrderModule,
    RouterModule.forRoot(ROUTES, {
      scrollPositionRestoration: 'disabled',
    })
  ],
  exports: [RouterModule],
  declarations: [],
})
export class AppRoutingModule {
}
