import { Component, EventEmitter, forwardRef, Input, OnInit, Output } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { CountryModel, HxCountryService } from 'hx-services';

@Component({
  selector: 'hx-country-select',
  templateUrl: './country-select.component.html',
  styleUrls: ['./country-select.component.css'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => HxCountrySelectComponent),
    multi: true
  }]
})
export class HxCountrySelectComponent implements OnInit, ControlValueAccessor {
  @Input() multiple = false;
  @Input() default = true;
  @Input() all = true;
  @Output() selectChange = new EventEmitter<CountryModel | CountryModel[] | undefined>();

  disabled = false;
  options: CountryModel[] = [];
  selected: number | number[] | undefined;

  constructor(
    private countryService: HxCountryService,
  ) {
  }

  async ngOnInit() {
    if (this.all) {
      this.options = await this.countryService.getCountries();
      if ((this.options ?? []).length === 1) {
        this.selected = this.options[0].id;
        this.onModelChanged(this.selected);
      }
    } else {
      this.countryService.getMyCountries().subscribe(countries => {
        this.options = countries;
        if ((this.options ?? []).length === 1) {
          this.selected = this.options[0].id;
          this.onModelChanged(this.selected);
        }
      });
    }
  }

  onModelChanged(val: any) {
    this.onChange(val);
    if (!this.multiple) {
      if (this.selected) {
        this.selectChange.emit(this.options.find(p => p.id === this.selected));
      } else {
        this.selectChange.emit();
      }
    } else {
      if (Array.isArray(this.selected)) {
        const ids: number[] = this.selected;
        this.selectChange.emit(this.options.filter(p => ids.includes(p.id)));
      } else {
        this.selectChange.emit([]);
      }
    }
  }

  onChange(val: any) {
    // console.log('onChange called', val);
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  writeValue(obj?: number | number[]): void {
    this.selected = obj;
  }
}
