import { Component, Inject, OnDestroy, OnInit, Optional, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import { ClientFullModel, ComponentType, extractNumber, HX_COMPONENT_NAME, HxClientService, toRouterQueryParams } from 'hx-services';
import { HxToastrService } from '../../services/toastr.service';
import { HxErrorHandlerService } from '../../services/error-handler.service';
import { HxClientEditModal } from '../../components/client/edit/client-edit-modal/client-edit.modal';

@Component({
  selector: 'hx-client-list.m-grid__item.m-grid__item--fluid.m-wrapper',
  templateUrl: './client-list.component.html',
  styleUrls: ['./client-list.component.css'],
  encapsulation: ViewEncapsulation.None,
})
export class HxClientListComponent implements OnInit, OnDestroy {
  list: ClientFullModel[] = [];
  pagination = {allItemCount: 0};
  editId?: number;
  isLoading = {
    list: false,
    edit: false
  };
  isCallcenter: boolean;
  params: {
    page: number;
    limit: number;
    query?: string;
    vip?: boolean;
    blacklisted?: boolean;
  } = {page: 1, limit: 20};
  private sub!: Subscription;

  constructor(
    private modal: NgbModal,
    private clientService: HxClientService,
    private aRoute: ActivatedRoute,
    private router: Router,
    private toaster: HxToastrService,
    private errorService: HxErrorHandlerService,
    @Optional() @Inject(HX_COMPONENT_NAME) private componentName: string,
  ) {
    this.isCallcenter = this.componentName === ComponentType.cc;
  }

  ngOnInit() {
    this.sub = this.aRoute.queryParamMap.subscribe(paramMap => {
      this.params.page = extractNumber('page', paramMap) ?? 1;
      this.params.limit = extractNumber('limit', paramMap) ?? 20;
      this.params.query = paramMap.get('query') ?? '';
      this.params.vip = paramMap.get('vip') === 'true' ? true : undefined;
      this.params.blacklisted = paramMap.get('blacklisted') === 'true' ? true : undefined;
      this.loadList();
    });
  }

  addItem() {
    this.modal.open(HxClientEditModal, {size: 'lg'}).result.then(() => {
      this.loadList();
    }, (reason) => {
      console.log('Dismissed reason : ', reason);
    });
  }

  updateItem(client: ClientFullModel) {
    const modalInstance = this.modal.open(HxClientEditModal, {size: 'lg'});
    modalInstance.componentInstance.id = client.client.id;
    modalInstance.result.then(() => {
      this.loadList();
    }, (reason) => {
      console.log('Dismissed reason : ', reason);
    });
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }

  resetFilter(): void {
    this.params = {page: 1, limit: 20};
    this.pageChanged(1);
  }

  onSubmitFilter(): void {
    this.pageChanged(1);
  }

  private async loadList() {
    this.isLoading.list = true;
    this.list = [];
    try {
      const result = await this.clientService.getClientList(this.params);
      this.list = result.list || [];
      this.pagination.allItemCount = result.count;
    } finally {
      this.isLoading.list = false;
    }
  }

  pageChanged(page: number) {
    this.params.page = page;
    this.navigateUrl();
  }

  applyFilters() {
    if (this.params.vip === false) {
      this.params.vip = undefined;
    }
    if (this.params.blacklisted === false) {
      this.params.blacklisted = undefined;
    }
    this.pageChanged(1);
  }

  private navigateUrl() {
    this.router.navigate(['./'], {
      queryParams: toRouterQueryParams(this.params),
      relativeTo: this.aRoute
    });
  }
}
