import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Data, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { FeedbackModel, FeedbackType, HxAuthService, HxFeedbackService, Review } from 'hx-services';
import { format } from 'date-fns';
import { TranslocoService } from '@ngneat/transloco';

/**
 * Component of feedback list added by Call-center or Kiosk
 */
@Component({
  selector: 'hx-feedback', // .m-grid__item.m-grid__item--fluid.m-wrapper //
  templateUrl: './hx-feedback.component.html',
  styleUrls: ['./hx-feedback.component.css']
})
export class HxFeedbackComponent implements OnInit {
  feedback!: FeedbackModel;
  isSaving = false;
  types: FeedbackType[] = [FeedbackType.CASHBOX, FeedbackType.DELIVERY, FeedbackType.PRODUCT,
    FeedbackType.CALLCENTER, FeedbackType.SITE, FeedbackType.SUPPORT, FeedbackType.DECOR];
  additionToClientText?: string;

  constructor(
    private feedbackService: HxFeedbackService,
    private aRoute: ActivatedRoute,
    private router: Router,
    private toastr: ToastrService,
    private auth: HxAuthService,
    private tr: TranslocoService
  ) {
  }

  ngOnInit() {
    const routeData: Data = this.aRoute.snapshot.data;
    this.feedback = routeData['feedback'] as FeedbackModel;
    this.filterFeedbackTypes();
  }

  goBack(): void {
    if (window.history.length > 0) {
      window.history.back();
    } else {
      this.router.navigateByUrl('..');
    }
  }

  emptyArr(rating: number) {
    return new Array(rating);
  }

  saveReply() {
    this.isSaving = true;
    if (this.additionToClientText) {
      this.feedback.clientText =
        this.feedback.clientText + '\n' + this.auth.user.fullname + ' ' + format(new Date(), 'dd.MM.yyy HH:mm:ss') + ': ' + this.additionToClientText;
    }
    this.feedbackService.saveReply(this.feedback.id, {
      supportText: this.feedback.supportText,
      supportNote: this.feedback.supportNote,
      clientText: this.feedback.clientText,
      reviews: this.feedback.reviews
    }).then(() => {
      this.isSaving = false;
      this.feedbackService.getFeedbackById(this.feedback.id).then(feedback => {
        Object.assign(this.feedback, feedback);
        this.additionToClientText = undefined;
        this.filterFeedbackTypes();
        this.toastr.success(this.tr.translate('ord.hx-feedback.ts.save'));
      });
    }, () => this.isSaving = false);
  }

  fullname(fb: Review): string {
    let name = '';
    if (fb.type === FeedbackType.CALLCENTER) {
      name = this.feedback.callcenterUserFullname;
    } else if (fb.type === FeedbackType.CASHBOX) {
      name = this.feedback.cashboxUserFullname;
    } else if (fb.type === FeedbackType.DELIVERY) {
      name = this.feedback.deliveryUserFullname;
    } else if (fb.type === FeedbackType.SUPPORT) {
      name = this.feedback.supportFullname;
    }
    return name ? `(${name})` : '';
  }

  rating(type: FeedbackType): number {
    const existingRating = this.feedback.reviews?.find(review => review.type === type);
    if (existingRating) {
      return existingRating.rating;
    } else {
      return 0;
    }
  }

  onRateChanged($event: number, type: FeedbackType): void {
    const existingRating = this.feedback.reviews?.find(review => review.type === type);
    if (existingRating) {
      existingRating.rating = $event;
    } else {
      const reviews = this.feedback.reviews ?? [];
      reviews.push({
        rating: $event,
        type: type
      } as Review);
      this.feedback.reviews = reviews;
    }
  }

  private filterFeedbackTypes() {
    this.types = this.types.filter(r => !this.feedback.reviews.map(r => r.type).includes(r));
  }
}
