<div class="modal-header">
  <h3 class="modal-title">{{ 'hx.event-diff.title' | transloco }}</h3>
  <button class="close" type="button" (click)="dismissModal()">
    <span>×</span>
  </button>
</div>

<div class="modal-body">
  <div class="row">
    <div class="col-md-12">
      <p>
        {{ 'hx.event-diff.changeOrder' | transloco }} {{ currentEvent?.actorFullName | fio }}
        <br> {{ 'hx.event-diff.changeData' | transloco }} {{ currentEvent?.date | date: 'dd.MM.yyyy HH:mm' }}
      </p>
    </div>
  </div>
  <div *ngIf="difference && !isLoading.diff">
    <table class="table">
      <thead class="thead-default">
      <tr>
        <th></th>
        <th>{{ 'hx.event-diff.table.head.before' | transloco }}</th>
        <th>{{ 'hx.event-diff.table.head.after' | transloco }}</th>
      </tr>
      </thead>
      <tbody>
      <tr *ngFor="let change of changes">
        <td>
          {{ 'hx.diff.' + change.globalId.valueObject + '.' + change.property | transloco }}
        </td>

        <td>
          {{change.left}}
        </td>

        <td>
          {{change.right}}
        </td>
      </tr>
      <tr *ngIf="difference.productsChanged && (difference.oldProducts.length > 0 || difference.newProducts.length > 0)">
        <td>{{ 'hx.diff.path.products' | transloco }}</td>
        <td>
          <table class="table-sm table--no-border w-100">
            <tr *ngFor="let product of difference.oldProducts">
              <td>
                {{ product.title | uiLabel }}
              </td>
              <td>
                {{ product.amount }} {{ 'hx.event-diff.table.body.num' | transloco }}.
              </td>
            </tr>
          </table>
        </td>
        <td>
          <table class="table-sm table--no-border w-100">
            <tr *ngFor="let product of difference.newProducts">
              <td>
                {{ product.title | uiLabel }}
              </td>
              <td>
                {{ product.amount }} {{ 'hx.event-diff.table.body.num' | transloco }}.
              </td>
            </tr>
          </table>
        </td>
      </tr>
      <tr *ngIf="difference.discountsChanged && (difference.oldDiscounts.length > 0 || difference.newDiscounts.length > 0)">
        <td>{{ 'hx.diff.path.discounts' | transloco }}</td>
        <td>
          <table class="table-sm table--no-border w-100">
            <tr *ngFor="let discount of difference.oldDiscounts">
              <td>
                {{ 'discount.'+discount.discountType  | transloco}}
              </td>
            </tr>
          </table>
        </td>
        <td>
          <table class="table-sm table--no-border w-100">
            <tr *ngFor="let discount of difference.newDiscounts">
              <td>
                {{ 'discount.'+discount.discountType  | transloco}}
              </td>
            </tr>
          </table>
        </td>
      </tr>
      <tr *ngIf="difference.filesChanged && (difference.oldFiles.length > 0 || difference.newFiles.length > 0)">
        <td>{{ 'hx.diff.path.products' | transloco }}</td>
        <td>
          <table class="table-sm table--no-border w-100">
            <tr *ngFor="let file of difference.oldFiles">
              <td>
                <span>
                  <a target="_blank" [href]="fileDownloadUrl + file.id">
                    <img width="75" [src]="fileDownloadUrl + file.id" [alt]="file.fileName">
                  </a>
                </span>
              </td>
            </tr>
          </table>
        </td>
        <td>
          <table class="table-sm table--no-border w-100">
            <tr *ngFor="let file of difference.newFiles">
              <td>
                <span>
                  <a target="_blank" [href]="fileDownloadUrl + file.id">
                    <img width="75" [src]="fileDownloadUrl + file.id" [alt]="file.fileName">
                  </a>
                </span>
              </td>
            </tr>
          </table>
        </td>
      </tr>
      </tbody>
    </table>
  </div>

  <div *ngIf="!difference && !isLoading?.diff">
    <h3>{{ 'hx.event-diff.noChange' | transloco }}</h3>
  </div>

  <div *ngIf="!difference && isLoading?.diff" class="m-page-loader m-page-loader--non-block m-page-loader--relative">
    <div class="m-loader m-loader--brand"></div>
  </div>
</div>
