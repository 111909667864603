import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'hx-rating',
  template: `
      <ng-container *ngFor="let rating of [1,2,3,4,5]">
          <svg *ngIf="isFilled(rating)" (click)="rate(rating)" style="margin-right: 0.3em; cursor: pointer;" width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
              <g clip-path="url(#clip0)">
                  <path d="M12.0626 1.14095L14.5439 6.16874C14.7164 6.51842 15.0501 6.76073 15.436 6.81671L20.9847 7.62301C21.9566 7.76435 22.3444 8.95843 21.6413 9.64358L17.6264 13.5571C17.3474 13.8293 17.2198 14.2216 17.2859 14.6057L18.2335 20.1319C18.3996 21.0997 17.3836 21.8377 16.5144 21.381L11.5518 18.7722C11.2067 18.5909 10.7942 18.5909 10.4491 18.7722L5.48644 21.381C4.61727 21.8381 3.60125 21.0997 3.76738 20.1319L4.71502 14.6057C4.7811 14.2216 4.65353 13.8293 4.37451 13.5571L0.359533 9.64358C-0.343513 8.95797 0.0442637 7.7639 1.01623 7.62301L6.56487 6.81671C6.95081 6.76073 7.28444 6.51842 7.45699 6.16874L9.9383 1.14095C10.3724 0.260308 11.628 0.260308 12.0626 1.14095Z"
                        fill="#FFBD00"/>
              </g>
              <defs>
                  <clipPath id="clip0">
                      <rect width="22" height="22" fill="white"/>
                  </clipPath>
              </defs>
          </svg>
          <svg *ngIf="isEmpty(rating)" (click)="rate(rating)" style="margin-right: 0.3em; cursor: pointer;" width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
              <g clip-path="url(#clip0)">
                  <path d="M11.6142 1.36222L11.6142 1.36223L14.0955 6.38998C14.0955 6.38999 14.0955 6.39 14.0955 6.39001C14.3409 6.88736 14.8154 7.23192 15.3642 7.31153L20.9127 8.11781C21.4747 8.19953 21.6985 8.88969 21.2924 9.2855L21.2923 9.28553L17.2774 13.1991L17.2773 13.1992C16.8806 13.586 16.6991 14.1438 16.7931 14.6902C16.7931 14.6903 16.7931 14.6904 16.7931 14.6905L17.7407 20.2164L17.7407 20.2165C17.8367 20.7756 17.2496 21.2025 16.747 20.9384L11.7845 18.3296L11.7843 18.3295C11.2937 18.0718 10.7072 18.0718 10.2166 18.3295L10.2164 18.3296L5.25378 20.9385L5.25372 20.9385C4.7515 21.2026 4.16414 20.7759 4.26017 20.2165L4.26018 20.2164L5.20778 14.6905C5.30181 14.1439 5.12029 13.5861 4.72362 13.1992L4.72351 13.1991L0.708619 9.28561C0.301969 8.88905 0.526591 8.19921 1.08795 8.11784L1.08813 8.11781L6.63666 7.31153C7.18545 7.23192 7.65994 6.88735 7.90536 6.39001C7.90536 6.39 7.90537 6.38999 7.90537 6.38999L10.3867 1.36223L10.3868 1.36203C10.6375 0.853478 11.3629 0.853023 11.6142 1.36222Z" stroke="#BDBDBD"/>
              </g>
              <defs>
                  <clipPath id="clip0">
                      <rect width="22" height="22" fill="white"/>
                  </clipPath>
              </defs>
          </svg>
      </ng-container>
  `
})
export class HxRatingComponent {
  @Input() rating!: number;
  @Input() enabled = true;
  @Output() rateChange = new EventEmitter<number>();

  rate(rating: number) {
    if (this.enabled) {
      if (this.rating === rating) {
        this.rating = 0;
      } else {
        this.rating = rating;
      }
    }
    this.rateChange.emit(this.rating);
  }

  isEmpty(rating: number) {
    return this.rating < rating;
  }

  isFilled(rating: number) {
    return this.rating >= rating;
  }
}
