import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { HxToastrService } from 'hx-component';
import { Subject } from 'rxjs';
import { Location } from '@angular/common';
import { takeUntil } from 'rxjs/operators';
import { NotifierEventBusAddress, NotifierService, UpdateOrderResponse } from 'hx-services';
import { TranslocoService } from '@ngneat/transloco';

@Component({
  selector: 'hx-order-edit',
  templateUrl: './order-edit.component.html',
  styleUrls: ['./order-edit.component.css']
})
export class HxOrderEditComponent implements OnInit, OnDestroy {
  orderId?: number;
  phone?: string;
  private $destroyed = new Subject<void>();
  private backLink!: string;

  constructor(private aRoute: ActivatedRoute,
              private router: Router,
              private location: Location,
              private toaster: HxToastrService,
              private tr: TranslocoService,
              private notifierService: NotifierService,
  ) {
  }

  ngOnInit() {
    const state: any = this.location.getState();
    this.backLink = state.backLink ?? '/orders/list?activeCart=false';
    this.aRoute.queryParamMap.pipe(takeUntil(this.$destroyed)).subscribe(paramMap => {
      this.orderId = paramMap.get('orderId') ? Number(paramMap.get('orderId')) : undefined;
      this.phone = paramMap.get('phone') ?? undefined;
    });

    this.notifierService.eventBusOpenObs.pipe(takeUntil(this.$destroyed)).subscribe(eb => {
      console.log('notifierService processed');
      if (eb && this.orderId) {
        this.notifierService.registerHandler(NotifierEventBusAddress.orderEvent(this.orderId), this.orderEventHandler);
      }
    });
  }

  ngOnDestroy(): void {
    this.$destroyed.next();
    this.$destroyed.complete();
  }

  onOrderUpdated(event?: UpdateOrderResponse) {
    if (event && event.uniqueNumber) {
      this.toaster.success(this.tr.translate('ord.order-edit.ts.success', {orderNumber: event.uniqueNumber}));
      if (this.orderId) {
        this.goToOrder(this.orderId);
      } else {
        this.goToOrders();
      }
    } else {
      this.goBack();
    }
  }

  onClientPhoneUpdated(phone: string) {
    this.router.navigate(['.'], {
      relativeTo: this.aRoute,
      queryParamsHandling: 'merge',
      queryParams: {orderId: this.orderId, phone: phone}
    });
  }

  onOrderCreated(orderId: number) {
    this.router.navigate(['.'], {
      relativeTo: this.aRoute,
      queryParamsHandling: 'merge',
      queryParams: {orderId: orderId}
    });
  }

  onOrderTransferred(orderId: number) {
    if (this.orderId !== orderId) {
      this.router.navigate(['.'], {
        relativeTo: this.aRoute,
        queryParamsHandling: 'merge',
        queryParams: {orderId: orderId}
      });
    }
  }

  private goToOrder(orderId: number): void {
    this.router.navigateByUrl(`/orders/${orderId}`);
  }

  private goToOrders(): void {
    this.router.navigateByUrl('/orders/list?activeCart=false');
  }

  private goBack(): void {
    this.router.navigateByUrl(this.backLink);
  }

  private orderEventHandler: (err: any, message: { body: any, headers: any }) => void = (err, message) => {
    if (err) {
      console.error(err);
    } else {
      if (message.headers.action === 'orderPaid') {
        if (this.orderId) {
          this.goToOrder(this.orderId);
        } else {
          this.goToOrders();
        }
      }
    }
  };
}
