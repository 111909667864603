import { Component, EventEmitter, Inject, Input, OnChanges, OnDestroy, OnInit, Optional, Output, SimpleChange, SimpleChanges, TrackByFunction } from '@angular/core';
import {
  AddressModel,
  CalculateDiscountRequest,
  CartChangeEvent,
  CartProduct,
  CityDeliveryMap,
  ComponentType,
  datelc,
  debtAllowed,
  DebtPropertyModel,
  DeliveryRangeModel,
  DeliveryType,
  DiscountResultModel,
  Entity,
  HX_COMPONENT_NAME,
  HxDeliveryService,
  HxDeliveryZoneService,
  HxOrderService,
  HxOrganizationService,
  HxProductService,
  HxRejectionService,
  HxStoreService,
  HxStoreWorkTimeService,
  isMarkerInsidePolygon,
  NotifierEventBusAddress,
  NotifierService,
  PriceShortModel,
  ProductAddedEvent,
  ProductInfoType,
  ProductShortModel,
  ProductStatus,
  ReceiveAfterTime,
  RejectionModel,
  SalePriceModel,
  StoreFullModel,
  toFixed,
  UiLabel,
  YaFeature,
  YaPolygon
} from 'hx-services';
import { firstValueFrom, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, map, takeUntil } from 'rxjs/operators';
import { HxErrorHandlerService } from 'hx-component';
import { ToastrService } from 'ngx-toastr';
import { TranslocoService } from '@ngneat/transloco';
import { DatePipe } from '@angular/common';
import { addHours, addMinutes, differenceInMinutes, format, getISODay, isBefore, parse, parseISO, set } from 'date-fns';
import Timeout = NodeJS.Timeout;

@Component({
  selector: 'hx-order-cart',
  templateUrl: './order-cart.component.html',
  styleUrls: ['./order-cart.component.css']
})
export class HxOrderCartComponent implements OnInit, OnDestroy, OnChanges {
  @Input() store!: StoreFullModel;
  @Input() date!: string;
  @Input() time?: string;
  @Input() orderId!: number;
  @Input() clientId?: number;
  @Input() products: ProductShortModel[] = [];
  @Input() readonly = false;
  @Input() clientType?: Entity;

  @Input() timezone?: string;
  @Input() deliveryType?: DeliveryType;
  @Input() pickupTime?: string;
  @Input() address?: AddressModel;
  @Input() addresses: AddressModel[] = [];
  @Input() rangeId?: number;
  @Input() cartRangeId?: number;

  @Output() rangeSelect = new EventEmitter<DeliveryRangeModel | undefined>();
  @Output() addressChange = new EventEmitter<{ address?: AddressModel, deliveryType: DeliveryType }>();
  @Output() cartChange = new EventEmitter<CartChangeEvent>();
  @Output() pickupTimeSelected = new EventEmitter<{ time: string }>();

  isLoading = {
    discounts: false,
    addProduct: false,
    deliveryRanges: false,
    deliveryPrices: false,
  };
  cart: OrderCart = {
    subTotalValue: 0,
    totalValue: 0,
    productCount: 0,
    products: [],
  };
  otp = {
    isConfirmed: false,
    isRequired: false,
    isCodeSent: false,
    code: '',
    resendTimeout: 0,
  };
  deliveryPriceId?: number;
  discounts: DscResultItem[] = [];
  debtAvailable = false;
  isDebt = false;
  delivery = ProductInfoType.DELIVERY;
  isCalculateDiscountsFailed = false;
  ranges: DeliveryRangeCell[] = [];
  pickupTimes: PickupTime[] = [];
  showWarningAlert = false;
  deliveryAddress?: AddressModel;
  cityDeliveryArea?: CityDeliveryMap;
  entrance?: string;
  floor?: string;
  flat?: string;
  note?: string;
  isManualDeliverySelection = false;
  deliveryPrices: PriceShortModel[] = [];
  preferredPickupTime = '';
  preferredDeliveryTime = '';
  stores: StoreFullModel[] = [];
  private $addressChange = new Subject<AddressModel | undefined>();
  private receiveAfterTime?: ReceiveAfterTime;
  private initialReservedProductMap = new Map<number, ProductShortModel>(); // мап продуктов для хранения первоначального кол-ва
  private $destroyed = new Subject<void>();
  private interval?: Timeout;
  private debtAllowed = false;

  constructor(private productService: HxProductService,
              private orgService: HxOrganizationService,
              private orderService: HxOrderService,
              private toastr: ToastrService,
              private errorService: HxErrorHandlerService,
              private tr: TranslocoService,
              private notifierService: NotifierService,
              private deliveryService: HxDeliveryService,
              private datePipe: DatePipe,
              private storeService: HxStoreService,
              private zoneService: HxDeliveryZoneService,
              private rejectionService: HxRejectionService,
              private storeWorkTimeService: HxStoreWorkTimeService,
              @Optional() @Inject(HX_COMPONENT_NAME) public componentName: ComponentType,
  ) {
  }

  trackByCell: TrackByFunction<any> = (index: number, obj: { id: number }) => obj.id;
  trackById: TrackByFunction<any> = (index: number, obj: { id: number }) => obj.id;

  ngOnInit(): void {
    this.orgService.getDebtAllowed(this.store.organizationId).subscribe(result => {
      this.debtAllowed = false;
      if (result && result.enabled && result.value) {
        const debtProperty = result.value as DebtPropertyModel;
        this.debtAllowed = debtProperty.allow;
      }
    });

    this.products.forEach(product => {
      const cartProduct: CartProductItem = this.cart.products.find(cp => cp.price.id === product.price.id) ?? {} as CartProductItem;
      if (!this.cart.products.includes(cartProduct)) {
        cartProduct.price = product.price;
        cartProduct.productInfo = product.productInfo;
        this.cart.products.push(cartProduct);
      }

      if (ProductStatus.RESERVED === product.status) {
        this.initialReservedProductMap.set(product.price.id, {...product});
        cartProduct.reserved = product;
      } else if (ProductStatus.CART === product.status) {
        cartProduct.cart = product;
      }
      this.initCartProductItem(cartProduct);
    });
    if (this.deliveryType === DeliveryType.DELIVERY) {
      const deliveryProduct = this.products.find(product => product.productInfo.type === ProductInfoType.DELIVERY);
      if (deliveryProduct) {
        this.deliveryPriceId = deliveryProduct.price.id;
      }
    }
    this.checkCurrentDiscount();

    this.orderService.recalcDiscountsObs.pipe(
      takeUntil(this.$destroyed),
      debounceTime(300),
    ).subscribe(async () => {
      try {
        this.isLoading.discounts = true;
        this.cart.totalValue = 0;
        this.cart.subTotalValue = 0;
        if (!this.time || !this.clientType || !this.deliveryType) {
          console.warn('[order-configurator] recalcDiscountsObs: orderTime/clientType/deliveryType is undefined');
          return;
        }
        const body: CalculateDiscountRequest = {
          results: this.getDiscounts(),
          order: {
            id: this.orderId,
            date: this.date,
            time: this.time,
            storeId: this.store.id,
            clientId: this.clientId,
            clientType: this.clientType,
            deliveryType: this.deliveryType,
          },
          products: [],
        };

        body.products = this.cart.products.filter(item => {
          if (this.deliveryType === DeliveryType.DELIVERY) {
            return true;
          }
          return item.productInfo.type !== ProductInfoType.DELIVERY;
        }).map(item => ({
          amount: (item.reserved?.amount ?? 0) + (item.cart?.amount ?? 0),
          priceId: item.price.id
        }));

        const result = await firstValueFrom(this.orderService.checkDiscount(body));

        console.debug('[order-cart] recalcDiscountsObs subscribe fired');
        this.isLoading.discounts = false;
        this.isCalculateDiscountsFailed = false;
        this.initDiscountList(result.results);
        this.initCanUnderSalary();
        this.cart.totalValue = result.order.total;
        this.cart.subTotalValue = result.order.subTotal;
        this.cart.productCount = this.cart.products.map(cp => (cp.cart?.amount ?? 0) + (cp.reserved?.amount ?? 0))
          .reduce((a, b) => a + b, 0);
        this.otp.isConfirmed = result.order.otpVerified;
        this.otp.isRequired = result.order.otpRequired;
        this.updateProductList();
      } catch (err: any) {
        this.isCalculateDiscountsFailed = true;
      } finally {
        this.isLoading.discounts = false;
      }
    });

    if (this.store.workDates) {
      const datelc = format(parseISO(this.date), 'yyyy-MM-dd');
      this.storeWorkTimeService.getStoreWorkTimeByStoreAndDate({storeId: this.store.id, date: datelc})
        .then(storeWorkDate => {
          if (storeWorkDate) {
            const [openHour, openMinute, openSeconds] = storeWorkDate.openTime.split(':').map(r => Number(r));
            const [closeHour, closeMinute, closeSeconds] = storeWorkDate.closeTime.split(':').map(r => Number(r));
            let startDate = set(Date.now(), {hours: openHour, minutes: openMinute, seconds: openSeconds});
            const endDate = set(Date.now(), {hours: closeHour, minutes: closeMinute, seconds: closeSeconds});
            while (differenceInMinutes(startDate, endDate) <= 0) {
              if (differenceInMinutes(startDate, endDate) === 0) {
                startDate = endDate;
              }
              this.pickupTimes.push({
                time: format(startDate, 'HH:mm'),
                isDisabled: false,
                orderCount: 0
              });
              startDate = addMinutes(startDate, 30);
            }

            if (this.orderId) {
              this.orderService.getReceiveAfterTime(this.orderId, this.store?.id).then(receiveAfterTime => {
                this.orderService.notifyReceiveAfterTimeChanged(this.orderId, receiveAfterTime);
              });
            }

            this.orderService.receiveAfterTimeChangeObs
              .pipe(
                takeUntil(this.$destroyed),
                filter(oe => oe.id === this.orderId),
                map(oe => oe.event),
                distinctUntilChanged((r1, r2) => r1.productTitle === r2.productTitle && r1.time === r2.time)
              )
              .subscribe(receiveAfterTime => {
                // console.log('[order-cart] receiveAfterTime', receiveAfterTime);
                this.receiveAfterTime = receiveAfterTime;
                this.ranges = this.initDeliveryRanges(this.ranges, this.date);
                this.pickupTimes = this.pickupTimes.map(pickupTime => {
                  pickupTime.isDisabled = this.isNewYearLimit(pickupTime.time, this.store.brandId) || !this.isTimeAllowed(receiveAfterTime.time, pickupTime.time);
                  if (pickupTime.isDisabled) {
                    pickupTime.tooltip = this.tr.translate('hx.product-list.receiveAfterTime', {
                      title: receiveAfterTime.productTitle,
                      receiveAfterTime: receiveAfterTime.time
                    });
                  } else {
                    pickupTime.tooltip = this.tr.translate('ord.order-delivery.ts.time', {
                      time: pickupTime.time,
                      orderCount: pickupTime.orderCount
                    });
                  }
                  return pickupTime;
                });
              });
          } else {
            this.toastr.error(this.tr.translate('ord.order-delivery.ts.noTime'));
          }
        });
    } else {
      this.toastr.error(this.tr.translate('ord.order-delivery.ts.noTime'));
    }

    this.$addressChange.pipe(
      takeUntil(this.$destroyed),
      debounceTime(250)
    ).subscribe(async address => {
      const deliveryType = this.deliveryType;
      if (address && deliveryType === DeliveryType.DELIVERY) {
        const deliveryPrice = this.getDeliveryPrice(address);
        this.deliveryPriceId = deliveryPrice?.id;
        if (deliveryPrice) {
          const deliveryProductExists = this.cart.products.some(p => p.price.id === deliveryPrice.id && this.getProductAmount(p) > 0);
          // remove other delivery products
          const promises = this.cart.products.filter(p => p.productInfo.type === ProductInfoType.DELIVERY && p.price.id !== deliveryPrice.id && this.getProductAmount(p) > 0)
            .map(p => this.removeProduct(p, false));
          if (!deliveryProductExists) {
            try {
              await Promise.all(promises);
              await this.addProduct(deliveryPrice);
            } catch (err) {
              console.error('[order-cart] remove product promises', err);
              throw err;
            }
          }
        } else {
          this.isManualDeliverySelection = true;
        }

        this.addressChange.emit({
          address: {
            address: address.address,
            admDiv: address.admDiv,
            entrance: this.entrance,
            floor: this.floor,
            flat: this.flat,
            note: this.note,
            latitude: address.latitude,
            longitude: address.longitude,
          } as AddressModel,
          deliveryType: deliveryType,
        });
        this.orderService.recalcDiscounts();
      } else if (deliveryType === DeliveryType.PICKUP) {
        const promises = this.cart.products.filter(p => p.productInfo.type === ProductInfoType.DELIVERY && this.getProductAmount(p) > 0)
          .map(p => this.removeProduct(p, false));
        try {
          await Promise.all(promises);
          this.addressChange.emit({deliveryType: deliveryType});
          this.orderService.recalcDiscounts();
        } catch (err) {
          console.error('[order-cart] remove product promises', err);
          throw err;
        }
      }
    });

    if (this.deliveryType) {
      this.onDeliveryTypeChanged();
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    console.debug('[order-cart] #ngOnChanges', changes);
    const clientChange: SimpleChange | undefined = changes['clientId'];
    const clientTypeChange: SimpleChange | undefined = changes['clientType'];
    if ((clientChange && clientChange.previousValue !== clientChange.currentValue) ||
      (clientTypeChange && clientTypeChange.previousValue !== clientTypeChange.currentValue)) {
      this.recalculateCart();
    }

    if (changes['cartRangeId'] && changes['rangeId'] && !this.cartRangeId && !this.rangeId) {
      this.unselectRangeIfEmpty();
    }

    if (changes['store']) {
      this.onStoreChanged(this.store);
      this.loadCityDeliveryArea();
      this.orderService.getReservedOrders(datelc(this.date), this.store.id).subscribe(pickupCounts => {
        const countMap = new Map<string, number>();
        pickupCounts.forEach(pickupCount => {
          if (pickupCount.time) {
            countMap.set(pickupCount.time.substring(0, 5), pickupCount.count);
          }
        });
        this.pickupTimes.forEach(pickupTime => {
          pickupTime.orderCount = countMap.get(pickupTime.time) || 0;
          pickupTime.tooltip = this.tr.translate('ord.order-delivery.ts.time', {
            time: pickupTime.time,
            orderCount: pickupTime.orderCount
          });
        });
      });
    }
    if (changes['address']) {
      this.deliveryAddress = changes['address'].currentValue;
      this.entrance = this.deliveryAddress?.entrance;
      this.floor = this.deliveryAddress?.floor;
      this.flat = this.deliveryAddress?.flat;
      this.note = this.deliveryAddress?.note;
      this.$addressChange.next(this.deliveryAddress);
    }
  }

  ngOnDestroy(): void {
    // console.log('[order-cart] #ngOnDestroy');
    this.unregisterDeliveryHandlers();
    this.$destroyed.next();
    this.$destroyed.complete();
  }

  onProductAdded(event: ProductAddedEvent) {
    if (!this.readonly) {
      this.addProduct(event.price);
    }
  }

  recalculateCart() {
    // console.log('[order-cart] recalculateCart called');
    this.orderService.recalcDiscounts();
  }

  addProductAmount(cp: CartProductItem) {
    console.debug('[order-cart] addProductAmount');
    if (!this.isLoading.addProduct) {
      cp.isLoading = true;
      setTimeout(() => cp.isLoading = false, 1500);
      this.addProduct(cp.price);
    }
  }

  subtractProductAmount(product: CartProductItem) {
    console.debug('[order-cart] subtractProductAmount');
    const cartProduct = product.cart;
    const reservedProduct = product.reserved;
    const onSubtractedFn = (p: ProductShortModel) => {
      product.isLoading = false;
      p.amount -= 1;
      p.value -= product.price.value;
      this.cart.productCount -= 1;
      this.cart.subTotalValue -= product.price.value;
      this.initCartProductItem(product);
      this.recalculateCart();
    };
    if (cartProduct && cartProduct.amount > 0) {
      product.isLoading = true;
      this.orderService.downCartProduct(this.orderId, cartProduct.id)
        .subscribe(() => onSubtractedFn(cartProduct), err => {
          console.error('err', err);
          product.isLoading = false;
          this.errorService.check(err.error);
        });
    } else if (reservedProduct && reservedProduct.amount > 0) {
      onSubtractedFn(reservedProduct);
    }
  }

  removeProduct(product: CartProductItem, recalc = true): Promise<void> {
    const cartProduct = product.cart;
    const reservedProduct = product.reserved;

    const subject = new Subject<void>();
    const onRemoveFn = () => {
      const amount = (cartProduct?.amount ?? 0) + (reservedProduct?.amount ?? 0);
      const value = (cartProduct?.value ?? 0) + (reservedProduct?.value ?? 0);
      if (cartProduct) {
        cartProduct.amount = 0;
        cartProduct.value = 0;
      }
      if (reservedProduct) {
        reservedProduct.amount = 0;
        reservedProduct.value = 0;
      }
      product.isLoading = false;
      this.initCartProductItem(product);
      this.cart.productCount -= amount;
      this.cart.subTotalValue -= value;
      if (recalc) {
        this.recalculateCart();
      }
      subject.next();
      subject.complete();
    };

    if (cartProduct && cartProduct.amount > 0) {
      product.isLoading = true;
      this.orderService.removeCartProduct(this.orderId, cartProduct.id).subscribe(() => onRemoveFn(), err => {
        console.error('err', err);
        product.isLoading = false;
        this.errorService.check(err.error);
        subject.error(err);
      });
    } else if (reservedProduct && reservedProduct.amount > 0) {
      onRemoveFn();
    }
    return subject.toPromise();
  }

  toggleDiscount(discount: DscResultItem) {
    if (this.readonly) {
      console.warn('[order-cart] #disableDiscount: readonly');
      return;
    }
    discount.enabled = !discount.enabled;
    this.recalculateCart();
  }

  validateOtpCode() {
    if (!this.otp.code) {
      this.toastr.error(this.tr.translate('ord.order-cart.ts.textCode'));
      return;
    }
    if (!this.clientId) {
      this.toastr.error(this.tr.translate('ord.order-cart.ts.client'));
      return;
    }
    this.orderService.confirmOtpCode({
      orderId: this.orderId,
      code: this.otp.code,
      clientId: this.clientId
    }).subscribe(() => {
      this.otp.isConfirmed = true;
    }, err => {
      this.otp.isConfirmed = false;
      this.errorService.check(err.error);
    });
  }

  sendOtpCode() {
    if ((this.otp.resendTimeout ?? 0) > 0) {
      this.toastr.error(this.tr.translate('ord.order-cart.ts.sendCode', {resendTimeout: this.otp.resendTimeout}));
      return;
    }
    if (!this.clientId) {
      this.toastr.error(this.tr.translate('ord.order-cart.ts.client'));
      return;
    }

    this.startTimeout();
    this.orderService.sendOtpCode({clientId: this.clientId, orderId: this.orderId}).subscribe(() => {
      this.otp.isCodeSent = true;
    }, err => {
      this.otp.isCodeSent = false;
      if (this.interval) {
        clearInterval(this.interval);
      }
      this.otp.resendTimeout = 0;
      this.errorService.check(err.error);
    });
  }

  onDebtChanged() {
    console.debug('[order-cart] onDebtChanged');
    this.updateProductList();
  }

  async onDeliveryTypeChanged() {
    if (this.deliveryType === DeliveryType.DELIVERY) {
      this.pickupTime = undefined;
      this.loadDeliveryRanges();
      await this.loadCityDeliveryArea();
      this.$addressChange.next(this.deliveryAddress);
    } else if (this.deliveryType === DeliveryType.PICKUP) {
      if (this.cartRangeId) {
        await this.deliveryService.releaseRange(this.cartRangeId, this.orderId);
        this.toastr.success(this.tr.translate('courier.checkout.success'));
        this.cartRangeId = undefined;
      }
      this.$addressChange.next(undefined);
    }
  }

  async toggleRange(range: DeliveryRangeCell) {
    if (this.cartRangeId === range.id) {
      await this.deliveryService.releaseRange(range.id, this.orderId);
      this.toastr.success(this.tr.translate('courier.checkout.success'));
      this.rangeSelect.emit();
    } else {
      await this.deliveryService.reserveRange(range.id, this.orderId);
      this.toastr.success(this.tr.translate('courier.booked.success'));
      this.rangeSelect.emit(range);
    }

    const timezonedDate = this.datePipe.transform(Date.now(), 'yyyy-MM-dd HH:mm:ss', this.timezone);
    if (timezonedDate) {
      const currentDate = parse(timezonedDate, 'yyyy-MM-dd HH:mm:ss', new Date());
      const toTimeDate = parse(`${range.date} ${range.toTime}`, 'yyyy-MM-dd HH:mm:ss', new Date());
      const limitDate = addHours(currentDate, 1);
      this.showWarningAlert = isBefore(toTimeDate, limitDate);
    } else {
      throw new Error('[order-cart] #toggleRange: timezonedDate var is undefined');
    }
  }

  async selectPickupTime(pickupTime: PickupTime) {
    const isLoading = this.pickupTimes.some(pt => pt.isLoading);
    if (isLoading) {
      this.toastr.info(this.tr.translate('ord.order-delivery.ts.loader'));
      return;
    }
    if (pickupTime) {
      this.pickupTimeChanged(pickupTime);
    }
  }

  onAddressChanged(address?: AddressModel) {
    this.deliveryAddress = address;
    this.entrance = this.deliveryAddress?.entrance;
    this.floor = this.deliveryAddress?.floor;
    this.flat = this.deliveryAddress?.flat;
    this.note = this.deliveryAddress?.note;

    this.$addressChange.next(this.deliveryAddress);
  }

  onAddressInfoChanged() {
    if (this.deliveryAddress) {
      this.deliveryAddress.entrance = this.entrance;
      this.deliveryAddress.floor = this.floor;
      this.deliveryAddress.flat = this.flat;
      this.deliveryAddress.note = this.note;
    }
    this.$addressChange.next(this.deliveryAddress);
  }

  selectDeliveryPrice(dp: PriceShortModel) {
    if (this.deliveryPriceId !== dp.id) {
      this.deliveryPriceId = dp.id;
      this.$addressChange.next(this.deliveryAddress);
    }
  }

  private getDeliveryPrice(deliveryAddress: AddressModel): PriceShortModel | undefined {
    if (!deliveryAddress?.address) {
      return undefined;
    }
    if (this.isManualDeliverySelection && this.deliveryPriceId) {
      return this.deliveryPrices.find(p => p.id === this.deliveryPriceId);
    }
    if (this.cityDeliveryArea && ['dgis', 'yandex', 'google'].includes(this.cityDeliveryArea.defaultMapType)) {
      let deliveryPriceId: number | undefined;
      const lat = deliveryAddress.latitude;
      const lng = deliveryAddress.longitude;
      if (lat && lng) {
        const polygon: YaFeature | undefined = this.cityDeliveryArea.deliveryMap.features
          .find(f => f.geometry.type === 'Polygon' && isMarkerInsidePolygon(f.geometry as YaPolygon, lat, lng));
        if (polygon) {
          if (polygon.properties['store-id'] === this.store.id) {
            deliveryPriceId = polygon.properties['price-id'];
          } else {
            deliveryPriceId = polygon.properties['other-stores']?.find(os => os['store-id'] === this.store.id)?.['price-id'];
          }
        }
      }
      console.log('[order-cart] deliveryPriceId', deliveryPriceId);
      if (deliveryPriceId) {
        return this.deliveryPrices.find(p => p.id === deliveryPriceId);
      }
    }
    return undefined;
  }

  toggleDropdown(event: Event) {
    event.stopPropagation();
  }

  onDeliveryAddressChanged() {
    console.debug('[order-cart] onDeliveryAddressChanged', this.deliveryAddress);
    const emitFn = () => {
      this.entrance = this.deliveryAddress?.entrance;
      this.floor = this.deliveryAddress?.floor;
      this.flat = this.deliveryAddress?.flat;
      this.note = this.deliveryAddress?.note;
      this.$addressChange.next(this.deliveryAddress);
    };
    if (this.cityDeliveryArea?.defaultMapType && ['dgis', 'google', 'yandex'].includes(this.cityDeliveryArea?.defaultMapType)) {
      let deliveryPriceId: number | undefined;
      const lat = this.deliveryAddress?.latitude;
      const lng = this.deliveryAddress?.longitude;
      if (lat && lng) {
        const polygons: YaFeature[] = this.cityDeliveryArea.deliveryMap.features.filter(f => f.geometry.type === 'Polygon');
        polygons.forEach(feature => {
          const storeId = feature.properties['store-id'];
          if (this.store.id === storeId) {
            if (isMarkerInsidePolygon(feature.geometry as YaPolygon, lat, lng)) {
              deliveryPriceId = feature.properties['price-id'];
            }
          }
        });
      }
      if (!this.deliveryAddress?.address) {
        this.deliveryPriceId = undefined;
        emitFn();
      } else {
        // address is defined
        this.isManualDeliverySelection = !(lng && lat);
      }
      if (!this.isManualDeliverySelection) {
        if (deliveryPriceId) {
          this.deliveryPriceId = this.deliveryPrices.find(p => p.id === deliveryPriceId)?.id;
        } else {
          this.deliveryPriceId = undefined;
        }
      }
      if (this.deliveryPriceId) {
        emitFn();
      } else {
        this.isManualDeliverySelection = true;
      }
    } else {
      emitFn();
    }
  }

  reject(event: Event, opt: { pickupTime?: string, deliveryTime?: string, delivery?: boolean, pickup?: boolean }) {
    event.stopPropagation();
    const rejection = {} as RejectionModel;
    if (opt.pickupTime) {
      rejection.pickupTimes = [opt.pickupTime];
    }
    if (opt.deliveryTime) {
      rejection.deliveryTimes = [opt.deliveryTime];
    }
    if (opt.delivery) {
      rejection.delivery = opt.delivery;
    }
    if (opt.pickup) {
      rejection.pickup = opt.pickup;
    }
    this.rejectionService.prepareRejection(rejection);
  }

  private unselectRangeIfEmpty() {
    if (!this.rangeId && !this.cartRangeId) {
      this.rangeSelect.emit();
    }
  }

  private onStoreChanged(store: StoreFullModel) {
    if (store && store.id) {
      if (this.deliveryType === DeliveryType.DELIVERY) {
        this.loadDeliveryRanges();
        this.onAddressInfoChanged();
      }
    } else {
      this.deliveryType = undefined;
      this.isLoading.deliveryRanges = false;
      this.ranges = [];
    }
  }

  private registerDeliveryRangesHandler() {
    if (this.store && this.store.id && this.date) {
      const component = this;
      this.notifierService.eventBusOpenObs.pipe(takeUntil(this.$destroyed), filter(eb => eb !== undefined)).subscribe(eb => {
        component.unregisterDeliveryHandlers();
        component.notifierService.registerHandler(NotifierEventBusAddress.deliveryTime(this.date, this.store.id), this.streamHandler);
      });
    }
  }

  private loadDeliveryRanges() {
    this.ranges = [];
    this.isLoading.deliveryRanges = true;
    this.deliveryService.getDeliveryRanges({
      date: datelc(this.date),
      storeId: this.store.id
    }).then(deliveryRanges => {
      this.ranges = this.initDeliveryRanges(deliveryRanges, this.date);
      this.emitRangeIfSelected();
      this.registerDeliveryRangesHandler();
      this.isLoading.deliveryRanges = false;
    }, () => {
      this.isLoading.deliveryRanges = false;
    });
  }

  private initDeliveryRanges(ranges: DeliveryRangeCell[], orderDate: string): DeliveryRangeCell[] {
    return ranges.filter(range => range.date === orderDate).map(range => {
      let isDisabled = false;
      range.tooltip = undefined;

      if (range.limit - (range.reserved ?? 0) <= 0) {
        isDisabled = true;
        range.tooltip = this.tr.translate('range.capacity.limit');
      }
      if (this.receiveAfterTime && this.receiveAfterTime.time) {
        if (!this.isTimeAllowed(this.receiveAfterTime.time, range.toTime, 30)) {
          isDisabled = true;
          range.tooltip = this.tr.translate('hx.product-list.receiveAfterTime', {
            title: this.receiveAfterTime.productTitle,
            receiveAfterTime: this.receiveAfterTime.time
          });
        }
      }

      const isToday = orderDate === format(new Date(), 'yyyy-MM-dd');
      const nowTime = new Date().getHours() + ':' + new Date().getMinutes();
      if (isToday && !isDisabled && !this.isTimeAllowed(nowTime, range.toTime, 60)) {
        isDisabled = true;
        range.tooltip = this.tr.translate('range.time.limit');
      }
      range.isDisabled = isDisabled;
      return range;
    });
  }

  private isNewYearLimit(time: string, brandId: number) {
    if (brandId !== 1) {
      return false;
    }
    const orderDate = parseISO(this.date);
    if (orderDate.getMonth() === 11 && orderDate.getDate() === 31 && orderDate.getFullYear() === 2021) {
      const [hours, minutes] = time.split(':').map(v => Number(v));
      const dayTime = toFixed(hours * 60 + minutes, 0);
      const cityId = this.store.city.id;
      if ([1, 2].includes(cityId)) {
        return dayTime > 17 * 60;
      } else if ([7, 20, 3].includes(cityId)) {
        return dayTime > 16 * 60 + 30;
      } else if ([5, 15].includes(cityId)) {
        return dayTime > 17 * 60 + 30;
      } else if (cityId === 12) {
        return dayTime > 19 * 60 + 30;
      } else if (cityId === 4) {
        return dayTime > 20 * 60 + 30;
      } else if (cityId === 17) {
        return dayTime > 21 * 60 + 30;
      } else if (cityId === 8) {
        return dayTime > 22 * 60 + 30;
      }
    }
    return false;
  }

  private isTimeAllowed(afterTime: string, time: string, limit = 0): boolean {
    const [afterHour, afterMinute] = afterTime.split(':').map(v => Number(v));
    const [rangeToHour, rangeToMinute] = time.split(':').map(v => Number(v));
    const afterReceiveDate = set(parseISO('2020-01-01'), {hours: afterHour, minutes: afterMinute, seconds: 0});
    const rangeToDate = set(parseISO('2020-01-01'), {hours: rangeToHour, minutes: rangeToMinute, seconds: 0});
    // если rangeToDate больше чем afterReceiveDate то значение будет положительным
    return differenceInMinutes(rangeToDate, afterReceiveDate) >= limit;
  }

  private unregisterDeliveryHandlers() {
    this.notifierService.unregisterHandlers(new RegExp(`^(${NotifierEventBusAddress.DELIVERY_TIME_PREFIX}).*`));
  }

  private streamHandler = (error: any, message: any) => {
    if (error) {
      console.error('error: ', error);
    } else {
      if (message.body) {
        this.ranges = this.initDeliveryRanges(message.body as DeliveryRangeCell[], this.date);
      }
    }
  };

  private emitRangeIfSelected() {
    const selectedRangeId = this.cartRangeId ?? this.rangeId;
    if (selectedRangeId) {
      this.rangeSelect.emit(this.ranges.find(range => range.id === selectedRangeId));
    }
  }

  private async pickupTimeChanged(pickupTime: PickupTime) {
    console.log('[order-new] onPickupTimeChanged', pickupTime);
    if (!this.orderId) {
      throw new Error('orderId is undefined');
    }
    pickupTime.isLoading = true;
    try {
      await this.orderService.reservePickupTime({orderId: this.orderId, pickupTime: pickupTime.time});
      this.pickupTime = pickupTime.time;
      this.pickupTimeSelected.emit({time: this.pickupTime});
    } catch (err) {
      console.error('error on reserve pickupTime', err);
    } finally {
      pickupTime.isLoading = false;
    }
  }

  private loadCityDeliveryArea(): Promise<void> {
    if (this.deliveryType === DeliveryType.DELIVERY) {
      return Promise.all([
        this.zoneService.getStoresCoordinatesByCityId(this.store.city.id, this.store.brandId, datelc(this.date)).then(deliveryArea => {
          deliveryArea.deliveryMap.features
            .filter(feature => feature.geometry.type === 'Polygon')
            .forEach(feature => (feature.geometry as YaPolygon).coordinates[0].forEach(item => {
              const b = item[0];
              item[0] = item[1];
              item[1] = b;
            }));
          this.cityDeliveryArea = deliveryArea;
          this.isManualDeliverySelection = this.cityDeliveryArea.defaultMapType === 'none';
        }),
        this.loadCityStores(),
        this.loadDeliveryPrices(),
      ]).then(() => undefined);
    }
    return Promise.resolve();
  }

  private loadDeliveryPrices(): Promise<void> {
    this.isLoading.deliveryPrices = true;
    return this.productService.getDeliveryPrices({storeId: this.store.id, date: this.date}).then(deliveryPrices => {
      this.deliveryPrices = deliveryPrices.sort((a, b) => a.value - b.value);
      this.isLoading.deliveryPrices = false;
    }, () => {
      this.isLoading.deliveryPrices = false;
    });
  }

  private getStoreWorkDate(store: StoreFullModel, date?: string): { openTime: string, closeTime: string } | undefined {
    const workDates = store.workDates;
    if (workDates === undefined || workDates === null || date === null || date === undefined) {
      return undefined;
    }
    return workDates.find(el => el.dayOfWeek === getISODay(parseISO(date)));
  }

  private loadCityStores(): Promise<void> {
    return this.storeService.getStoresByCity(this.store.cityId, {
      date: this.date,
      brandId: this.store.brandId,
      open: true
    }).then(stores => {
      this.stores = stores.list;
    });
  }

  private updateProductList() {
    console.log('[order-cart] updateProductList');
    const products: ProductShortModel[] = [];
    this.cart.products.forEach(cp => {
      const cartProduct = cp.cart;
      const reservedProduct = cp.reserved;
      if (cartProduct && cartProduct.amount > 0) {
        products.push(cartProduct);
      }
      if (reservedProduct && reservedProduct.amount > 0) {
        products.push(reservedProduct);
      }
    });
    const event: CartChangeEvent = {
      isDebt: this.isDebt,
      products: products,
      discounts: this.getDiscounts(),
      total: this.cart.totalValue,
      subTotal: this.cart.subTotalValue,
    };
    this.cartChange.emit(event);
  }

  private async addProduct(price: SalePriceModel) {
    this.isLoading.addProduct = true;
    const reservedProduct = this.initialReservedProductMap.get(price.id);
    const product = this.cart.products.find(cp => cp.price.id === price.id);
    if (reservedProduct && (!product || !product.reserved || reservedProduct.amount > product.reserved.amount)) {
      if (product && product.reserved) {
        product.reserved.amount += 1;
        product.reserved.value += price.value;
        this.initCartProductItem(product);
      } else {
        const pr = {...reservedProduct};
        pr.amount = 1;
        pr.value = pr.price.value;
        const cp = {
          price: {...pr.price},
          productInfo: {...pr.productInfo},
          reserved: pr
        } as CartProduct;
        this.initCartProductItem(cp);
        this.cart.products.push(cp);
      }
      this.orderService.getReceiveAfterTime(this.orderId).then(receiveAfterTime => {
        this.orderService.notifyReceiveAfterTimeChanged(this.orderId, receiveAfterTime);
      });
      this.cart.productCount += 1;
      this.cart.subTotalValue += price.value;
      this.isLoading.addProduct = false;
      this.recalculateCart();
    } else {
      try {
        const result = await this.orderService.addCartProduct(this.orderId, {priceId: price.id});
        if (product) {
          if (product.cart) {
            product.cart.amount += 1;
            product.cart.value += price.value;
          } else {
            product.cart = result;
          }
          this.initCartProductItem(product);
        } else {
          const pr = {...result};
          pr.amount = 1;
          pr.value = pr.price.value;
          const cp = {
            price: {...result.price},
            productInfo: {...result.productInfo},
            cart: pr
          } as CartProductItem;
          this.initCartProductItem(cp);
          this.cart.products.push(cp);
        }
        this.cart.productCount += 1;
        this.cart.subTotalValue += price.value;
        this.isLoading.addProduct = false;
        this.recalculateCart();
        this.orderService.getReceiveAfterTime(this.orderId).then(receiveAfterTime => {
          this.orderService.notifyReceiveAfterTimeChanged(this.orderId, receiveAfterTime);
        });
      } finally {
        this.isLoading.addProduct = false;
      }
    }
  }

  private isProductReserved(product: CartProductItem): boolean {
    return product.reserved !== undefined;
  }

  private initCartProductItem(cartProduct: CartProductItem) {
    if (!cartProduct) {
      return;
    }
    cartProduct.isLoading = false;
    if (cartProduct.cart && cartProduct.cart.amount === 0) {
      cartProduct.cart = undefined;
      if (!cartProduct.reserved) {
        this.cart.products = this.cart.products.filter(cp => cp.price.id !== cartProduct.price.id);
      }
    }
    const cartProductAmount = (cartProduct.cart?.amount ?? 0) + (cartProduct.reserved?.amount ?? 0);
    const cartProductValue = (cartProduct.cart?.value ?? 0) + (cartProduct.reserved?.value ?? 0);
    cartProduct.isDownAmountVisible = cartProductAmount > 0;
    cartProduct.amountText = toFixed(cartProductAmount).toString();
    cartProduct.priceValue = cartProductValue;
    cartProduct.reservedAmount = this.getReservedAmount(cartProduct);
    cartProduct.weight = this.getWeight(cartProduct);
  }

  private getWeight(product: CartProductItem): number | undefined {
    return (product.reserved || product.cart || {weight: undefined}).weight;
  }

  private getReservedAmount(product: CartProductItem): number {
    return this.initialReservedProductMap.get(product.price.id)?.amount ?? 0;
  }

  private startTimeout() {
    this.otp.resendTimeout = 2 * 60;

    if (this.interval) {
      clearInterval(this.interval);
    }

    this.interval = setInterval(() => {
      if ((this.otp.resendTimeout ?? 0) > 0) {
        this.otp.resendTimeout--;
      } else if (this.interval) {
        clearInterval(this.interval);
      }
    }, 1000);
  }

  private getDiscounts(): DiscountResultModel[] {
    const discounts: DscResultItem[] = JSON.parse(JSON.stringify(this.discounts));
    return discounts.map(discount => {
      delete discount.productTitle;
      return discount as DiscountResultModel;
    });
  }

  private initCanUnderSalary() {
    this.debtAvailable = this.debtAllowed && this.discounts.some(item => debtAllowed(item));
  }

  private initDiscountList(discounts: DiscountResultModel[]) {
    this.discounts = discounts.map(discount => {
      const discountItem: DscResultItem = discount;
      discountItem.productTitle = this.cart.products.find(item => item.price.id === discount.priceId)?.productInfo.title;
      return discountItem;
    });
  }

  private checkCurrentDiscount(): void {
    this.isLoading.discounts = true;
    this.orderService.getDiscountList(this.orderId).then(result => {
      this.initDiscountList(result.results);
      this.initCanUnderSalary();
      this.isCalculateDiscountsFailed = false;
      this.recalculateCart();
    }, () => {
      this.isLoading.discounts = false;
      this.isCalculateDiscountsFailed = true;
    });
  }

  private getProductAmount(p: CartProductItem): number {
    return (p.cart?.amount ?? 0) + (p.reserved?.amount ?? 0);
  }
}

interface OrderCart {
  subTotalValue: number;
  totalValue: number;
  productCount: number;
  products: CartProductItem[];
}

interface CartProductItem extends CartProduct {
  weight?: number;
  reservedAmount?: number;
  amountText?: string;
  priceValue?: number;
  isDownAmountVisible?: boolean;
  isLoading?: boolean;
}

interface DscResultItem extends DiscountResultModel {
  productTitle?: UiLabel;
}

interface DeliveryRangeCell extends DeliveryRangeModel {
  isDisabled?: boolean;
  isSelected?: boolean;
  tooltip?: string;
  productTitle?: string;
  isLoading?: boolean;
}

interface PickupTime {
  time: string;
  isLoading?: boolean;
  isDisabled?: boolean;
  tooltip?: string;
  orderCount?: number;
}
