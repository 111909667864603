import { Component, EventEmitter, forwardRef, Input, OnDestroy, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { of, Subject } from 'rxjs';
import { catchError, debounceTime, distinctUntilChanged, filter, finalize, switchMap, takeUntil } from 'rxjs/operators';
import { HxProductInfoService, PagedList, ProductInfoModel, ProductInfoType } from 'hx-services';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

/**
 * Component search product info by search text
 */
@Component({
  selector: 'hx-product-info-select',
  templateUrl: './product-info-select.component.html',
  styleUrls: ['./product-info-select.component.css'],
  encapsulation: ViewEncapsulation.None,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => HxProductInfoSelectComponent),  // replace name as appropriate
      multi: true
    }
  ]
})
export class HxProductInfoSelectComponent implements OnInit, OnDestroy, ControlValueAccessor {
  @Input() multiple = false;
  @Input() clearAfterSelect = false;
  @Input() types: ProductInfoType[] = [];
  @Input() isPurchasable = false;
  @Input() brandId?: number;
  @Input() brandIds?: number[];
  @Input() storeId?: number;
  @Output() selectChange = new EventEmitter<ProductInfoModel | ProductInfoModel[] | undefined>();
  selected: number | number[] | undefined;
  /*private _selected: number[] = [];
  get selected() {
    return this._selected || [];
  }

  set selected(val: number[] | undefined) {
    this._selected = val ?? [];
    if (val && val.length > 0) {
      if (this.model) {
        const modelIds = this.model.map(pd => pd.id);
        const load = val.some(selectedId => !modelIds.includes(selectedId));
        if (load) {
          this.productInfoService.getProductInfoByIds(val)
            .subscribe(productInfos => {
              this.model = productInfos;
            });
        }
      } else {
        this.productInfoService.getProductInfoByIds(val)
          .subscribe(productInfos => {
            this.model = productInfos;
          });
      }
    } else {
      this.model = undefined;
      this.options = [];
    }
    this.onChange(val);
  }*/
  disabled = false;
  options: ProductInfoModel[] = [];
  itemLoading = false;
  itemInput$ = new Subject<string>();
  private $destroyed = new Subject<void>();

  constructor(
    private productInfoService: HxProductInfoService,
  ) {
  }

  ngOnInit() {
    this.loadItems();
  }

  writeValue(val: number | number[] | undefined): void {
    this.selected = val;
    if (val) {
      if (Array.isArray(val)) {
        this.productInfoService.getProductInfoByIds(val).subscribe(list => this.options = list);
      } else {
        this.productInfoService.getProductInfoByIds([val]).subscribe(list => this.options = list);
      }
    }
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {

  }

  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  onChange(val: any) {

  }

  ngOnDestroy(): void {
    this.$destroyed.next();
    this.$destroyed.complete();
  }

  private loadItems() {
    this.itemInput$.pipe(
      takeUntil(this.$destroyed),
      debounceTime(500),
      distinctUntilChanged(),
      filter(term => {
        const isOk = (term ?? '').trim() !== '';
        this.itemLoading = isOk;
        return isOk;
      }),
      switchMap(term => {
        const brandIdList: number[] = [];
        if (this.brandId) {
          brandIdList.push(this.brandId);
        }
        if (this.brandIds && this.brandIds.length) {
          brandIdList.concat(this.brandIds);
        }
        return this.productInfoService.getProductInfos({
          brandIds: brandIdList,
          searchText: term,
          types: this.types,
          storeId: this.storeId,
          purchasable: this.isPurchasable
        }).pipe(
          finalize(() => this.itemLoading = false),
          catchError(() => of({list: [], count: 0} as PagedList<ProductInfoModel>)), // empty list on error
        );
      })
    ).subscribe(searchResult => this.options = searchResult.list);
  }

  onModelChanged(val: any) {
    this.onChange(val);
    this.selectChange.emit(this.getSelectedOptions());
    if (this.clearAfterSelect) {
      setTimeout(() => {
        this.selected = undefined;
        this.onChange(undefined);
      });
    }
  }

  private getSelectedOptions(): ProductInfoModel | ProductInfoModel[] | undefined {
    if (this.multiple) {
      if (Array.isArray(this.selected)) {
        const ids: number[] = this.selected;
        return this.options.filter(p => ids.includes(p.id));
      } else {
        return [];
      }
    } else {
      if (this.selected) {
        return this.options.find(p => p.id === this.selected);
      } else {
        return undefined;
      }
    }
  }
}
