import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AppEventFull, SmsInfo } from 'hx-services';

@Component({
  selector: 'hx-sms-info-modal.m-grid__item.m-grid__item--fluid',
  templateUrl: './sms-info-modal.component.html',
  styleUrls: ['./sms-info-modal.component.css']
})
export class HxSmsInfoModalComponent {
  @Input() smsInfo!: SmsInfo;
  @Input() event!: AppEventFull;

  constructor(
    private modalInstance: NgbActiveModal,
  ) {
  }

  close() {
    this.modalInstance.dismiss();
  }
}
