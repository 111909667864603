import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'joined'
})
export class HxJoinedPipe implements PipeTransform {

  transform(values: any[], field: string, delimiter = ', '): string {
    return values.map(p => this.fetchFromObject(p, field)).join(delimiter);
  }

  private fetchFromObject(obj: any, prop: string): any {
    if (typeof obj === 'undefined') {
      return false;
    }

    const ix = prop.indexOf('.');
    if (ix > -1) {
      return this.fetchFromObject(obj[prop.substring(0, ix)], prop.substr(ix + 1));
    }

    return obj[prop];
  }
}


