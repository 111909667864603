import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, TrackByFunction } from '@angular/core';
import { HxToastrService } from '../../../services/toastr.service';
import {
  AddressModel,
  CityModel,
  ClientFullModel,
  ClientModel,
  ClientPropertyModel,
  CoinTransactionModel, CommentModel,
  ContactModel,
  CountryModel,
  HxAuthService,
  HxCityService,
  HxClientService,
  HxCoinService,
  HxCountryService,
  SeekDirection
} from 'hx-services';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { HxClientPropertyEditModal } from './client-property-edit-modal/client-property-edit.modal';
import { TranslocoService } from '@ngneat/transloco';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

@Component({
  selector: 'hx-client-edit',
  templateUrl: './client-edit.component.html',
  styleUrls: ['./client-edit.component.css']
})
export class HxClientEditComponent implements OnInit, OnChanges {
  @Input() clientId!: number;
  @Input() clientName?: string;
  @Input() cityId?: number;
  @Output() clientChanged = new EventEmitter<ClientFullModel>();

  cityMap = new Map<number, CityModel>();
  client?: ClientModel;
  additionalAddresses: AddressModel[] = [];
  additionalContacts: ContactModel[] = [];
  country?: CountryModel;
  countries: CountryModel[] = [];
  activeTab: 'client' | 'brand' = 'client';
  isLoading = {
    clientSubmit: false,
    get: false,
    brandProperties: false,
    coins: false,
    comments: false,
  };
  errorText: { phone?: string } = {};

  customPatterns = {
    'X': {
      pattern: new RegExp('/|0|4|5|6|7|/'),
    }, '9': {
      pattern: new RegExp('/|9|/'),
    }, 'N': {
      pattern: new RegExp('^(?!3)'),
    }, '0': {
      pattern: new RegExp('\\d'),
    }
  };
  brandProperties: ClientPropertyModel[] = [];
  activeCoinAmount = 0;
  coinTransactions: CoinTransactionModel[] = [];
  coinBrandId?: number = 1; //happycake
  coinCountryId?: number = 1; // happycake kz
  seek: {
    last?: string;
    first?: string;
    val?: string;
    hasNext?: boolean;
    dir?: SeekDirection;
  } = {};
  clientBirthDate?: string;
  isBirthDateUpdated = false;
  comment?: string;
  comments: CommentModel[] = [];
  private $loadCoins = new Subject<{seek?: string, seekDir?: SeekDirection}>();

  constructor(
    private clientService: HxClientService,
    private countryService: HxCountryService,
    private cityService: HxCityService,
    private toaster: HxToastrService,
    private coinService: HxCoinService,
    private auth: HxAuthService,
    private modal: NgbModal,
    private tr: TranslocoService
  ) {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['clientName'] && this.client) {
      this.client.fullname = this.clientName ?? '';
    }
  }

  async ngOnInit() {
    this.isLoading.get = true;
    this.countries = await this.countryService.getCountries();

    if (this.clientId) {
      this.isLoading.get = true;
      try {
        const response = await this.clientService.getClient(this.clientId);
        this.initClient(response);
      } finally {
        this.isLoading.get = false;
      }
    } else {
      if (this.cityId) {
        this.countryService.getCountryByCityId(this.cityId).subscribe(country => {
          this.isLoading.get = false;
          if (country) {
            this.coinCountryId = country.id;
            this.selectCountry(country);
          }
        });
      } else {
        this.isLoading.get = false;
        this.additionalContacts = [];
        this.additionalAddresses = [];
      }
    }

    this.$loadCoins.pipe(debounceTime(300), distinctUntilChanged()).subscribe(seekParams => {
      this.loadCoinTransactions(seekParams);
    });
  }

  selectCountry(country: CountryModel) {
    this.country = country;

    if (country) {
      this.cityService.getAllCities({countryId: country.id})
        .then(cities => cities.forEach(city => this.cityMap.set(city.id, city)));
    }
  }

  addAContact(type: string) {
    if (!this.client) {
      return;
    }
    this.additionalContacts.push({type: type} as ContactModel);
  }

  removeContact(index: number) {
    if (!this.client) {
      return;
    }
    this.additionalContacts.splice(index, 1);
  }

  checkPhoneExist() {
    if (!this.client) {
      return;
    }
    let phone = `${this.client.phone}`;
    if (phone) {
      phone = phone.replace(/\D+/g, '');
      if (phone.length === this.country?.phoneLength) {
        this.errorText.phone = undefined;
        this.clientService.getClientByPhone((this.country.phonePrefix).replace(/\D+/g, '') + phone).then(result => {
          if (result) {
            this.errorText.phone = 'client.exist.byphone';
          }
        }, err => {
          if (err.error?.message !== 'client.notFound') {
            this.errorText.phone = 'unexpected';
          }
        });
      }
    }
  }

  async submit() {
    if (!this.client) {
      return;
    }
    if (this.isBirthDateUpdated && !this.comment && this.comment?.length) {
      this.toaster.error(this.tr.translate('hx.client-edit.ts.commentEmpty'));
      return;
    }
    this.isLoading.clientSubmit = true;
    const client = this.clientService.buildRequest({ client: this.client, additionalContacts: this.additionalContacts, additionalAddresses: this.additionalAddresses });
    client.phone = this.rebuildPhone(client.phone);
    client.comment = this.comment;

    client.isBirthDateUpdated = this.isBirthDateUpdated;
    (client.additionalContacts ?? [])
      .filter(contact => contact.type === 'PHONE')
      .forEach(contact => contact.value = this.rebuildPhone(contact.value));
    client.additionalAddresses = this.additionalAddresses ?? [];

    try {
      const result = await this.clientService.saveClient(client);
      const savedClient = await this.clientService.getClient(result.id);
      this.clientChanged.emit(savedClient);
      this.initClient(savedClient);
      this.isBirthDateUpdated = false;
      this.toaster.success(this.tr.translate('hx.client-edit.ts.dataUpdated'));
    } finally {
      this.isLoading.clientSubmit = false;
    }
  }

  cancel(): void {
    this.clientChanged.emit();
  }

  trackById: TrackByFunction<any> = (index: number, obj: { id: number }) => obj.id;

  removeAddress(addr: AddressModel) {
    if (this.client && this.additionalAddresses) {
      this.additionalAddresses = this.additionalAddresses.filter(el => el.id !== addr.id);
    }
  }

  onTabChanged(tab: string) {
    console.log('onTabChanged', tab);
    if (tab === 'brand') {
      this.loadProperties();
    } else if (tab === 'coins') {
      this.loadCoins();
    } else if (tab === 'comments') {
      this.loadComments();
    }
    this.comment = undefined;
  }

  async editBrandProperty(item: ClientPropertyModel) {
    const modalInstance = this.modal.open(HxClientPropertyEditModal, {size: 'lg'});
    modalInstance.componentInstance.client = this.client;
    modalInstance.componentInstance.property = item;
    await modalInstance.result;
    this.loadProperties();
  }

  async addBrandProperty() {
    const modalInstance = this.modal.open(HxClientPropertyEditModal, {size: 'lg'});
    modalInstance.componentInstance.client = this.client;
    await modalInstance.result;
    this.loadProperties();
  }

  removeBrandProperty(item: ClientPropertyModel) {
    this.clientService.removeClientBrandProperty(item.id).subscribe(() => this.loadProperties());
  }

  seekPrev() {
    this.$loadCoins.next({seek: this.seek.first, seekDir: SeekDirection.prev});
  }

  seekNext() {
    this.$loadCoins.next({seek: this.seek.last, seekDir: SeekDirection.next});
  }

  onCoinFilterChanged() {
    this.loadCoins();
  }

  async loadCoins() {
    if (!this.coinCountryId || !this.coinBrandId) {
      throw new Error();
    }
    try {
      this.isLoading.coins = true;
      this.activeCoinAmount = await this.coinService.getActiveCoins({clientId: this.clientId, countryId: this.coinCountryId, brandId: this.coinBrandId});
      this.$loadCoins.next({});
    } catch (err: any) {
      console.error(err);
      this.toaster.error('unexpected');
    } finally {
      this.isLoading.coins = false;
    }
  }

  async loadComments() {
    try {
      this.isLoading.comments = true;
      this.comments = await this.clientService.getClientComments(this.clientId);
    } finally {
      this.isLoading.comments = false;
    }
  }

  onDateChanged() {
    this.isBirthDateUpdated = false;
    if (this.clientBirthDate !== this.client?.birthDate) {
      this.isBirthDateUpdated = true;
    } else {
      this.comment = undefined;
    }
  }

  transformPhone(phone: string): string {
    if (!this.client || !this.country) {
      return phone;
    }
    return phone.substring(phone.length - this.country.phoneLength);
  }

  private loadProperties() {
    this.isLoading.brandProperties = true;
    this.clientService.getClientBrandProperties(this.clientId)
      .subscribe(props => {
        this.isLoading.brandProperties = false;
        this.brandProperties = props;
      }, err => this.isLoading.brandProperties = false);
  }

  private rebuildPhone(phone: string) {
    if (!this.client) {
      return phone;
    }
    return (this.country?.phonePrefix + phone).replace(/\D+/g, '');
  }

  private initClient(data: ClientFullModel) {
    this.client = data.client;
    this.country = data.country;
    this.additionalAddresses = data.additionalAddresses ?? [];
    this.additionalContacts = data.additionalContacts ?? [];

    this.client.phone = this.transformPhone(this.client.phone);
    this.additionalContacts.filter(c => c.type === 'PHONE' && c.value && c.value !== data.client.phone).forEach(contact => {
      contact.value = this.transformPhone(contact.value);
    });

    if (this.country) {
      this.coinCountryId = this.country.id;
      this.selectCountry(this.country);
    }
    if (this.clientName) {
      this.client.fullname = this.clientName;
    }
    this.clientBirthDate = this.client.birthDate;
    this.loadProperties();
  }

  private async loadCoinTransactions(params?: {seek?: string, seekDir?: SeekDirection}) {
    if (!this.coinCountryId || !this.coinBrandId) {
      throw new Error();
    }
    const {list, first, last, hasNext} = await this.coinService.getCoinTransaction({
      clientId: this.clientId,
      countryId: this.coinCountryId,
      brandId: this.coinBrandId,
      limit: 20,
      seek: params?.seek,
      seekDirection: params?.seekDir,
    });
    this.seek.dir = params?.seekDir;
    this.seek.val = params?.seek;
    this.seek.first = first;
    this.seek.last = last;
    this.seek.hasNext = hasNext;
    this.coinTransactions = list;
  }
}
