import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

export type Permission = 'denied' | 'granted' | 'default';

interface PushNotification {
  body?: string;
  icon?: string;
  tag?: string;
  data?: any;
  renotify?: boolean;
  silent?: boolean;
  sound?: string;
  noscreen?: boolean;
  sticky?: boolean;
  dir?: 'auto' | 'ltr' | 'rtl';
  lang?: string;
  vibrate?: number[];
}

declare const Notification: any;

@Injectable({
  providedIn: 'root'
})
export class PushNotificationsService {

  permission: Permission;

  constructor() {
    this.permission  = this.isSupported() ? Notification.permission : 'denied';
  }

  requestPermission() {
    if ('Notification' in window) {
      Notification.requestPermission((status: any) => this.permission = status);
    }
  }

  isSupported() {
      return 'Notification' in window;
  }

  create(title: string, options?: PushNotification): Observable<any> {

    return new Observable((obs: any) => {

      if (!('Notification' in window)) {
        obs.error('Notifications are not available in this environment');
        obs.complete();
      }

      if (this.permission !== 'granted') {
        obs.error(`The user hasn't granted you permission to send push notifications`);
        obs.complete();
      }

      const n = new Notification(title, options);

      n.onshow = (e: any) => obs.next({notification: n, event: e});
      n.onclick = (e: any) => obs.next({notification: n, event: e});
      n.onerror = (e: any) => obs.error({notification: n, event: e});
      n.onclose = () => obs.complete();
    });
  }

}
