<div class="modal-header">
  <h3 class="modal-title">{{ 'hx.comment-list.comment' | transloco }}</h3>

  <button class="close" type="button" (click)="dismissModal()">
    <span>×</span>
  </button>
</div>

<div class="modal-body">
  <div class="form-group m-form__group">
    <label>{{ 'hx.comment-list.comment' | transloco }}</label>
    <a class="mention" (click)="deliverySelected()">&#64;delivery</a>
    <i class="las la-question-circle m--margin-left-10" [ngbTooltip]="'hx.comment-add.mention' | transloco"></i>
    <textarea [(ngModel)]="comment" class="form-control textarea-height-100"
              rows="2"></textarea>
  </div>
</div>

<div class="modal-footer">
  <div class="text-right m--margin-top-15">
    <button  class="btn btn-success m--margin-right-5" (click)="save()" [disabled]="isLoading" [ngClass]="{'m-loader m-loader--light m-loader--primary m-loader--left': isLoading}">
      {{ 'hx.btn.save' | transloco }}
    </button>

    <button  class="btn btn-secondary" (click)="dismissModal()">
      {{ 'hx.btn.cancel' | transloco }}
    </button>
  </div>
</div>
