import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import {
  ClientFullModel,
  FeedbackModel,
  FeedbackType,
  HxFeedbackService,
  HxOrderService,
  HxStoreService,
  OrderResponse,
  Review,
  SourceSystem,
  StoreBasicModel
} from 'hx-services';
import { HxErrorHandlerService } from 'hx-component';
import { TranslocoService } from '@ngneat/transloco';

@Component({
  selector: 'hx-feedback-edit',
  templateUrl: './hx-feedback-edit.component.html',
  styleUrls: ['./hx-feedback-edit.component.css']
})
export class HxFeedbackEditComponent implements OnInit {
  order?: OrderResponse;
  feedback!: Partial<FeedbackModel>;
  dateTime?: string;
  isLoading = {
    get: false,
    save: false,
  };
  // withOrder: boolean;
  orderId?: number;
  feedbackId?: number;
  feedbackType = FeedbackType;
  types: FeedbackType[] = [FeedbackType.CASHBOX, FeedbackType.DELIVERY, FeedbackType.PRODUCT,
    FeedbackType.CALLCENTER, FeedbackType.SITE, FeedbackType.SUPPORT, FeedbackType.DECOR];
  client?: ClientFullModel;
  cityId?: number;

  sourceSystem?: SourceSystem;
  sourceSystems = [SourceSystem.WHATSAPP, SourceSystem.INSTAGRAM, SourceSystem.CALLCENTER];

  constructor(
    private feedbackService: HxFeedbackService,
    private aRoute: ActivatedRoute,
    private router: Router,
    private toastr: ToastrService,
    private storeService: HxStoreService,
    private orderService: HxOrderService,
    private errorService: HxErrorHandlerService,
    private tr: TranslocoService
  ) {
  }

  ngOnInit() {
    this.aRoute.queryParams.subscribe(async params => {
      this.feedbackId = params['feedbackId'];
      this.orderId = params['orderId'];

      if (this.feedbackId) {
        this.isLoading.get = true;
        try {
          this.feedback = await this.feedbackService.getFeedbackById(this.feedbackId);
          if (this.feedback.reviews && this.feedback.reviews.length > 0) {
            this.sourceSystem = this.feedback.reviews[0].sourceSystem;
          }
          if (this.feedback.date) {
            this.dateTime = this.feedback.date.substring(0, 16);
          }
        } finally {
          this.isLoading.get = false;
        }
      } else {
        this.feedback = {
          reviews: [] as Review[]
        } as FeedbackModel;

        if (this.orderId) {
          try {
            this.order = await this.orderService.getOrderById(this.orderId);

            this.feedback.orderId = this.order.id;
            this.feedback.storeId = this.order.storeId;
            this.feedback.clientId = this.order.clientId;
          } finally {
            this.isLoading.get = false;
          }
        } else {
          this.isLoading.get = false;
        }
      }
    });
  }

  goBack(): void {
    if (window.history.length > 0) {
      window.history.back();
    } else {
      this.router.navigateByUrl('..');
    }
  }


  selectStore(store: StoreBasicModel): void {
    this.feedback.storeId = store.id;
  }

  selectSourceSystem(sourceSystem: SourceSystem): void {
    this.sourceSystem = sourceSystem;
    if (this.feedback.reviews) {
      this.feedback.reviews.forEach(review => review.sourceSystem = sourceSystem);
    }
  }

  updateClient(client?: ClientFullModel): void {
    this.feedback.clientId = client?.client.id;
    this.client = client;
  }

  saveFeedback() {
    this.isLoading.save = true;
    if (this.feedback.reviews) {
      this.feedback.reviews.forEach(review => {
        if (this.sourceSystem) {
          review.sourceSystem = this.sourceSystem;
        }
      });
    }

    if (this.feedback.id) {
      this.feedbackService.updateFeedback(this.feedback.id, this.feedback as FeedbackModel).then(res => {
        this.isLoading.save = false;
        this.feedback = res;
        this.toastr.success(this.tr.translate('ord.hx-feedback-edit.ts.feedbackSaved'));
        this.goBack();
      }, err => {
        this.errorService.check(err.error);
        this.isLoading.save = false;
      });
    } else {
      this.feedbackService.createFeedback(this.feedback as FeedbackModel).then(res => {
        this.isLoading.save = false;
        this.feedback = res;
        this.toastr.success(this.tr.translate('ord.hx-feedback-edit.ts.save'));
        this.goBack();
      }, err => {
        this.errorService.check(err.error);
        this.isLoading.save = false;
      });
    }
  }

  rating(type: FeedbackType): number {
    const existingRating = this.feedback.reviews?.find(review => review.type === type);
    if (existingRating) {
      return existingRating.rating;
    } else {
      return 0;
    }
  }

  onRateChanged($event: number, type: FeedbackType): void {
    const existingRating = this.feedback.reviews?.find(review => review.type === type);
    if (existingRating) {
      existingRating.rating = $event;
    } else {
      const reviews = this.feedback.reviews ?? [];
      reviews.push({
        rating: $event,
        type: type
      } as Review);
      this.feedback.reviews = reviews;
    }
  }

  // trackByFn(item: ClientBasicModel) {
  //   return item.id;
  // }

  // loadOrders() {
  //   const initItems = (orders: OrderFullModel[]) => {
  //     this.items$ = concat(
  //       of(orders), // default items
  //       this.itemInput$.pipe(
  //         debounceTime(500),
  //         distinctUntilChanged(),
  //         filter(term => {
  //           const isOk = term && term.trim() !== '';
  //           this.itemLoading = isOk;
  //           return isOk;
  //         }),
  //         switchMap(term => this.orderService.getOrderByNumber(term).pipe(
  //           catchError(() => of([])), // empty list on error
  //           tap(() => this.itemLoading = false)
  //         ))
  //       )
  //     );
  //   };
  //   if (this.orderId) {
  //     this.orderService.getOrderById(this.orderId).subscribe(order => initItems([order]));
  //   } else {
  //     initItems([]);
  //   }
  // }
  //
  // onOrderChanged(order?: OrderFullModel) {
  //   this.feedback.orderId = order ? order.id : null;
  // }

  onDateTimeChanged(dateStr: string) {
    if (dateStr) {
      this.feedback.date = dateStr;
    } else {
      this.feedback.date = undefined;
    }
  }
}
