<div class="m-subheader ht-container ht-container--m">
  <div class="d-flex align-items-center">
    <div class="mr-auto">
      <h3 class="m-subheader__title m--margin-left-15">
        {{ 'profile.title.create' | transloco }}
      </h3>
    </div>

    <div *ngIf="isEdit">
      <button *ngIf="userProfile?.active" class="btn btn-primary" (click)="resetUserPassword()">
        {{ 'profile.action.reset.password' | transloco }}
      </button>
    </div>
  </div>
</div>

<div class="m-content-tab">
    <div class="m-portlet m-portlet--full-height m--portlet--page">
      <div class="m-portlet__body">
        <form *ngIf="formUserInfo" [formGroup]="formUserInfo" (ngSubmit)="onSubmitUserInfoForm()" class="m-form m-form--fit m-form--label-align-right">
          <div class="form-group m-form__group row">
            <div class="col-lg-4">
              <label>{{ 'user-edit.country' | transloco }}:</label>
              <hx-country-select formControlName="countryId" [all]="false" (selectChange)="countryChanged($event)"></hx-country-select>
            </div>
          </div>
          <div class="form-group m-form__group row" *ngIf="country">
            <div class="col-lg-4">
              <label class="is--required">{{ 'profile.form.telnumber' | transloco }}</label>

              <ng-container *ngIf="country">
                <input [patterns]="customPatterns" [showMaskTyped]="true" type="text" id="phone" formControlName="phone"
                       class="form-control m-input" [prefix]="country.phonePrefix" [mask]="country.phoneMask">
                <small class="is--danger">{{ 'user-edit.phoneNum' | transloco }}</small>
              </ng-container>
            </div>
            <div class="col-lg-4">
              <label>{{ 'user-edit.iin' | transloco }}:</label>
              <input formControlName="iin" type="text" class="form-control m-input">
            </div>
            <div class="col-lg-4">
              <label class="is--required">{{ 'user-edit.date' | transloco }}:</label>
              <hx-date-select formControlName="birthdate"></hx-date-select>
            </div>
          </div>
          <div class="form-group m-form__group row" *ngIf="country">
            <div class="col-lg-4">
              <label class="is--required">{{ 'user-edit.name' | transloco }}:</label>
              <input type="text" formControlName="firstname" class="form-control m-input">
            </div>
            <div class="col-lg-4">
              <label class="is--required">{{ 'user-edit.surname' | transloco }}:</label>
              <input type="text" formControlName="lastname" class="form-control m-input">
            </div>
            <div class="col-lg-4">
              <label>{{ 'user-edit.surnames' | transloco }}:</label>
              <input type="text" formControlName="patronymic" class="form-control m-input">
            </div>
          </div>
          <div class="form-group m-form__group row" *ngIf="country">
            <div class="col-lg-4">
              <label class="is--required">{{ 'user-edit.gender' | transloco }}</label>
              <hx-enum-select enumName="Gender" [enumType]="Gender" formControlName="gender"></hx-enum-select>
            </div>
            <div class="col-lg-4">
              <label>{{ 'user-edit.status' | transloco }}:</label>
              <hx-enum-select enumName="MaritalStatus" [enumType]="MaritalStatus" formControlName="maritalStatus"></hx-enum-select>
            </div>
            <div class="col-lg-4">
              <label>e-mail:</label>
              <input formControlName="email" type="email" class="form-control m-input">
            </div>
          </div>
          <div class="form-group m-form__group row" *ngIf="country">
            <div class="col-lg-12">
              <label>{{ 'user-edit.address' | transloco }}</label>
              <input formControlName="address" type="text" class="form-control m-input">
            </div>
          </div>

          <div class="form-group m-form__group row" *ngIf="country">
            <div class="col-lg-4">
              <label>{{ 'user-edit.position' | transloco }}</label>
              <input formControlName="position" type="text" class="form-control m-input">
            </div>
          </div>

          <div class="form-group m-form__group row">
            <div class="col-lg-8">
              <label class="is--required">{{ 'user-edit.countryMembership' | transloco }}:</label>
              <hx-country-select formControlName="countryIds" [multiple]="true" [all]="false"></hx-country-select>
            </div>
          </div>

          <div class="alert alert-danger" *ngIf="formUserInfo.invalid">{{ 'user-edit.requiredFields' | transloco }}</div>

          <div class="m-portlet__foot m-portlet__no-border m-portlet__foot--fit">
            <div class="m-form__actions">
              <div class="row">
                <div class="col-lg-8">
                  <button [disabled]="formUserInfo.invalid || formUserInfo.pristine || isLoading.save" type="submit" class="btn btn-primary">
                    {{ 'btn.save' | transloco }}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </form>

        <div *ngIf="isLoading.user" class="m-page-loader m-page-loader--non-block m-page-loader--relative">
          <div class="m-loader m-loader--brand"></div>
        </div>
      </div>
    </div>
</div>
