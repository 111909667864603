<div class="m-content">
  <div class="row">
    <div class="col-sm-9">
      <div class="m-portlet m-portlet--full-height">
        <div class="m-portlet__body">
          <hx-order-new [orderId]="orderId" [phone]="phone"
                        (orderCreate)="onOrderCreated($event)"
                        (orderTransfer)="onOrderTransferred($event)"
                        (clientPhoneUpdate)="onClientPhoneUpdated($event)"
                        (orderUpdate)="onOrderUpdated($event)"></hx-order-new>
        </div>
      </div>
    </div>
  </div>
</div>
