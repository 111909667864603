<div class="row m--margin-bottom-10">
  <div class="col-md-12">
    <small>{{ 'ord.order-configurator.chooseBrand' | transloco }}:</small>
    <hx-brand-select [(ngModel)]="brandId" (selectChange)="onBrandChanged()"></hx-brand-select>
  </div>
</div>

<div class="row m--margin-bottom-10" style="align-items: center;">
  <div class="col-md-4">
    <small>{{ 'ord.order-configurator.dataOrder' | transloco }}:</small>
    <hx-date-select [(ngModel)]="orderDate" [minDate]="today" (selectChange)="onDateChanged($event)"></hx-date-select>
  </div>
</div>

<div class="row m--margin-bottom-15">
  <div class="col-md-12">
    <small>{{ 'ord.order-configurator.chooseCity' | transloco }}:</small>
    <hx-city-selector [brandId]="brandId" [id]="cityId" (cityChange)="onCityChanged($event)"></hx-city-selector>
  </div>
</div>

<div class="row m--margin-bottom-15" *ngIf="closedStoreTitles.length > 0">
  <div class="col-md-12">
    <span class="d-block alert alert-danger"> {{ 'ord.stores.closed' | transloco }} : {{ closedStoreTitles.join(', ') }}</span>
  </div>
</div>

<ng-container *ngIf="brandId && cityId && orderDate">
  <div class="row row m--padding-bottom-15">
    <div class="col-lg-3">
      <ul class="m-nav m-nav--category">
        <li class="m-nav__item" *ngFor="let category of categories; let i = index; trackBy:trackById"
            [ngClass]="{'m-nav__item--active': category.id === selectedCategory?.id}">
          <a (click)="onCategorySelected(category)" class="m-nav__link">
          <span class="m-nav__link-title">
            <span class="m-nav__link-wrap">
              <span class="m-nav__link-text">
                {{ category.title | uiLabel }}
              </span>
            </span>
          </span>
          </a>
        </li>
      </ul>
    </div>

    <div class="col-lg-9" *ngIf="selectedCategory">
      <div class="m-widget1 m--padding-0">
        <div class="m-widget1__item">
          <div class="m--padding-bottom-10 hx-product-list-container">
            <div class="hx-product-container" *ngFor="let pi of productInfos; trackBy:trackById">
              <div (click)="addToCart($event, pi)"
                   [ngClass]="{'is--disabled': pi.isProductDisabled }"
                   [ngbPopover]="productRemainderPopContent"
                   (shown)="onProductPopoverShown(pi)"
                   [popoverTitle]="'ord.order-configurator.pr.productRemainders' | transloco"
                   placement="right"
                   triggers="mouseenter:mouseleave"
                   class="hx-product-container__element m-widget1__title m-widget1__title--list m--margin-bottom-10">
                <div class="m-widget1__line">
                  <div>
                    {{ pi.title | uiLabel }}
                    <i class="la la-spinner la-spin" *ngIf="isLoading.remainder" style="font-size: 14px;"></i>
                  </div>
                  <span class="free-product">{{ pi.free }}</span>
                </div>

                <div class="m-widget1__numbers">
                  <div [ngbTooltip]="(isToday ? 'Баланс' : 'Лимит') + ' | Резерв | Корзина | Свободно'">
                    <span class="m--font-success">{{ pi.balance ? pi.balance : '∞' }} | </span>
                    <span class="m--font-brand">{{ pi.reservedLabel }} | </span>
                    <span class="m--font-danger">{{ pi.cart }}</span>
                  </div>
                </div>

                <div class="button-container">
                  <button *ngFor="let price of pi.prices; trackBy:trackById"
                          (click)="addToCart($event, pi, price.amount)"
                          class="price-button" [ngClass]="{'is--disabled': price.isProductDisabled }">
                    <span>
                      <i *ngIf="price.isCart" class="m--font-warning la la-shopping-cart"></i>
                      {{price.amount | priceLabel}}
                    </span>
                    <br/>
                    <span *ngIf="price.min === price.max" class="price">{{ price.min | uiCurrency: currency }}</span>
                    <span *ngIf="price.min < price.max" class="price">
                      {{ price.min }}-{{ price.max | uiCurrency: currency }}
                    </span>
                  </button>
                </div>
              </div>
            </div>

            <div *ngIf="isLoading.category" style="margin-top: 10%;"
                 class="m-page-loader m-page-loader--non-block m-page-loader--relative">
              <div class="m-loader m-loader--brand"></div>
            </div>
          </div>
        </div>

        <div *ngIf="!categories?.length && isLoading.main" style="margin-top: 20px;"
             class="m-page-loader m-page-loader--non-block m-page-loader--relative">
          <div class="m-loader m-loader--brand"></div>
        </div>
      </div>
    </div>
  </div>

  <div class="row m--margin-top-10 m--margin-bottom-10">
    <div class="col-md-12 selected-products-container">
      <span *ngFor="let sp of selectedProducts; let ix = index" class="m-badge m-badge--info m-badge--wide mr-2">
        {{sp.title | uiLabel}}
        <span style="font-weight: 900;" *ngIf="sp.amount > 1">[{{sp.amount}}]</span>
        <i class="la la-close clickable" (click)="removeSelectedProduct(sp, ix)"></i>
      </span>
    </div>
  </div>

  <hx-client-checker [compact]="true"
                     [cityId]="cityId"
                     [(ngModel)]="clientPhone" (clientChange)="onClientUpdated($event)">
  </hx-client-checker>

  <div class="form-group m-form__group is--highlight">
    <label class="m-option hc-radio">
      <span class="m-option__control">
        <span class="m-radio m-radio--brand m-radio--check-bold">
          <input [(ngModel)]="deliveryType" (ngModelChange)="onDeliveryTypeChanged()" [disabled]="selectedProducts.length === 0"
                 type="radio" name="order_deliveryType" value="PICKUP">
          <span></span>
        </span>
      </span>
      <span class="m-option__label">
        <span class="m-option__head">
          <span class="m-option__title">
            {{ 'ord.order-delivery.pickup' | transloco }}
          </span>
        </span>
        <span class="m-option__body">
          {{ 'ord.order-delivery.clientOrder' | transloco }}
        </span>
      </span>
    </label>

    <label class="m-option hc-radio">
      <span class="m-option__control">
        <span class="m-radio m-radio--brand m-radio--check-bold">
          <input [(ngModel)]="deliveryType" (ngModelChange)="onDeliveryTypeChanged()" type="radio" [disabled]="!cityDeliveryArea || selectedProducts.length === 0"
                 name="order_deliveryType" value="DELIVERY">
          <span></span>
        </span>
      </span>
      <span class="m-option__label">
        <span class="m-option__head">
          <span class="m-option__title">
            {{ 'ord.order-delivery.delivery' | transloco }}
          </span>
        </span>
        <span class="m-option__body">
          {{ 'ord.order-delivery.orderCourier' | transloco }}
        </span>
      </span>
    </label>
  </div>

  <ng-container *ngIf="selectedProducts.length > 0">
    <ng-container *ngIf="deliveryType === 'DELIVERY' && cityDeliveryArea">
      <ng-container *ngIf="['dgis', 'yandex', 'google'].includes(cityDeliveryArea.defaultMapType)">
        <div class="row m--margin-top-10">
          <div class="col-md-12">
            <small>{{ 'ord.order-configurator.address' | transloco }}:</small>
            <hx-gis-address [(ngModel)]="deliveryAddress" (ngModelChange)="onDeliveryAddressChange()"
                            [cityDeliveryArea]="cityDeliveryArea" [stores]="stores"
                            [addresses]="clientAddresses"></hx-gis-address>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <div class="display-delivery">
              <div class="btn-group btn-toolbar m-btn-group m--margin-bottom-10" role="group">
                <div [ngbTooltip]="rng.tooltip" *ngFor="let rng of ranges; trackBy: trackByCell">
                  <button (click)="toggleRange(rng)" type="button"
                          [disabled]="rng.isDisabled"
                          [ngClass]="{'btn-success' : rng.id === range?.id, 'btn-default': rng.id !== range?.id}"
                          class="btn btn-xs delivery-range-btn">
                    <div class="display-delivery">
                      <span>{{ rng.fromTime | time }} - {{ rng.toTime | time}}</span>
                      <br>
                      <span *ngIf="rng.isLoading" class="m-loader m-loader--primary m-loader--sm"
                            style="width: 30px;display: inline-block;"></span>
                      <span *ngIf="!rng.isLoading">{{rng.reserved}} / {{rng.limit}}</span>
                    </div>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ng-container>

      <div class="row" *ngIf="cityDeliveryArea.defaultMapType === 'none' || isManualDeliverySelection">
        <div class="col-md-12">
          <div class="display-delivery" *ngFor="let rg of rangeGroups">
            <small>{{rg.store.title | uiLabel}}</small>
            <div class="btn-group btn-toolbar m-btn-group m--margin-bottom-10" role="group">
              <div [ngbTooltip]="rng.tooltip" *ngFor="let rng of rg.ranges; trackBy: trackByCell">
                <button (click)="toggleRange(rng)" type="button"
                        [disabled]="rng.isDisabled"
                        [ngClass]="{'btn-success' : rng.id === range?.id, 'btn-default': rng.id !== range?.id}"
                        class="btn btn-xs delivery-range-btn">
                  <div class="display-delivery">
                    <span>{{ rng.fromTime | time }} - {{ rng.toTime | time}}</span>
                    <br>
                    <span *ngIf="rng.isLoading" class="m-loader m-loader--primary m-loader--sm"
                          style="width: 30px;display: inline-block;"></span>
                    <span *ngIf="!rng.isLoading">{{rng.reserved}} / {{rng.limit}}</span>
                  </div>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row m--margin-top-15 manual-delivery-prices">
        <div class="col-md-12">
          <hx-loader [isLoading]="isLoading.deliveryPrices" [length]="deliveryPrices.length"></hx-loader>
          <button *ngFor="let dp of deliveryPrices"
                  [disabled]="!isManualDeliverySelection"
                  (click)="selectDeliveryPrice(dp)" class="btn btn-sm"
                  [ngClass]="{'btn-outline-info': deliveryPrice?.id !== dp.id, 'btn-info': deliveryPrice?.id === dp.id}">
            {{ dp.value | uiCurrency: currency }}
          </button>
        </div>
      </div>
    </ng-container>

    <ng-container *ngIf="deliveryType === 'PICKUP'">
      <div class="row m--margin-top-10">
        <div class="col-md-12">
          <small>{{ 'ord.order-configurator.chooseShop' | transloco }}:</small>
          <div class="btn-group m-btn-group btn-toolbar" *ngIf="!isLoading.stores && stores.length" role="group">
            <ng-container *ngFor="let store of stores">
              <button *ngIf="store.active" type="button" class="btn btn-secondary hc-store-btn"
                      [ngbPopover]="storeProductRemainderPopContent"
                      (shown)="onStorePopoverShown(store)"
                      [popoverTitle]="'ord.order-configurator.pr.storeRemainders' | transloco"
                      triggers="mouseenter:mouseleave"
                      [ngClass]="{'btn-success': store.id === storeId, 'disabled': (store.missingProducts?.length ?? 0) > 0 || store.isStoreClosed}"
                      (click)="onStoreSelected(store)">
                <span class="m--margin-0">
                  <span class="m-badge m-badge--dot"
                        [ngClass]="{'m-badge--accent': (store.missingProducts?.length ?? 0) === 0, 'm-badge--danger': (store.missingProducts?.length ?? 0) > 0 || store.isStoreClosed}"></span>
                  <i *ngIf="store.mainStore" class="las la-store m--margin-right-5 m--margin-left-5"></i>{{ store.title | uiLabel }}
                </span>
                <small *ngIf="store.workDates && !store.isStoreClosed" class="d-block">{{ store.workDates | storeWorkTime: orderDate }}</small>
                <small *ngIf="store.workDates && store.isStoreClosed" class="d-block text-red">{{ 'ord.store.closed' | transloco }}</small>
              </button>
            </ng-container>
          </div>

          <div class="form-group m-form__group is--highlight m--margin-top-10">
            <span class="m-option__control">
              <a (click)="getNearestStore()" class="link-none-color store-map-link">{{ 'ord.order-configurator.viewNearestStore' | transloco }}</a>

              <a *ngIf="cityDeliveryArea" [href]="cityDeliveryArea.url" target="_blank" class="link-none-color store-map-link">{{ 'ord.order-configurator.store-map' | transloco }}</a>

              <a *ngIf="store && store.mapUrl" [href]="store.mapUrl" target="_blank" class="link-none-color store-map-link">{{ store?.title | uiLabel }} {{ 'ord.order-configurator.onMap' | transloco }} </a>
            </span>
          </div>

          <ng-select [hidden]="!showNearestStore" style="display: inline-block; width: 75%;" #chosenstreet
                     [items]="streets"
                     (change)="onStreetChanged($event)"
                     [multiple]="false"
                     [closeOnSelect]="true"
                     [placeholder]="'hx.gis-address.address' | transloco"
                     [typeToSearchText]="'hx.gis-address.startText' | transloco"
                     [loadingText]="'hx.gis-address.search' | transloco"
                     [notFoundText]="'hx.gis-address.noFind' | transloco"
                     [trackByFn]="trackByFn"
                     [loading]="streetIsLoading"
                     [typeahead]="streetsInput$"
                     [(ngModel)]="gisItem"
                     [searchable]="true"
                     [clearable]="true"
                     dropdownPosition="bottom">
            <ng-template ng-label-tmp let-item="item">
              {{item.addressName}}
              <ng-container *ngIf="item.admDivName">({{item.admDivName}})</ng-container>
            </ng-template>
            <ng-template ng-option-tmp let-item="item">
              <ng-container *ngIf="item.name">{{item.name}}<br/></ng-container>
              <small>
                {{item.addressName}}
                <ng-container *ngIf="item.admDivName">({{item.admDivName}})</ng-container>
              </small>
              <small *ngIf="item.disabled" style="position: absolute;right: 5px;margin-top: 5px;">
                <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M5 0C2.24305 0 0 2.24289 0 5C0 7.7571 2.24289 10 5 10C7.7571 10 10 7.7571 10 5C10 2.24289 7.75695 0 5 0ZM0.915779 5C0.915779 2.74795 2.74795 0.915779 5 0.915779C6.01468 0.915779 6.94359 1.28865 7.65866 1.9036L1.9036 7.65866C1.28865 6.94359 0.915779 6.01468 0.915779 5ZM5 9.08422C4.09216 9.08422 3.25285 8.78613 2.57395 8.28322L8.28307 2.5741C8.78598 3.253 9.08407 4.09231 9.08407 5.00015C9.08422 7.25205 7.25205 9.08422 5 9.08422Z"
                    fill="#FF0000"/>
                </svg>
                {{ 'hx.gis-address.noDelivery' | transloco }}
              </small>
              <small *ngIf="item.hint" style="position: absolute;right: 5px;margin-top: 5px;">
                <i class="la la-info-circle"></i> {{item.hint | transloco}}
              </small>
            </ng-template>
          </ng-select>

          <label [hidden]="!showNearestStore" for="toFavourite" class="m--margin-right-10 m--margin-left-10" style="font-weight: 400;vertical-align: middle;">{{ 'ord.order-configurator.showAddress' | transloco }}:</label>

          <span [hidden]="!showNearestStore" class="m-switch m-switch--icon m-switch--outline m-switch--success">
                  <label>
                    <input type="checkbox" id="showAddress" (click)="switchAddressName()" [(ngModel)]="showAddress">
                    <span></span>
                  </label>
            </span>

          <div id="map" [hidden]="!showNearestStore">
            <hx-loader [length]="1" [isLoading]="isLoading.map"></hx-loader>
          </div>

          <div *ngIf="isLoading.stores" class="btn-group m-btn-group btn-toolbar w-50" role="group">
            <div class="m-page-loader m-page-loader--non-block m-page-loader--relative">
              <div class="m-loader m-loader--brand"></div>
            </div>
          </div>
        </div>
      </div>

      <div *ngIf="orderConfiguration.orderId" class="form-group m-form__group">
        <div class="row">
          <div class="col-md-5">
            <label class="m--font-bold m--margin-top-5">
              <span class="display-delivery">
                {{ 'ord.order-delivery.timePickup' | transloco }}:
              </span>
            </label>
          </div>
        </div>
        <div class="row">
          <div class="display-delivery">
            <div class="col-md-12">
              <span *ngFor="let timeConst of pickupTimes" [ngbTooltip]="timeConst.tooltip">
                <button class="time" [disabled]="timeConst.isDisabled"
                        [ngClass]="{'time--enabled': !timeConst.isDisabled, 'time--disabled': timeConst.isDisabled, 'time--selected': pickupTime === timeConst.time}"
                        (click)="onPickupTimeSelected(timeConst)">
                  <span *ngIf="timeConst.isLoading" class="m-loader m-loader--primary m-loader--sm"
                        style="width: 30px;display: inline-block;"></span>

                  <ng-container *ngIf="!timeConst.isLoading">
                    <span class="time__title">
                      {{ timeConst.time }}
                    </span>

                    <span class="time__footer">
                      <span class="time__numbers">
                        <span class="time__numb" [ngClass]="{'time__numb--selected': pickupTime === timeConst.time}">
                          <span *ngIf="!timeConst.isLoading">{{ timeConst.orderCount }}</span>
                        </span>
                      </span>
                    </span>
                  </ng-container>
                </button>
              </span>
            </div>
          </div>
        </div>
      </div>
    </ng-container>
  </ng-container>

  <ng-container *ngIf="storeId">
    <hr>

    <div class="m-widget1 m--padding-0" *ngIf="!isLoading.discounts">
      <div class="m_datatable m-datatable m-datatable--default m-datatable--loaded" style="overflow-x: auto;">
        <h6>{{ 'ord.order-cart.table.body.discount' | transloco }}:</h6>
        <table class="m-datatable__table">
          <tbody class="m-datatable__body">
            <ng-container *ngFor="let discount of discounts; let i = index">
              <tr *ngIf="!discount.removed" class="m-datatable__row" [ngClass]="{'is--disabled': !discount.enabled }">
                <td class="m-datatable__cell" colspan="4">
                  {{ discount.promoTitle | uiLabel }}
                  <span *ngIf="discount.priceId">- {{ discount.productId }}</span>
                  ({{ discount.productAmount }})
                </td>
                <td class="m-datatable__cell text-center m--font-success">
                  <!-- TODO @deedarb #ins other result types ? -->
                  <span *ngIf="discount.resultType === 'PERCENT_DISCOUNT'">{{ discount.promoValue }} %</span>
                  <span *ngIf="discount.resultType === 'MONEY_DISCOUNT'">{{ discount.value | uiCurrency: currency }}</span>
                </td>
                <td class="m-datatable__cell">
                  <a (click)="toggleDiscount(discount)"
                     [ngClass]="{'m--disabled-discount': !discount.enabled, 'm--enabled-discount': discount.enabled}"
                     [ngbTooltip]="(discount.enabled ? 'ord.order-cart.ng.offDiscount' : 'ord.order-cart.ng.onDiscount') | transloco">
                    <i class="la" [ngClass]="{'la-check-circle-o': discount.enabled, 'la-circle' : !discount.enabled}"></i>
                  </a>
                </td>
              </tr>
            </ng-container>
            <tr *ngIf="discounts.length === 0" class="m-datatable__row">
              <td class="m-datatable__cell" colspan="6">
                <h6>{{ 'ord.order-cart.table.body.emptyDiscounts' | transloco }}:</h6>
              </td>
            </tr>
          </tbody>
          <tfoot class="m-datatable__foot">
            <tr class="m-datatable__row">
              <td class="m-datatable__cell" colspan="5">
                <h6>{{ 'ord.order-configurator.orderTotal' | transloco }}:</h6>
              </td>
              <td class="m-datatable__cell">
                <span style="text-decoration: line-through;" *ngIf="orderSubtotal !== orderTotal">{{orderSubtotal}}</span>
                {{orderTotal}}
              </td>
            </tr>
          </tfoot>
        </table>

        <div class="alert alert-danger m--margin-top-10"
             *ngIf="isEditing && (storeId !== orderConfiguration.storeId || (orderTotal !== orderConfiguration.orderTotal && orderConfiguration.paid))">
          {{(orderConfiguration.paid ? 'ord.order-configurator.paidTransferOrderAlert' : 'ord.order-configurator.transferOrderAlert') | transloco}}
        </div>
      </div>
    </div>
  </ng-container>

  <div *ngIf="storeId" class="row m--margin-top-10">
    <div class="col-md-3"></div>

    <div class="col-md-9">
      <button *ngIf="!orderConfiguration.orderId"
              [disabled]="isLoading.save"
              [ngClass]="{'m-loader m-loader--light m-loader--primary m-loader--left': isLoading.save}"
              class="btn btn-success" type="button"
              (click)="createNewOrder()">{{ 'ord.order-configurator.btn.newOrder' | transloco }}
      </button>
      <button *ngIf="orderConfiguration.orderId"
              [disabled]="isLoading.changeOrder || isLoading.products || isLoading.discounts"
              [ngClass]="{'m-loader m-loader--light m-loader--primary m-loader--left': isLoading.changeOrder}"
              class="btn btn-success" type="button"
              (click)="changeOrder()">{{ 'ord.order-configurator.btn.basket' | transloco }}
      </button>
    </div>
  </div>
</ng-container>


<ng-template #storeProductRemainderPopContent>
  <div [innerHTML]="storePopContentHtml"></div>
</ng-template>

<ng-template #productRemainderPopContent>
  <div [innerHTML]="productPopContentHtml"></div>
</ng-template>
