import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { concat, of, Subject } from 'rxjs';
import { ClientBasicModel, ClientModel, HxClientService } from 'hx-services';
import { catchError, debounceTime, distinctUntilChanged, filter, switchMap, tap } from 'rxjs/operators';

/**
 * Component search user by phone or name
 * use any format of numbers or text
 */
@Component({
  selector: 'hx-client-search',
  templateUrl: './client-search.component.html',
  styleUrls: ['./client-search.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class HxClientSearchComponent implements OnInit {
  @Input() clientId?: number;
  @Output() clientChange = new EventEmitter<ClientBasicModel>();

  constructor(
    private clientService: HxClientService,
  ) {
  }

  items: ClientModel[] = [];
  itemLoading = false;
  itemInput$ = new Subject<string>();

  ngOnInit() {
    this.loadItems();
  }

  trackByFn(item: ClientBasicModel) {
    return item.id;
  }

  private loadItems() {
    const initItems = (clients: ClientModel[]) => {
      concat(
        of(clients), // default items
        this.itemInput$.pipe(
          debounceTime(500),
          distinctUntilChanged(),
          filter((term?: string) => {
            const isOk = (term ?? '').trim() !== '';
            this.itemLoading = isOk;
            return isOk;
          }),
          switchMap((term: string | undefined) => this.clientService.searchClient(term ?? '').pipe(
            catchError(() => of([])), // empty list on error
            tap(() => this.itemLoading = false)
          ))
        )
      ).subscribe(items => this.items = items);
    };
    if (this.clientId) {
      this.clientService.getClientById(this.clientId).then(c => initItems([c.client]));
    } else {
      initItems([]);
    }
  }

  onClientChanged(client?: ClientBasicModel) {
    if (client) {
      this.clientChange.emit(client);
    } else {
      this.clientChange.emit(undefined);
    }
  }
}
