import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { AuthorityModel, CompanyModel, FileModel, Folder, HxClientService, HxFileService, HxOrderService, isoDate, SysFilename, TableRef } from 'hx-services';
import { FormBuilder, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, ValidatorFn, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { TranslocoService } from '@ngneat/transloco';
import { debounceTime, distinctUntilChanged, finalize, map } from 'rxjs/operators';
import { Observable, OperatorFunction } from 'rxjs';
// @ts-ignore
import { saveAs } from 'file-saver';
import { HxErrorHandlerService } from '../../services/error-handler.service';

interface FormElement {
  control: UntypedFormControl;
  required?: boolean;
  validators?: ValidatorFn[];
}

@Component({
  selector: 'hx-company-info',
  templateUrl: './company-info.component.html',
  styleUrls: ['./company-info.component.css']
})
export class HxCompanyInfoComponent implements OnInit, OnChanges {
  @Input() orderId!: number;
  @Input() clientId?: number;
  @Input() files?: FileModel[];
  @Output() companyUpdate = new EventEmitter<{ files: FileModel[], deletedFiles: FileModel[] }>();

  hcOrdersRef = TableRef.hc_orders;
  folderLetterOfAuthority = Folder.LETTER_OF_AUTHORITY;
  letterOfAuthority = SysFilename.letterOfAuthority;
  companyForm!: UntypedFormGroup;
  authorityForm!: UntypedFormGroup;
  infoExists = false;
  authorityExists = false;
  isDisabled = false;
  isLoading = {
    file: false,
    save: false,
  };
  companies: CompanyModel[] = [];
  filteredOptions: string[] = [];

  fileLetterList: FileModel[] = [];
  ngCompanyForm!: {
    uin: FormElement,
    title: FormElement,
    address: FormElement,
    contract: FormElement,
  };
  ngAuthorityForm!: {
    representativeFullname: FormElement,
    letterOfAuthorityDate: FormElement,
    letterOfAuthorityNumber: FormElement,
    letterOfAuthorityFileId: FormElement,
  };

  private deletedLetterFileList: FileModel[] = [];

  constructor(
    private clientService: HxClientService,
    private orderService: HxOrderService,
    private fb: UntypedFormBuilder,
    private toastr: ToastrService,
    private tr: TranslocoService,
    private fileService: HxFileService,
    private errorService: HxErrorHandlerService,
  ) {
  }

  search: OperatorFunction<string, readonly string[]> = (text$: Observable<string>) => text$.pipe(
    debounceTime(200),
    distinctUntilChanged(),
    map(term => term.length < 2 ? []
      : this.filteredOptions.filter(v => v.toLowerCase().indexOf(term.toLowerCase()) > -1).slice(0, 10))
  );

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['clientId'] && this.clientId) {
      this.loadCompanies();
    }
  }

  ngOnInit(): void {
    this.ngAuthorityForm = {
      representativeFullname: {control: this.fb.control(null), required: true},
      letterOfAuthorityDate: {control: this.fb.control(null), required: true},
      letterOfAuthorityNumber: {control: this.fb.control(null), required: true},
      letterOfAuthorityFileId: {control: this.fb.control(null), required: true},
    };
    this.ngCompanyForm = {
      uin: {control: this.fb.control(null), required: true},
      title: {control: this.fb.control(null), required: true},
      address: {control: this.fb.control(null), required: true},
      contract: {control: this.fb.control(null)},
    };
    this.fileLetterList = this.files ? this.files : [];
    const buildFormEntriesFn = (form: { [key: string]: FormElement }) => {
      const formObj = Object.entries(form).map(([key, fe]) => {
        const validators = fe.validators ?? [];
        if (fe.required) {
          validators.push(Validators.required);
        }
        fe.control.setValidators(validators);
        if (this.isDisabled) {
          fe.control.disable();
        }
        fe.control.updateValueAndValidity();
        return [key, fe.control];
      });
      // @ts-ignore
      return Object.fromEntries(formObj);
    };

    this.companyForm = this.fb.group(buildFormEntriesFn(this.ngCompanyForm));
    this.authorityForm = this.fb.group(buildFormEntriesFn(this.ngAuthorityForm));

    this.orderService.getCompanyInfo(this.orderId).subscribe(res => {
      if (res.company?.uin) {
        this.companyForm.patchValue(res.company);
        this.authorityForm.patchValue(res.authority);
        if (res.authority && res.authority.letterOfAuthorityFileId) {
          this.fileService.getFileById(res.authority.letterOfAuthorityFileId).subscribe(result => {
            this.fileLetterList = [result];
          });
        }
        this.infoExists = true;
        this.authorityExists = res.authority.letterOfAuthorityFileId !== undefined;
      }
    });
  }

  saveCompany() {
    const form = this.companyForm;
    // eslint-disable-next-line guard-for-in
    for (const i in form.controls) {
      form.controls[i].markAsDirty();
      form.controls[i].updateValueAndValidity();
    }
    if (form.valid) {
      this.isLoading.save = true;
      // raw.letterOfAuthorityDate = isoDate(raw.letterOfAuthorityDate);
      const req: CompanyModel = form.getRawValue();
      this.orderService.saveCompanyInfo(this.orderId, req, {clientId: this.clientId})
        .pipe(finalize(() => this.isLoading.save = false))
        .subscribe(() => {
          this.toastr.success(this.tr.translate('nt.saved'));
          form.markAsPristine();
          this.infoExists = true;
        }, err => {
          console.log('err', err);
          this.errorService.check(err.error);
        });
    } else {
      this.toastr.error(this.tr.translate('error.companyInfo.required'));
    }
  }

  saveAuthority() {
    const form = this.authorityForm;
// eslint-disable-next-line guard-for-in
    for (const i in form.controls) {
      form.controls[i].markAsDirty();
      form.controls[i].updateValueAndValidity();
    }
    if (form.valid) {
      this.isLoading.save = true;
      const raw = form.getRawValue();
      raw.letterOfAuthorityDate = isoDate(raw.letterOfAuthorityDate);
      const req: AuthorityModel = raw;
      this.orderService.saveAuthorityInfo(this.orderId, req)
        .pipe(finalize(() => this.isLoading.save = false))
        .subscribe(() => {
          this.toastr.success(this.tr.translate('nt.saved'));
          form.markAsPristine();
          this.authorityExists = true;
        });
    } else {
      this.toastr.error(this.tr.translate('error.authorityInfo.required'));
    }
  }

  onLetterFileUploaded(event: FileModel) {
    this.ngAuthorityForm.letterOfAuthorityFileId.control.patchValue(event.id);
    this.fileLetterList.push(event);
    this.companyUpdate.emit({files: this.fileLetterList, deletedFiles: this.deletedLetterFileList});
  }

  onLetterFileRemoved(event: { file: FileModel, index: number }) {
    this.deleteLetterFileList(event.file, event.index);
  }

  deleteLetterFileList(file: FileModel, index: number) {
    this.fileLetterList.splice(index, 1);
    this.deletedLetterFileList.push(file);
  }

  private loadCompanies() {
    this.clientService.getClientCompanies({clientId: this.clientId}).subscribe(companies => {
      this.companies = companies;
      this.filteredOptions = companies.map(cmp => cmp.title);
      this.ngCompanyForm.title.control.valueChanges
        .pipe(
          // startWith(''),
          map(value => this._filter(value))
        ).subscribe(res => this.filteredOptions = res);
    });
  }

  private _filter(value: string): string[] {
    if (!value) {
      return this.companies.map(cmp => cmp.title);
    }
    const filterValue = value.toLowerCase();
    return this.companies.map(cmp => cmp.title).filter(title => title.toLowerCase().includes(filterValue));
  }
}
