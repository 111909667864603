import { Pipe, PipeTransform } from '@angular/core';
import { getISODay, parseISO } from 'date-fns';
import { StoreWorkTimeModel, WorkTimeType } from '../vn-api';

/**
 * Pipe Currency - show store work time
 */
@Pipe({
  name: 'storeWorkTime'
})
export class HxStoreWorkTimePipe implements PipeTransform {

  transform(workDates?: StoreWorkTimeModel[], date?: string): string {
    if (workDates === undefined || workDates === null || date === null || date === undefined) {
      return '';
    }
    const dayOfWeek: number = getISODay(parseISO(date));

    let workDate = workDates.find(el => el.dayOfWeek === dayOfWeek && el.date === date && el.type === WorkTimeType.CUSTOM);
    if (workDate) {
      return `${this.formatTime(workDate.openTime)} - ${this.formatTime(workDate.closeTime)}`;
    } else {
      workDate = workDates.find(el => el.dayOfWeek === dayOfWeek && el.type === WorkTimeType.DEFAULT);
      if (workDate) {
        return `${this.formatTime(workDate.openTime)} - ${this.formatTime(workDate.closeTime)}`;
      }
    }
    return '';
  }

  private formatTime(value?: string): string {
    if (value === undefined || value === null) {
      return '';
    }
    const splitArr = value.split(':');
    if (splitArr.length === 3 && splitArr[2] === '00') {
      return splitArr[0] + ':' + splitArr[1];
    }
    return value;
  }

}
