import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { firstValueFrom, Observable } from 'rxjs';
import {
  CallStatusInHourModel,
  CallStatusModel,
  DateRange,
  FeedbackTypeRankingModel,
  OperatorActivityModel,
  OperatorKpiModel,
  OperatorOrderStatisticModel,
  OperatorRankingModel,
  toHttpParams
} from 'hx-services';

@Injectable({
  providedIn: 'root'
})
export class ReportService {

  constructor(
    private http: HttpClient,
  ) {
  }

  getCallReport(params: { date?: DateRange }): Observable<OperatorKpiModel[]> {
    return this.http.get<OperatorKpiModel[]>(`/api/vanilla/reports/calls/operator-kpi`, {params: toHttpParams(params, true)});
  }

  getCallStatusReport(params: { date?: DateRange }): Observable<CallStatusModel[]> {
    return this.http.get<CallStatusModel[]>(`/api/vanilla/reports/calls/status-statistics`, {params: toHttpParams(params, true)});
  }

  getCallInHour(params: {date: string, interval: number }): Observable<CallStatusInHourModel[]> {
    return this.http.get<CallStatusInHourModel[]>(`/api/vanilla/reports/calls/status-statistics/in-hour`, { params: toHttpParams(params, true) });
  }

  getOperatorActivity(date: string): Observable<OperatorActivityModel[]> {
    return this.http.get<OperatorActivityModel[]>(`/api/vanilla/reports/operator-activity?date=${date}`);
  }

  getOperatorActivityDatailed(date: string): Observable<OperatorActivityDetailedReportModel[]> {
    return this.http.get<OperatorActivityDetailedReportModel[]>(`/api/vanilla/reports/operator-activity-detailed?date=${date}`);
  }

  getOperatorOrderStat(params: { date?: DateRange }): Promise<OperatorOrderStatisticModel[]> {
    return firstValueFrom(this.http.get<OperatorOrderStatisticModel[]>(`/api/vanilla/reports/operator-order-statistics`, {params: toHttpParams(params, true)}));
  }

  getOperatorRating(params: { date?: DateRange }): Observable<OperatorRankingModel[]> {
    return this.http.get<OperatorRankingModel[]>(`/api/vanilla/feedbacks/operator/rating`, {params: toHttpParams(params, true)});
  }

  getFeedbackTypeRating(params: { date?: DateRange }): Observable<FeedbackTypeRankingModel[]> {
    return this.http.get<FeedbackTypeRankingModel[]>(`/api/vanilla/feedbacks/type/rating`, {params: toHttpParams(params, true)});
  }
}

export interface OperatorActivityDetailedReportModel {
  lastname: string;
  firstname: string;
  patronymic: string;
  asteriskId: string;
  onlineTime: number;
  offlineTime: number;
  isOnline: boolean;
  lastOnlineTime: number;
}
