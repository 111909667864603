<a (click)="showModal()" class="btn btn-outline-danger m-btn m-btn--icon" title="Отменить заказ">
  <span>
    <i class="la la-remove"></i>
    <span>Отменить</span>
  </span>
</a>

<ng-template #cancelOrder let-modal>
  <div class="modal-header">
    <h3 class="modal-title">Вы действительно хотите отменить заказ?</h3>

    <button class="close" type="button" (click)="modal.dismiss()">
      <span>×</span>
    </button>
  </div>

  <div class="modal-body">
    <label>Причина:</label>

    <textarea class="form-control m-input" [(ngModel)]="cancelReason"></textarea>

    <div class="text-right m--margin-top-15">
      <button type="button" (click)="submitCancel(modal)" class="btn btn-success m--margin-right-10">
        Да, отменить
      </button>

      <button type="button" (click)="modal.dismiss()" class="btn btn-secondary">
        Нет, передумал
      </button>
    </div>
  </div>
</ng-template>
