import { Component, EventEmitter, forwardRef, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { HxStorageInvoiceService, StorageInvoiceModel } from 'hx-services';

@Component({
  selector: 'hx-invoice-select',
  templateUrl: './invoice-select.component.html',
  styleUrls: ['./invoice-select.component.css'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => HxInvoiceSelectComponent),
    multi: true
  }]
})
export class HxInvoiceSelectComponent implements OnChanges, ControlValueAccessor {
  @Input() supplierId!: number;
  @Input() storeId!: number;
  @Input() dueDateFrom?: string;
  @Input() dueDateTo?: string;
  @Input() createDateFrom?: string;
  @Input() createDateTo?: string;
  @Input() paymentApprovalId?: number;
  @Input() isDisabled = false;
  @Input() multiple = false;
  @Output() selectChange = new EventEmitter<StorageInvoiceModel | StorageInvoiceModel[] | undefined>();

  options: StorageInvoiceModel[] = [];
  selected?: number | number[];

  constructor(
    private storageInvoiceService: HxStorageInvoiceService,
  ) {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['supplierId'] || changes['storeId']) {
      this.loadInvoices();
    }
  }

  onModelChanged(val: any) {
    this.onChange(val);
    if (!this.multiple) {
      if (this.selected) {
        this.selectChange.emit(this.options.find(p => p.id === this.selected));
      } else {
        this.selectChange.emit();
      }
    } else {
      if (Array.isArray(this.selected)) {
        const ids: number[] = this.selected;
        this.selectChange.emit(this.options.filter(p => ids.includes(p.id)));
      } else {
        this.selectChange.emit([]);
      }
    }
  }

  onChange(val: any) {
    // console.log('onChange called', val);
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
  }

  setDisabledState(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }

  writeValue(obj?: number | number[]): void {
    if (obj) {
      if (!Array.isArray(obj)) {
        this.storageInvoiceService.getStorageInvoiceById(obj, {paymentApprovalId: this.paymentApprovalId}).subscribe(result => {
          this.selectChange.emit(result);
        });
      }
    }
    this.selected = obj;
  }

  private loadInvoices() {
    this.storageInvoiceService.getStorageInvoiceList({limit: 1000, storeIds: [this.storeId],
      supplierId: this.supplierId, createDate: { from: this.createDateFrom, to: this.createDateTo },
      dueDate: { from: this.dueDateFrom, to: this.dueDateTo }, paymentApprovalId: this.paymentApprovalId}).subscribe(list => {
      this.options = list.list;
    });
  }
}
