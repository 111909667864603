import { Injectable } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { CommonError, ErrorMessage, ErrorOrder, ErrorResponse } from 'hx-services';
import { HxToastrService } from 'hx-component';

@Injectable({
  providedIn: 'root'
})
export class ErrorHandlerService {

  constructor(
    private toastr: HxToastrService,
    private translate: TranslocoService,
  ) { }

  /**
   * Error code:
   * 106 - validation error
   * 112 - balance error
   * 116 - limit error
   * 117 - balance booked error
   * 133 - нельзя редактировать, кто-то уже взял заказ
   * 134 - нельзя сохранить кто-то редактирует
   */
  check(error: CommonError) {
    if (error && error.code) {
      switch (error.code) {
        case 106:
          this.showToastr(error.uiMessages ?? []);
          break;
        case 112:
        case 117:
          if (error.data?.order) {
            this.showToastrEmptyProduct(error.data.order);
          }
          break;
        case 116:
          if (error.data?.order) {
            this.showToastrEmptyProduct(error.data.order, 'limit');
          }
          break;
        case 133:
        case 134:
          const text = this.translate.translate(error.message);
          if (error.data.event) {
            this.toastr.warning(`${text} ${error.data.event.actorFullName}`);
          } else if (error.data.order) {
            this.toastr.warning(`${text} ${error.data.order.actorFullName}`);
          }
          break;
        default:
          this.toastr.error(error.message);
          break;
      }
    }
  }

  handler(error: ErrorResponse) {
    switch (error.message) {
      case 'requiredFields':
        this.showToastrField(error.data.fields);
        break;
      case 'cb.store.balanceEmpty':
        this.showToastrEmptyProduct(error.data);
        break;
      default:
        this.toastr.error(error.message, error.data);
        break;
    }
  }

  private showToastrField(msg: any[]) {
    for (let index = 0; index < msg.length; index++) {
      const element = msg[index];
      const data = { value: this.getTrans('hc.' + element.field) };
      this.toastr.error(element.message, data);
    }
  }

  private getTrans(code: string) {
    return this.translate.translate(code);
  }

  private showToastr(msg: ErrorMessage[]) {
    if (!msg.length) {
      return;
    }

    msg.forEach(el => {
      this.toastr.error(`${el.field}.${el.message}`);
    });
  }

  private showToastrEmptyProduct(msg: ErrorOrder, status?: string) {
    if (msg.product && msg.product.length) {
      let list = '';

      msg.product.forEach((element, index) => {
        list += index > 0 ? `, ${element}` : `${element}`;
      });

      let message = `В ${msg.store} пополните баланс следующими продуктами: ${list}`;

      if (status && status === 'limit') {
        message = `Превышен лимит по продуктам: ${list}`;
      }

      this.toastr.info(message);
    }
  }

}
