import { Component, Input, OnInit } from '@angular/core';
import { CommentModel, HxOrderService } from 'hx-services';
import { ActivatedRoute } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { HxModalCommentAddComponent } from '../modal/comment-add.modal';

@Component({
  selector: 'hx-comment-list',
  templateUrl: './comment-list.component.html',
  styleUrls: ['./comment-list.component.css']
})
export class HxCommentListComponent implements OnInit {
  @Input({required: true}) orderId!: number;
  @Input() showAddBtn?: boolean = true;

  list: CommentModel[] = [];
  filteredList: CommentModel[] = [];
  shownList: CommentModel[] = [];
  isLoading = {
    list: false
  };
  isCompact = false;

  constructor(
    protected aRoute: ActivatedRoute,
    private orderService: HxOrderService,
    private modal: NgbModal,
  ) {

  }
  ngOnInit() {
    this.loadList();
  }
  previous() {
    this.isCompact = false;
    this.shownList = this.list;
  }

  showModal() {
    const modalRef = this.modal.open(HxModalCommentAddComponent);
    modalRef.componentInstance.orderId = this.orderId;
    modalRef.result.then(() => this.loadList());
  }
  private async loadList() {
    this.list = [];
    this.filteredList = [];
    this.shownList = [];
    if (this.orderId) {
      this.isLoading.list = true;
      try {
        this.list = await this.orderService.getCommentsByOrderId(this.orderId);
        this.filteredList = [...this.list];
        if (this.list.length > 3) {
          this.filteredList = this.filteredList.splice(this.filteredList.length - 3, 3);
          this.isCompact = true;
          this.shownList = this.filteredList;
        } else {
          this.isCompact = false;
          this.shownList = this.list;
        }

      } finally {
        this.isLoading.list = false;
      }
    }
  }
}
