import { Component, Input, ViewEncapsulation } from '@angular/core';
import { HxErrorHandlerService } from '../../services/error-handler.service';
import { HxCallcenterService } from 'hx-services';

@Component({
  selector: 'hx-call-audio',
  templateUrl: './call-audio.component.html',
  encapsulation: ViewEncapsulation.None
})
export class HxCallAudioComponent {

  @Input() callId?: string;

  callUrl?: string;

  constructor(
    private callcenterService: HxCallcenterService,
    private errorService: HxErrorHandlerService
  ) {
  }

  play() {
    if (this.callId) {
      this.callcenterService.getCallUrlById(this.callId).subscribe(data => {
        this.callUrl = data.url;
      }, err => this.errorService.check(err.error));
    }
  }

}
