import { Component, EventEmitter, Input, Output, TemplateRef, ViewChild } from '@angular/core';
import { FileModel } from 'hx-services';
import { DomSanitizer } from '@angular/platform-browser';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

/**
 * Component list of files with preview
 */
@Component({
  selector: 'hx-file-list',
  templateUrl: './file-list.component.html',
  styleUrls: ['./file-list.component.css'],
})
export class HxFileListComponent {
  @ViewChild('templateRef') public templateRef!: TemplateRef<any>;
  @Input() fileList: FileModel[] = [];
  @Output() fileRemove = new EventEmitter<{file: FileModel, index: number}>();

  image = {
    name: '',
    path: '',
    mimeType: ''
  };
  baseLink = `/api/vanilla/files/download/`;

  constructor(
    private domSanitizer: DomSanitizer,
    private modal: NgbModal
  ) { }

  deleteFile(file: FileModel, ix: number) {
    this.fileRemove.emit({
      file: file,
      index: ix
    });
  }

  showImage(file: FileModel): void {
    this.image.name = file.fileName;
    this.image.path = this.baseLink + file.guid;
    this.image.mimeType = file.mimeType;
    if (file.mimeType === 'application/pdf') {
      // @ts-ignore
      this.image.path = this.domSanitizer.bypassSecurityTrustResourceUrl(this.image.path);
    }

    this.modal.open(this.templateRef, {size: 'lg'}).result
        .then(() => this.resetImage())
        .catch(() => this.resetImage());
  }

  closeModal(modal: any) {
    modal.dismiss();
  }

  private resetImage() {
    this.image.name = '';
    this.image.path = '';
  }
}
