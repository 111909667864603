import { Component, EventEmitter, Inject, Input, OnChanges, OnDestroy, OnInit, Optional, Output, SimpleChanges } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import {
  AddressModel,
  CartChangeEvent,
  CartProduct,
  ClientFullModel,
  ClientShortModel,
  ComponentType,
  Currency,
  datelc,
  DeliveryRangeModel,
  DeliveryType,
  DictionaryEntryModel,
  Entity,
  FileModel,
  Folder,
  getPaymentValue,
  HX_COMPONENT_NAME,
  HxAuthService,
  HxCallcenterService,
  HxDatePropertyService,
  HxDeliveryService,
  HxFileService,
  HxOrderService,
  HxRejectionService,
  HxStoreService,
  InscriptionModel,
  isoDate,
  Order,
  OrderResponse,
  PaymentType,
  ProductAddedEvent,
  ProductStatus,
  RejectionFullModel,
  SourceSystem,
  StoreFullModel,
  TableRef,
  UiError,
  uiLabel,
  UpdateOrderRequest,
  UpdateOrderResponse
} from 'hx-services';
import { Subject } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';
import { HxAddCallModalComponent } from './modal/add-call.component';
import { HxConfirmModalComponent, HxErrorHandlerService, HxModalData, HxToastrService } from 'hx-component';
import { TranslocoService } from '@ngneat/transloco';
import { format } from 'date-fns';
import { OrderConfiguration } from '../model/interface';
import Timeout = NodeJS.Timeout;

@Component({
  selector: 'hx-order-new',
  templateUrl: './order-new.component.html',
  styleUrls: ['./order-new.component.css']
})
export class HxOrderNewComponent implements OnInit, OnDestroy, OnChanges {
  lang!: string;
  private _orderId: number | undefined;
  @Input()
  set orderId(val) {
    this._orderId = val;
    if (val) {
      this.loadOrder().then(() => setTimeout(() => {
        this.activeNav = 'cart';
      }));
    } else {
      this.resetOrder();
    }
  }

  get orderId() {
    return this._orderId;
  }

  private _phone: string | undefined;
  @Input()
  set phone(val) {
    this._phone = val;
    this.clientPhone = val;
  }

  get phone() {
    return this._phone;
  }

  @Input() callId?: string;

  @Output() orderUpdate = new EventEmitter<UpdateOrderResponse>();
  @Output() clientPhoneUpdate = new EventEmitter<string>();
  @Output() orderCreate = new EventEmitter<number>();
  @Output() orderTransfer = new EventEmitter<number>();

  orderUniqueNumber?: string;
  order: Partial<Order> = {
    deliveryType: DeliveryType.PICKUP,
  };

  whatsapp?: string;
  fileList: FileModel[] = [];
  fileLetterList: FileModel[] = [];
  client?: ClientFullModel;
  isLoading = {
    client: false,
    order: false,
    city: false,
    orderText: 'hx.loading.create.order',
    remainderDiscount: false,
    decorEnabled: false,
    rejectSaving: false,
  };
  fromList: string[] = [];
  today = Date.now();
  folderDecor = Folder.DECOR;
  timezone?: string;
  currency?: Currency;
  countDown?: number;
  clientPhone?: string;
  activeNav?: string;
  status = 'NONE';
  store?: StoreFullModel;
  entityTypes = Object.keys(Entity);
  orderAddress?: AddressModel;
  viewTotal?: number;
  errorMessage?: string;
  rejectionInfo?: RejectionFullModel;
  hcOrdersRef = TableRef.hc_orders;
  showLegal = false;
  referrers: DictionaryEntryModel[] = [];
  otherReferrers: DictionaryEntryModel[] = [];
  otherReferrersVisible = false;
  changeRecipient = false;
  orderConf: OrderConfiguration = {};
  textDecorWarning?: string;
  inscription: InscriptionModel = {enabled: false};
  comment?: string;
  private viewAddress?: AddressModel;
  private paidTotal = 0;
  private recipientClient?: ClientShortModel;
  private fromSet = new Set<string>(['WHATSAPP', 'INSTAGRAM']);
  private deletedFileList: FileModel[] = [];
  private interval?: Timeout;
  private selectedRange?: DeliveryRangeModel;
  private defaultSourceSystem!: SourceSystem;
  private $destroyed = new Subject<boolean>();
  private $whatsAppMessage = new Subject<void>();

  constructor(
    private toastr: HxToastrService,
    private modal: NgbModal,
    private fileService: HxFileService,
    private callService: HxCallcenterService,
    private errorHandler: HxErrorHandlerService,
    private datePropertyService: HxDatePropertyService,
    private orderService: HxOrderService,
    private storeService: HxStoreService,
    private deliveryService: HxDeliveryService,
    private rejectionService: HxRejectionService,
    private tr: TranslocoService,
    private auth: HxAuthService,
    @Optional() @Inject(HX_COMPONENT_NAME) private componentName: string,
  ) {
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.fillOrderConfiguration();
  }

  ngOnInit() {
    this.textDecorWarning = undefined;
    this.lang = this.tr.getActiveLang();
    if (this.componentName === ComponentType.cc) {
      this.defaultSourceSystem = SourceSystem.CALLCENTER;
    } else if (this.componentName === ComponentType.manager) {
      this.defaultSourceSystem = SourceSystem.MANAGER;
    }
    this.fromSet.add(this.defaultSourceSystem);
    this.order.from = this.defaultSourceSystem;
    this.$whatsAppMessage
      .pipe(takeUntil(this.$destroyed), debounceTime(500))
      .subscribe(() => this.updateWhatsAppMsg());
    this.rejectionService.rejectionObs.pipe(takeUntil(this.$destroyed)).subscribe(rejection => {
      if (rejection) {
        this.isLoading.rejectSaving = true;
        this.rejectionService.saveRejection(rejection).subscribe(result => {
          this.rejectionInfo = result;
          this.rejectionService.setRejection(this.rejectionInfo);
          if (rejection && rejection.note) {
            this.toastr.success(this.tr.translate('ord.order-new.ts.successSave'));
          }
          this.isLoading.rejectSaving = false;
        }, err => {
          this.errorHandler.check(err.error);
          this.isLoading.rejectSaving = false;
        });
      } else {
        this.rejectionInfo = undefined;
      }
    });
  }

  ngOnDestroy(): void {
    if (this.interval) {
      clearInterval(this.interval);
    }

    this.modal.dismissAll();
    this.$destroyed.next(true);
    this.$destroyed.complete();
  }

  onFileUploaded(event: FileModel) {
    this.fileList.push(event);
  }

  onFileRemoved(event: { file: FileModel, index: number }) {
    this.deleteFile(event.file, event.index);
  }

  deleteFile(file: FileModel, index: number) {
    this.fileList.splice(index, 1);
    this.deletedFileList.push(file);
  }

  onCartChanged(event: CartChangeEvent) {
    console.debug('[order-new] onCartChanged', event);
    let errorMessage: string | undefined;
    if (this.order.paid && this.paidTotal !== event.total) {
      errorMessage = 'hx.paidOrderChangeForbidden';
    }
    this.errorMessage = errorMessage;
    this.order.debt = event.isDebt;
    this.order.products = event.products;
    this.order.discounts = event.discounts;
    this.order.total = event.total;
    this.order.subTotal = event.subTotal;
    this.changeWhatsAppMsg();
    this.checkCartExpiration();
  }

  onViewCartChanged(event: CartChangeEvent) {
    this.viewTotal = event.total;
    let errorMessage: string | undefined;
    if (this.order.paid && this.viewTotal !== this.order.total) {
      errorMessage = 'hx.paidOrderChangeForbidden';
    }
    this.errorMessage = errorMessage;
  }

  onRangeSelected(range?: DeliveryRangeModel) {
    this.selectedRange = range;
    if (range) {
      this.order.orderTime = range.toTime;
      this.order.deliveryType = DeliveryType.DELIVERY;
    }
    this.order.cartRangeId = range?.id;
    this.orderService.recalcDiscounts();
    this.changeWhatsAppMsg();
    this.checkCartExpiration();
  }

  onRejectionSaved() {
    this.activeNav = 'view';
  }

  changeWhatsAppMsg() {
    this.$whatsAppMessage.next();
  }

   async submitOrder() {
    if (!this.orderId) {
      throw new Error('orderId is undefined');
    }
    this.isLoading.order = true;
    this.isLoading.orderText = 'hx.loading.create.order';

    if (this.client) {
      if (this.order.deliveryType === DeliveryType.PICKUP) {
        if (!this.order.orderTime) {
          this.toastr.error('deliver.time.not.pickuped');
          this.isLoading.order = false;
          return;
        }
      }
      const updateRequest: UpdateOrderRequest = this.getOrderUpdateRequest();

      this.isLoading.orderText = 'hx.loading.order.send';
      try {
        const result = await this.orderService.updateOrder(this.orderId, updateRequest);
        if (result) {
          this.orderUpdate.emit(result);
        }
        this.isLoading.orderText = 'hx.loading.create.finished';
        this.rejectionService.clearRejection();
      } finally {
        this.isLoading.order = false;
      }
    } else {
      this.toastr.error('orders.client.notfound');
      this.isLoading.order = false;
    }
  }

  onClientUpdated(clientModel?: ClientFullModel): void {
    this.client = clientModel;
    this.order.client = clientModel?.client.id;
    const phone = this.client ? this.client.client.phone : undefined;
    this.clientPhoneUpdate.emit(phone);
    this.changeWhatsAppMsg();
  }

  onProductAdded(event: ProductAddedEvent) {
    if (this.order.paid) {
      return this.toastr.warning(this.tr.translate('ord.order-new.ts.addProduct'));
    }
    if (!this.orderId) {
      this.toastr.warning(this.tr.translate('ord.order-new.ts.createOrder'));
    }
    this.changeWhatsAppMsg();
  }

  canCreateOrder(): boolean {
    return this.isLoading.order || ((this.order.subTotal ?? 0) + (this.order.total ?? 0) <= 0);
  }

  isFromSite(): boolean {
    return this.order.from === SourceSystem.ONLINE;
  }

  prolongCartExpiration() {
    if (!this.orderId) {
      throw new Error('orderId is undefined');
    }
    this.orderService.prolongCartExpiration(this.orderId).subscribe(expiration => {
      this.status = expiration.status;
      this.checkCartExpiration();
    });
  }

  cancelCart() {
    const modalInstance = this.modal.open(HxConfirmModalComponent, {size: 'lg'});
    modalInstance.componentInstance.data = {
      message: 'cart.cancel.question',
      confirmMessage: 'cart.continue.yes',
      cancelMessage: 'cart.continue.no'
    } as HxModalData;
    modalInstance.result.then(() => {
      if (!this.orderId) {
        throw new Error('orderId is undefined');
      } else {
        this.orderService.cancelCart(this.orderId).then(() => {
          this.rejectionService.clearRejection();
          this.orderUpdate.emit();
        }, err => {
          console.error('err', err);
        });
      }
    }, reason => {
      console.log('Dismissed reason : ', reason);
    });
  }

  async onPickupTimeSelected(event: { time: string }) {
    this.order.orderTime = event.time;
    this.order.pickupTime = event.time;
    this.order.deliveryType = DeliveryType.PICKUP;
    this.changeWhatsAppMsg();
    this.checkCartExpiration();
  }

  onTabChanged() {
    if (this.activeNav === 'cart') {
      this.viewTotal = undefined;
    } else if (this.activeNav === 'view') {
      this.fillOrderConfiguration();
    }
  }

  onAddressChanged(addressInfo: { deliveryType: DeliveryType, address?: AddressModel }) {
    console.log('[order-new] onAddressChanged', addressInfo);
    this.order.deliveryType = addressInfo.deliveryType;
    const address = addressInfo.address;
    this.order.address = address?.address;
    this.order.admDiv = address?.admDiv;
    this.order.entrance = address?.entrance;
    this.order.floor = address?.floor;
    this.order.flat = address?.flat;
    this.order.deliveryLatitude = address?.latitude;
    this.order.deliveryLongitude = address?.longitude;
    this.order.addressNote = address?.note;
    this.changeWhatsAppMsg();
  }

  reject(opts?: { decor?: boolean, note?: string }) {
    if (opts) {
      this.rejectionService.prepareRejection({note: opts.note, decor: opts.decor});
    }
  }

  toggleLegal() {
    this.showLegal = !this.showLegal;
  }

  onClientTypeChanged() {
    if (this.order.clientType === Entity.INDIVIDUAL) {
      this.showLegal = false;
    }
  }

  onRecipientClientUpdated(client?: ClientFullModel) {
    if (client) {
      this.order.recipientClientId = client.client.id;
      this.order.recipientClientPhone = client.client.phone;
    }
  }

  onRecipientChanged() {
    if (!this.changeRecipient) {
      this.order.recipientClientId = undefined;
      this.order.recipientClientPhone = undefined;
    }
  }

  // works with https or localhost only
  copyText() {
    if (this.order.orderDate) {
      const deliveryMessage = this.order.deliveryType === DeliveryType.DELIVERY ? `доставка из магазина ${this.store?.title?.ru}\r\n` : '';
      const message = `Заказ #${this.orderUniqueNumber} на ${format(new Date(this.order.orderDate), 'dd.MM.yyyy')}\r\n`
        + deliveryMessage
        + this.whatsapp + `\r\n к оплате: ${this.order.total} ${this.currency}`;
      console.log('message', message);
      window.navigator.clipboard.writeText(message).then(() => {
        console.log('Async: Copying to clipboard was successful!');
      }, err => {
        console.error('Async: Could not copy text: ', err);
      });
    }
  }

  setReferrer(item: DictionaryEntryModel) {
    if (this.order.referrer === item.code) {
      this.order.referrer = undefined;
    } else {
      this.order.referrer = item.code;
    }
  }

  toggleMoreReferrers() {
    this.otherReferrersVisible = !this.otherReferrersVisible;
  }

  onOrderCreated(event: { orderId: number }) {
    this.isLoading.orderText = 'hx.loading.save';
    setTimeout(() => this.activeNav = 'cart');
    this.orderCreate.emit(event.orderId);
  }

  onOrderTransferred(event: { orderId: number }) {
    this.resetOrder();
    this.orderId = event.orderId;
    this.orderTransfer.emit(event.orderId);
  }

  deliverySelected() {
    this.comment = `${(this.comment ?? '')} @delivery`;
  }
  private async loadOrder(): Promise<void> {
    console.log('[order-new] load order', this.orderId);
    this.isLoading.order = true;
    const orderId = this.orderId;
    if (!orderId) {
      throw new Error('orderId is undefined');
    }
    const [files, orderResponse, tracking] = await Promise.all([
      this.fileService.getUserTemporaryFiles(orderId, this.hcOrdersRef),
      this.orderService.getFullOrderById(orderId),
      this.orderService.getTracking(orderId),
    ]);
    this.storeService.getReferrersByStoreId(orderResponse.storeId)
      .then(refs => {
        const allReferrers = refs.filter(ref => ref.enabled);
        if (allReferrers.length > 5) {
          this.referrers = allReferrers.slice(0, 5);
          this.otherReferrers = allReferrers.slice(5);
        } else {
          this.referrers = allReferrers;
        }
      });//
    this.store = await this.storeService.getFullStore(orderResponse.storeId);
    this.currency = this.store.currency;
    this.updateData(orderResponse, files);
    this.order.referrer = tracking?.referrer;
    this.checkCartExpiration();
    // console.log('[order-new] loadOrder finished');
    this.isLoading.order = false;
    this.rejectionService.clearRejection();
    this.rejectionService.initRejection({
      storeId: this.store?.id,
      cityId: this.store?.city?.id,
      date: this.order.orderDate,
      orderId: this.orderId
    });
    if (this.store.hasDecoration) {
      // check if decor enabled for date
      const orderDate = isoDate(orderResponse.date);
      if (orderDate) {
        this.isLoading.decorEnabled = true;
        try {
          const decorInfo = await this.datePropertyService.getDecor({date: datelc(orderDate), storeId: orderResponse.storeId});
          this.inscription = decorInfo.inscription;
          if (this.inscription && this.inscription.enabled) {
            const from = (this.inscription.from ? this.inscription.from.substring(0, 5) : undefined);
            const to = (this.inscription.to ? this.inscription.to.substring(0, 5) : undefined);
            this.textDecorWarning = this.tr.translate('ord.order-new.decorWarningText', {from: from, to: to});
          }
        } finally {
          this.isLoading.decorEnabled = false;
        }
      }
    }
    const receiveAfterTime = await this.orderService.getReceiveAfterTime(orderId);
    this.orderService.notifyReceiveAfterTimeChanged(orderId, receiveAfterTime);
  }

  private updateData(order: OrderResponse, temporaryFiles: FileModel[]) {
    if (order) {
      this.orderUniqueNumber = order.uniqueNumber;
      this.order.id = order.id;
      this.order.total = order.total;
      this.order.subTotal = order.subTotal;
      this.order.cityId = order.cityId;
      this.order.storeId = order.storeId;
      this.order.address = order.address ?? this.viewAddress?.address;
      this.order.admDiv = order.admDiv ?? this.viewAddress?.admDiv;
      this.order.entrance = order.entrance ?? this.viewAddress?.entrance;
      this.order.floor = order.floor ?? this.viewAddress?.floor;
      this.order.flat = order.flat ?? this.viewAddress?.flat;
      this.order.deliveryLatitude = order.deliveryLatitude ?? this.viewAddress?.latitude;
      this.order.deliveryLongitude = order.deliveryLongitude ?? this.viewAddress?.longitude;
      this.order.addressNote = order.addressNote ?? this.viewAddress?.note;
      this.order.withoutReceipt = !order.withoutReceipt;
      if (this.order.important === undefined) {
        this.order.important = order.important;
      }
      this.order.deliveryRangeId = order.deliveryRangeId;
      this.order.cartRangeId = order.cartRangeId;
      this.order.note = this.order.note || order.note;
      this.order.decorText = this.order.decorText || order.decorText;
      const files = temporaryFiles.concat(order.files);
      this.fileList = files.filter(r => r.folder === Folder.DECOR) || [];
      this.fileLetterList = files.filter(r => r.folder === Folder.LETTER_OF_AUTHORITY) || [];
      this.order.paid = order.paid;
      this.order.deliveryType = order.deliveryType;
      if (order.from === SourceSystem.MANAGER) {
        this.fromSet.add(SourceSystem.MANAGER);
      }
      if (order.from === SourceSystem.ONLINE) {
        this.fromSet.add(SourceSystem.ONLINE);
      }
      this.order.from = order.from ?? this.defaultSourceSystem;
      this.order.debt = getPaymentValue(order.payment?.payments, PaymentType.DEBT) > 0;
      this.order.clientType = order.clientType;
      this.timezone = this.store?.timezone;
      this.order.orderDate = isoDate(order.date);
      // this.order.pickupTime = order.
      this.order.products = order.products;
      if (!this.order.deliveryType) {
        if (this.order.cartRangeId || this.order.deliveryRangeId) {
          this.order.deliveryType = DeliveryType.DELIVERY;
        }
      }
      this.order.pickupTime = order.pickupTime;
      this.clientPhone = this.phone || order.phone;
      this.recipientClient = order.recipientClient;
      if (order.recipientClient?.id) {
        this.changeRecipient = true;
        this.order.recipientClientPhone = order.recipientClient?.phone;
        this.order.recipientClientId = order.recipientClient?.id;
      } else {
        this.changeRecipient = false;
      }
      const time = format(new Date(order.date), 'HH:mm');
      if (time && Number(time.split(':')[0]) > 0) {
        this.order.orderTime = time;
      }

      if (this.order.address) {
        this.orderAddress = {
          address: this.order.address,
          admDiv: this.order.admDiv,
          entrance: this.order.entrance,
          floor: this.order.floor,
          flat: this.order.flat,
          latitude: this.order.deliveryLatitude,
          longitude: this.order.deliveryLongitude,
          note: this.order.addressNote,
        } as AddressModel;
      }
      console.log('[order-new] orderAddress: ', this.orderAddress);
      if (order.paid) {
        this.paidTotal = order.total;
      }
    }
    this.fromList = [];
    this.fromSet.forEach(val => this.fromList.push(val));
    if (this.componentName === ComponentType.cc) {
      this.getCurrentCallId();
    }
    this.changeWhatsAppMsg();
  }

  private getCurrentCallId() {
    const asteriskId = this.auth.user.asteriskId;

    if (asteriskId && !this.callId) {
      this.callService.getLastCallByAsteriskId(asteriskId).subscribe(call => {
        if (!this.orderId) {
          throw new Error('orderId is undefined');
        }
        if (call && call.phone && !(call.orderIds || []).includes(this.orderId)) {
          const bookPhone = this.recipientClient ? this.recipientClient.phone : undefined;
          if (call.phone === this.order.phone || call.phone === bookPhone) {
            // silently bind order to call
          } else {
            const modalRef = this.modal.open(HxAddCallModalComponent, {size: 'sm'});
            modalRef.componentInstance.orderNumber = this.orderUniqueNumber;
            modalRef.componentInstance.orderId = this.orderId;
            modalRef.componentInstance.callId = call.callId;
            modalRef.componentInstance.clientPhone = call.phone;
            modalRef.result.then(() => {
              if (!this.clientPhone) {
                this.clientPhone = call.phone;
              }
            }, () => {
            });
          }
        }
      });
    }
  }

  private deleteFileFromDb(id: any) {
    this.fileService.deleteFile(id).subscribe(() => this.toastr.warning(`files.removed`));
  }

  private resetOrder(nav?: 'view' | 'cart') {
    // console.log('[order-new] resetOrder called');
    this.whatsapp = '';
    this.order = {
      deliveryType: DeliveryType.PICKUP
    } as Order;
    this.order.from = this.defaultSourceSystem;
    // this.order.products = [];
    this.order.discounts = [];
    this.order.files = [];
    this.fileList = [];
    this.deletedFileList = [];
    this.client = undefined;
    this.isLoading.order = false;
    this.isLoading.orderText = 'hx.loading.create.order';

    if (this.interval) {
      clearInterval(this.interval);
    }
    this.countDown = 0;
    this.status = 'NONE';
    this.modal.dismissAll();
    setTimeout(() => {
      this.activeNav = nav ?? 'view';
      this.fillOrderConfiguration();
    });
  }

  private getOrderUpdateRequest(): UpdateOrderRequest {
    if (!this.orderId) {
      throw new Error('orderId is undefined');
    }
    if (this.client) {
      this.order.client = this.client.client.id;
      this.order.phone = this.client.client.phone.replace(/\D+/g, '') || '';
    }
    this.order.clientType = this.order.clientType ?? Entity.INDIVIDUAL;
    if (!this.order.client) {
      throw new UiError('order.clientId.required');
    }
    if (!this.order.phone) {
      throw new UiError('order.clientPhone.required');
    }
    if (!this.order.storeId) {
      throw new UiError('order.storeId.required');
    }
    if (!this.order.deliveryType) {
      throw new UiError('order.deliveryType.required');
    }
    if (this.order.total === undefined || this.order.total === null) {
      throw new UiError('order.total.required');
    }
    if (this.order.subTotal === undefined || this.order.subTotal === null) {
      throw new UiError('order.subTotal.required');
    }
    if (!this.order.orderDate) {
      throw new UiError('order.orderDate.required');
    }
    if (!this.order.orderTime) {
      throw new UiError('order.orderTime.required');
    }

    this.deletedFileList.forEach(item => this.deleteFileFromDb(item.id));
    this.order.files = this.fileList.map(item => item.id);

    if (this.order.deliveryType === DeliveryType.PICKUP) {
      if (this.order.cartRangeId) {
        this.deliveryService.releaseRange(this.order.cartRangeId, this.orderId).then(() => {
          this.toastr.success('courier.checkout.success');
          this.order.cartRangeId = undefined;
        });
      }
      this.order.deliveryRangeId = undefined;
      this.order.address = undefined;
      this.order.admDiv = undefined;
      this.order.entrance = undefined;
      this.order.floor = undefined;
      this.order.flat = undefined;
      this.order.addressNote = undefined;
    }
    if (this.rejectionInfo) {
      this.order.rejectionId = this.rejectionInfo.id;
    }
    const updateRequest: UpdateOrderRequest = {
      clientId: this.order.client,
      clientType: this.order.clientType,
      clientPhone: this.order.phone,
      deliveryType: this.order.deliveryType,
      storeId: this.order.storeId,
      total: this.order.total,
      subTotal: this.order.subTotal,
      orderDate: this.order.orderDate,
      orderTime: this.order.orderTime,
      discounts: this.order.discounts ?? [],
      products: this.order.products?.map(p => ({
        priceAmount: p.price.amount,
        amount: p.amount,
        productDefinitionId: p.productInfo.id,
      })) ?? [],
      from: this.order.from,
      note: this.order.note,
      decorText: this.order.decorText,
      debt: this.order.debt,
      important: this.order.important,
      files: this.order.files,
      recipientClientPhone: this.order.recipientClientPhone,
      recipientClientId: this.order.recipientClientId,
      rejectionId: this.order.rejectionId,
      referrer: this.order.referrer,
      withoutReceipt: !this.order.withoutReceipt,
      comment: this.comment,
    };

    if (this.order.deliveryType === DeliveryType.DELIVERY) {
      updateRequest.address = this.order.address;
      updateRequest.admDiv = this.order.admDiv;
      updateRequest.entrance = this.order.entrance;
      updateRequest.floor = this.order.floor;
      updateRequest.flat = this.order.flat;
      updateRequest.deliveryLongitude = this.order.deliveryLongitude;
      updateRequest.deliveryLatitude = this.order.deliveryLatitude;
      updateRequest.addressNote = this.order.addressNote;
      updateRequest.cartRangeId = this.order.cartRangeId;
    }

    return updateRequest;
  }

  private checkCartExpiration() {
    if (!this.orderId) {
      throw new Error('orderId is undefined');
    }
    this.orderService.getCartExpiration(this.orderId).subscribe(expiration => {
      this.status = expiration.status;
      if (expiration.status === 'ACTIVE') {
        this.startTimeout(expiration.seconds);
      } else {
        if (this.interval) {
          clearInterval(this.interval);
        }
      }
    });
  }

  private startTimeout(seconds: number) {
    this.countDown = seconds;

    if (this.interval) {
      clearInterval(this.interval);
    }

    this.interval = setInterval(() => {
      if (this.countDown && this.countDown > 0) {
        this.countDown -= 1;
      } else {
        this.orderUpdate.emit();
      }
      if (this.countDown === 60) {
        const modalInstance = this.modal.open(HxConfirmModalComponent, {size: 'lg'});
        modalInstance.componentInstance.data = {
          message: 'cart.continue.question',
          confirmMessage: 'cart.continue.yes',
          cancelMessage: 'cart.continue.no'
        } as HxModalData;
        modalInstance.result.then(() => this.prolongCartExpiration(),
          reason => console.log('Dismissed reason : ', reason));
      }
    }, 1000);
  }

  private updateWhatsAppMsg() {
    // console.log('[order-new] updateWhatsAppMsg', JSON.stringify(this.order));
    const cartProducts: CartProduct[] = [];

    let deliver = '';
    let time = '';
    const clientName = this.client ? this.client.client.fullname : '';
    let phone = this.order.phone || '';

    if (this.client && this.client.client.phone) {
      phone = `+${this.client.client.phone.replace(/\D+/g, '')}` || phone;
    }
    this.whatsapp = clientName ? `${clientName} ${phone}` : `${phone}`;
    if (this.order.products) {
      this.order.products.forEach(item => {
        const cartProduct = cartProducts.find(cp => cp.price.id === item.price.id) || {} as CartProduct;
        if (!cartProducts.includes(cartProduct)) {
          cartProduct.price = item.price;
          cartProduct.productInfo = item.productInfo;
          cartProducts.push(cartProduct);
        }

        if (ProductStatus.RESERVED === item.status) {
          cartProduct.reserved = item;
        } else if (ProductStatus.CART === item.status) {
          cartProduct.cart = item;
        }
      });
    }

    if (cartProducts.length !== 0) {
      cartProducts.forEach(item => {
        const mainProduct = item.reserved || item.cart;
        if (mainProduct && mainProduct.amount > 0) {
          let value = `${mainProduct.weight} гр`;
          if (mainProduct.weight > 500) {
            value = mainProduct.weight / 1000 + ' кг';
          }

          let amount = mainProduct.amount;

          if (item.reserved !== undefined && item.cart !== undefined) {
            amount += item.cart.amount;
          }

          const weight = `${value} -`;
          const priceAmount = item.price.amount !== 1 ? item.price.amount : '';

          this.whatsapp += `\r\n${uiLabel(this.tr.getActiveLang(), item.productInfo.title)} ${priceAmount}`;
          this.whatsapp += ` ${weight} ${amount}`;
          // if (item.cart) {
          //   this.whatsapp += `(${item.cart.amount})`;
          // }
          this.whatsapp += 'шт';
        }
      });
    }

    if (this.order.deliveryType === DeliveryType.PICKUP) {
      if (this.store) {
        const store = uiLabel(this.tr.getActiveLang(), this.store.title);
        deliver = `\r\n${store} `;
      }
      if (this.order.orderTime) {
        const clockTime = this.order.orderTime.replace(/_/g, '');
        time = `в ${clockTime}`;
      }
      deliver += `\r\nСамовывоз: ${time}`;
    } else if (this.order.deliveryType === DeliveryType.DELIVERY) {
      if (this.order.address) {
        deliver = `\r\n${this.order.address}`;
      }
      if (this.order.admDiv) {
        deliver += ` (${this.order.admDiv})`;
      }
      if (this.order.entrance) {
        deliver += ` подъезд ${this.order.entrance}`;
      }
      if (this.order.floor) {
        deliver += ` этаж ${this.order.floor}`;
      }
      if (this.order.flat) {
        deliver += ` кв. ${this.order.flat}`;
      }
      if (this.selectedRange) {
        time = ` в ${this.selectedRange.fromTime.substring(0, 5)} - ${this.selectedRange.toTime.substring(0, 5)}`;
      }
      deliver += `\r\nДоставка`;
    }

    if (this.order.address) {
      deliver += `${time}`;
    }

    this.whatsapp += deliver;

    if (this.order.decorText) {
      this.whatsapp += `\r\nОформление: ${this.order.decorText}`;
    }

    if (this.order.note) {
      this.whatsapp += `\r\nПримечание: ${this.order.note}`;
    }

    this.whatsapp += this.order.paid ? `\r\nОплачено` : '';

    if (this.order.clientType === Entity.LEGAL) {
      this.whatsapp += `\r\nДля юр. лица`;
    }
  }

  private fillOrderConfiguration() {
    this.orderConf = {
      callId: this.callId,
      orderId: this.orderId,
      orderTotal: this.order.total,
      clientId: this.client?.client.id,
      clientPhone: this.clientPhone,
      clientType: this.order.clientType,
      products: this.order.products,
      address: this.orderAddress,
      addresses: this.client?.additionalAddresses ?? [],
      paid: this.order.paid,
      storeId: this.order.storeId,
    };
  }
}
