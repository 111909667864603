import { APP_VERSION } from '@callcenter-env/version';

export const environment = {
  production: true,
  version: APP_VERSION,
  sentryUrl: 'https://fe25314ea5d54de48b4d662b82ab040b@sentry.happycake.co/2',
  keycloakUrl: 'https://auth.happytech.app/auth',
  keycloakRealm: 'happy',
  keycloakClientId: 'cc-frontend',
  googleMapsApiKey: 'AIzaSyDPz9ZKroutn4O7Erujg1_TKGXR3hqsy08'
};
