import { Component, Inject, OnDestroy, OnInit, Optional, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import {
  CityModel,
  ComponentType, extractDefaultStoreId,
  extractNumber,
  extractNumberArr,
  HX_COMPONENT_NAME,
  HxCityService,
  HxRejectionService,
  HxStoreService,
  ProductInfoType,
  RejectionFullModel,
  StoreBasicModel,
  StoreFullModel,
  toRouterQueryParams
} from 'hx-services';
import { HxToastrService } from '../../services/toastr.service';
import { HxErrorHandlerService } from '../../services/error-handler.service';

@Component({
  selector: 'hx-rejection-list.m-grid__item.m-grid__item--fluid.m-wrapper',
  templateUrl: './rejection-list.component.html',
  styleUrls: ['./rejection-list.component.css'],
  encapsulation: ViewEncapsulation.None,
})
export class HxRejectionListComponent implements OnInit, OnDestroy {
  list: RejectionFullModel[] = [];
  pagination = {allItemCount: 0};
  isLoading = {
    list: false,
  };
  selectedItems = [];
  stores: StoreFullModel[] = [];
  cityList: CityModel[] = [];
  isCallcenter = false;
  isManager = false;
  params: {
    cityId?: number;
    storeId?: number;
    productInfoIds?: number[];
    date?: string;
    query?: string;
    deliveryExists?: boolean;
    pickupExists?: boolean;
    decorExists?: boolean;
    promoExists?: boolean;
    page: number;
    limit: number;
  } = {page: 1, limit: 20};
  brandId?: number;
  selectedPITypes: ProductInfoType[] = [ProductInfoType.PRODUCT, ProductInfoType.PROPERTY];
  private timeout?: NodeJS.Timeout;
  private sub!: Subscription;

  constructor(
    private rejectionService: HxRejectionService,
    private storeService: HxStoreService,
    private cityService: HxCityService,
    private aRoute: ActivatedRoute,
    private router: Router,
    private toaster: HxToastrService,
    private errorService: HxErrorHandlerService,
    @Optional() @Inject(HX_COMPONENT_NAME) private componentName: string
  ) {
  }

  ngOnInit() {
    this.isCallcenter = this.componentName === ComponentType.cc;
    this.isManager = this.componentName === ComponentType.manager;

    this.sub = this.aRoute.queryParamMap.subscribe(paramMap => {
      this.params.date = paramMap.get('date') ?? undefined;
      this.params.productInfoIds = extractNumberArr('productInfoIds', paramMap);
      this.params.storeId = extractNumber('storeId', paramMap) ?? extractDefaultStoreId();
      this.params.cityId = extractNumber('cityId', paramMap);
      this.params.query = paramMap.get('query') ?? undefined;
      this.params.page = extractNumber('page', paramMap) ?? 1;
      this.params.limit = extractNumber('limit', paramMap) ?? 20;

      if (!this.cityList.length && this.isCallcenter) {
        this.getDictionary();
      }

      if (this.isCallcenter) {
        this.loadList();
      } else if (this.isManager && this.params.storeId) {
        this.loadList();
      }
    });
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }

  pageChanged(event: number) {
    this.params.page = event;
    this.navigateUrl();
  }

  resetFilter() {
    this.selectedItems = [];
    this.stores = [];
    this.params = {page: 1, limit: 20};
    this.applyFilters();
  }

  async loadStores() {
    if (!this.params.cityId) {
      this.stores = [];
      return;
    }

    try {
      const {list} = await this.storeService.getStoresByCity(this.params.cityId);
      this.stores = list.filter(store => store.active);
    } catch (err: any) {
      this.errorService.check(err.error);
    }

    this.pageChanged(1);
  }

  getDictionary(): void {
    this.cityService.getAllCities().then(cities => {
      this.cityList = cities;
      this.loadStores();
    }, err => {
      this.errorService.check(err.error);
    });
  }

  onStoreChanged(store: StoreBasicModel | StoreBasicModel[] | undefined) {
    if (Array.isArray(store)) {
      return;
    }
    if (!store || this.brandId !== store?.brandId) {
      this.params.productInfoIds = [];
    }
    this.brandId = store?.brandId;
    this.applyFilters();
  }

  applyFilters(delay?: number): void {
    const applyFn = () => {
      if (!this.params.deliveryExists) {
        this.params.deliveryExists = undefined;
      }
      if (!this.params.pickupExists) {
        this.params.pickupExists = undefined;
      }
      if (!this.params.decorExists) {
        this.params.decorExists = undefined;
      }
      if (!this.params.promoExists) {
        this.params.promoExists = undefined;
      }
      this.pageChanged(1);
    };
    if (delay) {
      if (this.timeout) {
        clearTimeout(this.timeout);
      }
      this.timeout = setTimeout(() => applyFn(), delay);
    } else {
      applyFn();
    }
  }

  private navigateUrl() {
    this.router.navigate(['./'], {
      queryParams: toRouterQueryParams(this.params),
      relativeTo: this.aRoute
    });
  }

  private loadList() {
    this.isLoading.list = true;
    this.list = [];
    this.rejectionService.getRejectionList(this.params).subscribe(result => {
      this.list = result.list || [];
      this.pagination.allItemCount = result.count;
      this.isLoading.list = false;
    }, err => {
      this.errorService.check(err.error);
      this.isLoading.list = false;
    });
  }
}
